import { featureMatrix } from "data/featureMatrix";
import { create } from "zustand";
// import { featureMatrix } from '../config/featureMatrix';

const useUserPlanStore = create((set) => ({
  plan: null,
  features: {},
  loading: true,
  error: null,

  // Set the plan manually when fetched elsewhere
  setPlan: (plan) => {
    set(() => ({
      plan,
      features: featureMatrix[plan] || {},
      loading: false,
      error: null,
    }));
  },

  // If you need to handle loading or reset states, you can still have this
  startLoading: () => {
    set({ loading: true, error: null });
  },

  // Optional: Handle error scenarios separately
  setError: (error) => {
    set({ error, loading: false });
  },
}));

export default useUserPlanStore;
