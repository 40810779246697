import BlueButton from "components/Button/BlueButton";
import SelectInput from "components/Inputs/SelectInput";
import Modal from "components/Modal";
import React, { useState } from "react";

const SkipLogicModal = ({
  handleModal,
  selectedQuestion,
  surveyQuestionsArray,
  setSurveyQuestionsArray,
  selectedQuestionIndex,
}) => {
  function getAllQuestions(array) {
    return array.map((survey) => survey.question);
  }

  function convertArrayToObject(array) {
    return [
      { value: "", label: "Select..." },
      ...array.map((element) => ({ value: element, label: element })),
      { value: "MooyiSubmit", label: "Submit Survey" },
    ];
  }

  //Create an object with null values for each element
  const [resultObject, setResultObject] = useState(() => {
    return {
      ...Object.fromEntries(selectedQuestion?.options?.map((option) => [option, ""])),
      ...(selectedQuestion?.skipLogic || {}),
    };
  });

  const handleInputChange = (key, value) => {
    setResultObject((prevState) => ({
      ...prevState,
      [key]: value === "" ? null : value,
    }));
  };

  const saveLogic = () => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedQuestionIndex] = { ...updatedValues[selectedQuestionIndex], skipLogic: resultObject };
      return updatedValues;
    });
    handleModal();
  };

  const handleClearAll = () => {
    setResultObject({
      ...Object.fromEntries(selectedQuestion?.options?.map((option) => [option, ""])),
      ...(selectedQuestion?.skipLogic || {}),
    });
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedQuestionIndex] = { ...updatedValues[selectedQuestionIndex], skipLogic: {} };
      return updatedValues;
    });
  };

  // console.log(
  //   selectedQuestionIndex,
  //   getAllQuestions(surveyQuestionsArray),
  //   surveyQuestionsArray.slice(+selectedQuestionIndex).map((survey) => survey.question),
  // );

  return (
    <Modal title="Skip logic" onClose={handleModal}>
      <div className="w-[550px] px-5">
        <p className="my-4 text-sm text-neutral-600">Add logic to your survey questions</p>

        <hr className=" bg-neutral-300 my-4" />

        <div className="h-auto rounded-lg bg-primary-50 w-[100%] p-4">
          <p className="text-white bg-primary-500 w-[130px] rounded-3xl text-xs font-semibold py-[2px] mb-2 flex items-center justify-center">
            Adding skip logic
          </p>

          <p className="text-xs  text-neutral-600">
            Logic is applied to only questions with options. You cannot skip to an item, which is placed before the
            question that triggers the “jump to” consequence. Questions can only be skipped forwards, but never
            backwards!
          </p>
        </div>

        <div className="my-4 bg-neutral-100 p-4 rounded-md max-h-[250px] overflow-y-scroll">
          <p className="text-sm">
            {+selectedQuestionIndex + 1}. {selectedQuestion.question}
          </p>

          <div className="flex items-center text-xs my-6">
            <p className="w-[50%]">If answer is:</p>

            <p>Then jump to:</p>
          </div>

          {Object.keys(resultObject).map((key, index) => (
            <div key={index} className="flex items-center text-xs my-2 gap-5">
              <p className="w-[47%] bg-slate-50 h-[40px] flex items-center px-4 rounded-md truncate ...">{key}:</p>

              <div className="w-[50%]">
                <SelectInput
                  options={convertArrayToObject(
                    getAllQuestions(surveyQuestionsArray).slice(+selectedQuestionIndex + 1),
                  )}
                  name={`value-${index}`}
                  value={resultObject[key]}
                  onChange={(event) => handleInputChange(key, event.target.value)}
                  css="bg-white text-xs"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-between h-[70px] border-t-[1px] mt-6">
          <p className="text-sm text-error-600 cursor-pointer" onClick={handleClearAll}>
            Clear All
          </p>

          <BlueButton text="Save" type="button" className="w-[120px]" onClick={saveLogic} />
        </div>
      </div>
    </Modal>
  );
};

export default SkipLogicModal;
