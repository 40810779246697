import { useQuery } from "@tanstack/react-query";
import SelectInput from "components/Inputs/SelectInput";
import { getAllSMSId } from "services/senderIdService";
import { useState } from "react";
import { useFormik } from "formik";
import { FiChevronDown } from "react-icons/fi";
import * as Yup from "yup";
import { replacePlaceholders } from "lib/personalizationFunctions";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { useStore } from "store";
import Tooltip from "components/Tooltip";
import Modal from "components/Modal";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";

const EditContentModal = ({ data, setData, handleOpen }) => {
  const { data: sms, isLoading } = useQuery({ queryKey: ["smsSenderIDs"], queryFn: getAllSMSId });
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [personalizationArray, setPersonalizationArray] = useState([]);
  const { smsTemplate } = useStore();

  const initialValues = {
    content: data?.content || "",
    sender_name: data?.sender_name || "",
    campaign_name: data?.campaign_name || "",
    campaignObjective: data?.campaignObjective || "",
    ...smsTemplate,
  };

  const validationSchema = Yup.object({
    content: Yup.string().required("Please fill in this field"),
    sender_name: Yup.string().required("Please fill in this field"),
    campaign_name: Yup.string().required("Please fill in this field"),
    campaignObjective: Yup.string().required("Please fill in this field"),
  });

  const onSubmit = async (values) => {
    setData((prevData) => ({ ...prevData, ...values }));
    handleOpen();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const { handleChange, values, handleSubmit, setFieldValue, errors, touched } = formik;

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const handleDropDown = () => {
    setOpen(!open);
  };

  const options = sms
    ?.filter((filtered) => filtered?.isActive)
    .map((el) => ({
      value: el.name,
      label: el?.name,
    }));

  const handleContent = (e) => {
    setCount(e.target.value.length);
    setFieldValue("content", e.target.value);

    // This function is to return the literals in the text into an array
    setPersonalizationArray(e.target.value?.match(/{{(.*?)}}/g) === null ? [] : e.target.value?.match(/{{(.*?)}}/g));
  };

  const contactArray = [
    { value: "{{firstname}}", label: "First name" },
    { value: "{{lastname}}", label: "Last name" },
    { value: "{{fullname}}", label: "Full name" },
  ];

  const objectives = [
    { label: "Educational", value: "Educational" },
    { label: "Promotional", value: "Promotional" },
    { label: "Reminder", value: "Reminder" },
  ];

  const option1 = [{ value: "", label: "Loading..." }];

  const addToText = (word) => {
    setFieldValue("content", values.content + word);
    setPersonalizationArray([...personalizationArray, word]);
    handleDropDown();
  };

  return (
    <Modal title={<p className="text-lg font-semibold ">Edit SMS </p>} onClose={handleOpen}>
      <div className="flex w-[450px] h-[400px] overflow-y-auto justify-between">
        <form onSubmit={handleSubmit} className="w-full px-5">
          <div>
            <div className="flex space-x-1 items-center mt-[1em]">
              <p className="text-gray-700 text-sm font-medium">SMS Name</p>
            </div>
            <div>
              <PrimaryInput
                name="campaign_name"
                onChange={handleChange}
                value={values.campaign_name}
                placeholder="Enter the name of your message"
                error={getError("campaign_name")}
              />
            </div>
          </div>

          <div className="flex space-x-2 items-center mt-[1em]">
            <p className="text-gray-700 text-sm font-medium">SMS Sender ID</p>
            <Tooltip text="What name should be displayed to the receiver?" />
          </div>
          <SelectInput
            name="sender_name"
            options={
              isLoading
                ? option1
                : [
                    {
                      label: "Select a Sender ID",
                      value: "",
                    },
                    ...options,
                  ]
            }
            disabled={isLoading}
            css="h-[50px] rounded-[8px]"
            value={values.sender_name}
            onChange={handleChange}
            error={getError("sender_name")}
          />

          <div>
            <div className="flex space-x-2 items-center mt-[1em]">
              <p className="text-gray-700 text-sm font-medium">Objectives</p>
              <Tooltip text="What is the aim of the message?" />
            </div>
            <SelectInput
              name="campaignObjective"
              options={
                isLoading
                  ? option1
                  : [
                      {
                        label: "Select objective",
                        value: "",
                      },
                      ...objectives,
                    ]
              }
              disabled={isLoading}
              css="h-[50px] rounded-[8px]"
              value={values.campaignObjective}
              onChange={handleChange}
              error={getError("campaignObjective")}
            />
          </div>

          <div></div>

          <div className="flex space-x-2 items-center mt-[1em]">
            <p className="text-gray-700 text-sm font-medium">Add Personalisation</p>
            <Tooltip text="Personalise this message for each receiver. " />
          </div>

          <div className="relative mt-2">
            <div
              className="h-[45px] w-[100%] border-[1px] rounded-md flex items-center justify-between px-4 cursor-pointer"
              onClick={handleDropDown}
            >
              {personalizationArray?.length === 0 || personalizationArray === null ? (
                <p className="text-s text-grey">No personalisation added</p>
              ) : (
                <div className="flex space-x-2 w-[100%] overflow-x-scroll no-scrollbar">
                  {replacePlaceholders(personalizationArray).map((el, i) => (
                    <p
                      key={i}
                      className="text-ss bg-white border-[1px] border-[#BFCCDA] min-w-[80px]  px-2 rounded-[6px] h-[30px] flex items-center justify-center"
                    >
                      {el}
                    </p>
                  ))}
                </div>
              )}

              <FiChevronDown />
            </div>

            {open && (
              <div className="bg-white rounded-[6px] shadow-md absolute  top-[3.5em] w-[100%] z-10">
                {contactArray.map((el, i) => (
                  <p key={i} className="border-b-[1px] p-2 text-ss cursor-pointer" onClick={() => addToText(el.value)}>
                    {el.label}
                  </p>
                ))}
              </div>
            )}
          </div>

          <div>
            <div className="flex items-center justify-between mt-[1em]">
              <p className="text-gray-700 text-sm font-medium">SMS Text</p>
              <p className="text-s text-[#525252]">{count} /160</p>
            </div>

            <div className="">
              <textarea
                name="content"
                cols="30"
                value={values.content}
                onChange={handleContent}
                placeholder={"Enter a message"}
                rows="10"
                className="border-[1px] rounded-md w-[100%] h-[200px] resize-none p-2 outline-none text-sm placeholder:text-sm"
              />
            </div>

            {count >= 160 ? (
              <p className="text-[#D97706] text-s mt-[1em]">
                You have exceeded the standard text limit of 160 characters. Additional charges will apply.
              </p>
            ) : null}
          </div>
        </form>
      </div>
      <div className="bottom-0 bg-white flex items-center justify-between border-t-[1px] h-[50px] mt-4 pt-4 px-5">
        <GreyButton text="Cancel" className="w-[120px]" onClick={handleOpen} />
        <BlueButton text="Save" className="w-[120px]" onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export default EditContentModal;
