import SelectInput from "components/Inputs/SelectInput";
import React, { useMemo, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";

const NewPagination = ({ data, itemsPerPage, currentPage, setCurrentPage, isFetching, total, setPageCount }) => {
  // const [itemsPerPage, setPageCount] = useState(itemsPerPage);
  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = data?.length;
    let endValue = 0;

    let startValue = (currentPage - 1) * itemsPerPage + 1;
    if (totalValue > currentPage * itemsPerPage) {
      endValue = Math.min(currentPage * itemsPerPage, totalValue);
    } else {
      endValue = totalValue;
    }

    return [startValue, endValue];
  }, [data?.length, currentPage, itemsPerPage]);

  const options = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "30", value: 30 },
  ];

  const handlePrev = () => {
    // if (currentPage > 1) {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    // }
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <div className="flex justify-between items-center w-[100%] py-4">
      <div className="flex items-center space-x-1">
        <Typography.SubText>Preview per page : </Typography.SubText>
        <SelectInput options={options} value={itemsPerPage} onChange={(e) => setPageCount(e.target.value)} />
      </div>

      <div className="flex items-center space-x-4">
        <div
          onClick={pageCountStart === 1 || isFetching ? () => {} : handlePrev}
          className={`hover:border py-1 px-2 ${
            pageCountStart === 1 || isFetching ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <MdKeyboardArrowLeft />
        </div>

        <div className="flex items-center space-x-2 text-sm">
          {isFetching ? (
            <Skeleton className="w-[50px]" />
          ) : (
            <span className="border py-1 px-3 flex items-center justify-center">
              {pageCountStart} - {pageCountEnd}
            </span>
          )}
          <span>of</span> <span className="border py-1 px-3 flex items-center justify-center">{total}</span>
        </div>
        <div
          onClick={pageCountEnd === total || isFetching ? () => {} : handleNext}
          className={`hover:border py-1 px-2 ${
            pageCountEnd === total || isFetching ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <MdKeyboardArrowRight />
        </div>
      </div>
    </div>
  );
};

export default NewPagination;
