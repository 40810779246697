import SelectInput from "components/Inputs/SelectInput";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { useStore } from "store";
import fileIcon from "assets/icons/broadcast/fileIcon.png";
import receiptIcon from "assets/icons/broadcast/receiptIcon.png";
import MediaUpload from "components/Inputs/customFileUpload/MediaUpload";
import Tooltip from "components/Tooltip";
import { GoSync } from "react-icons/go";
import { useMutation } from "@tanstack/react-query";
import { textToSpeech } from "services/campaignService";
import Toast from "config/toast";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";

const EditVoiceModal = ({ data, setData, handleOpen }) => {
  const [duration, setDuration] = useState(null);
  const { voiceTemplate } = useStore();
  const [selectType, setType] = useState(null);
  const [audioData, setAudioData] = useState();
  const [hover, setHover] = useState(null);
  const [voice, setVoice] = useState({
    voiceType: "",
    voiceText: "",
  });

  const handleHover = (id) => {
    setHover(id === hover ? null : id);
  };

  useEffect(() => {
    if (data) {
      setAudioData(data.content);
    }
  }, [data]);

  const handleClearAudio = () => {
    setData({
      ...data,
      content: "",
    });
  };

  const handleSelectType = (id) => setType(selectType !== id ? id : null);

  const initialValues = {
    content: data?.content || "",
    campaign_name: data?.campaign_name || "",
    campaignObjective: data?.campaignObjective || voiceTemplate.category || "",
    ...voiceTemplate,
  };

  const onSubmit = async (values) => {
    setData((prevData) => ({ ...prevData, ...values }));
    handleOpen();
  };

  const { handleChange, values, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    const getDuration = (audioData) => {
      return new Promise((resolve) => {
        const audio = new Audio();
        audio.addEventListener("loadedmetadata", () => {
          resolve(audio.duration);
        });
        audio.src = audioData;
      });
    };

    getDuration(audioData).then((length) => {
      const roundedLength = Math.round(length);
      setDuration(roundedLength);
      setData((prevData) => ({ ...prevData, audioLength: roundedLength }));
    });
  }, [audioData]);

  console.log(duration, typeof duration, "duration");

  useEffect(() => {
    if (audioData) {
      setData((prevData) => ({ ...prevData, ...values, content: audioData?.audio ?? audioData }));
    }
  }, [audioData, setData, values]);

  const objectives = [
    { label: "Educational", value: "Educational" },
    { label: "Promotional", value: "Promotional" },
    { label: "Reminder", value: "Reminder" },
  ];

  const { mutate } = useMutation({
    mutationFn: textToSpeech,
    onSuccess: (data) => {
      Toast.success("Successful conversion to audio");
      console.log(data);
      setData((prevData) => ({ ...prevData, ...values, content: data?.file }));
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const type = [
    { name: "Upload a file", id: 1, icon: fileIcon },
    { name: "Text to speech", id: 2, icon: receiptIcon },
  ];

  const handleChangeText = (e) => {
    setVoice({
      ...voice,
      voiceText: e,
    });
  };

  const handleVoiceSubmit = () => {
    mutate({ txt: voice.voiceText });
  };

  return (
    <Modal title={<p className="text-lg font-semibold ">Edit SMS </p>} onClose={handleOpen}>
      <div className="flex w-[500px] justify-between px-5">
        <form onSubmit={handleSubmit} className="w-full">
          <p className="text-[20px] text-[#0F172A] tracking-[-0.02em] font-semibold">Content</p>
          <p className="text-s font-[450] text-[#737373]">Enter the details of your message</p>
          <div>
            <div className="flex space-x-1 items-center mt-[1em]">
              <p className="text-gray-700 text-sm font-medium">Title</p>
            </div>
            <div>
              <PrimaryInput
                name="campaign_name"
                onChange={handleChange}
                value={values.campaign_name}
                placeholder="Enter the name of your message"
              />
            </div>
          </div>

          <div>
            <div className="flex space-x-2 items-center mt-[1em]">
              <p className="text-gray-700 text-sm font-medium">Objectives</p>
              <Tooltip text="What is the aim of the message?" />
            </div>
            <SelectInput
              name="campaignObjective"
              options={[
                {
                  label: "Select objective",
                  value: "",
                },
                ...objectives,
              ]}
              css="h-[50px] rounded-[8px]"
              value={values.campaignObjective}
              onChange={handleChange}
            />
          </div>

          <div>
            <div className="flex space-x-2 items-center mt-[1em]">
              <p className="text-gray-700 text-sm font-medium">Type</p>
            </div>
            <div className="flex items-center space-x-5 w-full pt-2">
              {type.map((el, i) => (
                <div
                  key={i}
                  className={`border rounded-[8px] cursor-pointer ${
                    selectType === el.id && "border-primary-600 bg-[#EBF1FF]"
                  } flex items-center px-4 py-3 w-full`}
                  onClick={() => handleSelectType(el.id)}
                >
                  <div
                    className={`w-[32px] h-[32px] p-2 ${
                      selectType === el.id ? "bg-white" : "bg-[#EBF1FF]"
                    } rounded-full flex items-center justify-center`}
                  >
                    <img src={el.icon} alt="" className="" />
                  </div>
                  <div>{el.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div>
            {selectType === 1 ? (
              <div>
                <div className="mt-[1.5em]">
                  <p className="text-gray-700 text-sm font-medium">Audio file</p>
                  <p className="text-gray-500 text-sm pt-2">Upload an audio file to continue.</p>
                </div>
                <div className="mt-3">
                  <MediaUpload name="audio" data={audioData} setData={setAudioData} handleClear={handleClearAudio} />
                </div>
              </div>
            ) : selectType === 2 ? (
              <div>
                <div className="">
                  <div>
                    <div className="w-full mt-5">
                      <div className="flex space-x-2 items-center ">
                        <p className="text-m font-[500]">Text</p>
                      </div>
                      <p className="text-[12px] py-1 flex items-center space-x-1">
                        Type in your message and click on the convert icon ({" "}
                        <span>
                          <GoSync />
                        </span>{" "}
                        )process your text
                      </p>
                      <div className="mt-2 border rounded-md overflow-hidden">
                        <textarea
                          name="voice"
                          id="voice"
                          cols="30"
                          rows="3"
                          placeholder="Type in your message"
                          className="w-full mr-4 p-2 outline-none text-s placeholder:text-s resize-none"
                          onChange={(e) => handleChangeText(e.target.value)}
                          value={voice.voiceText}
                        ></textarea>
                        <div className="w-full px-5 py-1 flex justify-end border-t relative">
                          <div
                            className="w-[50px] h-[40px] rounded flex items-center cursor-pointer border justify-center bg-gray-50 hover:bg-primary-100 hover:border-primary-800 hover:text-primary-800"
                            onClick={handleVoiceSubmit}
                            onMouseEnter={() => handleHover(1)}
                            onMouseLeave={() => handleHover(null)}
                          >
                            <GoSync />
                          </div>
                          {hover === 1 ? (
                            <div className="bg-white drop-shadow-sm w-fit px-2 py-1 flex items-center justify-center absolute rounded-md border top-[-10px] text-xs text-primary-800">
                              <p>convert</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </form>
      </div>
      <div className="bottom-0 bg-white flex items-center justify-between border-t-[1px] h-[50px] mt-4 pt-4 px-5">
        <GreyButton text="Cancel" className="w-[120px]" onClick={handleOpen} />
        <BlueButton text="Save" className="w-[120px]" onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export default EditVoiceModal;
