import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import SelectInput from "components/Inputs/SelectInput";
import Modal from "components/Modal";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { addNewUser } from "services/userService";
import * as Yup from "yup";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import { GrAdd } from "react-icons/gr";
import dayjs from "dayjs";
import { IoCloseOutline } from "react-icons/io5";
import { fetchWorkspace } from "services/workspaceService";

const options = [
  { value: "rwx_user", label: "Admin" },
  { value: "rw_user", label: "Editor" },
  { value: "r_user", label: "Viewer" },
];

const validationSchema = Yup.object({
  invitations: Yup.array().of(
    Yup.object({
      email: Yup.string().email("Enter a valid email address").required("Email is required"),
    }),
  ),
});

const InviteModal = ({ toggleModal }) => {
  const { user } = useLoggedInUser();
  const { data: getWorkspace } = useQuery({ queryKey: ["defaultWorkspace"], queryFn: fetchWorkspace });
  const { mutate } = useMutation({
    mutationFn: addNewUser,
    onSuccess: () => {
      Toast.success("User added successfully");
      toggleModal();
      queryClient.invalidateQueries({ queryKey: ["workspaceUsers"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    try {
      const payload = {
        workspaceId: getWorkspace.id,
        invitations: data.invitations,
      };
      mutate(payload);
      trackEvent("invite_team", {
        email: user?.data?.email,
        inviteeEmail: values.invitations.map((el) => el.email),
        settingSection: "members",
        attributeUpdated: "new user invited",
        date: dayjs().format(),
      });
    } catch (error) {}
  };

  const { handleChange, values, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      invitations: [{ email: "", permission: "rwx_user", approve: false }],
    },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const addEmailInput = () => {
    setFieldValue("invitations", [...values.invitations, { email: "", permission: "rwx_user", approve: false }]);
  };

  const removeEmailInput = (index) => {
    setFieldValue(
      "invitations",
      values.invitations.filter((_, i) => i !== index),
    );
  };

  return (
    <Modal title="Invite your coworkers" onClose={() => toggleModal(2)}>
      <form className="w-[450px] px-5" onSubmit={handleSubmit}>
        {values.invitations.map((item, index) => (
          <div key={index}>
            {index === 0 ? <p className="mt-[2em] font-semibold">Email Address</p> : ""}
            <div className="border-[1px] h-[50px] rounded-[8px] flex justify-between w-[100%] px-4 items-center mt-2">
              <input
                placeholder="Enter email address"
                className="w-[100%] outline-none h-[100%]"
                name={`invitations.${index}.email`}
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={item.email}
              />
              <div>
                <SelectInput
                  options={options}
                  css="h-[42px] border-none w-[100px] rounded-[6px] text-primary-800 bg-transparent cursor-pointer"
                  value={item.permission}
                  name={`invitations.${index}.permission`}
                  onChange={handleChange}
                />
              </div>
              <div>
                {index > 0 && (
                  <button type="button" onClick={() => removeEmailInput(index)}>
                    <IoCloseOutline />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
        <div
          className="text-primary-800 font-[500] text-[14px] flex items-center space-x-1 mt-3 cursor-pointer"
          onClick={addEmailInput}
        >
          <GrAdd />
          <p>Add another</p>
        </div>
        <div className="flex justify-between items-center my-[2em] mt-[5em]">
          <div></div>
          <div className="flex space-x-4">
            <GreyButton text="Cancel" onClick={() => toggleModal(2)} type="button" />
            <BlueButton text="Send Invites" type="submit" />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default InviteModal;
