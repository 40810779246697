import { cn } from "helpers/classHelpers";
import React, { useState, useRef, useEffect } from "react";
import { IoMdInformationCircle } from "react-icons/io";

const Tooltip = ({ text, className }) => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState("top");
  const tooltipRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    if (show && tooltipRef.current && iconRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const iconRect = iconRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      // Check if the tooltip is outside the viewport, adjust if necessary
      if (tooltipRect.bottom > viewportHeight) {
        setPosition("top");
      } else if (tooltipRect.top < 0) {
        setPosition("bottom");
      }

      if (tooltipRect.right > viewportWidth) {
        setPosition("left");
      } else if (tooltipRect.left < 0) {
        setPosition("right");
      }
    }
  }, [show]);

  return (
    <div
      ref={iconRef}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className={cn("cursor-pointer relative")}
    >
      <IoMdInformationCircle className={cn("text-sm", className)} />
      {show && (
        <span
          ref={tooltipRef}
          className={cn(
            "w-[200px] h-auto rounded-[4px] bg-white shadow-primary p-2 text-[10px] absolute z-[999999] border-[1px]",
            position === "top"
              ? "top-4 left-0"
              : position === "bottom"
              ? "top-auto bottom-4 left-0"
              : position === "left"
              ? "top-0 right-full mr-2"
              : "top-0 left-full ml-2",
          )}
        >
          {text}
        </span>
      )}
    </div>
  );
};

export default Tooltip;
