import React, { useState } from "react";
import Toggle from "react-toggle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchBySurveyId } from "services/newSurveyService";
import SaveAndExitModal from "./SaveAndExitModal";
import BottomBar from "./BottomBar";
import PublishModal from "./FinishingModals/publishModal";
import Typography from "utils/typography";
import GreyButton from "components/Button/GreyButton";
import { FaPencilAlt } from "react-icons/fa";
import previewImage from "assets/images/previewImage.png";
import useCredit from "hooks/useCredit";

const Publish = ({ step, setStep }) => {
  const [open, setOpen] = useState(null);
  const { wsCredit } = useCredit();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  console.log(wsCredit);

  const locationArray = location.pathname.split("/");

  const { data } = useQuery({
    queryKey: ["fetchBySurveyId-Publish", id],
    queryFn: () => fetchBySurveyId(id),
    enabled: !!id,
  });

  const freshData = data?.data;
  console.log(freshData);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };
  const [saveLeads, setSaveLeads] = useState(false);

  const handleToggles = (value) => {
    setSaveLeads(value);
  };

  const handleBackToQuestion = () => {
    setStep(1);
  };

  const handlePublish = () => {};

  const handleSaveAndExit = () => {
    if (locationArray.includes("brand-tracking")) {
      navigate("/research/brand-tracking");
    } else if (locationArray.includes("online-survey")) {
      navigate("/research/online-survey");
    } else {
      navigate("/research/field-interview");
    }
  };

  return (
    <div className="flex gap-[2em]">
      {open === 4 ? <SaveAndExitModal handleModal={handleModal} callBackFn={handleSaveAndExit} /> : null}

      <div className="w-[60%]">
        <p className="font-semibold">Publish</p>
        <p className="text-sm text-gray-600">See how your survey will appear to respondents before publishing it.</p>

        <div className="border-[1px] rounded-md p-6 mt-4">
          <div className="flex items-center space-x-4">
            <div className="w-[70px] h-[40px] overflow-hidden border-[1px] rounded-md">
              <img
                src={freshData?.surveyDetails?.coverImage || previewImage}
                className="w-[100%] h-[100%] object-cover text-[6px]"
                alt="SurveyImage"
              />
            </div>

            <div>
              <Typography.Text>{freshData?.surveyDetails?.surveyName}</Typography.Text>

              <Typography.SubText className="text-gray-600">
                {freshData?.surveyDetails?.surveyCategory}
              </Typography.SubText>
            </div>
          </div>

          <div></div>
        </div>

        <div className="border-[1px] rounded p-4 mt-4">
          <p className="text-[16px] font-[600]">Settings</p>

          <div className="flex items-center justify-between border-b-[1px] py-4 text-sm text-gray-700">
            <p className=" ">Audience</p>

            <p>{freshData?.targetingOption?.expectedResponse}</p>
          </div>

          {freshData?.targetOption?.foreignVoucher !== undefined &&
          freshData?.targetingOption?.foreignVoucher?.code !== "" ? (
            <div className="flex items-center justify-between border-b-[1px] py-4 text-sm text-gray-700">
              <p className="">Promo code </p>

              <p>{freshData?.targetingOption?.foreignVoucher?.code}</p>
            </div>
          ) : null}

          <div className="flex items-center justify-between border-b-[1px] py-4 text-sm text-gray-700">
            <p className="">Reward per response</p>

            <p>{freshData?.targetingOption?.reward}</p>
          </div>

          <div className="flex items-center justify-between py-4">
            <p className="text-[16px] text-gray-700 font-[600]">Cost Estimate</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm text-gray-700">Total spends</p>

            <div className="flex flex-col items-end">
              <p className="text-xl font-bold">{freshData?.cost}</p>
              <p className="text-xs font-bold text-primary-800 flex justify-end">
                Available balance: {wsCredit?.sends?.toLocaleString()}
              </p>
            </div>
          </div>
        </div>

        <>
          {locationArray.includes("brand-tracking") ? null : (
            <div className="border-[1px] rounded-md p-6 mt-4">
              <div className="flex items-center space-x-3  mb-1">
                <p className=" text-sm font-medium">Enable Follow-up</p>
                <label>
                  <Toggle
                    checked={saveLeads}
                    icons={false}
                    onChange={() => handleToggles(!saveLeads)}
                    name="saveLeads"
                    className="toggle mt-1"
                  />
                </label>
              </div>

              <p className="text-xs text-[#737373]">Survey respondents will be added to your leads audience.</p>
            </div>
          )}
        </>
      </div>

      <div className="w-[40%] bg-gray-50 border-[1px] h-[70vh]">
        <div className="text-sm bg-white p-4 border-b-[1px]">
          <p className="text-xs text-gray-600 text-center">Preview</p>
        </div>

        <div className="p-4">
          <div className="bg-green-400 h-[55vh] w-[100%] flex items-start justify-center flex-col space-y-5 p-4">
            <div className="h-[120px] w-[100%] bg-green-100 rounded-lg overflow-hidden">
              <img
                src={freshData?.surveyDetails?.coverImage || previewImage}
                alt="surveyDetails.coverImage"
                className="w-[100%] h-[100%] object-cover text-[6px]"
              />
            </div>

            <div className="h-[100px] w-[100%] bg-white rounded-lg p-4 overflow-y-scroll">
              <Typography.SubText className="font-semibold">{freshData?.surveyDetails?.surveyName}</Typography.SubText>

              <Typography.MicroText className="w-[100%]">
                {freshData?.surveyDetails?.surveyDescription}
              </Typography.MicroText>
            </div>

            <div className="bg-green-800 w-[140px] flex items-center justify-center h-[40px] rounded-3xl">
              <Typography.MicroText className="font-semibold text-white">Start</Typography.MicroText>
            </div>
          </div>

          <div className="my-2 flex items-center justify-center">
            <GreyButton
              text={
                <div className="flex items-center space-x-2">
                  <FaPencilAlt />
                  <Typography.MicroText className="text-primary-800">Edit questions</Typography.MicroText>
                </div>
              }
              onClick={handleBackToQuestion}
            />
          </div>
        </div>
      </div>

      <BottomBar
        step={step}
        setStep={setStep}
        handleSave={false ? handlePublish : () => handleModal(1)}
        handleModal={() => handleModal(4)}
      />

      {/* open === 1 */}

      {open === 1 ? <PublishModal handleModal={handleModal} saveLeads={saveLeads} /> : null}
    </div>
  );
};

export default Publish;
