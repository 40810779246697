import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// import Logo from "assets/images/newLogo.png";
import { getToken } from "helpers/authHelper";

const AuthLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let token = getToken();

    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <div className="w-[100%] bg-primary-50 min-h-[100vh] p-6">
      <div>
        <div></div>
      </div>

      <div className="min-h-[90vh] flex justify-center items-center flex-col">
        {/* <a href={process.env.REACT_APP_WEB_BASE_URL} className="logo">
          <img src={Logo || ""} alt="Mooyi logo" className="w-[150px] my-[2em]" />
        </a> */}
        <div className="sm:w-[35%] w-[100%] bg-white mx-auto rounded-[12px] py-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
