import BlueButton from "components/Button/BlueButton";
import checker from "assets/images/pricing/check.png";
import { HashLink } from "react-router-hash-link";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "store";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { paymentPlans, verifyPayment } from "services/workspaceService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import usePlan from "hooks/usePlan";

const publicKey =
  process.env.REACT_APP_NODE_ENV !== "production" ? process.env.REACT_APP_FLW_KEY : process.env.REACT_APP_FLW_LIVE_KEY;

const PlanCard = ({
  title,
  subtitle,
  category,
  tag,
  responses,
  planId,
  info,
  plan,
  amount,
  featureTitle,
  features,
  conversionRate,
  toggle,
}) => {
  const { user } = useStore();

  const configs = {
    public_key: publicKey,
    tx_ref: Date.now(),
    amount: "",
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user?.email,
      phone_number: `+234${user?.phone}`,
      name: user?.firstname + " " + user?.lastname,
    },
    customizations: {
      title: "MOOYI",
      description: "Plan Upgrade",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const initializePayment = useFlutterwave(configs);

  const { mutate: paymentVerify } = useMutation({
    mutationFn: verifyPayment,
    onSuccess: () => {
      closePaymentModal();
      Toast.success("Plan purchased successfully");
      queryClient.invalidateQueries({ queryKey: ["myPlan"] });
      queryClient.invalidateQueries({ queryKey: ["getPlan"] });
      queryClient.invalidateQueries({ queryKey: ["wsCredit"] });
      queryClient.invalidateQueries({ queryKey: ["wsData"] });
    },
    onError: () => {
      Toast.error("Plan purchase failed; please try again");
    },
  });

  const { mutate: payment } = useMutation({
    mutationFn: paymentPlans,
    onSuccess: ({ data }) => {
      console.log(data);
      // configs.amount = data?.plan_price;
      console.log(data);
      configs.amount = data?.plan_price;
      initializePayment({
        callback: (response) => {
          const userData = {
            tx_ref: data?.tx_ref,
            transactionId: response?.transaction_id,
          };
          paymentVerify(userData);
        },
        onClose: () => {
          // routeFn();
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit = (planId) => {
    const payload = {
      plan_id: [planId],
      email: user?.email,
      fullname: user?.fullname,
    };
    payment(payload);
  };

  const { data } = usePlan();
  const planData = data;
  // {title !== "Free" ? "Contact us" : "Sign Up Free"}
  const buttonText =
    title.toLowerCase() === planData?.plan
      ? "Current plan"
      : title.toLowerCase() !== "free"
      ? "Buy now"
      : "Sign Up Free";

  return (
    <div className="border w-full h-[39rem] p-3 bg-white">
      <div className="bg-blue-100 p-5 rounded-t-xl h-[7rem]">
        <div className="flex justify-between">
          <p className="text-[20px] font-[600] text-primary">{title}</p>
          {tag !== "" || undefined ? (
            <div className="w-fit rounded flex items-center justify-center">
              <p className="text-primary  text-[12px] font-[600]">{tag}</p>
            </div>
          ) : null}
        </div>
        <p className="text-grey-600 md:text-[15px] text-[13px] font-medium">{subtitle}</p>
      </div>
      <div className="mt-2 px-1">
        <p className="text-[12px] font-medium h-[50px]">{info}</p>
        {title === "Pro Plan" ? (
          <div>
            <p className="text-[38px] md:text-[40px] font-[600] mt-3"> Custom </p>
          </div>
        ) : (
          <div className="flex items-center space-x-3 mt-2">
            <p className={`text-[38px] ${toggle ? "md:text-[30px]" : "md:text-[40px]"}  font-[600]`}>
              {toggle ? `₦${(amount * conversionRate).toLocaleString()}` : `$${amount.toLocaleString()}`}
            </p>
            <p className="font-[600] text-gray-500 ">Per month</p>
          </div>
        )}
        {/* "mailto:support@enterscale.com" */}
        <div className="mt-2 w-full">
          {/* <Link to={title !== "Free" ? null : null}> */}
          <BlueButton
            text={buttonText}
            type="button"
            disabled={title.toLowerCase() === planData?.plan}
            onClick={() => handleSubmit(planId)}
            className="w-full"
            buttonId={title === "free" ? "eh_contact_us_3" : "eh_sign_up_free"}
          />
          {/* </Link> */}
        </div>
        <div className="my-5">
          <hr />
        </div>
        <div>
          <p className="font-secondary">{featureTitle}</p>
          {features !== undefined
            ? features.map((el, i) => (
                <div className="flex items-start space-x-3 mt-3" key={i}>
                  <div className="mt-1 w-[20px] h-[20px]">
                    <img src={checker} alt="" />
                  </div>
                  <p className="text-[12px] w-[90%] font-medium">{el}</p>
                </div>
              ))
            : null}
        </div>
        <HashLink smooth to="/pricing/engagement-hub/#explore">
          <div className="flex items-center space-x-3 text-primary underline text-[12px] mt-5">
            <p>Explore all features</p>{" "}
          </div>
        </HashLink>
      </div>
    </div>
  );
};

export default PlanCard;
