import { useMutation, useQuery } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import Modal from "components/Modal";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useEffect, useState } from "react";
import { approveTopUp, getCredit, topUpCredit } from "services/workspaceService";
import { useStore } from "store";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import BlueButton from "components/Button/BlueButton";
import Dropdown from "components/DropDown";
import { formatAmount } from "lib/formatAmount";

const publicKey =
  process.env.REACT_APP_NODE_ENV !== "production" ? process.env.REACT_APP_FLW_KEY : process.env.REACT_APP_FLW_LIVE_KEY;

const TopUpModal = ({ handleModal }) => {
  const [bundle, setBundle] = useState();
  const [type, setType] = useState("");
  const {
    data,
    refetch: fetchCredit,
    isFetching: loadCredit,
  } = useQuery({ queryKey: ["getCredit"], queryFn: () => getCredit(type) });
  const { user: storeUser } = useStore();

  const tag = loadCredit ? "Loading" : "Select unit";

  const bundleArray =
    data === undefined
      ? ""
      : data.map((el, i) => {
          return { label: formatAmount(el?.credit), value: i, amount: el?.amount, bundleId: el?._id };
        });

  useEffect(() => {
    fetchCredit(type);
  }, [fetchCredit, type]);

  const configs = {
    public_key: publicKey,
    tx_ref: Date.now(),
    amount: "",
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: storeUser?.email,
      phone_number: `+234${storeUser?.phone}`,
      name: storeUser?.firstname + " " + storeUser?.lastname,
    },
    customizations: {
      title: "MOOYI",
      description: "Plan Upgrade",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const { mutate: mutateTopUp, isLoading } = useMutation({
    mutationFn: approveTopUp,
    onSuccess: () => {
      closePaymentModal();
      Toast.success("Top-Up successful.");
      handleModal();
      queryClient.invalidateQueries({ queryKey: ["wsCredit"] });
    },
    onError: () => {
      Toast.error("Top-up failed, please try again");
    },
  });
  // data?.amount;

  const initializePayment = useFlutterwave(configs);
  const { refetch, isFetching } = useQuery({
    queryKey: ["topUpCredit", bundle?.bundleId],
    queryFn: () => topUpCredit(bundle?.bundleId),
    enabled: false,
    onSuccess: (data) => {
      console.log(data);
      configs.amount = data?.amount;
      initializePayment({
        callback: (response) => {
          const userData = {
            tx_ref: data?.tx_ref,
            transactionId: response?.transaction_id,
          };
          mutateTopUp(userData);
        },
        onClose: () => {
          // routeFn();
        },
      });
    },
    onError: () => {
      Toast.error("Top-up failed, please try again");
    },
  });

  const creditData = [
    { label: "Sends", value: "sends" },
    { label: "Research", value: "research" },
    { label: "Rewards", value: "rewards" },
  ];

  const handleSetBundle = (id) => {
    setBundle(bundleArray[id]);
  };
  const handleSelect = (type) => {
    setType(type);
  };

  const handleSubmit = () => {
    refetch();
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <Modal title="Top up bundles" onClose={() => handleModal(1)}>
      <div className="w-[450px] px-5">
        <p className="text-sm text-gray-500 my-2">Easily add more units to your account directly with your card.</p>

        <div className="space-y-1 my-2">
          <p className="font-medium">Select Bundle Type</p>
          <p className="text-xs text-gray-500">Choose the type of bundle you need.</p>
          <Dropdown tag="Select bundle type" options={creditData} onSelect={(e) => handleSelect(e.value)} />
        </div>

        <div className="mt-[1em]">
          <p className="font-medium">Units</p>
          <p className="text-xs text-gray-500">Enter the number of units to top up.</p>

          {/* <SecondaryInput
            // text="Amount"
            placeholder="Enter Amount"
            // onChange={handleChange}
            name="amount"
            type="num"
            value={credit}
          /> */}
          <Dropdown
            tag={tag}
            options={loadCredit ? [{ value: "", label: "loading" }] : bundleArray}
            onSelect={(e) => handleSetBundle(e.value)}
          />

          <p className="text-xs text-gray-500">Minimum bundle top-up: 1</p>
        </div>

        {/* <div className="relative">
          <div
            className="h-[50px] border-[#D0D5DD] w-[100%] rounded-[6px] border-[1px] flex items-center justify-between px-4 cursor-pointer"
            onClick={handleDropdown}
          >
            {!amount?.value ? (
              <p className="text-[#737373] tracking-[-0.01em] text-s">Select amount</p>
            ) : (
              <p className="text-[#737373] tracking-[-0.01em] text-s">
                {amount?.value} {"credits"}
              </p>
            )}

            <IoIosArrowDown />
          </div>

          {open ? (
            <div className="rounded-[6px] shadow-secondary absolute w-[100%] top-[3.5em] z-[9999] bg-white cursor-pointer">
              {creditData?.map((el, i) => {
                return (
                  <div
                    onClick={() => handleSelect(el)}
                    className={`h-[45px] ${
                      i !== creditData.length - 1 && "border-b-[1px]"
                    }  flex items-center px-4 justify-between`}
                  >
                    <p className="text-s text-[#404040]">{el?.value} credits</p>

                    <p className="text-s">NGN {formatAmount(el?.price)}.00</p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div> */}

        <div className="flex justify-between items-center  mt-[1.5em] w-[100%]">
          <BlueButton
            text={`Pay $${bundle !== undefined ? formatAmount(bundle?.amount) : "0.00"}`}
            onClick={handleSubmit}
            disabled={isFetching}
            className="w-[100%]"
          />
        </div>
      </div>
    </Modal>
  );
};

export default TopUpModal;
