import { useMutation } from "@tanstack/react-query";
import SecondaryInput from "components/Inputs/SecondaryInput";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { createContact } from "services/contactService";
import PreLoader from "components/Loader/PreLoader";
import { useFormik } from "formik";
import { TiInfo } from "react-icons/ti";
import BlueButton from "components/Button/BlueButton";
import dayjs from "dayjs";
import Checkbox from "components/Inputs/CheckBox";
// import Tooltip from "components/Tooltip";
import PhoneNumberInput from "components/Inputs/PhoneNumberInput";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import { IoChevronBackOutline } from "react-icons/io5";
import { CreateTagSuccessModal } from "../contactTabs/Tags/CreateTagSuccessModal";
import Dropdown from "components/DropDown";
import { Link } from "react-router-dom";
import DropDownWithSearch from "components/DropDownWithSearch";
import { transformToLabelValue } from "lib/transformData";
import countryData from "data/countries.json";
import stateData from "data/states.json";
import TagDropDown from "components/dropdowns/TagDropDown";

// import { ContactListIcon } from "assets/icons/DashboardIcons";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  birthday: "",
  address: "",
  gender: "",
  country: "",
  state: "",
  lga: "",
};

// const presentDate = dayjs(); // Get the present date

// const eighteenYearsAgo = presentDate.subtract(18, "year");
const gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Prefer not to say", value: "prefer not to say" },
];

const inputArr = [
  {
    id: 1,
    type: "text",
    text: "First Name",
    name: "firstname",
    placeholder: "Enter your first name",
    // value: values.firstname,
  },
  {
    id: 2,
    type: "text",
    text: "Last Name",
    name: "lastname",
    placeholder: "Enter your last name",
    // value: values.lastname,
  },
  {
    id: 3,
  },
  {
    id: 4,
    type: "email",
    text: "Email Address",
    name: "email",
    placeholder: "Enter your email address",
    // value: values.email,
  },
  {
    id: 5,
  },
  {
    id: 6,
    type: "date",
    name: "birthday",
    text: "Birthday",
    placeholder: "Enter Birthday",
    // min: eighteenYearsAgo.format("YYYY-MM-DD"),
    // value: values.birthday
  },
  {
    id: 7,
    // min: eighteenYearsAgo.format("YYYY-MM-DD"),
    // value: values.birthday
  },
  {
    id: 8,
  },
  {
    id: 9,
    type: "text",
    name: "address",
    text: "(Optional) Address",
    placeholder: "Enter your address",
    // value: values.address,
  },
];

const validationSchema = Yup.object().shape(
  {
    email: Yup.string().email("Enter a valid email address").required("Please complete this field."),
    firstname: Yup.string().required("Please complete this field."),
    lastname: Yup.string().required("Please complete this field."),
    birthday: Yup.date()
      .nullable()
      .notRequired()
      .max(new Date(), "Selected date must be today or earlier")
      .test("age", "Your contact must be at least 18 years old", (value) => {
        const currentDate = new Date();
        const minDate = new Date();
        minDate.setFullYear(currentDate.getFullYear() - 18);
        if (!value) return true;
        return new Date(value) <= minDate;
      }),
    phone: Yup.string().required("Please complete this field."),
  },
  [["birthday", "birthday"]],
);
const IndividualEntry = ({ outSideContact, handleModal: toggleModal }) => {
  const [globalArr] = useState(inputArr);
  const [isChecked, setIsChecked] = useState(false);
  const [tagArr, setTagArr] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [selectedCode, setSelectedCode] = useState("+234");
  const { user } = useLoggedInUser();

  const countryValues = transformToLabelValue(countryData, "name");

  const getStatesForCountry = (countryName) => {
    const data = stateData.find((country) => country.name === countryName)?.states || [{}];
    return transformToLabelValue(data, "name");
  };

  const { mutate: addContact, isLoading } = useMutation({
    mutationFn: createContact,
    onSuccess: () => {
      Toast.success("Contact added successfully");
      navigate("/contacts");
      queryClient.invalidateQueries({ queryKey: ["allContacts", "allTags", "allSegment", "onBoarding"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: addContact1, isLoading: isLoading1 } = useMutation({
    mutationFn: createContact,
    onSuccess: () => {
      Toast.success("Contact added successfully");
      toggleModal();
      queryClient.invalidateQueries({ queryKey: ["allContacts", "allTags", "allSegment", "onBoarding"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onSubmit = (data) => {
    // const { state, ...restData } = data;
    trackEvent("add_contact", {
      email: user?.user?.data?.email,
      addContactMethod: "individual entry",
      date: dayjs().format(),
    });
    const userData = {
      ...data,
      phone: `${selectedCode}${data.phone}`,
      tag: tagArr,
    };
    if (outSideContact) {
      addContact1(userData);
    } else {
      addContact(userData);
    }
  };

  // const handleDropdown = () => {
  //   setOpenDrop(!openDrop);
  // };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  // const refer = useOutsideClick(handleDropdown);

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit: normalSub,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  // const citiesArray =
  //   values.state !== "" && cityData !== undefined && Array.isArray(cityData[values.state.replace(" State", "")])
  //     ? cityData[values.state.replace(" State", "")]?.map((el) => ({
  //         label: el,
  //         value: el,
  //       }))
  //     : [];

  const onKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  // useEffect(() => {
  //   setSearchedArray(tagData?.tags);
  // }, [setSearchedArray, tagData]);

  // const handleCheck = (e, el) => {
  //   if (e.target.checked) {
  //     if (!tagArr.includes(el)) {
  //       setTagArr([...tagArr, el]);
  //     }
  //   } else {
  //     const filterArray = tagArr?.filter((elm) => elm !== el);
  //     setTagArr(filterArray);
  //   }
  // };

  if (isLoading) {
    return <PreLoader text="Adding Contact" />;
  }

  const handleInputChange = (e) => {
    // console.log(e.target.value)
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    setFieldValue(name, numericValue);
  };

  // const handleQueryChange = (e) => {
  //   setQuery(e.target.value);
  //   if (query !== "") {
  //     const filteredData = tagData?.tags?.filter(
  //       (filtered) => filtered?.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
  //     );
  //     if (filteredData.length !== 0) {
  //       setSearchedArray(filteredData);
  //     }
  //   } else {
  //     setSearchedArray(tagData?.tags);
  //   }
  // };

  // const removedFromArray = (el) => {
  //   const filterArray = tagArr?.filter((elm) => elm !== el);
  //   setTagArr(filterArray);
  // };

  // const checkInArray = (y) => {
  //   let checked = false;
  //   for (let x in tagArr) {
  //     if (tagArr[x] === y) {
  //       checked = true;
  //     }
  //   }
  //   return checked;
  // };

  // if (isLoading) {
  //   return <PreLoader />;
  // }

  // globalArr.splice(1, 0, newArray)

  return (
    <div className="w-full bg-white rounded-md p-5">
      <div
        className="w-fit flex justify-start items-center text-[14px] text-primary-800 space-x-2 cursor-pointer pb-5"
        onClick={() => navigate(-1)}
      >
        <IoChevronBackOutline />
        <p>Back to add contacts</p>
      </div>
      <div className="border-[1px] p-4 rounded-[8px] bg-primary-50 flex space-x-2 my-4 mt-2">
        <TiInfo className="text-primary-800 text-[24px]" />
        <div>
          <p className="text-[14px font-[600] text-primary-800"> Please note: </p>

          <p className="text-s font-semibold">
            We do not send confirmation emails to your contacts. Ensure you have obtained permission before adding
            contacts, and adhere to our guidelines to prevent misuse.
          </p>
        </div>
      </div>
      <form onSubmit={normalSub} onKeyDown={onKeyDown}>
        <div className="w-full grid grid-cols-2 gap-[1em] items-start">
          {globalArr?.map((el, i) => {
            if (el.id === 5) {
              return (
                <PhoneNumberInput
                  text="Phone Number"
                  onChange={handleInputChange}
                  value={values.phone}
                  name="phone"
                  setSelectedCode={setSelectedCode}
                  selectedCode={selectedCode}
                />
                // <div className="text-primary flex flex-col w-[100%]">
                //   <label htmlFor="phone" className="text-[#292D32] text-m font-[500]">
                //     Phone Number
                //   </label>

                //   <div className="border-[#D0D5DD] border-[1px] flex items-center rounded-[8px] my-2 ">
                //     <div className="flex items-center px-3">
                //       <img src={Flag} alt="Flag" />
                //       <p className="ml-2">+234</p>
                //     </div>
                //     <input
                //       type="tel"
                //       placeholder="8025556792"
                //       onChange={handleInputChange}
                //       value={values.phone}
                //       name="phone"
                //       className=" border-l-[1px] rounded-[1px]  border-[#D0D5DD] h-[48px] pl-2 outline-none w-[70%]  bg-[#FAFAFA] text-m"
                //       maxlength="10"
                //     />
                //   </div>
                // </div>
              );
            } else if (el.id === 3) {
              return (
                <div className="mt-1">
                  <div className="flex space-x-2 items-center ">
                    <p className="text-m font-[500]">Gender</p>
                  </div>
                  <div className="mt-2">
                    <Dropdown
                      tag="Select your gender"
                      options={gender}
                      onSelect={(el) => setFieldValue("gender", el.value)}
                    />
                  </div>
                </div>
              );
            } else if (el.id === 7) {
              return (
                <div className="mt-1">
                  <div className="flex space-x-2 items-center ">
                    <p className="text-m font-[500]">Country</p>
                  </div>
                  <div className="mt-2">
                    <DropDownWithSearch
                      tag="Select country"
                      options={countryValues}
                      onSelect={(el) => setFieldValue("country", el.value)}
                    />
                  </div>
                </div>
              );
            } else if (el.id === 8) {
              return (
                <div className="mt-1">
                  <div className="flex space-x-2 items-center ">
                    <p className="text-m font-[500]">State</p>
                  </div>
                  <div className="mt-2">
                    <DropDownWithSearch
                      tag="Select state"
                      options={getStatesForCountry(values.country)}
                      onSelect={(el) => setFieldValue("state", el.value)}
                    />
                  </div>
                </div>
              );
            } else if (el.id === 9) {
              return (
                <div className="flex w-full space-x-2 items-center">
                  <Fragment key={el.id} className="w-full">
                    <SecondaryInput
                      text={el.text}
                      name={el.name}
                      placeholder={el.placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type={el.type}
                      value={values[`${el.name}`]}
                      error={getError(`${el.name}`)}
                      // min={el?.min}
                    />
                  </Fragment>
                </div>
              );
            } else {
              return (
                <Fragment key={el.id}>
                  <SecondaryInput
                    text={el.text}
                    name={el.name}
                    placeholder={el.placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={el.type}
                    value={values[`${el.name}`]}
                    error={getError(`${el.name}`)}
                    // min={el?.min}
                  />
                </Fragment>
              );
            }
          })}

          <TagDropDown tagArr={tagArr} setTagArr={setTagArr} />
        </div>

        <div>
          <div className="flex items-start space-x-3 w-full">
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />

            <div className="-mt-[3px]">
              <p className="text-ss mt-1">
                I confirm that my customers have given permission (or have opted in to receive messages) for their data
                to be accessed for marketing purposes. By proceeding, you agree to our{" "}
                <Link to="https://usemooyi.com/terms-of-use" className="text-blue-800">
                  {" "}
                  Terms of use.{" "}
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-between my-4 w-[35%]">
          <BlueButton text="Save" className="w-[150px] h-[30px]" type="submit" disabled={!isChecked || isLoading1} />
          <div></div>
        </div>
      </form>

      {/* {open === 1 ? <CreateTagModal toggleModal={() => handleModal(2)} closeModal={handleModal} /> : null} */}
      {open === 2 ? <CreateTagSuccessModal handleModal={handleModal} /> : null}
    </div>
  );
};

export default IndividualEntry;
