import React from "react";

export const InsightIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.25 1.5C2.66421 1.5 3 1.83579 3 2.25V14.25C3 14.6642 3.33579 15 3.75 15H15.75C16.1642 15 16.5 15.3358 16.5 15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H3.75C2.50736 16.5 1.5 15.4927 1.5 14.25V2.25C1.5 1.83579 1.83579 1.5 2.25 1.5Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2803 6.21967C16.5732 6.51257 16.5732 6.98744 16.2803 7.28033L12.841 10.7197C11.9623 11.5984 10.5377 11.5984 9.65903 10.7197L8.03032 9.09098C7.73745 8.7981 7.26256 8.7981 6.96967 9.09098L5.40533 10.6553C5.11243 10.9482 4.63757 10.9482 4.34467 10.6553C4.05178 10.3625 4.05178 9.88755 4.34467 9.59468L5.90901 8.03033C6.78769 7.15165 8.21228 7.15165 9.09097 8.03033L10.7197 9.65903C11.0125 9.9519 11.4875 9.9519 11.7803 9.65903L15.2197 6.21967C15.5126 5.92678 15.9874 5.92678 16.2803 6.21967Z"
      fill={fill1}
    />
  </svg>
);
