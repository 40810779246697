import { useQuery } from "@tanstack/react-query";
import Checkbox from "components/Inputs/CheckBox";
import Toast from "config/toast";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Toggle from "react-toggle";
import { getACampaign } from "services/campaignService";
import send from "assets/images/broadcast/send.png";
import document from "assets/images/broadcast/documentIcon.png";

const Content = ({ setData, data, handleTest }) => {
  const { emailId } = useParams();
  const [audience, setAudience] = useState([]);
  const [active, setActive] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [display, setDisplay] = useState(false);
  const [value, setValue] = useState(0);
  const statArray = ["delivered", "sent", "opened"];
  const icons = [send, document, document];

  const handleCardClick = ({ name, initvalue }) => {
    const isSelected = selectedCards.includes(name);
    if (isSelected) {
      setSelectedCards(selectedCards.filter((cardName) => cardName !== name));
      setValue(value - initvalue);
    } else {
      setSelectedCards([...selectedCards, name]);
      setValue(value + initvalue);
    }
  };

  const handleChange = () => {
    setDisplay(!display);
  };

  const handleTelChange = (e) => {
    const inputValue = e.target.value;
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);

    if (inputValue.length > 6 && isValidEmail) {
      setActive(true);
    } else {
      setActive(false);
    }

    setData({
      ...data,
      testContact: inputValue,
    });
  };

  useEffect(() => {
    setData({
      ...data,
      audience: selectedCards,
      totalReceiver: value,
    });
  }, [selectedCards, value]);

  const { refetch } = useQuery({
    queryKey: ["oneCampaign", emailId],
    queryFn: () => getACampaign(emailId),
    enabled: !!emailId,
    onError: (error) => {
      Toast.error(error);
    },
    onSuccess: (data) => {
      const keys = Object.keys(data);
      for (const x of keys) {
        if (!(data[x] instanceof Array) && data[x] instanceof Object) {
          setAudience((prevAudience) => [...prevAudience, { id: x, name: x, value: data[x].value }]);
          // audience.push({ id: x, name: x, value: data[x].value });
          // console.log(x, "object");
        }
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [emailId, refetch]);

  const handleCheck = (e, x, y) => {
    if (e.target.checked) {
      setData({
        ...data,
        audience: x,
        personal: y,
      });
    } else {
      setData({
        ...data,
        audience: "",
        personal: 0,
      });
    }
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in data.recipientArray) {
      if (data.recipientArray[x].group_name === y) {
        checked = true;
      }
    }
    return checked;
  };

  const stats = [];
  if (audience.length !== 0) {
    audience.forEach((el) => {
      if (statArray.includes(el?.id)) {
        stats.push(el);
      }
    });
  }

  return (
    <div>
      <p className="text-lg font-semibold">Retargeting options</p>
      <p className="text-sm">Choose the section of your audience you want to follow up with</p>

      <div className="mt-4">
        <p className="text-sm">Resend to</p>
        <p className="text-sm text-gray-500">Select multiple sections to retarget</p>
      </div>

      <div className="grid grid-cols-2 gap-[1em] mt-4">
        {stats?.map((el, i) => {
          return (
            <div
              className={`p-4 text-ss cursor-pointer font-extrabold text-[#404040] border-[1px] rounded border-[#D0D5DD]  flex items-center justify-between hover:shadow-lg hover:rounded-[8px] group ${
                selectedCards.includes(el?.name) ? "bg-[#E5E5E5] hover:bg-[#E5E5E5] hover:text-primary-800" : ""
              }`}
              key={el.id}
              onClick={() => handleCardClick({ name: el?.name, initvalue: el?.value })}
            >
              <div className="flex items-center space-x-4 relative w-[100%] cursor-pointer ">
                <div className="flex items-start space-x-3">
                  <div
                    className={`w-[30px] h-[30px] flex items-center justify-center rounded-full p-2 ${
                      selectedCards.includes(el?.name) ? "bg-white" : "bg-primary-100"
                    }`}
                  >
                    <img src={icons[i]} alt="" className="object-contain" />
                  </div>
                  <span>
                    <p className="text-m"> Total {el?.name}</p>
                    <p className="text-ss">{el?.value} contacts</p>
                  </span>
                </div>
              </div>

              <Checkbox onChange={(e) => handleCheck(e, el?.name, el?.value)} checked={checkInArray(el?.name)} />
            </div>
          );
        })}
      </div>

      <p className="underline text-primary-400 text-xs underline-offset-2 mt-4">Clear Selection</p>

      <div>
        <div className="flex space-x-4 items-center mt-5">
          <p className="text-s font-semibold">Send Test</p>
          <Toggle checked={display} onChange={handleChange} icons={false} />
        </div>

        {display && (
          <div>
            <div className="flex space-x-4 items-center my-[1em]">
              <div className="flex items-center px-4 rounded-[8px] border-[#DFE5EC] border-[1px] space-x-2 w-[300px] h-[45px]">
                <input
                  type="email"
                  className="outline-none h-[100%] w-[300px] text-sm placeholder:text-sm"
                  placeholder="test@enterscale.com"
                  value={data.testContact}
                  onChange={handleTelChange}
                  name="testContact"
                />
              </div>
              <p
                className={`text-ss h-[45px] px-2 cursor-pointer rounded-md flex items-center justify-center ${
                  active ? "text-white font-bold bg-primary-800 " : "text-primary-800 bg-primary-100 "
                }`}
                onClick={active ? handleTest : null}
              >
                Send Test
              </p>
            </div>
            <p className="text-ss w-[50%]">
              You have 10 free test emails. Additional usage will be deducted from your email balance.{" "}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;
