import { toast } from "react-toastify";
import smallLogo from "assets/images/Sidebar/smallLogo.png";

export const Msg = ({ title, message }) => {
  // const dismissAll = () => toast.dismiss();

  return (
    <div className="font-primary flex w-[100%] items-center space-x-4">
      <img src={smallLogo} alt="Avatar" className="h-[20px]" />

      <div>
        <p className="text-sm font-semibold text-primary-800">{title}</p>
        <p className="text-xs text-gray-800 font-light">{message}</p>

        {/* <p className="text-xs text-gray-400 font-light mt-1" onClick={dismissAll}>
          Dismiss
        </p> */}
      </div>
    </div>
  );
};

class ToastClass {
  success(message) {
    toast.success(<Msg message={message} title={"Success"} />, {
      icon: false,
      style: {
        padding: "5px 8px",
        color: "#1D19AF",
        background: "#EBF1FF",
        borderRadius: "4px",
        fontWeight: "500",
        minWidth: "300px",
        maxWidth: "300px",
        height: "45px",
        textAlign: "left",
        fontSize: "14px",
      },
    });
  }

  error(message) {
    toast.error(<Msg message={message} title={"Error"} />, {
      icon: false,
      style: {
        padding: "5px 8px",
        color: "#DC2626",
        background: "#FEF2F2",
        borderRadius: "4px",
        fontWeight: "500",
        maxWidth: "300px",
        minWidth: "300px",
        height: "45px",
        textAlign: "left",
        fontSize: "14px",
      },
    });
  }

  promise(promise, messages) {
    toast.promise(
      promise,
      {
        pending: {
          render() {
            return <Msg message={messages.pending} title={"Loading"} />;
          },
        },
        success: {
          render() {
            return <Msg message={messages.pending} title={"Success"} />;
          },
        },
        error: {
          render() {
            return <Msg message={messages.pending} title={"Loading"} />;
          },
        },
      },
      {
        icon: false,
        style: {
          padding: "5px 8px",
          background: "#EBF1FF",
          borderRadius: "4px",
          fontWeight: "500",
          minWidth: "300px",
          maxWidth: "300px",
          height: "45px",
          textAlign: "left",
          fontSize: "14px",
        },
      },
    );
  }
}

const Toast = new ToastClass();
export default Toast;
