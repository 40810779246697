import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { login } from "services/authService";
import Toast from "config/toast";
import PreLoader from "components/Loader/PreLoader";
import IconButton from "components/Button/IconButton";
import { HiArrowNarrowRight } from "react-icons/hi";
import Checkbox from "components/Inputs/CheckBox";
import dayjs from "dayjs";
import { trackEvent } from "config/mixpanel";
import Logo from "assets/images/newLogo.png";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Enter a valid email address").required("Please fill in this field"),
  password: Yup.string().required("Please enter a password"),
});

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: login,
    onError: (error) => {
      Toast.error(error);
    },

    onSuccess: () => {
      Toast.success("Login Successful!");
      navigate("/dashboard");
    },
  });

  const onSubmit = async (data) => {
    try {
      mutate(data);
      trackEvent("login", { email: initialValues.email, date: dayjs().format() });
    } catch (err) {
      Toast.error(err);
    }
  };

  const hidePassword = () => {
    setShowPassword(!showPassword);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  if (isPending) {
    return <PreLoader text="Please wait" />;
  }

  return (
    <div className="w-[80%] mx-auto flex flex-col justify-center h-[100%]">
      <div className="flex justify-center">
        <img src={Logo || ""} alt="Mooyi logo" className="w-[150px] mt-[1em]" />
      </div>
      <h1 className="font-semibold text-[36px]">Sign in</h1>
      <p className="text-[#737373]">
        Don't have an account?{" "}
        <Link to="/auth/register" className="text-primary-800">
          Sign up
        </Link>
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mt-[1em]">
          <PrimaryInput
            type="email"
            name="email"
            placeholder=""
            onChange={handleChange}
            text="Email Address"
            onBlur={handleBlur}
            value={values.email}
            error={getError("email")}
          />

          <div className="relative mt-2">
            <PrimaryInput
              type={`${showPassword ? "text" : "password"}`}
              placeholder="8+ characters"
              onChange={handleChange}
              text="Password"
              name="password"
              onBlur={handleBlur}
              value={values.password}
              error={getError("password")}
            />

            <p
              className={`absolute select-none cursor-pointer ${showPassword ? "text-black" : "text-grey"} ${
                getError("password") ? "top-[45%]" : "top-[52%] "
              } mr-6 right-0 text-s font-[500]`}
              onClick={hidePassword}
            >
              {showPassword ? "Hide" : "Show"}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between my-2">
          <div className="flex space-x-1 pt-0 items-center">
            <Checkbox
              name="promotion"
              // value={values.promotion}
              onChange={(e) => {
                // handleChange(e); // Formik handleChange
                // const checked = e.target.checked;
                // setFieldValue("promotion", checked); // Formik setFieldValue
              }}
            />
            <p className="text-ss mt-1">Remember Me</p>
          </div>
          <div className="text-[12px] mt-1 ">
            <Link to="/auth/forgot-password">
              <span className="text-primary-800 font-[500] text-[12px] inline-block">Forgot your password?</span>
            </Link>
          </div>
        </div>
        <div className="mt-4">
          <IconButton
            type="submit"
            text="Login"
            css="w-[100%] flex items-center justify-center font-[500]"
            icon={<HiArrowNarrowRight />}
          />
        </div>
      </form>
    </div>
  );
}
