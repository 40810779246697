import DeleteButton from "components/Button/DeleteButton";
import GreyButton from "components/Button/GreyButton";
import deleteOwl from "assets/images/deleteOwl.png";
import Modal from "components/Modal";
import React from "react";

const DeleteSurveyModal = ({ handleModal, handleDeleteSurvey }) => {
  return (
    <Modal title="Delete survey" onClose={handleModal}>
      <div className="w-[400px] ">
        <div className="flex justify-center py-2 bg-red-100 mt-3">
          <div className="w-[208px]">
            <img src={deleteOwl} alt="" />
          </div>
        </div>
        <div className="px-5 py-5">
          <p>Are you sure you want to delete your survey[s]?.</p>
        </div>
        <div className="flex justify-center px-5 pt-2 border-t">
          {/* <div></div> */}
          <div className="flex gap-[1em] mt-[1em]">
            <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={handleModal} />
            <DeleteButton text="Yes, delete" onClick={handleDeleteSurvey} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteSurveyModal;
