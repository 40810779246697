import BlueButton from "components/Button/BlueButton";
import ModalWithoutClose from "components/ModalWithoutClose";
import { sectionParseStringToJson } from "lib/parseStringtoJson";
import { parseStringToJson } from "lib/parseStringtoJson";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import useBranchingStore from "store/branchingStore";
import Typography from "utils/typography";

const CopyAndPasteModal = ({ handleModal, setSurveyQuestionsArray, surveyQuestionsArray, from }) => {
  const [name, setName] = useState("");
  const { createSectionQuestion } = useBranchingStore();

  const handleStuff = (e) => {
    setName(e.target.value);
  };

  const addQuestions = () => {
    if (from === "branching") {
      createSectionQuestion(sectionParseStringToJson(name));
    } else {
      setSurveyQuestionsArray([...surveyQuestionsArray, ...parseStringToJson(name)]);
    }

    handleModal();
  };

  return (
    <ModalWithoutClose>
      <div className="w-[60vw] h-[70vh] bg-white flex items-start">
        <div className="w-[60%] bg-gray-50 p-4 space-y-2 h-[100%] border-r-[1px]">
          <Typography.H4 className="text-gray-700">Paste survey content</Typography.H4>

          <Typography className="text-gray-500">
            Add each question and answer choice on its own line. Press Enter on your keyboard twice to separate each
            question.
          </Typography>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            className="border-[1px] rounded-md p-3 resize-none w-[100%] text-sm placeholder:text-sm outline-none h-[84%]"
            value={name}
            onChange={handleStuff}
            placeholder="Paste your questions here"
          ></textarea>
        </div>

        <div className="w-[40%] p-4 flex flex-col justify-between h-[100%]">
          <div>
            <div className="flex items-center justify-between">
              <Typography.H4 className="text-gray-700">Preview</Typography.H4>

              <IoClose className="text-2xl text-gray-600 cursor-pointer" onClick={handleModal} />
            </div>

            {parseStringToJson(name)[0].question !== "" && (
              <div className="space-y-2">
                {parseStringToJson(name).map((el, i) => (
                  <div>
                    <div className="flex items-center space-x-2">
                      <Typography>{i + 1}.</Typography>
                      <Typography>{el?.question}</Typography>
                    </div>

                    {el?.questionType !== "text_response" && (
                      <div className="px-4">
                        {el?.options?.map((elm) => (
                          <Typography.MicroText>{elm}</Typography.MicroText>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          <BlueButton
            text={`ADD QUESTION${parseStringToJson(name).length > 1 ? "S" : ""}`}
            className="w-[100%]"
            onClick={addQuestions}
            disabled={parseStringToJson(name)[0].question === ""}
          />
        </div>
      </div>
    </ModalWithoutClose>
  );
};

export default CopyAndPasteModal;
