import React from "react";

export const SmsIconResizable = ({ fill1, fill2, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.58203 5.25C1.58203 3.93833 2.64536 2.875 3.95703 2.875H15.0404C16.3521 2.875 17.4154 3.93833 17.4154 5.25V13.1667C17.4154 14.4784 16.3521 15.5417 15.0404 15.5417H6.33203L4.17899 17.3359C3.14772 18.1953 1.58203 17.4619 1.58203 16.1195V5.25Z"
      fill={fill1}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.54297 7.62516C5.54297 7.18794 5.89741 6.8335 6.33464 6.8335H12.668C13.1052 6.8335 13.4596 7.18794 13.4596 7.62516C13.4596 8.06238 13.1052 8.41683 12.668 8.41683H6.33464C5.89741 8.41683 5.54297 8.06238 5.54297 7.62516Z"
      fill={fill2}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.54297 10.7917C5.54297 10.3544 5.89741 10 6.33464 10H9.5013C9.93854 10 10.293 10.3544 10.293 10.7917C10.293 11.2289 9.93854 11.5833 9.5013 11.5833H6.33464C5.89741 11.5833 5.54297 11.2289 5.54297 10.7917Z"
      fill={fill2}
    />
  </svg>
);
