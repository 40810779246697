import { useMutation, useQuery } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import Dropdown from "components/DropDown";
import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useFormik } from "formik";
import { useState } from "react";
import { fetchWorkspace } from "services/workspaceService";
import { transferWorkspaceCredit } from "services/workspaceService";
import { getAllWorkspace } from "services/workspaceService";

const initialValues = {
  amount: "",
};

const TransferModal = ({ handleModal }) => {
  const { data } = useQuery({ queryKey: ["allWorkspaces"], queryFn: getAllWorkspace });
  const { data: defaultWorkspace } = useQuery({ queryKey: ["defaultWorkspace"], queryFn: fetchWorkspace });
  const { mutate } = useMutation({
    mutationFn: transferWorkspaceCredit,
    onSuccess: () => {
      Toast.success("Credit transferred successfully");
      queryClient.invalidateQueries({ queryKey: ["wsCredit"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const removeObjectIfIdFound = (objects, targetId) => {
    const filteredObjects = objects?.filter((obj) => obj?.name !== targetId);
    return filteredObjects;
  };
  const result = removeObjectIfIdFound(data, defaultWorkspace?.name);

  const options = result?.map((el) => ({
    id: el._id,
    name: el?.name,
    label: <p className="text-left text-sm">{el?.name}</p>,
  }));

  const [wsData, setWsData] = useState({
    workspace: "",
    workspaceId: "",
  });

  const handleSelect = (x) => {
    setWsData({
      ...wsData,
      workspace: x.name,
      workspaceId: x.id,
    });
  };

  const onSubmit = async (data) => {
    if (wsData.workspaceId !== "") {
      try {
        const userData = {
          ...data,
          receipent_ws: wsData.workspaceId,
        };
        mutate(userData);
        handleModal();
      } catch (error) {}
    }
  };

  const { handleChange, values, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Modal title="Transfer credit" onClose={() => handleModal(3)}>
      <form className="w-[400px] h-[300px] px-5" onSubmit={handleSubmit}>
        <div className="mt-[1em]">
          <SecondaryInput
            text="Amount"
            placeholder="Enter Amount"
            onChange={handleChange}
            name="amount"
            type="num"
            value={values.amount}
          />
        </div>

        <p className="mt-[1em] font-[500] mb-2">Workspace</p>

        <Dropdown tag="Select a workspace" options={options} onSelect={handleSelect} />
        {/* <div className="relative">
          <div
            className="h-[50px] border-[#D0D5DD] w-[100%] rounded-[6px] border-[1px] flex items-center justify-between px-4 cursor-pointer"
            onClick={handleDropdown}
          >
            {wsData.workspace === "" ? <p>Select a workspace</p> : wsData.workspace}

            <IoIosArrowDown />
          </div>

          {open ? (
            <div className="rounded-[6px] shadow-secondary absolute w-[100%] top-[3.5em] z-[9999] bg-white cursor-pointer">
              {data?.map((el, i) => {
                return (
                  <p
                    className={`h-[45px] ${i !== data.length - 1 && "border-b-[1px]"}  flex items-center px-4`}
                    onClick={() => handleSelect(el?.name, el?._id)}
                    key={i}
                  >
                    {el?.name}
                  </p>
                );
              })}
            </div>
          ) : null}
        </div> */}

        <div className="flex justify-between items-center my-[2em] mt-[5em]">
          <div></div>

          <div className="flex space-x-4">
            <BlueButton text="Transfer" type="submit" />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TransferModal;
