import React, { useState } from "react";
import Typography from "utils/typography";
import BlueButton from "components/Button/BlueButton";
import broadcastBucket from "assets/images/broadcast/broadcastBucket.png";
import { useNavigate } from "react-router-dom";
import EmailTab from "./homeTab/emails";
import SmsTab from "./homeTab/sms";
import VoiceTab from "./homeTab/voice";
import RecentCardSkeleton from "./homeTab/components/RecentCardSkeleton";
import RecentCards from "./homeTab/components/RecentCards";
import { useQuery } from "@tanstack/react-query";
import { getRecent } from "services/campaignService";
import RecentsEmptyState from "./homeTab/components/RecentsEmptyState";
import { motion } from "framer-motion";
import Tooltip from "components/Tooltip";
import { getTopPerforming } from "services/campaignService";

const tabs = [
  { id: 1, name: "Email" },
  { id: 2, name: "SMS" },
  // { id: 3, name: "Voice" },
];

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.2, // Stagger the animation by 0.2 seconds per card
      duration: 0.5,
      ease: "easeInOut",
    },
  }),
};

const Home = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);

  const campNavigate = (channel, id) => {
    switch (channel) {
      case "sms":
        return `/broadcasts/sms/${id}`;
      case "email":
        return `/broadcasts/email/${id}`;
      case "robocall":
        return `/broadcasts/voice/${id}`;
      default:
    }
  };

  const { data, isLoading } = useQuery({ queryKey: ["recentMessages"], queryFn: getRecent });
  const { data: top, isLoading: loadTop } = useQuery({ queryKey: ["topPerforming"], queryFn: getTopPerforming });
  const recentMessaging = data?.data;
  const topPerforming = top?.data;
  localStorage.setItem("tagData", []);

  const handleTabChange = (e) => {
    setStep(e);
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return <EmailTab />;
      case 2:
        return <SmsTab />;
      case 3:
        return <VoiceTab />;

      default:
    }
  };

  const surveyTypeFunc = (str) => {
    if (str === "robocall") {
      return "voice";
    } else return str;
  };

  return (
    <div className="">
      <div className="border-[1px] rounded-lg h-[240px] w-[100%] bg-white ">
        <div className="bg-contactBg bg-cover h-[100%] flex items-center justify-center ">
          <div className="w-[40%] space-y-4">
            <Typography.H3 className="font-semibold text-primary-800">Reach and engage your audience</Typography.H3>

            <Typography.SubText className="w-[80%]">
              Let your audience hear from you. Share messages with your uploaded contacts to expand your reach.
            </Typography.SubText>

            <BlueButton text="Get started" onClick={() => navigate("setup")} />
          </div>

          <div className="w-[40%] h-[100%]">
            <img src={broadcastBucket} alt="researchGif" className="h-[100%]" />
          </div>
        </div>
      </div>
      <div>
        <Typography.Text className="pt-5 flex items-center w-fit space-x-2">
          <p>Overview </p>
          <Tooltip text="This shows a breakdown of all campaigns sent, opened and clicked." />
        </Typography.Text>
      </div>
      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <Typography.SubText
              className={` pb-[4px] cursor-pointer ${
                step === el.id ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.id !== 4 ? () => handleTabChange(el.id) : null}
              key={el.id}
            >
              {el.name}
            </Typography.SubText>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
      <div className="flex items-center justify-between space-x-6 mt-4">
        <div className="bg-white rounded-lg p-4 border-[1px] w-[50%] h-[29rem]">
          <Typography.Text className="pb-2">Recent campaigns</Typography.Text>

          <div className="flex items-start justify-between">
            <div className="flex flex-col justify-center items-center">
              {recentMessaging?.slice(0, 4).map((el) => (
                <div className="flex flex-col justify-center items-center" key={`line1=${el}`}>
                  <div className="w-[10px] h-[10px] border-[2px] border-primary-500  rounded-full bg-primary-300"></div>

                  <div className="h-[88px] border-l-[1px]"></div>
                </div>
              ))}
            </div>

            <div className="space-y-4 w-[95%]">
              {isLoading ? (
                <>
                  {[1, 2, 3, 4].map((el, i) => (
                    <motion.div
                      key={i}
                      custom={i}
                      initial="hidden"
                      animate="visible"
                      variants={cardVariants}
                      className="relative"
                    >
                      <RecentCardSkeleton key={el} />
                    </motion.div>
                  ))}
                </>
              ) : (
                <>
                  {recentMessaging?.length === 0 ? (
                    <div className="h-[300px] flex items-center justify-center">
                      <RecentsEmptyState />
                    </div>
                  ) : (
                    recentMessaging?.slice(0, 4).map((el) => (
                      <div onClick={() => navigate(campNavigate(el.campaign_channel, el?._id))}>
                        <RecentCards
                          key={`RecentCards-${el?._id}`}
                          newData={el}
                          isLoading={isLoading}
                          // isFetching={isFetching}
                          surveyType={surveyTypeFunc(el.campaign_channel)}
                        />
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 border-[1px] w-[50%] h-[29rem]">
          <Typography.Text className="pb-2">Top performing campaign</Typography.Text>

          <div className="flex items-start justify-between">
            <div className="flex flex-col justify-center items-center">
              {topPerforming?.slice(0, 4)?.map((el) => (
                <div className="flex flex-col justify-center items-center" key={`line1=${el}`}>
                  <div className="w-[10px] h-[10px] border-[2px] border-primary-500  rounded-full bg-primary-300"></div>

                  <div className="h-[88px] border-l-[1px]"></div>
                </div>
              ))}
            </div>
            <div className="space-y-4 w-[95%]">
              {loadTop ? (
                <>
                  {[1, 2, 3, 4].map((el, i) => (
                    <motion.div
                      key={i}
                      custom={i}
                      initial="hidden"
                      animate="visible"
                      variants={cardVariants}
                      className="relative"
                    >
                      <RecentCardSkeleton key={el} />
                    </motion.div>
                  ))}
                </>
              ) : (
                <>
                  {topPerforming?.length === 0 ? (
                    <div className="h-[300px] flex items-center justify-center">
                      <RecentsEmptyState />
                    </div>
                  ) : (
                    topPerforming?.slice(0, 4).map((el) => (
                      <RecentCards
                        key={`RecentCards-${el.id}`}
                        newData={el}
                        isLoading={isLoading}
                        // isFetching={isFetching}
                        surveyType={surveyTypeFunc(el.campaignType)}
                      />
                    ))
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
