import React, { useState } from "react";

import Typography from "utils/typography";

import { SmsIconResizable } from "assets/icons/sidebar";
import { MessagingIconReziable } from "assets/icons/sidebar";

const cardData = [
  {
    id: 1,
    name: "Emails",
    value: "email",
    description: "Gather quality feedback, compose, send or schedule email surveys to your contacts.",
    image: MessagingIconReziable,
  },
  {
    id: 2,
    name: "SMS",
    value: "sms",
    description: "Gather quality feedback, compose, send or schedule SMS surveys to your contacts.",
    image: SmsIconResizable,
  },
];
const DispatchChannel = ({ setSurveyData }) => {
  const [selected, setSelected] = useState();
  const [hover, setHover] = useState();

  const handleSelect = (el) => {
    setSurveyData((prevData) => ({
      ...prevData,
      dispatchChannel: el,
    }));
    setSelected(el);
  };

  const handleHover = (id) => {
    setHover(hover === id ? null : id);
  };

  return (
    <div className="">
      <div className="bg-white h-full rounded border-[1px] p-6 mt-3">
        <Typography.SubText className="pb-4 font-[600]">How would you like to share your survey?</Typography.SubText>
        <div className="flex items-center justify-center space-x-7 py-1">
          {cardData.map((el) => (
            <div
              key={el.id}
              className={`border-[1px] rounded-md h-[200px] w-full flex flex-col items-center overflow-hidden cursor-pointer ${
                el.value === selected || el.value === hover ? "border-primary-800" : ""
              }`}
              onClick={() => handleSelect(el.value)}
              onMouseEnter={() => handleHover(el.value)}
              onMouseLeave={() => setHover(false)}
            >
              <div
                className={`${
                  el.value === selected || el.value === hover ? "bg-primary-50" : "bg-gray-50"
                } hover:bg-primary-50 h-[45%] w-[100%] flex items-start justify-between  p-4`}
              >
                <div className="w-[90%] h-[100%] flex items-center justify-center">
                  {/* <img src={el.image} alt={el.name} className="w-[50px] mx-auto" /> */}
                  <el.image
                    fill1={el.value === hover || el.value === selected ? "#95ADFF" : "#D4D4D4"}
                    fill2={el.value === hover || el.value === selected ? "#1D19AF" : "#404040"}
                    width="55"
                    height="55"
                  />
                </div>

                <div
                  className={`border-[1px] rounded-full  h-[15px] w-[15px] flex items-center justify-center ${
                    el.value === selected ? "border-primary-800 " : "border-gray-400 bg-white"
                  }`}
                >
                  {el.value === selected ? <div className="h-[9px] w-[9px] rounded-full bg-primary-800"> </div> : null}
                </div>
              </div>

              <div className="text-center p-4  space-y-2 h-[45%] flex items-center flex-col justify-center w-[100%]">
                <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>

                <p className=" mx-auto text-[11px]">{el.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DispatchChannel;
