export const OptionalIcon = () => (
  <svg width="17" height="17" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.90635 2.48633C2.22787 2.48633 0.867188 3.84701 0.867188 5.52549C0.867188 7.20398 2.22787 8.56466 3.90635 8.56466H6.51135C8.18984 8.56466 9.55052 7.20398 9.55052 5.52549C9.55052 3.84701 8.18984 2.48633 6.51135 2.48633H3.90635Z"
      fill="#95ADFF"
    />
    <path
      d="M6.51177 4.00586C5.67253 4.00586 4.99219 4.6862 4.99219 5.52544C4.99219 6.36469 5.67253 7.04503 6.51177 7.04503C7.35102 7.04503 8.03135 6.36469 8.03135 5.52544C8.03135 4.6862 7.35102 4.00586 6.51177 4.00586Z"
      fill="#1D19AF"
    />
  </svg>
);
