import React, { useState } from "react";
import moment from "moment";
import { campStatus } from "lib/campaignFunction";
import { toTitleCase } from "lib/titleCase";
import { getTemplateEmail } from "services/externalService";
import { useQuery } from "@tanstack/react-query";
import "./HtmlContent.css";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";

const Details = ({ data }) => {
  const [htmlContent, setHtmlContent] = useState("");

  const templateUrl = JSON.parse(data?.campaignContent).filePath;
  const { isLoading } = useQuery({
    queryKey: ["getTemplateEmail", templateUrl],
    queryFn: () => getTemplateEmail(templateUrl),
    onSuccess: (data) => {
      setHtmlContent(data.data);
    },
  });

  console.log("data", templateUrl);
  return (
    <div className="bg-white p-4 rounded-md border-[1px]">
      <div className="flex items-start justify-between gap-4">
        <div className="w-[50%] p-5 border rounded-lg space-y-3 bg-white">
          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Objective</p>
            <p className=" text-black font-[500]">{data?.campaignObjective}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Type</p>
            <p className=" text-black font-[500]">{toTitleCase(data?.CampaignChannel)}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Published</p>
            <p className=" text-black font-[500]">{moment(data?.campaignDate).format("MMMM Do YYYY, h:mm:ss a")}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px] items-center">
            <p className="w-[30%] text-[#525252] font-[400]">Tag</p>
            <div className="flex space-x-4">
              {data?.audience?.personalContacts?.map((el, i) => {
                return (
                  <p key={i} className="text-[12px] p-2 bg-gray-200 rounded-md">
                    {el}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Status</p>
            <p className=" text-black font-[500]">{campStatus(data?.campaignStatus)}</p>
          </div>
          <div className="text-m flex justify-between p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Unit spent</p>
            <p className=" text-[20px] font-[700] text-primary-700">{data.campaignCost}</p>
          </div>
        </div>

        <div className="w-[50%] border-[1px] rounded-md h-[100%] overflow-hidden">
          <div className="w-full h-[60px] flex justify-center items-center px-5  bg-white border-b-[1px]">
            <Typography>Preview</Typography>
          </div>

          {isLoading ? (
            <div className="p-4">
              <Skeleton className="h-[100px]" />
              <Skeleton className="h-[50px]" />
              <Skeleton className="h-[40px]" />
              <Skeleton className="h-[30px]" />
              <Skeleton className="h-[30px]" />
              <Skeleton className="h-[30px]" />
              <Skeleton className="h-[20px]" />
              <Skeleton className="h-[15px]" />
              <Skeleton className="h-[15px]" />
              <Skeleton className="h-[20px]" />
              <Skeleton className="h-[20px]" />
              <Skeleton className="h-[15px]" />
              <Skeleton className="h-[15px]" />
            </div>
          ) : (
            <div className="html-container">
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} className="w-[90%]" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
