import { useQuery } from "@tanstack/react-query";
import { getAllTags } from "services/contactService";

const useGetAllTags = () => {
  const { data: tagData, isLoading: loadingTags } = useQuery({
    queryKey: ["allTags"],
    queryFn: () => getAllTags("name"),
  });

  return {
    tagData,
    loadingTags,
  };
};

export default useGetAllTags;
