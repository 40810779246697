import DeleteButton from "components/Button/DeleteButton";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import deleteOwl from "assets/images/deleteOwl.png";
import React from "react";

const DeleteContact = ({ toggleModal, deleteContactsFunc, title, content }) => {
  return (
    <Modal title={title} onClose={toggleModal}>
      <div className="w-[500px]">
        <div className="flex justify-center py-2 bg-red-100 mt-3">
          <div className="w-[208px]">
            <img src={deleteOwl} alt="" />
          </div>
        </div>
        <p className="px-5 my-5 text-center text-s">{content}</p>
        <div className="flex justify-center border-t px-5 pt-2">
          {/* <div></div> */}
          <div className="flex gap-[1em]">
            <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={toggleModal} />
            <DeleteButton text="Yes, Delete" onClick={deleteContactsFunc} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteContact;
