import { useSuspenseQuery } from "@tanstack/react-query";
import { getWorkspaceToken } from "helpers/authHelper";
const { getInsight } = require("services/insightService");

const useInsight = (insightType) => {
  const workspaceToken = getWorkspaceToken();
  const { data: insightData, isLoading: loadingInsight } = useSuspenseQuery({
    queryKey: [`getInsight-${insightType}`],
    queryFn: () => getInsight(insightType),
    enabled: !!workspaceToken,
  });

  return {
    insightData,
    loadingInsight,
  };
};

export default useInsight;
