import Api from "utils/axios";
import BACKEND_URLS from "utils/backendUrls";
import { handleApiError } from "utils/handleApiError";

export const getTemplateEmail = async (templateUrl) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.BROADCAST.GET_EMAIL_TEMPLATE(templateUrl)}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
