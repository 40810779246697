import React from "react";
import ToolTip from "components/Tooltip";
import Typography from "utils/typography";

const SecondaryInput = ({
  text,
  type,
  placeholder,
  onChange,
  onBlur,
  error,
  value,
  name,
  disabled,
  min,
  tooltip,
  defaultValue = "",
  ...rest
}) => {
  return (
    <div className="text-primary flex flex-col w-[100%]">
      <div className="flex items-center gap-2">
        <Typography.SubText className="font-semibold" htmlFor={name}>
          {text}
        </Typography.SubText>
        {tooltip && <ToolTip text={tooltip} />}
      </div>
      <input
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={rest.onFocus}
        onBlur={onBlur}
        value={value}
        name={name}
        required={rest.required}
        className="rounded-[5px] border-[1px] border-[#D0D5DD] h-[40px] pl-2 outline-none my-1 bg-[#FAFAFA] text-xs placeholder:font-[450] placeholder:text-xs placeholder:text-[#A3A3A3]"
        disabled={disabled}
        min={min}
      />
      <p className="text-ss text-red-500">{error}</p>
    </div>
  );
};

export default SecondaryInput;
