import { useQuery } from "@tanstack/react-query";
import { getRates } from "services/rates";

const useCurrency = () => {
  const rates = useQuery({
    queryKey: ["rates"],
    queryFn: getRates,
  });
  const conversion = rates?.data?.data[0].currencyRates;

  const getUserRateByCode = (codeName) => {
    const currency = conversion?.find((rate) => rate.code === codeName);
    return currency ? currency.userRate : null;
  };

  const conversionRate = Math.floor(getUserRateByCode("USD-NGN") ?? 0);

  return {
    conversionRate,
  };
};

export default useCurrency;
