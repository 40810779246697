import dayjs from "dayjs";
import React, { useState } from "react";
import { PiDotOutlineFill } from "react-icons/pi";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";

import { Messaging } from "assets/svgs/Messaging";
import { SmsIcon } from "assets/svgs/SmsIcon";
import { Volume } from "assets/svgs/Volume";
import { toTitleCase } from "lib/titleCase";

const RecentCards = ({ newData, isLoading, isFetching, surveyType }) => {
  const [hover, setHover] = useState(null);

  const handleHover = (id) => {
    setHover(id === hover ? null : id);
  };
  const icon = () => {
    switch (surveyType) {
      case "email":
        return (
          <Messaging
            fill1={hover === newData?._id ? "#95ADFF" : "#D4D4D4"}
            fill2={hover === newData?._id ? "#1D19AF" : "#404040"}
          />
        );
      case "sms":
        return (
          <SmsIcon
            fill1={hover === newData?._id ? "#95ADFF" : "#D4D4D4"}
            fill2={hover === newData?._id ? "#1D19AF" : "#404040"}
          />
        );
      case "voice":
        return (
          <Volume
            fill1={hover === newData?._id ? "#95ADFF" : "#D4D4D4"}
            fill2={hover === newData?._id ? "#1D19AF" : "#404040"}
          />
        );
      default:
    }
  };
  return (
    <div
      className={`flex items-center space-x-2 bg-[#F8FAFFB2] border-[1px] p-3 rounded-lg cursor-pointer hover:border-primary-800 w-[100%]`}
      onMouseEnter={() => handleHover(newData?._id)}
      onMouseLeave={() => handleHover(null)}
    >
      <div className="w-[60%] px-3 space-y-3">
        {isLoading || isFetching ? (
          <Skeleton className="w-[70%]" />
        ) : (
          <Typography.SubText className="font-semibold w-[100%] flex items-center space-x-2 truncate ... ">
            <span>{icon()}</span>
            <span>{newData?.campaign_name}</span>
          </Typography.SubText>
        )}

        {isLoading || isFetching ? (
          <Skeleton className="w-[90%]" />
        ) : (
          <div className="flex items-center w-[100%]">
            <Typography.MicroText>{surveyType === "sms" ? "SMS" : toTitleCase(surveyType)}</Typography.MicroText>
            <PiDotOutlineFill className="text-gray-500" />
            <Typography.MicroText>
              {dayjs(newData?.campaign_date).format("ddd, DD MMM, YYYY | hh:mm a")}
            </Typography.MicroText>
          </div>
        )}
      </div>

      <div className="border-l-[1px] w-[1px] pr-2 h-[60px] border-gray-300"></div>

      <div className="w-[20%] space-y-2">
        {isLoading || isFetching ? (
          <Skeleton className="w-[100%]" />
        ) : (
          <Typography.MicroText>Delivered: {newData?.totalReceiver} </Typography.MicroText>
        )}

        {isLoading || isFetching ? (
          <Skeleton className="w-[100%]" />
        ) : (
          <div className="bg-neutral-200 rounded-lg h-[7px] overflow-hidden">
            <div
              className="bg-success-600 h-[100%]"
              style={{
                width: newData?.responses !== 0 ? `${(newData?.totalReceiver / 100) * 100}%` : "0%",
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentCards;
