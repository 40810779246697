import { useQuery } from "@tanstack/react-query";
import { getSurveyOverview } from "services/newSurveyService";

const useSurveyOverview = ({ key, surveyType }) => {
  const { data: statsData, isFetching: fetchingOverview } = useQuery({
    queryKey: [key],
    queryFn: () => getSurveyOverview({ surveyType }),
  });

  const overviewData = statsData?.data;

  return {
    overviewData,
    fetchingOverview,
  };
};

export default useSurveyOverview;
