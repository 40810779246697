import { handleApiError } from "utils/handleApiError";
import Api from "utils/axios";

export const createContact = async (payload) => {
  try {
    const { data } = await Api.post("/contact/single-contact-create/", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createCustom = async (payload) => {
  try {
    const { data } = await Api.post(`/contact/custom-field/create`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllCustomFields = async () => {
  try {
    const { data } = await Api.get(`/contact/custom-field/fetch`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getContactDashboard = async (payload) => {
  try {
    const { data } = await Api.get("/contact/dashboard");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllContactHome = async (page, sortBy, searchVal) => {
  const urlPath = searchVal
    ? `/contact/contacts/${page}?sort=${sortBy}&search=${searchVal}}`
    : `/contact/contacts/${page}?sort=${sortBy}`;

  try {
    const { data } = await Api.get(urlPath);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllContacts = async (page, sortBy, searchVal, perPage) => {
  const urlPath = searchVal
    ? `/contact/contacts/${page}?sort=${sortBy}&search=${searchVal}&perPage=${perPage}`
    : `/contact/contacts/${page}?sort=${sortBy}&perPage=${perPage}`;

  try {
    const { data } = await Api.get(urlPath);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllLeads = async () => {
  // const urlPath = searchVal
  // ? `/contact/contacts/all-leads${page}?sort=${sortBy}&search=${searchVal}`
  // : `/contact/contacts/${page}?sort=${sortBy}`;

  try {
    const { data } = await Api.get(`/contact/contacts/all-leads`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllLead = async () => {
  // const urlPath = searchVal
  // ? `/contact/contacts/all-leads${page}?sort=${sortBy}&search=${searchVal}`
  // : `/contact/contacts/${page}?sort=${sortBy}`;

  try {
    const { data } = await Api.get(`/contact/all-leads`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getSingleContact = async (contactId) => {
  const urlPath = `contact/contacts/single/${contactId}`;

  try {
    const { data } = await Api.get(urlPath);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getContactsBySegment = async (groupName, page) => {
  try {
    const { data } = await Api.get(`/contact/contacts/segment/${groupName}/${page}?sort=name`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getContactsByTag = async (tagName, page) => {
  try {
    const { data } = await Api.get(`/contact/contacts/tag/${tagName}/${page}?sort=recently_added&fetchAll=true`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteContacts = async (payload) => {
  try {
    const { data } = await Api.delete("/contact/contacts/delete", {
      data: payload,
    });
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createSegment = async (payload) => {
  try {
    const { data } = await Api.post("/contact/create-segment", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const addToTags = async (payload) => {
  try {
    const { data } = await Api.post("/contact/add-to-tag", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const editSegment = async ({ payload, id }) => {
  try {
    const { data } = await Api.put(`/contact/edit-segment/${id}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllSegments = async (sortBy) => {
  try {
    const { data } = await Api.get(`/contact/segments?sort=${sortBy}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const segmentation = async (payload) => {
  try {
    const { data } = await Api.post(`/contact/segmentation`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const addContactToTag = async (payload) => {
  try {
    const { data } = await Api.post(`/contact/add-to-tag`, payload);
    return data;
  } catch (error) {
    throw handleApiError;
  }
};

export const getContactSpace = async () => {
  try {
    const { data } = await Api.get(`/contact/count-remaining-contact`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteSegments = async (payload) => {
  try {
    const { data } = await Api.delete("/contact/segment", { data: payload });
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createTag = async (payload) => {
  try {
    const { data } = await Api.post("/contact/create-tag", payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAllTags = async () => {
  try {
    const { data } = await Api.get(`/contact/tags`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteTag = async (payload) => {
  try {
    const { data } = await Api.delete(`/contact/tag`, {
      data: payload,
    });
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteCustom = async (payload) => {
  try {
    const { data } = await Api.delete(`/contact/custom-field/delete`, {
      data: payload,
    });
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const editTag = async ({ payload, id: tagId }) => {
  try {
    const { data } = await Api.put(`/contact/edit-tag/${tagId}`, payload);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const editContact = async ({ payload: newData, id: contactId }) => {
  try {
    const { data } = await Api.patch(`/contact/edit/${contactId}`, newData);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCSVData = async (payload) => {
  try {
    const { data } = await Api.post("/contact/export", payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const upcomingEvents = async () => {
  try {
    const { data } = await Api.get("/contact/upcoming-event?daysAhead=7");
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
