import { useState } from "react";
import TopUpModal from "./TopUpModal";
import { SingleContactIcon } from "assets/icons/dashboard/ContactIcon";
import { RewardIcon } from "assets/icons/dashboard/RewardIcon";
import { SendsIcon } from "assets/icons/dashboard/SendsIcon";
import { SurveyIcon } from "assets/icons/sidebar";
import Email from "./Email";
import Sms from "./Sms";
import Voice from "./Voice";
import useCredit from "hooks/useCredit";
import useSubscription from "hooks/usesubscription";
import { formatDateAlone, getDaysLeft } from "lib/formatDate";
import { toTitleCase } from "lib/titleCase";
import { FaArrowUp } from "react-icons/fa";
import CreditCard from "components/BalanceCard";

const tabs = [
  { name: "Email", value: 0 },
  { name: "SMS", value: 1 },
  { name: "Voice message", value: 2 },
];

const Campaign = () => {
  const { wsCredit } = useCredit();
  const { subscription } = useSubscription();
  const [open, setOpen] = useState(false);

  const [step, setStep] = useState(0);

  const displayStep = () => {
    switch (step) {
      case 0:
        return <Email />;
      case 1:
        return <Sms />;
      case 2:
        return <Voice />;
      default:
    }
  };

  const balances = [
    {
      id: 1,
      name: "Contacts",
      count: wsCredit?.contact,
      toolTip: "This shows the available contacts in your database against limits you have on your current plan.",
      icon: SingleContactIcon,
    },
    {
      id: 2,
      name: "Sends",
      count: wsCredit?.sends,
      toolTip: "Available credits for emails and SMS to send to your audience.",
      icon: SendsIcon,
    },

    {
      id: 3,
      name: "Research responses",
      count: wsCredit?.research,
      toolTip: "Available credits for demographic, detailed targeting and research responses. ",
      icon: SurveyIcon,
    },
    {
      id: 4,
      name: "Mooyi rewards credits",
      count: wsCredit?.incentives,
      toolTip: "Your available Mooyi rewards credits to incentivise your respondents.",
      icon: RewardIcon,
    },
  ];

  const handleTabChange = (e) => {
    setStep(e);
    // setSearchBarParams("");
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  // const totalUsage = wsUsage?.email + wsUsage?.sms + wsUsage?.robocall;

  // const calculateUsage = (x) => {
  //   return `w-[${Math.floor((x / totalUsage) * 100)}%]`;
  // };

  return (
    <div>
      <div className="flex gap-[1em] my-1">
        <div className="min-h-full bg-primary-50 rounded-[8px] w-[50%]">
          <div className="bg-creditBg bg-cover bg-center bg-no-repeat h-[100%] w-[100%]  p-4 px-8 flex justify-between">
            <div className="space-y-2">
              {subscription !== undefined ? (
                <div>
                  <p className="text-xl font-semibold text-primary-800">{toTitleCase(subscription?.plan)} plan</p>
                  <p className="text-[12px]"></p>
                </div>
              ) : (
                <p className="text-xl font-semibold text-primary-800">SME plan</p>
              )}
            </div>

            <div className="flex flex-col justify-between">
              <div>
                <div className="flex items-center justify-end text-sm text-neutral-600 space-x-2">
                  <p>Next subscription date</p>:
                  <p className="text-primary-800 font-semibold text-lg">
                    {subscription !== undefined ? formatDateAlone(subscription?.ends_on) : "24th March 2023"}
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <p></p>

                  <p className="text-sm mt-2 text-neutral-600">
                    {subscription !== undefined ? getDaysLeft(subscription?.ends_on) : "13"} days left
                  </p>
                </div>
              </div>

              <div className="flex space-x-4 mt-[2em] justify-end">
                {/* <div
                  className="flex justify-center items-center bg-primary-600  text-white hover:bg-primary-800  w-[160px] rounded-[6px] h-[40px] px-4 space-x-1 cursor-pointer"
                  onClick={() => navigate("/pricing")}
                >
                  <p className="text-s font-semibold ">Upgrade plan</p>
                </div> */}
                <div
                  onClick={() => handleModal(1)}
                  className="flex justify-center items-center bg-primary-800  text-white hover:bg-primary-600  w-[160px] rounded-[6px] h-[40px] px-4 space-x-1 cursor-pointer"
                >
                  <p className="text-s font-semibold ">Top Up Bundle</p>
                  <FaArrowUp />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 w-[50%] h-full rounded-[12px] border-[1px] bg-white">
          <p className="font-[600] text-[14px]">Account balance</p>
          <div className="grid grid-cols-2 gap-2 mt-3">
            {balances.map((el, i) => (
              <div className="" key={i}>
                <CreditCard name={el.name} icon={el.icon} tooltipContent={el.toolTip} id={el.id} count={el.count} />
                <div>
                  <hr />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div> */}

      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[4px] px-2 cursor-pointer ${
                step === el.value ? "border-b-[2px] text-[#0F172A] border-purple font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.value !== 4 ? () => handleTabChange(el.value) : null}
              key={el.value.toLocaleString()}
            >
              {el.name}
            </p>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>

      {open === 1 ? <TopUpModal handleModal={handleModal} /> : null}
    </div>
  );
};

export default Campaign;
