import React from "react";

const AdditionalPriceTag = ({ type, amount }) => {
  return (
    <div
      className={`border bg-[#EBF1FF] h-full p-5 space-y-2 w-full opacity-80 hover:opacity-100 transition-all duration-300 rounded-lg flex flex-col justify-center`}
    >
      <div className="pt-3">
        <p className={`text-[14px] font-secondary transition-all duration-300 tracking-tight leading-tight `}>{type}</p>
        <div className="flex items-end w-full text-[30px] font-[600] mt-5">{amount}</div>
        <p className={` text-[14px] transition-all duration-300 font-medium`}>credits per response</p>
      </div>
    </div>
  );
};

export default AdditionalPriceTag;
