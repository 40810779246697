import React, { useState } from "react";
import useBranchingStore from "store/branchingStore";
import Typography from "utils/typography";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { BranchingIcon } from "assets/icons/survey/BranchingIcon";
import { questionIcon } from "pages/Insight/components/builder/questionTypes";
import { cn } from "helpers/classHelpers";

const SidePanel = () => {
  const [open, setOpen] = useState(null);

  const { sectionArray, setSectionIndex, sectionIndex } = useBranchingStore();

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleStuff = (i) => {
    handleOpen(i);
    setSectionIndex(i);
  };

  return (
    <div className="w-[100%] h-[100%] p-4 ">
      <div>
        {sectionArray?.map((el, i) => (
          <div className="mb-3">
            <div
              className={cn(
                "flex items-center justify-between w-[100%] border-[1px] shadow h-[40px] px-4  cursor-pointer transition duration-300 ease-in-out",
                sectionIndex === i ? "bg-primary-50" : " hover:bg-gray-50",
              )}
              onClick={() => handleStuff(i)}
            >
              <div className="flex items-center space-x-4 w-[90%]">
                <div className="w-[15px]">
                  <BranchingIcon
                    fill1={open === i || sectionIndex === i ? "#95ADFF" : "#A3A3A3"}
                    fill2={open === i || sectionIndex === i ? "#1D19AF" : "#404040"}
                  />
                </div>

                <Typography.MicroText
                  className={cn("truncate ... w-[100%]", sectionIndex === i ? "font-semibold text-primary-800" : "")}
                >
                  {el.sectionOption}
                </Typography.MicroText>
              </div>

              <motion.div
                initial={{ rotate: 0 }}
                animate={{ rotate: open === i ? 180 : 0 }}
                transition={{ duration: 0.4 }}
              >
                {open === i ? <IoIosArrowDown /> : <IoIosArrowDown />}
              </motion.div>
            </div>

            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: open === i ? 1 : 0, height: open === i ? "auto" : 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{ overflow: "hidden" }} // Ensures that the content is hidden when height is 0
            >
              <div className="border-[1px] rounded mb-4 pb-2 space-y-2">
                <div className="px-4 pt-4 space-y-1">
                  <Typography.MicroText className="truncate ... w-[100%]">Branch option</Typography.MicroText>

                  <div className="border-[1px] rounded-md bg-gray-50 py-3 px-3">
                    <Typography.MicroText className="truncate ... w-[100%]">{el.sectionOption}</Typography.MicroText>
                  </div>
                </div>

                <div>
                  <Typography.MicroText className="truncate ... w-[100%]  p-4">Questions</Typography.MicroText>

                  {el.questions.map((el) => (
                    <div className="flex items-center space-x-2 h-[40px] px-4 bg-gray-50 my-1 cursor-pointer">
                      <div className="w-[25px] text-gray-700">{questionIcon(el.questionType)}</div>
                      <Typography.MicroText className="truncate ... w-[100%]">{el.question}</Typography.MicroText>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidePanel;
