import Tooltip from "components/Tooltip";
import React, { useCallback, useEffect, useState } from "react";
import Toggle from "react-toggle";
import Mascot from "assets/images/mascotOne.png";
import { useMutation, useQuery } from "@tanstack/react-query";
import WordTag from "components/common/WordTag";
import { useNavigate, useParams } from "react-router-dom";
import { createSurveyTarget } from "services/newSurveyService";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import SaveAndExitModal from "./SaveAndExitModal";
import UploadNewContactModal from "./UploadNewContactModal";
import BottomBar from "./BottomBar";
import SurveyDetailsModal from "./SurveyDetailsModal";
import PrimaryInput from "components/Inputs/PrimaryInput";
import Typography from "utils/typography";
import SavedContacts from "./Contacts/SavedContacts";
import { fetchBySurveyId } from "services/newSurveyService";
import Modal from "components/Modal";
import DispatchChannel from "./DispatchChannel";

const reminder = [
  {
    id: 1,
    name: "Same audience",
    value: 1,
  },
  {
    id: 2,
    name: "New audience",
    value: 2,
  },
];

const SavedContact = ({ audienceType, step, setStep }) => {
  const [surveyData, setSurveyData] = useState({
    audienceType: audienceType,
    expectedResponse: "",
    reward: "",
    sendReminderAfter24hrs: false,
    dispatchChannel: "",
    shareToPublic: false,
    saveLeads: false,
  });

  const [disableButton, setDisabledButton] = useState(true);
  const [recipientArray, setRecipientArray] = useState([]);
  const [additionalRecipient, setAdditionalRecipient] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [contactModal, setContactModal] = useState(false);
  const [active, setActive] = useState(null);

  const [open, setOpen] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: draftedData } = useQuery({
    queryKey: ["fetchBySurveyId", id],
    queryFn: () => fetchBySurveyId(id),
    enabled: !!id,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: createSurveyTarget,
    onSuccess: () => {
      Toast.success("Audience saved successfully");
      setStep(step + 1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  useEffect(() => {
    setSurveyData((prevSurveyData) => {
      const updatedRecipientArray = [];

      if (recipientArray.length > 0) {
        updatedRecipientArray.push(recipientArray);
      }

      if (additionalRecipient.length > 0) {
        updatedRecipientArray.push(additionalRecipient);
      }

      return {
        ...prevSurveyData,
        ...(updatedRecipientArray.length > 0 && { recipientArray: updatedRecipientArray }),
      };
    });
  }, [recipientArray, additionalRecipient]);

  const { mutate: saveMutate, isPending: saveLoading } = useMutation({
    mutationFn: createSurveyTarget,
    onSuccess: () => {
      navigate(`/surveys`);
      queryClient.invalidateQueries({ queryKey: ["allSurvey"] });
      Toast.success("Audience saved successfully");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleClick = (id) => {
    setActive(id !== null ? id : null);
  };

  const handleSavedContactInputs = (e) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]:
        (e.target.name === "expectedResponse") | (e.target.name === "reward")
          ? Number(e.target.value.replace(/[^0-9]/g, 0))
          : e.target.value.replace(/[^0-9]/g, ""),
    });
  };

  const handleForeignChange = (event) => {
    const { name, value } = event.target;

    setSurveyData((prevData) => ({
      ...prevData,
      foreignVoucher: {
        ...prevData.foreignVoucher,
        [name]: value,
      },
    }));
  };

  const handleToggles = (e, value) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]: value,
    });
  };

  const handleSave = () => {
    if (surveyData.dispatchChannel !== "") {
      const newSurveyData = {
        ...surveyData,
        expectedResponse: +surveyData.expectedResponse,
        reward: +surveyData.reward,
      };
      mutate({
        surveyId: id,
        payload: { ...newSurveyData, contactTag: recipientArray.map((el) => el.name) },
      });
    } else {
      setOpenModal(true);
    }
  };

  const handleSaveAndExit = () => {
    const newSurveyData = {
      ...surveyData,
      expectedResponse: +surveyData.expectedResponse,
      reward: +surveyData.reward,
    };
    saveMutate({
      surveyId: id,
      payload: { ...newSurveyData, contactTag: recipientArray.map((el) => el.name) },
    });
  };

  const checkState = useCallback(() => {
    if (surveyData.dispatchChannel !== "" && recipientArray.length > 0 && surveyData.expectedResponse) {
      setDisabledButton(false);
    }
  }, [recipientArray.length, surveyData.dispatchChannel, surveyData.expectedResponse]);

  useEffect(() => {
    checkState();
  }, [checkState]);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  if (isPending || saveLoading) {
    return <PreLoader />;
  }

  return (
    <div>
      <div>
        {open === 4 ? <SaveAndExitModal handleModal={handleModal} callBackFn={handleSaveAndExit} /> : null}

        {open === "openSurveyModal" ? (
          <SurveyDetailsModal handleOpen={handleModal} from="onlineSurvey" fromStep={true} />
        ) : null}

        <div>
          <div className="bg-primary-50 bg-no-repeat w-[100%] bg-cover p-4 rounded-md border-[1px] flex justify-between items-center mt-[1em]">
            <div className="flex items-center">
              <img src={Mascot} alt="Mascot" className="w-[90px]" />

              <div>
                <div className="flex items-center">
                  <p className="text-s font-bold">Enable link sharing</p>
                  <Tooltip text="" />
                  <WordTag text="Recommended" />
                </div>

                <p className="text-ss mt-2">
                  Enable this to generate a shareable link you can send to your preferred contacts.
                </p>
              </div>
            </div>

            <label>
              <Toggle
                checked={surveyData?.shareToPublic}
                icons={false}
                onChange={(e) => handleToggles(e, !surveyData.shareToPublic)}
                name="shareToPublic"
              />
            </label>
          </div>
        </div>
      </div>
      <div className="my-[1.5em] flex justify-between">
        <div className="w-[50%]">
          <SavedContacts
            data={surveyData}
            setData={setSurveyData}
            populatedArray={recipientArray}
            setPopulatedArray={setRecipientArray}
          />

          <div>
            <DispatchChannel
              surveyData={surveyData}
              setSurveyData={setSurveyData}
              handleSave={handleSave}
              handleClose={() => setOpenModal(false)}
            />
          </div>
        </div>

        <div className="border-[1px] rounded-md p-4 w-[45%]">
          <div>
            <div className="flex items-center text-s space-x-1">
              <p>Responses Needed</p>
              <Tooltip />
            </div>

            <p className="text-s text-[#737373]">How many responses do you need?</p>

            <PrimaryInput
              placeholder="200"
              onChange={handleSavedContactInputs}
              name="expectedResponse"
              type="tel"
              value={surveyData.expectedResponse}
            />
          </div>

          <div className="pt-3">
            <div className="flex items-center text-s space-x-1">
              <p>Reward per response</p>
              <Tooltip text="We recommend offering rewards to get a complete 360° view of your respondents" />
            </div>
            <PrimaryInput
              // text="Rewards per response"
              placeholder="400 credits"
              onChange={handleSavedContactInputs}
              name="reward"
              type="tel"
              value={surveyData.reward}
            />
          </div>
          <div className="my-5">
            <hr />
          </div>
          <div className="">
            <div className="flex items-center text-s space-x-1">
              <p>(Optional) Add a promo code</p>
              <Tooltip text="Give respondents vouchers or promo codes they can use to patronise your business" />
            </div>
            <PrimaryInput
              placeholder="0"
              onChange={handleForeignChange}
              name="voucher"
              type="number"
              value={surveyData.voucher}
            />
          </div>
          <div className="pt-3">
            <div className="flex items-center text-s space-x-1">
              <p>Provide instructions to redeem your offer</p>
              {/* <Tooltip text="The reward per response is the number of credits you give to someone who completes your survey. It's like a thank-you gift for answering all the questions and helps improve your completion time." /> */}
            </div>
            <textarea
              type="text"
              className="border rounded-[8px] w-full mt-2 focus-none outline-none p-2"
              rows={4}
              onChange={handleForeignChange}
              value={surveyData.link}
              name="link"
              placeholder=""
            />
          </div>
        </div>
      </div>
      {draftedData?.data?.surveyDetails?.recurringProp?.recurringInterval !== "" ? (
        <div>
          <div className="space-y-3">
            <Typography.Text>Audience Settings</Typography.Text>
            <Typography.SubText>Select the contacts you want to reshare your survey with.</Typography.SubText>
          </div>
          <p className="flex gap-x-1 items-center heading-4 mb-2"></p>{" "}
          <div className="flex items-center gap-[2em] my-[1em]">
            {reminder.map((el, i) => {
              return (
                <div
                  key={i}
                  className="space-x-2 flex items-center cursor-pointer"
                  onClick={() => handleClick(el.value)}
                >
                  <div
                    className={`w-[15px] h-[15px] border flex items-center justify-center rounded-full p-2  ${
                      active === el.value ? "border-primary-800" : ""
                    } `}
                  >
                    <div
                      className={`${
                        active === el.value ? "bg-primary-800" : ""
                      } rounded-full w-[10px] h-[10px] p-[4px] border`}
                    ></div>
                  </div>
                  <div>
                    <Typography.SubText>{el.name}</Typography.SubText>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {active === 2 ? (
        <div className="w-[50%] mt-5">
          <SavedContacts
            data={surveyData}
            setData={setSurveyData}
            populatedArray={additionalRecipient}
            setPopulatedArray={setAdditionalRecipient}
          />
        </div>
      ) : null}
      <BottomBar
        step={step}
        setStep={setStep}
        handleSave={handleSave}
        handleModal={() => handleModal(4)}
        disabled={disableButton}
      />
      <UploadNewContactModal contactModal={contactModal} setContactModal={setContactModal} />
      {openModal ? (
        <Modal title="How would you like to share your survey?" onClose={() => setOpenModal(false)}>
          <DispatchChannel
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            handleSave={handleSave}
            handleClose={() => setOpenModal(false)}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default SavedContact;
