import React from "react";
import Typography from "utils/typography";

const PrimaryInput = ({ text, type, placeholder, onChange, onBlur, error, value, name, disabled, maxNum = 4 }) => {
  const handleInputChange = (e) => {
    let inputValue = e.target.value;

    // If the type is "number," allow only numeric characters
    if (type === "tel") {
      inputValue = inputValue.replace(/[^0-9]/g, "");
    } else if (type === "pin") {
      const numericInput = inputValue.replace(/[^0-9]/g, "");

      inputValue = numericInput.slice(0, maxNum);
    }

    const customEvent = {
      target: {
        name,
        value: inputValue,
      },
    };

    // Call the onChange callback with the custom event
    onChange(customEvent);
  };

  return (
    <div className="flex flex-col">
      <Typography.SubText className="font-semibold" htmlFor={name}>
        {text}
      </Typography.SubText>
      <input
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={onBlur}
        value={value}
        name={name}
        className={`rounded-[5px] border-[1px] text-xs placeholder:text-xs focus:border-purple border-[#DFE5EC] text-black h-[40px] pl-2 outline-none ${
          text !== "" ? "my-1" : ""
        } bg-[#FAFAFA]`}
      />
      <p className="text-ss text-red-500">{error}</p>
    </div>
  );
};

export default PrimaryInput;
