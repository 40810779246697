import { useQuery } from "@tanstack/react-query";
import { fetchUser } from "services/userService";

const useLoggedInUser = () => {
  const { data: user, isFetching } = useQuery({ queryKey: ["currentUser"], queryFn: fetchUser });

  return {
    user,
    isFetching,
  };
};

export default useLoggedInUser;
