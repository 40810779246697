import React from "react";
import { motion } from "framer-motion";
import usePageStore from "store/usePageStore";
import Typography from "utils/typography";
import useSurveyStore from "store/insightStore";
import SurveyTypes from "./questionsPreview";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import BlueButton from "components/Button/BlueButton";
import Logo from "assets/images/newLogo.png";
import surveyCardImage from "assets/images/surveyCardImage.png";

const containerVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const itemVariants = {
  initial: { opacity: 0, y: 15 },
  animate: { opacity: 1, y: 0 },
};

const PreviewCard = () => {
  const { page, incrementPage, decrementPage } = usePageStore();
  const { surveyQuestionsArray, screenConfig } = useSurveyStore();

  return (
    <div className="h-[70%] bg-white rounded-md w-[90%] mx-auto">
      {page === -1 ? (
        <div className="w-[90%] py-[2em] h-[100%] mx-auto flex flex-col items-center justify-between">
          <img src={Logo} alt="logo" width={70} className="mx-auto" />
          <div className="h-[40%] w-[80%] overflow-hidden rounded-lg">
            <img
              src={screenConfig.welcome.image || surveyCardImage}
              alt="surveyCardImage"
              className="w-[100%] h-[100%] mx-auto object-cover"
            />
          </div>
          <div>
            <motion.div variants={itemVariants} transition={{ delay: 0.4, duration: 0.3 }}>
              <Typography.H4 className="text-center">{screenConfig.welcome.pryText}</Typography.H4>
            </motion.div>

            <motion.div variants={itemVariants} transition={{ delay: 0.6, duration: 0.3 }}>
              <Typography.SubText className="text-center">{screenConfig.welcome.description}</Typography.SubText>
            </motion.div>
          </div>
          <BlueButton text={screenConfig.welcome.buttonText} onClick={incrementPage} />{" "}
        </div>
      ) : null}
      {page >= 0 ? (
        <div className="w-[90%] py-[2em] h-[100%] mx-auto flex flex-col items-center justify-between">
          <motion.div
            key={page} // This key will trigger re-animation on page change
            initial="initial"
            animate="animate"
            variants={containerVariants}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center"
          >
            <motion.div variants={itemVariants} transition={{ delay: 0.2, duration: 0.3 }}>
              <Typography.MicroText className="text-center">
                Question {page + 1} of {surveyQuestionsArray.length}
              </Typography.MicroText>
            </motion.div>
            <motion.div variants={itemVariants} transition={{ delay: 0.4, duration: 0.3 }}>
              <Typography.H4 className="text-center">{surveyQuestionsArray[page]?.question}</Typography.H4>
            </motion.div>
            <motion.div variants={itemVariants} transition={{ delay: 0.6, duration: 0.3 }}>
              <Typography.SubText className="text-center">{surveyQuestionsArray[page]?.description}</Typography.SubText>
            </motion.div>
          </motion.div>

          <motion.div
            key={surveyQuestionsArray[page]?.question}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="w-[100%]"
          >
            {SurveyTypes({ type: surveyQuestionsArray[page]?.questionType, newData: surveyQuestionsArray[page] })}
          </motion.div>

          <div className="flex items-center space-x-4">
            <motion.div
              className="bg-primary-50 w-[40px] h-[40px] rounded-full flex items-center justify-center text-primary-800 text-xl cursor-pointer"
              onClick={decrementPage}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <LuChevronLeft />
            </motion.div>

            <motion.div
              className="bg-primary-800 w-[40px] h-[40px] rounded-full flex items-center justify-center text-primary-50 text-xl cursor-pointer"
              onClick={incrementPage}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <LuChevronRight />
            </motion.div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PreviewCard;
