import React from "react";

const CardList = ({ icon, description, action, priceTag, bgcolor, borderColor }) => {
  return (
    <div
      className={`${bgcolor} ${borderColor} border mt-5 md:mt-0 h-full p-5 space-y-2 w-full opacity-80 hover:opacity-100 transition-all duration-300 rounded-lg flex items-start space-x-5`}
    >
      <div className="w-[50px] h-[50px]">
        <img src={icon} alt="" />
      </div>
      <div className="">
        <p className={`text-[18px] font-[700] transition-all duration-300 tracking-tight leading-tight `}>{action}</p>
        <p className={`text-[14px] pt-2 transition-all duration-300 font-medium`}>{description}</p>
        <div className="flex items-end w-full text-[18px] font-[700]">{priceTag}</div>
      </div>
    </div>
  );
};

export default CardList;
