import React from "react";

export const SendsIcon = ({ fill1, fill2 }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10208_118861)">
      <mask
        id="mask0_10208_118861"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="17"
      >
        <path d="M7.45709 -1.95503e-05L0 9.40167L9.40169 16.8588L16.8588 7.45707L7.45709 -1.95503e-05Z" fill="white" />
      </mask>
      <g mask="url(#mask0_10208_118861)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.2582 10.5866C2.02344 10.1376 1.92174 8.43063 3.09444 7.83814L9.87846 4.4106C11.0206 3.83357 12.3144 4.85976 12.0125 6.10328L10.2195 13.4894C9.9095 14.7662 8.22424 15.0558 7.50588 13.9557L6.21693 11.9819C6.03948 11.7101 5.7787 11.5033 5.47369 11.3924L3.2582 10.5866Z"
          fill={fill1}
        />
        <path
          d="M6.23172 12.0045L6.21694 11.9818C6.03948 11.7101 5.77871 11.5032 5.4737 11.3923L5.44824 11.3831L8.65894 7.3351C8.83055 7.11874 9.14503 7.08247 9.36139 7.25407C9.57774 7.42568 9.61402 7.74017 9.44241 7.95652L6.23172 12.0045Z"
          fill={fill2}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_10208_118861">
        <rect width="12" height="12" fill="white" transform="translate(0 9.40167) rotate(-51.5798)" />
      </clipPath>
    </defs>
  </svg>
);
