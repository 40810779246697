import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import React from "react";

const WorkspaceModal = ({ name, handleModal, status, members, active, handleCheck, checkInArray, selectArray }) => {
  return (
    <Modal title={name} onClose={() => handleModal(null)}>
      <div className="w-[516px] px-5">
        <p className={`text-[14px] ${status === false ? "text-green-500" : "text-red-500"}`}>
          {status === false ? "Active" : "Deactivated"}
        </p>
        <div className="mt-3">
          <p className="mb-2 font-[600]">Members {`(${members?.length})`}</p>
          <div className="space-y-3">
            {members?.map((el, i) => (
              <div key={i} className="space-x-2 flex items-center">
                <input
                  type="checkbox"
                  name="recipient"
                  id="recipient"
                  checked={checkInArray(el?._id, selectArray)}
                  className="pl-0 w-[15px] h-[15px] accent-purple"
                  onChange={(e) => handleCheck(e, el?._id)}
                />
                <p>{el?.fullname}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="my-5">
            <hr />
          </div>
          <div className="flex justify-end items-center space-x-5">
            <div className="">
              <BlueButton text="Remove member" disabled={!active} />
            </div>
            <div className="">
              <BlueButton
                text={status === false ? "Deactivate workspace" : "Reactivate workspace"}
                className={status === false ? "bg-red-500 hover:bg-red-700" : ""}
                type="button"
                onClick={() => handleModal(status === false ? 10 : 11)}
              />
            </div>
          </div>
          {/* ---------------------------Add member --------------------------- */}
          {/* <div>
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-3 mt-2">
                <div className="border-[1px] h-[40px] rounded-[8px] flex justify-between w-[100%] px-4 items-center">
                  <input
                    placeholder="Enter Email address"
                    className="w-[100%] outline-none h-[100%]"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <div>
                    <SelectInput
                      options={options}
                      css="h-[40px] border-none w-[100px] rounded-[6px] text-primary-800 bg-transparent cursor-pointer"
                      value={values.permission}
                      name="permission"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="flex space-x-4">
                  <BlueButton text="Add" type="submit" />
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default WorkspaceModal;
