import { IoPersonCircleSharp } from "react-icons/io5";
import dayjs from "dayjs";
import { serializeMessage } from "lib/personalizationFunctions";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { createTestCampaign } from "services/campaignService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import SavedContacts from "pages/Broadcasts/Contacts/SavedContacts";
import Toggle from "react-toggle";

const Audience = ({ setData, data }) => {
  const { user } = useLoggedInUser();
  const [display, setDisplay] = useState(false);
  const [active, setActive] = useState(false);

  const handleChange = () => {
    setDisplay(!display);
  };

  const handleTelChange = (e) => {
    if (e.target.value.length === 10) {
      setActive(true);
    } else {
      setActive(false);
    }
    setData({
      ...data,
      testContact: e.target.value,
    });
  };
  const { mutate: testMutate } = useMutation({
    mutationFn: createTestCampaign,
    onSuccess: () => {
      Toast.success("Test created");
      queryClient.invalidateQueries({ queryKey: ["wsCredit"] });
      setData({
        ...data,
        testContact: "",
      });
    },
    onMutate: () => {
      Toast.success("Sending test Campaign...");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleTest = async () => {
    const test = {
      campaign_channel: "sms",
      sender_name: data.sender_name,
      campaign_subject: data.campaign_subject,
      content: data.content,
      tester: { phone: "+234" + data.testContact },
    };

    testMutate(test);
  };

  return (
    <div className="w-[100%]">
      <div className="flex items-start justify-between">
        <div className="w-[50%]">
          <div className="flex items-center justify-between mb-5">
            <div>
              <p className="font-bold">Add your audience</p>
              <p className="text-s">Share your email to your contacts</p>
            </div>
          </div>
          <div>
            <SavedContacts data={data} setData={setData} />
          </div>
          <div>
            <div className="flex space-x-4 items-center mt-5">
              <p className="text-s font-semibold">Send Test</p>
              <Toggle checked={display} onChange={handleChange} icons={false} />
            </div>

            {display && (
              <div>
                <div className="flex space-x-2 items-center my-[1em]">
                  <div className="flex items-center px-4 rounded-[8px] border-[#DFE5EC] border-[1px] space-x-2 w-[80%] h-[45px]">
                    <p className="border-r pr-2">+234</p>
                    <input
                      type="tel"
                      className="outline-none h-[100%] w-full"
                      placeholder="8123456789"
                      value={data.testContact}
                      onChange={handleTelChange}
                      maxlength="10"
                    />
                  </div>
                  <p
                    className={`text-ss cursor-pointer rounded-lg px-3 h-[45px] flex items-center justify-center ${
                      active ? "text-primary-800 bg-primary-100 font-bold " : "text-[#737373] bg-gray-50 "
                    }`}
                    onClick={active ? handleTest : null}
                  >
                    Send Test
                  </p>
                </div>
                <p className="text-ss">
                  You have 10 free test SMS. Additional usage will be deducted from your SMS balance.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="bg-[#FAFAFA] flex flex-col items-center w-[400px]">
          <div className="bg-white flex items-center justify-center drop-shadow-md py-3 px-2 w-full">
            <p>Preview</p>
          </div>
          <div className="border-[2px]  border-black bg-white  w-[300px] h-[60vh] items-end rounded-[48px] p-2 pb-0 mt-10">
            <div className="border-[2px]  border-[#D0D5DD]  w-[100%] h-[98%] items-end rounded-[40px] overflow-hidden">
              <div className="bg-[#F5F5F5] h-[90px] flex items-center justify-center flex-col space-y-1">
                <div className="bg-[#969AA5] w-[40px] h-[40px] mx-auto rounded-full flex justify-center items-center">
                  <div className=" bg-[#969AA5]  flex items-center justify-center rounded-full overflow-hidden w-[40px] h-[30px]">
                    <IoPersonCircleSharp className="text-[28px] text-white" />
                  </div>
                </div>

                <p className="text-s font-[400]">{data?.sender_name || "Select a sender ID"}</p>
              </div>

              <div className="text-center space-y-[1px] mt-2 text-ss">
                <p>Text message</p>
                <p>{dayjs().format("h:mm a")}</p>
              </div>

              <div className="bg-[#E9E9EB] m-3 p-3 rounded-[10px] min-h-[100px]">
                <p className="text-s">
                  {data?.content.length < 1
                    ? "Enter your SMS text to see preview."
                    : serializeMessage(data?.content, user?.data)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Audience;
