import React, { useState } from "react";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Typography from "utils/typography";
import { FaChevronLeft } from "react-icons/fa";
import overviewSms from "assets/images/broadcast/overviewIcons/overviewSms.png";
import overviewMessaging from "assets/images/broadcast/overviewIcons/overviewMessaging.png";
import SmsModal from "./sms/tabs/SmsModal";
import SmsPreview from "./sms/tabs/TemplatePreview";
import VoiceModal from "./voice/voiceModal";
import VoicePreview from "./voice/voicePreview";

const cardData = [
  {
    id: 1,
    name: "Emails",
    description: "Reach and engage your customers in multiple ways",
    image: overviewMessaging,
  },
  {
    id: 2,
    name: "SMS",
    description: "Connect and share messages with your uploaded audience to expand your reach.",
    image: overviewSms,
  },
];
const MesssagingSetup = () => {
  const [open, setOpen] = useState(null);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState();

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleNext = () => {
    handleOpen(selected);
  };
  return (
    <div>
      <div className="bg-white h-[calc(100vh-150px)] rounded border-[1px] p-6 py-[3em]">
        <div className="flex items-center ">
          <Typography.SubText
            className="flex items-center text-primary-700 space-x-2 cursor-pointer "
            onClick={() => navigate("/broadcasts")}
          >
            <FaChevronLeft className="" />
            <span className="font-semibold"> Go back</span>
          </Typography.SubText>

          <Typography.H3 className="w-[90%] text-center">
            Reach and engage your customers in multiple ways
          </Typography.H3>
        </div>

        <div className="flex items-center space-x-7 py-[3em] w-[85%] mx-auto">
          {cardData.map((el) => (
            <div
              key={el.id}
              className={`border-[1px] rounded-md h-[280px] w-[50%] flex flex-col justify-between items-center overflow-hidden cursor-pointer ${
                el.id === selected ? "border-primary-800" : ""
              }`}
              onClick={() => setSelected(el.id)}
            >
              <div
                className={`${
                  el.id === selected ? "bg-primary-50" : "bg-gray-50"
                } hover:bg-primary-50 h-[55%] w-[100%] flex items-start justify-between  p-4`}
              >
                <div className="w-[90%] h-[100%] flex items-center justify-center">
                  <img src={el.image} alt={el.id} className="w-[80px] mx-auto" />
                </div>

                <div
                  className={`border-[1px] rounded-full  h-[15px] w-[15px] flex items-center justify-center ${
                    el.id === selected ? "border-primary-800" : "border-gray-400"
                  }`}
                >
                  {el.id === selected ? <div className="h-[9px] w-[9px] rounded-full bg-primary-800"> </div> : null}
                </div>
              </div>

              <div className="text-center p-4  space-y-2 h-[45%] flex items-center flex-col justify-center w-[100%]">
                <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>

                <Typography.MicroText className="w-[90%] mx-auto ">{el.description}</Typography.MicroText>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center">
          <p className="text-sm text-neutral-600 font-semibold">Need help getting started?</p>

          <div className="text-primary-800 text-xs flex space-x-3 items-center mt-3">
            <a href="https://usemooyi.com/faq" target="_blank" rel="noopener noreferrer">
              Visit our FAQ
            </a>

            <span className="border-neutral-300 border-l-[1px] h-[20px]"></span>

            <a
              href="https://www.youtube.com/channel/UC-KbRjjhExwbHkOurEtdNFQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              Watch tutorial video
            </a>
          </div>
        </div>
      </div>

      <div
        className={`flex justify-between border-t-[1px] fixed bottom-0 right-0 px-4 h-[60px] items-center bg-white ${
          params.get("sidebar") === "true" ? "w-[calc(100vw-80px)] " : "w-[calc(100vw-250px)]"
        } transition ease-in`}
      >
        <div onClick={() => navigate("/broadcast")}>
          <GreyButton text="Cancel" className="w-[140px]" />
        </div>

        <div className="flex justify-between space-x-4">
          <BlueButton text={"Next"} className="h-[40px] text-s w-[140px]" onClick={handleNext} />
        </div>
      </div>
      {open === 1 ? navigate("/broadcasts/email/setup") : null}
      {open === 2 ? <SmsModal handleModal={() => handleOpen(12)} onClose={() => handleOpen()} /> : null}
      {open === 12 ? <SmsPreview handleModal={() => handleOpen(2)} /> : null}
      {open === 3 ? <VoiceModal handleModal={() => handleOpen(13)} onClose={() => handleOpen()} /> : null}
      {open === 13 ? <VoicePreview handleModal={() => handleOpen(3)} /> : null}
    </div>
  );
};

export default MesssagingSetup;
