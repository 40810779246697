export const coreListOptions = [
  {
    name: "Education",
    value: 3,
    subcategories: [
      { label: "Primary school", value: "primarySchool" },
      { label: "Junior secondary school", value: "juniorSecondarySchool" },
      { label: "Senior secondary school", value: "seniorSecondarySchool" },
      { label: "National diploma (ND)", value: "nationalDiploma" },
      { label: "Higher national diploma (HND)", value: "higherNationalDiploma" },
      { label: "Bachelor's Degree", value: "bachelorsDegree" },
      { label: "Master's Degrees", value: "mastersDegree" },
      { label: "Doctorate Degrees", value: "doctorateDegree" },
      { label: "No educational background", value: "noEducationalBackground" },
    ],
  },
  {
    name: "Employment",
    value: 4,
    subcategories: [
      { label: "Unemployed", value: "unemployed" },
      { label: "Full-Time Employment", value: "fullTimeEmployment" },
      { label: "Part-Time Employment", value: "partTimeEmployment" },
      { label: "Temporary or Contract", value: "temporaryOrContract" },
      { label: "Freelance / Self-Employment", value: "freelanceSelfEmployment" },
      { label: "Internship", value: "internship" },
      { label: "Apprenticeship", value: "apprenticeship" },
      { label: "Remote or Telecommuting", value: "remoteOrTelecommuting" },
    ],
  },
  {
    name: "Occupation",
    value: 5,
    subcategories: [
      { label: "Medical and Healthcare", value: "medicalAndHealthcare" },
      { label: "Technology and IT", value: "technologyAndIt" },
      { label: "Business and Management", value: "businessAndManagement" },
      { label: "Engineering", value: "engineering" },
      { label: "Architecture", value: "architecture" },
      { label: "Education and Teaching", value: "educationAndTeaching" },
      { label: "Education", value: "education" },
      { label: "Arts and Design", value: "artsAndDesign" },
      { label: "Science and Research", value: "scienceAndResearch" },
      { label: "Retail and Sales", value: "retailAndSales" },
      { label: "Legal and Law Enforcement", value: "legalAndLawEnforcement" },
    ],
  },
  {
    name: "Income",
    value: 6,
    subcategories: [
      { label: "0 - 9,999 NGN", value: "0-9999" },
      { label: "10,000 - 49,999 NGN", value: "10000-49999" },
      { label: "50,000 - 149,999 NGN", value: "50000-149999" },
      { label: "150,000 - 499,999 NGN", value: "150000-499999" },
      { label: "500,000 - 1M NGN", value: "500000-1000000" },
      { label: "1M - 5M", value: "1000000-50000000" },
      { label: "5M+", value: "50000000+" },
    ],
  },
  {
    name: "Relationship status",
    value: 7,
    subcategories: [
      { label: "Single", value: "single" },
      { label: "In a Relationship", value: "inARelationship" },
      { label: "Married", value: "married" },
      { label: "Divorced", value: "divorced" },
      { label: "Widowed", value: "widowed" },
    ],
  },
  {
    name: "Parental Status",
    value: 8,
    subcategories: [
      { label: "No children", value: "noChildren" },
      { label: "1 child under 18", value: "oneChildUnder18" },
      { label: "2 children under 18", value: "twoChildrenUnder18" },
      { label: "3 children under 18", value: "threeChildrenUnder18" },
      { label: "4 children under 18", value: "fourChildrenUnder18" },
    ],
  },

  //   {
  //     name: "Other",
  //     value: 21,
  //     subcategories: [{ label: "Other (Please specify)", value: "other" }],
  //   },
];

export const interestList = [
  {
    name: "Health & Fitness",
    value: 1,
    subcategories: [
      { label: "Gym-goers", value: "gym_goers" },
      { label: "Yoga enthusiasts", value: "yoga_enthusiasts" },
      { label: "Nutrition-conscious", value: "nutrition_conscious" },
      { label: "Strength Training", value: "strength_training" },
      { label: "Cardio Workouts (Running, Cycling)", value: "cardio_workouts" },
      { label: "High-Intensity Interval Training (HIIT)", value: "hiit" },
      { label: "Pilates & Barre", value: "pilates_barre" },
      { label: "Functional Training (Bodyweight Exercises)", value: "functional_training" },
      { label: "Home Workouts (Bodyweight, Equipment-Free)", value: "home_workouts" },
      { label: "Group Fitness Classes (CrossFit, Zumba)", value: "group_fitness_classes" },
      { label: "Endurance Training", value: "endurance_training" },
      { label: "Flexibility & Mobility (Stretching, Foam Rolling)", value: "flexibility_mobility" },
      { label: "Hiking & Trekking", value: "hiking_trekking" },
      { label: "Rock Climbing & Mountaineering", value: "rock_climbing_mountaineering" },
      { label: "Camping & Backpacking", value: "camping_backpacking" },
      { label: "Kayaking, Canoeing & Water Sports", value: "water_sports" },
      { label: "Surfing, Windsurfing, Kitesurfing", value: "surfing_windsurfing_kitesurfing" },
      { label: "Trail Running & Off-Road Biking", value: "trail_running_off_road_biking" },
      { label: "Skiing & Snowboarding", value: "skiing_snowboarding" },
      { label: "Scuba Diving & Snorkeling", value: "scuba_diving_snorkeling" },
      { label: "Orienteering & Geocaching", value: "orienteering_geocaching" },
      { label: "Nature Walks & Ecotherapy", value: "nature_walks_ecotherapy" },
      { label: "Mindfulness Practices", value: "mindfulness_practices" },
      { label: "Meditation & Relaxation Techniques", value: "meditation_relaxation" },
      { label: "Stress & Anxiety Management", value: "stress_anxiety_management" },
      { label: "Sleep Hygiene & Improvement", value: "sleep_hygiene_improvement" },
      { label: "Work-Life Balance", value: "work_life_balance" },
      { label: "Journaling for Mental Health", value: "journaling_mental_health" },
      { label: "Self-Care Routines", value: "self_care_routines" },
      { label: "Healthy Fat Loss Supplements", value: "fat_loss_supplements" },
      { label: "Body Transformation Journeys", value: "body_transformation" },
      { label: "Sustainable Weight Maintenance", value: "weight_maintenance" },
    ],
  },
  {
    name: "Entertainment",
    value: 2,
    subcategories: [
      { label: "Action Movies", value: "action_movies" },
      { label: "Adventure Films", value: "adventure_films" },
      { label: "Comedy Films", value: "comedy_films" },
      { label: "Drama & Romance", value: "drama_romance" },
      { label: "Sci-Fi & Fantasy Films", value: "sci_fi_fantasy_films" },
      { label: "Horror & Thriller Movies", value: "horror_thriller_movies" },
      { label: "Animated Films (Pixar, Disney, Anime)", value: "animated_films" },
      { label: "Documentary Films", value: "documentary_films" },
      { label: "Independent & Indie Films", value: "independent_indie_films" },
      { label: "Classic & Retro Movies", value: "classic_retro_movies" },
      { label: "Film Festivals (Sundance, Cannes)", value: "film_festivals" },
      { label: "Foreign & International Cinema", value: "foreign_international_cinema" },
      { label: "Movie Reviews & Criticism", value: "movie_reviews_criticism" },
      { label: "Film Production & Directing", value: "film_production_directing" },
      { label: "Streaming Services (Netflix, Hulu, Prime Video)", value: "streaming_services_movies" },
      { label: "Pop Music", value: "pop_music" },
      { label: "Afro music", value: "afro_music" },
      { label: "Fuji music", value: "fuji_music" },
      { label: "Rock & Alternative Music", value: "rock_alternative_music" },
      { label: "Hip-Hop & Rap", value: "hip_hop_rap" },
      { label: "EDM & Dance Music", value: "edm_dance_music" },
      { label: "Jazz & Blues", value: "jazz_blues" },
      { label: "Classical Music", value: "classical_music" },
      { label: "Indie & Alternative Music", value: "indie_alternative_music" },
      { label: "Country Music", value: "country_music" },
      { label: "Reggae & Ska", value: "reggae_ska" },
      { label: "R&B & Soul Music", value: "rnb_soul_music" },
      { label: "Metal & Punk Music", value: "metal_punk_music" },
      { label: "Live Concerts & Music Festivals", value: "live_concerts_festivals" },
      { label: "Music Production & DJing", value: "music_production_djing" },
      { label: "Music Streaming (Spotify, Apple Music)", value: "music_streaming" },
      { label: "Music Awards (Grammys, MTV VMAs)", value: "music_awards" },
      { label: "TV Series (Drama, Sitcoms, Reality TV)", value: "tv_series" },
      { label: "Streaming Services (Netflix, Disney+, HBO Max)", value: "streaming_services_tv" },
      { label: "Binge-Watching Trends", value: "binge_watching_trends" },
      { label: "Classic TV Shows (Friends, Seinfeld, The Office)", value: "classic_tv_shows" },
      { label: "Reality TV Shows (Survivor, Big Brother)", value: "reality_tv_shows" },
      { label: "Talk Shows & Late-Night TV (Jimmy Fallon, Ellen)", value: "talk_shows_late_night_tv" },
      { label: "Soap Operas & Daytime TV", value: "soap_operas_daytime_tv" },
      { label: "Award Shows (Oscars, Emmys, Golden Globes)", value: "tv_award_shows" },
      { label: "TV Reviews & Recaps", value: "tv_reviews_recaps" },
      { label: "Animated TV Shows (Rick and Morty, Family Guy)", value: "animated_tv_shows" },
      { label: "Docuseries (True Crime, Nature, History)", value: "docuseries" },
      { label: "Limited Series & Miniseries", value: "limited_series_miniseries" },
      { label: "TV Streaming Devices (Roku, Apple TV)", value: "tv_streaming_devices" },
      { label: "TV Networks (HBO, AMC, FX)", value: "tv_networks" },
      { label: "Upcoming TV Shows & Premieres", value: "upcoming_tv_shows_premieres" },
      { label: "Console Gaming (PlayStation, Xbox, Nintendo)", value: "console_gaming" },
      { label: "PC Gaming (Steam, eSports)", value: "pc_gaming" },
      { label: "Mobile Gaming (iOS, Android Games)", value: "mobile_gaming" },
      { label: "eSports & Competitive Gaming", value: "esports_competitive_gaming" },
      { label: "MMORPGs (World of Warcraft, Final Fantasy)", value: "mmorpgs" },
      { label: "First-Person Shooters (Call of Duty, Fortnite)", value: "first_person_shooters" },
      { label: "Role-Playing Games (The Witcher, Skyrim)", value: "role_playing_games" },
      { label: "Sports Games (FIFA, NBA 2K)", value: "sports_games" },
      { label: "Puzzle & Strategy Games", value: "puzzle_strategy_games" },
      { label: "Indie Games & Game Development", value: "indie_games_development" },
      { label: "Virtual Reality (VR) Gaming", value: "vr_gaming" },
      { label: "Retro & Classic Games", value: "retro_classic_games" },
      { label: "Gaming Communities (Twitch, Discord)", value: "gaming_communities" },
      { label: "Game Reviews & Walkthroughs", value: "game_reviews_walkthroughs" },
      { label: "Gaming Consoles & Accessories (Controllers, Headsets)", value: "gaming_consoles_accessories" },
      { label: "True Crime Podcasts", value: "true_crime_podcasts" },
      { label: "Comedy Podcasts", value: "comedy_podcasts" },
      { label: "News & Politics Podcasts", value: "news_politics_podcasts" },
      { label: "Interview & Talk Show Podcasts", value: "interview_talk_show_podcasts" },
      { label: "Educational & Self-Improvement Podcasts", value: "educational_self_improvement_podcasts" },
      { label: "Pop Culture & Entertainment Podcasts", value: "pop_culture_entertainment_podcasts" },
      { label: "Music Discovery Podcasts", value: "music_discovery_podcasts" },
      { label: "Audiobooks & Storytelling Podcasts", value: "audiobooks_storytelling_podcasts" },
      { label: "Tech & Gaming Podcasts", value: "tech_gaming_podcasts" },
      { label: "Radio Shows (AM, FM, Satellite)", value: "radio_shows" },
      { label: "Podcast Networks (Spotify Originals, NPR)", value: "podcast_networks" },
      { label: "Podcast Reviews & Recommendations", value: "podcast_reviews_recommendations" },
      { label: "Podcasting Equipment & How-To Guides", value: "podcasting_equipment_guides" },
      { label: "Streaming Radio Platforms (iHeartRadio, TuneIn)", value: "streaming_radio_platforms" },
      { label: "Sports & Fantasy Football Podcasts", value: "sports_fantasy_football_podcasts" },
      { label: "Theater Performances", value: "theater_performances" },
      { label: "Concerts", value: "concerts" },
      { label: "Fiction (Novels, Short Stories)", value: "fiction_books" },
      { label: "Non-Fiction (Biographies, Self-Help)", value: "non_fiction_books" },
      { label: "Mystery & Thriller Books", value: "mystery_thriller_books" },
      { label: "Science Fiction & Fantasy Books", value: "science_fiction_fantasy_books" },
      { label: "Romance Novels", value: "romance_novels" },
      { label: "Historical Fiction", value: "historical_fiction" },
      { label: "Graphic Novels & Comics", value: "graphic_novels_comics" },
      { label: "Young Adult (YA) Fiction", value: "young_adult_fiction" },
      { label: "Poetry & Spoken Word", value: "poetry_spoken_word" },
      { label: "Book Reviews & Recommendations", value: "book_reviews_recommendations" },
      { label: "Audiobooks & Book Podcasts", value: "audiobooks_book_podcasts" },
      { label: "Book Clubs & Reading Groups", value: "book_clubs_reading_groups" },
    ],
  },
  {
    name: "Food_&_Drink",
    value: 3,
    subcategories: [
      { label: "Vegan", value: "vegan" },
      { label: "Foodie", value: "foodie" },
      { label: "Fast Food", value: "fast_food" },
      { label: "Alcohol Preferences", value: "alcohol_preferences" },
      { label: "Non-Alcoholic Beverages (Mocktails, Smoothies)", value: "non_alcoholic_beverages" },
      { label: "Coffee (Espresso, Cold Brew, Lattes)", value: "coffee" },
      { label: "Tea (Green, Black, Herbal, Iced)", value: "tea" },
      { label: "Craft Beer & Breweries", value: "craft_beer_breweries" },
      { label: "Wine (Red, White, Rosé, Sparkling)", value: "wine" },
      { label: "Cocktails & Mixology (Mojitos, Margaritas, Old Fashioneds)", value: "cocktails_mixology" },
      { label: "Juices & Fresh-Pressed Drinks", value: "juices_fresh_pressed" },
      { label: "Kombucha & Fermented Beverages", value: "kombucha_fermented_beverages" },
      { label: "Energy Drinks & Sports Beverages", value: "energy_drinks_sports_beverages" },
      { label: "Milk Alternatives (Almond, Oat, Soy)", value: "milk_alternatives" },
      { label: "Spirits & Liquor (Rum, Vodka, Gin)", value: "spirits_liquor" },
      { label: "Champagne & Sparkling Wine", value: "champagne_sparkling_wine" },
      { label: "Healthy Hydration (Infused Water, Electrolyte Drinks)", value: "healthy_hydration" },
      { label: "Barista Skills & Coffee Brewing Techniques", value: "barista_skills" },
      { label: "Balanced Macros (Carbohydrates, Protein, Fats)", value: "balanced_macros" },
      { label: "Plant-Based Nutrition", value: "plant_based_nutrition" },
      { label: "Organic & Whole Foods", value: "organic_whole_foods" },
      { label: "Clean Eating", value: "clean_eating" },
      { label: "Keto, Paleo, Low-Carb Diets", value: "keto_paleo_low_carb_diets" },
      { label: "Intermittent Fasting", value: "intermittent_fasting" },
      { label: "Meal Planning & Meal Prep", value: "meal_planning_prep" },
      { label: "Supplements (Vitamins, Protein Powders)", value: "supplements" },
      { label: "Hydration & Water Intake", value: "hydration_water_intake" },
      { label: "Italian Cuisine (Pasta, Pizza, Risotto)", value: "italian_cuisine" },
      { label: "Mexican Cuisine (Tacos, Burritos, Salsa)", value: "mexican_cuisine" },
      { label: "Indian Cuisine (Curries, Tandoori, Samosas)", value: "indian_cuisine" },
      { label: "Chinese Cuisine (Dim Sum, Stir Fry, Noodles)", value: "chinese_cuisine" },
      { label: "Japanese Cuisine (Sushi, Ramen, Tempura)", value: "japanese_cuisine" },
      { label: "Mediterranean Cuisine (Hummus, Falafel, Gyros)", value: "mediterranean_cuisine" },
      { label: "Thai Cuisine (Pad Thai, Green Curry)", value: "thai_cuisine" },
      { label: "French Cuisine (Croissants, Baguettes, Escargot)", value: "french_cuisine" },
      { label: "American Cuisine (Burgers, BBQ, Southern Comfort Food)", value: "american_cuisine" },
      { label: "Middle Eastern Cuisine (Shawarma, Kebabs, Meze)", value: "middle_eastern_cuisine" },
      { label: "African Cuisine (Jollof Rice, Couscous, Tagine)", value: "african_cuisine" },
      { label: "Caribbean Cuisine (Jerk Chicken, Plantains, Rum Punch)", value: "caribbean_cuisine" },
      { label: "Southeast Asian Cuisine (Pho, Satay, Banh Mi)", value: "southeast_asian_cuisine" },
      { label: "Korean Cuisine (Kimchi, Bibimbap, Korean BBQ)", value: "korean_cuisine" },
      { label: "Eastern European Cuisine (Borscht, Pierogi, Goulash)", value: "eastern_european_cuisine" },
      { label: "Cakes", value: "cakes" },
      { label: "Cookies & Biscuits (Chocolate Chip, Macarons)", value: "cookies_biscuits" },
      { label: "Pastries (Croissants, Danish Pastries)", value: "pastries" },
      { label: "Bread", value: "bread" },
      { label: "Pies & Tarts (Apple Pie, Quiche, Lemon Tart)", value: "pies_tarts" },
      { label: "Brownies & Bars (Fudge, Blondies)", value: "brownies_bars" },
      { label: "Gluten-Free & Vegan Baking", value: "gluten_free_vegan_baking" },
      { label: "Frosting & Cake Decoration Techniques", value: "frosting_decoration_techniques" },
      { label: "Cheesecakes & No-Bake Desserts", value: "cheesecakes_no_bake_desserts" },
      { label: "Ice Cream & Gelato", value: "ice_cream_gelato" },
      { label: "Chocolates & Truffles", value: "chocolates_truffles" },
      { label: "Puddings & Custards", value: "puddings_custards" },
      { label: "Dessert Pairings (Wine, Coffee, Liqueurs)", value: "dessert_pairings" },
      { label: "Sweet Snacks & Energy Bites", value: "sweet_snacks_energy_bites" },
    ],
  },
  {
    name: "Technology",
    value: 4,
    subcategories: [
      { label: "Gadgets", value: "gadgets" },
      { label: "Software", value: "software" },
      { label: "Gaming", value: "gaming" },
      { label: "Digital tools", value: "digital_tools" },
    ],
  },
  {
    name: "Sports",
    value: 5,
    subcategories: [
      { label: "Football", value: "football" },
      { label: "Basketball", value: "basketball" },
      { label: "Golf", value: "golf" },
      { label: "Tennis", value: "tennis" },
      { label: "E-sports", value: "e_sports" },
    ],
  },
  {
    name: "Fashion & Style",
    value: 6,
    subcategories: [
      { label: "High-end fashion", value: "high_end_fashion" },
      { label: "Casual wear", value: "casual_wear" },
      { label: "Sustainable fashion", value: "sustainable_fashion" },
    ],
  },
  {
    name: "Home & Garden",
    value: 7,
    subcategories: [
      { label: "DIY enthusiasts", value: "diy_enthusiasts" },
      { label: "Interior decorators", value: "interior_decorators" },
      { label: "Gardening", value: "gardening" },
    ],
  },
  {
    name: "Art & Culture",
    value: 8,
    subcategories: [
      { label: "Visual arts", value: "visual_arts" },
      { label: "Performing arts", value: "performing_arts" },
      { label: "Literature", value: "literature" },
      { label: "Museums", value: "museums" },
    ],
  },
  {
    name: "Finance & Investments",
    value: 9,
    subcategories: [
      { label: "Stock market", value: "stock_market" },
      { label: "Cryptocurrency", value: "cryptocurrency" },
      { label: "Real estate", value: "real_estate" },
    ],
  },
  {
    name: "Hobbies",
    value: 10,
    subcategories: [
      { label: "Reading", value: "reading" },
      { label: "Cooking", value: "cooking" },
      { label: "Photography", value: "photography" },
      { label: "DIY", value: "diy" },
      { label: "Crafting", value: "crafting" },
      { label: "Woodworking", value: "woodworking" },
      { label: "Gardening", value: "gardening" },
      { label: "Painting", value: "painting" },
      { label: "Beading", value: "beading" },
      { label: "Drumming", value: "drumming" },
      { label: "Traditional Crafts", value: "traditional_crafts" },
      { label: "Farming", value: "farming" },
    ],
  },
];

export const behaviourialList = [
  {
    name: "Spending Habits",
    value: 1,
    subcategories: [
      { label: "High-spender", value: "high_spender" },
      { label: "Budget-conscious", value: "budget_conscious" },
      { label: "Impulse buyer", value: "impulse_buyer" },
    ],
  },
  {
    name: "Shopping Preferences",
    value: 2,
    subcategories: [
      { label: "Online shopping", value: "online_shopping" },
      { label: "In-store shopping", value: "in_store_shopping" },
      { label: "Hybrid shopping", value: "hybrid_shopping" },
    ],
  },
  {
    name: "Tech Savviness",
    value: 3,
    subcategories: [
      { label: "Early adopter", value: "early_adopter" },
      { label: "Average user", value: "average_user" },
      { label: "Late adopter", value: "late_adopter" },
    ],
  },
  {
    name: "Social Media Usage",
    value: 4,
    subcategories: [
      { label: "Facebook", value: "facebook" },
      { label: "Instagram", value: "instagram" },
      { label: "TikTok", value: "tiktok" },
      { label: "Frequency of use", value: "frequency_of_use" },
    ],
  },
  {
    name: "Subscription Services",
    value: 5,
    subcategories: [
      { label: "Streaming services", value: "streaming_services" },
      { label: "Newsletters", value: "newsletters" },
      { label: "Box subscriptions", value: "box_subscriptions" },
    ],
  },
  {
    name: "Brand Loyalty",
    value: 6,
    subcategories: [
      { label: "Loyal customer", value: "loyal_customer" },
      { label: "Brand switcher", value: "brand_switcher" },
      { label: "New customer", value: "new_customer" },
    ],
  },
  {
    name: "Travel Habits",
    value: 7,
    subcategories: [
      { label: "Frequent traveller", value: "frequent_traveller" },
      { label: "Occasional traveller", value: "occasional_traveller" },
      { label: "No travel", value: "no_travel" },
    ],
  },
];
