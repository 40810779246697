import React, { useEffect, useState } from "react";
import BottomBar from "./BottomBar";
import Typography from "utils/typography";
import { UIConstants } from "data/constants/uiConstants";
import classNames from "classnames";
import { SMSIcon } from "assets/icons/icon";
import { EmailIcon } from "assets/icons/icon";
import PrimaryInput from "components/Inputs/PrimaryInput";
import SelectInput from "components/Inputs/SelectInput";
import useEmailSenderId from "hooks/useEmailSenderId";
import Dropdown from "components/DropDown";
import Toggle from "react-toggle";
import FileUpload from "components/Inputs/FileUpload";
import { cn } from "helpers/classHelpers";
import { useMutation } from "@tanstack/react-query";
import { createInsightDispatch } from "services/insightService";
import Toast from "config/toast";
import { useLocation, useSearchParams } from "react-router-dom";
import useInsight from "hooks/useInsight";

const option1 = [{ value: "", label: "Loading..." }];
const channel = [
  {
    name: "Email",
    id: "email",
    description: " Gather quality feedback, compose, send or schedule email surveys to your audience.",
    icon: EmailIcon,
  },
  {
    name: "SMS",
    id: "sms",
    description: "Gather quality feedback, compose, send or schedule SMS surveys to your audience.",
    icon: SMSIcon,
  },
];

const SurveyChannel = ({ step, setStep }) => {
  const { emailOptions, emailLoading } = useEmailSenderId();
  const [showDropNDrag, setShowDropNDrag] = useState(false);
  const [params] = useSearchParams();
  const insightId = params.get("insightId");
  const location = useLocation();
  const pathArray = location.pathname.split("/");

  const [surveyData, setSurveyData] = useState({
    channel: "email",
    subject: "",
    senderId: "",
    salutation: "",
    personalization: "",
    content: "",
    coverImage: "",
  });

  const { insightData } = useInsight(pathArray.includes("nps") ? "nps" : pathArray.includes("csat") ? "csat" : "cp");

  const fetchedMode = insightData?.insights[0].dispatchMode;

  useEffect(() => {
    if (fetchedMode !== undefined) {
      setSurveyData(fetchedMode);
    }
  }, [fetchedMode]);

  const { mutate, isPending } = useMutation({
    mutationFn: createInsightDispatch,
    onSuccess: () => {
      Toast.success("Progress Saved!");
      setStep(step + 1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleDropNDrag = () => {
    setShowDropNDrag(!showDropNDrag);

    setSurveyData({
      ...surveyData,
      coverImage: "",
      allowImage: !surveyData.allowImage,
    });
  };

  const handleTextInput = (e) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDropdownSelect = (e, key) => {
    setSurveyData({
      ...surveyData,
      [key]: e,
    });
  };

  const salutations = [
    { label: "Hey", value: "Hey" },
    { label: "Hello", value: "Hello" },
    { label: "Good Day", value: "Good Day" },
  ];

  const personalization = [
    { label: "First Name", value: "firstname" },
    { label: "Last Name", value: "lastname" },
    { label: "Full Name", value: "fullname" },
  ];

  const getValue = (value) => {
    var values = value.split(" ");
    var email = values[values.length - 1];

    return email;
  };
  const handleNext = () => {
    const dispatchData = {
      insightId,
      dispatchMode: { ...surveyData, senderId: getValue(surveyData.senderId) },
    };

    mutate(dispatchData);
  };

  return (
    <div className="h-[70vh] flex items-start gap-[2em]">
      <div className={`w-[55%]  overflow-y-auto h-[100%]`}>
        <div>
          <Typography.Text className="font-semibold">Survey channel</Typography.Text>

          <Typography.SubText className="">Choose an option to share your survey.</Typography.SubText>
        </div>

        <div className="flex items-center gap-[1em] my-4">
          {channel.map((el) => (
            <div
              className={classNames(
                "border-[1px] rounded p-4 w-[50%] space-y-2 cursor-pointer",
                el.id === surveyData.channel ? "bg-primary-50 border-primary-800" : "bg-slate-50 ",
              )}
              onClick={() => handleDropdownSelect(el.id, "channel")}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="w-[20px] h-[]">{<el.icon />}</div>

                  <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>
                </div>

                <div
                  className={classNames(
                    "border-[1px] w-[15px] h-[15px] flex items-center justify-center rounded-full",
                    el.id === surveyData.channel ? "border-primary-800" : "border-gray-200",
                  )}
                >
                  {el.id === surveyData.channel && (
                    <div className={cn("bg-primary-800 h-[8px] w-[8px] rounded-full")}></div>
                  )}
                </div>
              </div>

              <Typography.MicroText className="">{el.description}</Typography.MicroText>
            </div>
          ))}
        </div>

        <hr className="mb-2" />

        <div className="">
          <Typography.Text className="font-semibold">Customise your email </Typography.Text>

          <div className="bg-slate-50 p-4 rounded-md space-y-3">
            <PrimaryInput
              text="Subject"
              placeholder="Enter email subject"
              value={surveyData.subject}
              onChange={handleTextInput}
              name="subject"
            />

            <div>
              <SelectInput
                options={
                  emailLoading
                    ? option1
                    : [
                        {
                          label: "Select a Sender ID",
                          value: "",
                        },
                        ...emailOptions,
                      ]
                }
                disabled={emailLoading ? true : false}
                css="h-[40px] rounded-[8px]"
                name="senderId"
                text="Email Sender ID"
                onChange={handleTextInput}
                value={surveyData.senderId}
              />
            </div>

            <div className="w-full">
              <div className="flex space-x-2 items-center ">
                <Typography.SubText className="font-semibold">Salutation</Typography.SubText>
              </div>
              <div className="mt-2">
                <Dropdown
                  tag={surveyData?.salutation !== "" ? surveyData?.salutation : "No salutation added"}
                  options={salutations}
                  onSelect={(el) => handleDropdownSelect(el.value, "salutation")}
                  defaultValue={surveyData.salutation}
                />
              </div>
            </div>

            <div className="w-full">
              <div className="flex space-x-2 items-center ">
                <Typography.SubText className="font-semibold">Add Personalisation</Typography.SubText>
              </div>
              <div className="mt-2">
                <Dropdown
                  tag={surveyData?.personalization !== "" ? surveyData?.personalization : "No personalisation added"}
                  options={personalization}
                  onSelect={(el) => handleDropdownSelect(el.value, "personalization")}
                  defaultValue={surveyData.personalization}
                />
              </div>
            </div>

            <div className="">
              <div className="flex space-x-2 items-center cursor-pointer">
                <Typography.SubText className="font-semibold">Add cover image</Typography.SubText>

                <Toggle checked={showDropNDrag} onChange={handleDropNDrag} icons={false} />
              </div>

              {showDropNDrag || surveyData.coverImage !== "" ? (
                <div className="mt-2">
                  <FileUpload name="coverImage" setData={setSurveyData} data={surveyData} />
                </div>
              ) : null}
            </div>

            <div className="space-y-2">
              <div className="flex space-x-2 items-center ">
                <Typography.SubText className="font-semibold">Copy</Typography.SubText>
              </div>
              <textarea
                name="content"
                id=""
                cols="30"
                value={surveyData.content}
                onChange={handleTextInput}
                placeholder={"Enter a message"}
                rows="10"
                className="border-[1px] rounded-md w-[100%] h-[200px] resize-none p-2 outline-none text-sm placeholder:text-sm"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-[45%] border-[1px] shadow-md h-[100%] bg-neutral-100 overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <div className="h-[50px] border-b-[1px] flex items-center justify-between px-4 bg-white">
          <div className="text-sm flex items-center">
            <Typography.MicroText className="font-semibold">Preview</Typography.MicroText>
          </div>
        </div>

        <div className="h-[calc(100%-50px)] flex flex-col  items-center justify-center p-4  ">
          <div className="p-4 bg-white space-y-3 w-[100%] h-[100%]">
            {surveyData.coverImage !== "" && (
              <div className="w-[100%] h-[150px] rounded-md overflow-hidden border-[1px]">
                <img
                  src={surveyData.coverImage}
                  alt="surveyData.coverImage"
                  className="object-cover h-[100%] w-[100%]"
                />
              </div>
            )}

            <div className="flex items-center space-x-1">
              <Typography className="text-gray-600">{surveyData.salutation}</Typography>

              <Typography className="text-gray-600">{surveyData.personalization},</Typography>
            </div>

            <Typography className="text-gray-600">{surveyData.content}</Typography>

            <Typography className="text-primary-800">[Survey Link]</Typography>

            <Typography className="text-gray-600">[Workspace Name]</Typography>
          </div>

          {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
        </div>
      </div>

      <BottomBar
        step={step}
        setStep={setStep}
        handleSave={handleNext}
        handleModal={() => {}}
        disableButton={surveyData.channel === "" || isPending}
      />
    </div>
  );
};

export default SurveyChannel;
