export const customerProfileData = {
  automotive_and_accessories: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },

    {
      question: "What is your gender?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Male", "Female"],
    },

    {
      question: "What  is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },

    {
      question: "What is the primary use of your vehicle?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: [
        "Commuting (e.g., daily work or school)",
        "Ride-hailing (e.g., Uber, Bolt)",
        "Business purposes",
        "Family/leisure trips",
      ],
    },

    {
      question: "How frequently do you purchase automotive products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Monthly", "Every 3-6 months", "Annually", "Rarely"],
    },

    {
      question: "What are your preferred automotive brands?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Toyota", "Honda", "Hyundai", "Mercedes-Benz", "Kai", "Lexus"],
    },

    {
      question: "How frequently do you purchase automotive products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Sedan", "SUV", "Pickup truck", "Minibus", "Electric"],
    },

    {
      question: "What is your budget range for automotive accessories?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Below ₦50,000", "₦50,000 - ₦150,000", "₦150,000 - ₦500,000", "₦500,000+"],
    },
  ],

  baby_and_kids_products: [
    {
      question: "What is the age range of the parent/guardian?",
      description: "",
      questionType: "date",
      optional: true,
    },

    {
      question: "How many children do you have, and what are their ages?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: [
        "1 child (0-2 years)",
        "1 child (3-5 years)",
        "2 or more children (under 5 years)",
        "2 or more children (5 years and above)",
      ],
    },

    {
      question: "What  is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },

    {
      question: "What are your preferred brands for baby and kids products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Cussons Baby", "Molfix", "Huggies", "Pampers", "Dr Brown's", "Gerber", "MooyiOption"],
    },

    {
      question: "What are your primary considerations when purchasing?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Safety", "Price", "Brand reputation", "Avaliability of product", "Eco-friendly", "MooyiOption"],
    },

    {
      question: "How frequently do you buy baby products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Weekly", "Monthly", "Quater", "Rarely"],
    },

    {
      question: "What are the most frequently purchased product categories?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Diapers/wipes", "Clothing", "Toys", "Food and snacks", "Personal care products", "MooyiOption"],
    },

    {
      question: "Do you prefer online or in-store purchasing",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Online", "In-store", "Both"],
    },
  ],

  banking_and_financial_services: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },

    {
      question: "What is yout employment status",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Employed", "Self-Employed", "Student", "Unemployed", "Retired"],
    },

    {
      question: "What  is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },

    {
      question: "What typical banking services do you use?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Savings", "Loans", "Investments", "Mobile banking", "Transfer", "MooyiOption"],
    },

    {
      question: "Do you prefer digital banking or traditional banking?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Digital", "Traditonal", "Both"],
    },

    {
      question: "How frequently do you use financial services?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Daily", "Weekly", "Monthly", "Rarely"],
    },

    {
      question: "What are your main financial goals or concerns?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: [
        "Saving for the future",
        "Managing expenses",
        "Getting loans",
        "Investment opportunities",
        "MooyiOption",
      ],
    },

    {
      question: "Are you interested in financial advice or planning services?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Yes", "No", "Maybe"],
    },
  ],

  beauty_and_personal_care: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },

    {
      question: "What  is your gender?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Male", "Female"],
    },

    {
      question: "What  is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },

    {
      question: "What are your skin type/concerns?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Oily", "Dry", "Sensitive", "Combination", "Acne-prone", "MooyiOption"],
    },

    {
      question: "What are your preferred beauty product types?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Skincare", "Makeup", "Haircare", "Fragrance", "Personal hygiene", "MooyiOption"],
    },

    {
      question: "What is your average monthly spend on beauty products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Below ₦5,000", "₦5,000 - ₦15,000", "₦15,000 - ₦30,000", "Above ₦30,000"],
    },

    {
      question: "What are your preferred shopping channels for beauty products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Online stores", "Local markets", "Supermarkets", "Specialty beauty stores"],
    },

    {
      question: "Are you interested in natural/organic products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Yes", "No", "Maybe"],
    },
  ],

  business: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },

    {
      question: "What  is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },

    {
      question: "What is your industry of employment?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Agriculture", "Manufacturing", "IT/Tech", "Education", "Healthcare", "Finance", "MooyiOption"],
    },

    {
      question: "What is your position level within the company?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Entry-level", "Mid-level", "Senior-level", "Executive", "Business owner", "MooyiOption"],
    },

    {
      question: "What is the size of your business?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Micro", "Small", "Medium", "Large"],
    },

    {
      question: "What are your business needs or challenges?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Marketing", "Financial management", "Customer acquisition", "Technology upgrades", "MooyiOption"],
    },

    {
      question: "Do you prefer digital or traditional business solutions?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Digital", "Traditional", "Both"],
    },

    {
      question: "How frequently do you seek new business services or products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Monthly", "Quarterly", "Annually", "Rarely"],
    },
  ],

  education: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },

    {
      question: "What  is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },

    {
      question: "What is your current education level or role?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Student", "Teacher/Educator", "Administrator", "MooyiOption"],
    },

    {
      question: "What is your field of study or interest?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Science", "Technology", "Arts", "Business", "Engineering", "MooyiOption"],
    },

    {
      question: "What are your preferred learning formats?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Online", "In-person", "Hybrid (both online and in-person)"],
    },

    {
      question: "How frequently do you engage with educational content?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Daily", "Weekly", "Monthly", "Rarely"],
    },

    {
      question: "What are your main objectives for seeking educational resources?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Career advancement", "Personal interest", "Skill development", "Academic requirement", "MooyiOption"],
    },

    {
      question: "Are you interested in certifications?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Yes", "No", "Maybe"],
    },
  ],

  electronics_and_gadgets: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },

    {
      question: "What  is your gender?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Male", "Female"],
    },

    {
      question: "What  is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },

    {
      question: "What are your preferred types of gadgets?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Smartphones", "Laptops", "Tablets", "Smart home devices", "Wearables", "MooyiOption"],
    },

    {
      question: "What is your average spend on electronics?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Below ₦50,000", "₦50,000 - ₦150,000", "₦150,000 - ₦500,000", "₦500,000+"],
    },

    {
      question: "Do you have brand loyalty in electronics?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Yes", "No", "Depends on the product"],
    },

    {
      question: "Are you interested in emerging technology?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Yes", "No", "Maybe"],
    },

    {
      question: "How frequently do you upgrade or purchase new gadgets?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Every 6 months", "Annually", "Every 2-3 years", "Rarely"],
    },
  ],

  food_and_beverages: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },
    {
      question: "What is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },
    {
      question: "What are your dietary preferences or restrictions?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["No restrictions", "Vegetarian", "Vegan", "Gluten-free", "Low-carb", "MooyiOption"],
    },
    {
      question: "What is your average weekly spend on food and beverages?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Below ₦10,000", "₦10,000 - ₦30,000", "₦30,000 - ₦50,000", "Above ₦50,000"],
    },
    {
      question: "What are your preferred food and beverage types?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Nigerian/local", "Fast food", "Organic", "Processed", "Other (please specify)"],
    },
    {
      question: "What are your cooking habits?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Frequently cook at home", "Occasionally cook at home", "Rarely cook at home", "Never cook at home"],
    },
    {
      question: "How important is nutrition and ingredients to you?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Very important", "Somewhat important", "Not important"],
    },
    {
      question: "Do you prefer eating out or eating at home?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Eating out", "Eating at home", "Both"],
    },
  ],

  health_and_wellness_products: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },
    {
      question: "What is your gender?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Male", "Female"],
    },
    {
      question: "What is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },
    {
      question: "What are your health and wellness goals?",
      description: "",
      questionType: "multi_choice",
      optional: true,
      options: [
        "Fitness",
        "Weight loss",
        "Relaxation",
        "Improved diet",
        "Better sleep",
        "Mental well-being",
        "MooyiOption",
      ],
    },
    {
      question: "How frequently do you purchase health products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Weekly", "Monthly", "Every few months", "Rarely"],
    },
    {
      question: "What are your preferred types of wellness products?",
      description: "",
      questionType: "multi_choice",
      optional: true,
      options: ["Supplements", "Fitness equipment", "Essential oils", "Skincare", "Herbal remedies", "MooyiOption"],
    },
    {
      question: "How important are product ingredients to you?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Very important (e.g. natural, organic)", "Somewhat important", "Not important"],
    },
    {
      question: "Do you prefer online or in-store purchasing?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Online", "In-store", "No preference"],
    },
  ],

  home_decor_and_furnishings: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },
    {
      question: "What is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },
    {
      question: "What is your homeownership status?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Renter", "Homeowner", "Living with family", "MooyiOption"],
    },
    {
      question: "What is your preferred home decor style?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Modern", "Vintage", "Minimalist", "Traditional Nigerian", "Bohemian"],
    },
    {
      question: "What is your budget range for home furnishings?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Under ₦200,000", "₦200,000 - ₦500,000", "₦500,000 - ₦1,000,000", "Above ₦1,000,000"],
    },
    {
      question: "How frequently do you redecorate or purchase home decor?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Monthly", "Quarterly", "Annually", "Rarely"],
    },
    {
      question: "How important is sustainability in home products?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Very important", "Somewhat important", "Not important"],
    },
    {
      question: "Do you prefer online or in-store purchasing?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Online", "In-store", "Both"],
    },
  ],

  home_and_kitchen_appliances: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },
    {
      question: "What is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },
    {
      question: "What is your homeownership status?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Renter", "Homeowner", "Living with family", "MooyiOption"],
    },
    {
      question: "What type of home do you live in?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Apartment", "House", "Shared accommodation", "MooyiOption"],
    },
    {
      question: "What are your preferred appliance brands?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["LG", "Samsung", "Haier Thermocool", "Panasonic", "Philips", "MooyiOption"],
    },
    {
      question: "What is your budget range for appliances?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Under ₦200,000", "₦200,000 - ₦500,000", "₦500,000 - ₦1,000,000", "Above ₦1,000,000"],
    },
    {
      question: "How important are appliance features to you?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Very important (e.g. energy efficiency, smart technology)", "Somewhat important", "Not important"],
    },
    {
      question: "How frequently do you purchase or upgrade appliances?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Annually", "Every 2-3 years", "Rarely"],
    },
  ],

  jobs_and_career: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "date",
      optional: true,
    },
    {
      question: "What is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },
    {
      question: "What is your current employment status?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Employed full-time", "Employed part-time", "Self-employed", "Unemployed", "Student", "MooyiOption"],
    },
    {
      question: "What is your industry of employment or interest?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Agriculture", "IT/Tech", "Finance", "Education", "Health", "MooyiOption"],
    },
    {
      question: "What is your career stage?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Entry-level", "Mid-career", "Senior/executive", "Business owner", "Retired"],
    },
    {
      question: "What are your career goals or aspirations?",
      description: "",
      questionType: "multi_choice",
      optional: true,
      options: ["Career advancement", "Skills development", "Job stability", "Entrepreneurship"],
    },
    {
      question: "How important is work-life balance to you?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Very important", "Somewhat important", "Not important"],
    },
    {
      question: "Are you interested in career development resources?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Yes", "No", "Maybe"],
    },
  ],

  mobile_phones_and_accessories: [
    {
      question: "What is your age range?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["18-24", "25-34", "35-44", "45-54", "55+"],
    },
    {
      question: "What is your location?",
      description: "",
      questionType: "location",
      optional: true,
    },
    {
      question: "What are your preferred mobile phone brands?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["iPhone", "Samsung", "Tecno", "Infinix", "MooyiOptions"],
    },
    {
      question: "What is your average spend on mobile phones and accessories?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Under ₦200,000", "₦200,000 - ₦500,000", "₦500,000 - ₦1,000,000", "Above ₦1,000,000"],
    },
    {
      question: "How frequently do you upgrade your mobile phone?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Every year", "Every 2-3 years", "Every 4-5 years", "Rarely"],
    },
    {
      question: "How important are phone features to you? (e.g. camera quality, battery life)",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Very important", "Somewhat important", "Not important"],
    },
    {
      question: "Are you interested in mobile phone accessories?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Yes", "No", "Maybe"],
    },
    {
      question: "Do you prefer online or in-store purchasing?",
      description: "",
      questionType: "single_choice",
      optional: true,
      options: ["Online", "In-store", "Both"],
    },
  ],
};
