export const cSatData = {
  automotive_and_accessories: [
    {
      question: "How satisfied are you with the overall quality of our automotive products and accessories?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the durability and performance of the products you purchased?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to find the specific automotive accessories you were looking for?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the installation and usage instructions provided?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate your overall shopping experience with our automotive products?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  baby_and_kids_products: [
    {
      question: "How satisfied are you with the safety and quality of our baby and kids products?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the variety of products available for different age groups?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to find information on the products you were interested in?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the customer support for any questions or issues?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our baby and kids products to other parents?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  banking_and_financial_services: [
    {
      question: "How satisfied are you with the range of banking services we offer?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the ease of use of our online banking platform?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the clarity and transparency of our financial products?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How responsive and helpful is our customer service team?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our banking and financial services to others?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  beauty_and_personal_care: [
    {
      question: "How satisfied are you with the quality of our beauty and personal care products?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the effectiveness of the products you have used?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to find the beauty products that suited your needs?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the information and instructions provided for each product?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to purchase our beauty and personal care products again?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  business: [
    {
      question: "How satisfied are you with the range of business services we offer?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the professionalism and expertise of our team?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to access and utilize our business services?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the support and communication from our staff?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our business services to other companies?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  education: [
    {
      question: "How satisfied are you with the quality of the educational content we provide?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the ease of access to our educational materials?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How effective have our educational resources been in meeting your learning goals?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the support provided by our educators or staff?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our educational services to others?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  electronics_and_gadgets: [
    {
      question: "How satisfied are you with the performance and quality of our electronics and gadgets?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the user-friendliness of the products you purchased?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to find and purchase the electronics you were looking for?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the technical support and customer service?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our electronics and gadgets to others?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  food_and_beverages: [
    {
      question: "How satisfied are you with the taste and quality of our food and beverages?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the freshness and presentation of the products?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to place and receive your order?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the variety of food and beverage options available?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our food and beverages to others?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  health_and_wellness_products: [
    {
      question: "How satisfied are you with the quality and effectiveness of our health and wellness products?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the variety of products available for different health needs?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to find the health and wellness products you were looking for?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the information and instructions provided with each product?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our health and wellness products to others?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  home_decor_and_furnishings: [
    {
      question: "How satisfied are you with the quality and style of our home decor and furnishings?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the variety of products available to suit your taste?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to navigate and find products on our platform?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the delivery and installation services provided?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our home decor and furnishings to others?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  home_and_kitchen_appliances: [
    {
      question: "How likely are you to suggest our home and kitchen appliances to someone you know (0-10 scale)?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "What's the biggest reason for the rating you gave?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "What improvements can we make in our home and kitchen appliance range?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How well do our appliances meet your home needs?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "Are there any appliance types you wish we had?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  jobs_and_career: [
    {
      question: "How satisfied are you with the job listings and career resources we offer?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the relevance and quality of the job opportunities available?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to use our platform to apply for jobs or access career resources?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the support and guidance provided by our career advisors?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our job and career services to others?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
  mobile_phones_and_accessories: [
    {
      question: "How satisfied are you with the quality and performance of our mobile phones and accessories?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How would you rate the ease of use of the products you purchased?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How easy was it to find and purchase the mobile phones or accessories you were looking for?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How satisfied are you with the customer support and technical assistance provided?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
    {
      question: "How likely are you to recommend our mobile phones and accessories to others?",
      description: "Add some text here (Optional)",
      questionType: "rating",
      options: {
        shape: "star",
        iconQuantity: 5,
        label: {
          first: "Bad",
          last: "Good",
        },
      },
    },
  ],
};
