import React from "react";
import Tables from "./Tables";
import { pricingplans } from "../data";
import PlanCard from "../components/PlanCard";

const Plans = ({ conversionRate, values, toggleValue }) => {
  return (
    <div>
      <div id="plans">
        <div className="justify-center border rounded-[20px] overflow-hidden items-start h-fit mt-10 hidden md:flex">
          {pricingplans.map((el, i) => (
            <PlanCard
              title={el.title}
              subtitle={el.subtitle}
              plan={el.plan}
              planId={el.planId}
              category={el.category}
              tag={el.tag}
              info={el.info}
              featureTitle={el.featureTitle}
              features={el.features}
              amount={el.amount}
              responses={el.responses}
              conversionRate={conversionRate}
              toggle={toggleValue}
              key={i}
            />
          ))}
        </div>
      </div>
      <div className="bg-white py-[2rem] my-[2rem]">
        <p className="font-secondary font-[700] text-[24px] text-center" id="explore">
          Explore all our features
        </p>
        <div className="flex justify-center">
          <p className="text-[14px] text-center px-3">
            Discover the benefits of each pricing tier and choose the one that best fit your business.
          </p>
        </div>
        <div className="flex justify-center">
          <div className="w-full px-4 flex items-center justify-center">
            <Tables toggleValue={values} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
