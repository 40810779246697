import { UIConstants } from "data/constants/uiConstants";
import React, { useState } from "react";
import Typography from "utils/typography";
import PreviewCard from "./PreviewCard";
import BottomBar from "./BottomBar";
import { useMutation } from "@tanstack/react-query";
import Toast from "config/toast";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { TiInfo } from "react-icons/ti";
import SelectContactTag from "./SavedContactTag";
import { useSearchParams } from "react-router-dom";
import { createInsightAudience } from "services/insightService";
import PreLoader from "components/Loader/PreLoader";
import useGetAllTags from "hooks/useGetAllTags";

const AudienceSettings = ({ step, setStep }) => {
  const [contactSize, setContactSize] = useState(0);
  const { tagData } = useGetAllTags();
  const [populatedArray, setPopulatedArray] = useState([]);
  const [params] = useSearchParams();
  const insightId = params.get("insightId");

  const [surveyData, setSurveyData] = useState({
    recurringInterval: "",
    expectedResponse: "",
  });

  const { mutate, isPending } = useMutation({
    mutationFn: createInsightAudience,
    onSuccess: () => {
      Toast.success("Audience saved successfully");
      setStep(step + 1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleText = (e) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    const audienceDetails = {
      insightId,
      insightAudienceConfig: {
        contactTag: populatedArray.map((el) => el.name),
        expectedResponse: +surveyData.expectedResponse,
        frequencyConfig: {
          recurringInterval: surveyData.recurringInterval,
        },
      },
    };
    mutate(audienceDetails);
  };

  if (isPending) {
    return <PreLoader />;
  }

  return (
    <div className="h-[70vh] flex items-start gap-[2em]">
      <div className={`w-[50%] space-y-[1.5em] overflow-y-auto h-[100%]`}>
        <div className="border-[1px] p-4 rounded space-y-5">
          <div>
            <Typography className="font-semibold">Add your audience</Typography>
            <Typography.MicroText className="font-medium">Select who to send your survey to.</Typography.MicroText>
          </div>

          <SelectContactTag
            tagData={tagData}
            contactSize={contactSize}
            setContactSize={setContactSize}
            populatedArray={populatedArray}
            setPopulatedArray={setPopulatedArray}
          />

          <div>
            <Typography className="font-semibold">Contact size</Typography>
            <Typography.MicroText className="font-medium">{contactSize}</Typography.MicroText>
          </div>
        </div>
        <div className="border-[1px] p-4 rounded">
          <div className="space-y-2">
            <Typography className="font-semibold">Target responses</Typography>

            <PrimaryInput
              text="How many responses do you need?"
              value={surveyData.expectedResponse}
              name="expectedResponse"
              onChange={handleText}
            />
          </div>
        </div>

        <div className="border-[1px] p-4 rounded space-y-3">
          <div className=" bg-primary-50 p-4 rounded-md flex items-start space-x-4">
            <TiInfo className="text-primary-800 text-2xl" />

            <div>
              <Typography className="font-semibold">PLEASE:</Typography>
              <Typography.MicroText className="font-medium">
                This is an always on survey and it goes out on a monthly basis. Set a time to send out your next survey.
              </Typography.MicroText>
            </div>
          </div>

          <PrimaryInput
            text="Set time"
            type="time"
            onChange={handleText}
            value={surveyData.recurringInterval}
            name="recurringInterval"
          />
        </div>
      </div>

      <div
        className={`w-[50%] border-[1px] shadow-md h-[100%] bg-neutral-100 overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <div className="h-[50px] border-b-[1px] flex items-center justify-between px-4 bg-white">
          <div className="text-sm flex items-center">
            <Typography.SubText className="font-semibold">Preview</Typography.SubText>
          </div>
        </div>

        <div className="h-[calc(100%-50px)] flex items-center justify-center">
          <PreviewCard />
        </div>
      </div>

      <BottomBar
        step={step}
        setStep={setStep}
        handleSave={handleSave}
        handleModal={() => {}}
        disableButton={surveyData.expectedResponse === "" || isPending}
      />
    </div>
  );
};

export default AudienceSettings;
