import Api from "utils/axios";
import BACKEND_URLS from "utils/backendUrls";
import ExternalInstance from "utils/externalnstance";
import { handleApiError } from "utils/handleApiError";
import Instance from "utils/instance";

export const getCountry = async () => {
  try {
    const { data } = await ExternalInstance.get(`${BACKEND_URLS.location.getCountry}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getLocal = async ({ country, state, batch }) => {
  try {
    const { data } = await Instance.get(
      `${BACKEND_URLS.location.getLocation}?country=${country}&state=${state}&batch=${batch}`,
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getState = async (country) => {
  try {
    const { data } = await ExternalInstance.get(`${BACKEND_URLS.location.getState}?country=${country}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getCities = async (country) => {
  try {
    const { data } = await ExternalInstance.post(`${BACKEND_URLS.location.getCities}`, country);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getLocations = async (params) => {
  try {
    const { data } = await Api.get(`${BACKEND_URLS.location.getLocation(params)}`);
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
