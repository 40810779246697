import useGetAllTags from "hooks/useGetAllTags";
import useOutsideClick from "hooks/useOutsideClick";
import CreateTagModal from "pages/Contacts/CreateTagModal";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowDown, IoMdCheckmark, IoMdPie } from "react-icons/io";

const TagDropDown = ({ tagArr, setTagArr }) => {
  const { tagData } = useGetAllTags();
  const [searchedArray, setSearchedArray] = useState(tagData?.tags);
  const [open, setOpen] = useState(null);
  const [query, setQuery] = useState("");
  const [openDrop, setOpenDrop] = useState(false);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  useEffect(() => {
    setSearchedArray(tagData?.tags);
  }, [setSearchedArray, tagData]);

  const handleCheck = (e, el) => {
    if (e.target.checked) {
      if (!tagArr.includes(el)) {
        setTagArr([...tagArr, el]);
      }
    } else {
      const filterArray = tagArr?.filter((elm) => elm !== el);
      setTagArr(filterArray);
    }
  };

  const handleDropdown = () => {
    setOpenDrop(!openDrop);
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in tagArr) {
      if (tagArr[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    if (query !== "") {
      const filteredData = tagData?.tags?.filter(
        (filtered) => filtered?.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
      );
      if (filteredData.length !== 0) {
        setSearchedArray(filteredData);
      }
    } else {
      setSearchedArray(tagData?.tags);
    }
  };

  const removedFromArray = (el) => {
    const filterArray = tagArr?.filter((elm) => elm !== el);
    setTagArr(filterArray);
  };

  const refer = useOutsideClick(handleDropdown);

  return (
    <div>
      <div>
        <div className="flex justify-between">
          <div className="flex space-x-2 items-center">
            <p className="text-sm">Select tag(s)</p>
            {/* <Tooltip text="Select the category of contacts you want to send your campaign to." /> */}
          </div>

          <p className="text-xs text-primary-800 font-[500] cursor-pointer" onClick={() => handleModal(1)}>
            Create Tag
          </p>
        </div>
        <div
          className="rounded-[6px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px] pl-2 outline-none my-2 flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%] flex items-center relative"
          onClick={handleDropdown}
        >
          <p className="text-[#A3A3A3] text-xs">{tagArr.length} tags selected</p>

          <div className="text-[#101828] h-[100%] absolute top-0 right-6 flex items-center justify-center text-[18px]">
            <IoIosArrowDown />
          </div>
        </div>

        <div className=" flex-wrap gap-[1em]  py-[0.5em] max-w-[100%] flex items-center relative pb-[1em]">
          {tagArr?.map((el, i) => {
            return (
              <div
                key={i}
                className="bg-white border-[1px] border-[#BFCCDA] flex items-center px-4 rounded-[6px] h-[30px]  text-ss font-semibold cursor-pointer"
              >
                {el}
                <FaTimes className="ml-2" onClick={() => removedFromArray(el)} />
              </div>
            );
          })}
        </div>

        {openDrop && (
          <div className="rounded-[6px] shadow-secondary pb-4 h-[250px] overflow-y-scroll bg-white" ref={refer}>
            <div
              className="bg-[#F5F5F5]  flex min-h-[45px] items-center flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%]"
              // onClick={handleDropdown}
            >
              <span className="text-[#737373]">
                <AiOutlineSearch size={20} />
              </span>

              <input
                type="text"
                className="w-auto bg-black pl-2 h-[80%] bg-transparent outline-none text-s"
                placeholder="Search for tags"
                value={query}
                name="query"
                onChange={handleQueryChange}
              />
            </div>

            {searchedArray?.map((el) => {
              return (
                <div
                  className={`px-4 text-ss font-extrabold text-[#404040] border-b-[1px] border-[#D0D5DD] py-[8px] flex items-center justify-between hover:text-white hover:bg-primary-800 group ${
                    checkInArray(el?.name) ? "bg-[#E5E5E5] hover:bg-[#E5E5E5] hover:text-primary-800" : "bg-white"
                  }`}
                  key={el._id}
                  onClick={handleDropdown}
                >
                  <div className="flex items-center space-x-4 relative w-[100%]">
                    <div>
                      <input
                        type="checkbox"
                        name="recipient"
                        id="recipient"
                        onChange={(e) => handleCheck(e, el?.name)}
                        checked={checkInArray(el?.name)}
                        className="absolute w-[100%] h-[100%] top-0 right-0 opacity-0 z-[9999]"
                      />

                      <IoMdPie
                        className={` text-[24px] ${
                          checkInArray(el?.name) ? "block group-hover:text-black" : "group-hover:text-white"
                        }`}
                      />
                    </div>
                    <div>
                      <p className="text-m font-[500]">{el?.name}</p>
                      <p className="text-ss font-[400]">{el?.contactCount} contacts</p>
                    </div>
                  </div>

                  <IoMdCheckmark
                    className={`group-hover:block text-[24px] ${
                      checkInArray(el?.name) ? "block group-hover:text-black" : "hidden group-hover:text-white"
                    }`}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      {open === 1 ? <CreateTagModal toggleModal={() => handleModal(2)} closeModal={handleModal} /> : null}
    </div>
  );
};

export default TagDropDown;
