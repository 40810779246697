import React from "react";
import { motion } from "framer-motion";
import usePageStore from "store/usePageStore";
import Typography from "utils/typography";
import useSurveyStore from "store/insightStore";
import SurveyTypes from "./questionsPreview";
import BlueButton from "components/Button/BlueButton";
import surveyCardImage from "assets/images/surveyCardImage.png";
import coin from "assets/svgs/coin.svg";
import timer from "assets/svgs/timer.svg";

const containerVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const itemVariants = {
  initial: { opacity: 0, y: 15 },
  animate: { opacity: 1, y: 0 },
};

const PreviewCard = (data) => {
  const { page, incrementPage, decrementPage } = usePageStore();
  const { surveyQuestionsArray } = useSurveyStore();

  const previewData = data?.data;

  return (
    <div className={`h-full ${page === -1 ? "bg-primary-50" : "bg-white"} w-full mx-auto`}>
      {page === -1 ? (
        <div className="w-[90%] py-[2em] h-[100%] mx-auto flex flex-col items-center space-y-5">
          {/* <img src={Logo} alt="logo" width={70} className="mx-auto" /> */}
          <div className="h-[30%] w-[80%] overflow-hidden rounded-lg">
            <img
              src={previewData?.coverImage || surveyCardImage}
              alt="surveyCardImage"
              className="w-[100%] h-[100%] mx-auto object-cover"
            />
          </div>
          <div className="bg-white rounded-md w-[80%] py-5">
            <motion.div variants={itemVariants} transition={{ delay: 0.4, duration: 0.3 }}>
              {/* <Typography.SubText className="px-5">
                Question {page} of {previewData?.questions?.length}
              </Typography.SubText> */}
              <Typography.H4 className="px-5">{previewData?.surveyName}</Typography.H4>
              <div className="px-5 flex items-center space-x-3">
                <img src={coin} alt="" />
                <Typography.SubText className=" items-center space-x-2">
                  Reward: <span className="text-[12px] font-bold">{previewData?.reward} credits</span>
                </Typography.SubText>
              </div>
              <div className="px-5 flex items-center space-x-3">
                <img src={timer} alt="" />
                <Typography.SubText className=" items-center space-x-2">
                  Estimated cost: <span className="text-[12px] font-bold">2-5 minutes</span>
                </Typography.SubText>
              </div>
            </motion.div>

            {/* <motion.div variants={itemVariants} transition={{ delay: 0.6, duration: 0.3 }}>
              <Typography.SubText className="text-center">{screenConfig.welcome.description}</Typography.SubText>
            </motion.div> */}
          </div>
          <div className="bg-white rounded-md w-[80%] py-5">
            <motion.div variants={itemVariants} transition={{ delay: 0.4, duration: 0.3 }}>
              <Typography.H4 className="px-5">Description</Typography.H4>
              <div className="px-5 flex items-center space-x-3">
                <Typography.SubText className="">{previewData?.surveyDescription}</Typography.SubText>
              </div>
            </motion.div>

            {/* <motion.div variants={itemVariants} transition={{ delay: 0.6, duration: 0.3 }}>
              <Typography.SubText className="text-center">{screenConfig.welcome.description}</Typography.SubText>
            </motion.div> */}
          </div>
          <div className="w-[80%] flex justify-start">
            <BlueButton text="Let’s go" onClick={incrementPage} />{" "}
          </div>
        </div>
      ) : null}
      {page >= 0 ? (
        <div className="flex flex-col w-full h-full items-center justify-center">
          <div className="w-[60%] py-[2em] h-[60%] overflow-hidden bg-white px-5 mx-auto flex flex-col drop-shadow-lg space-y-5">
            <motion.div
              key={page} // This key will trigger re-animation on page change
              initial="initial"
              animate="animate"
              variants={containerVariants}
              transition={{ duration: 0.5 }}
              className="flex flex-col"
            >
              <motion.div variants={itemVariants} transition={{ delay: 0.2, duration: 0.3 }}>
                <Typography.MicroText>
                  Question {page + 1} of {surveyQuestionsArray.length}
                </Typography.MicroText>
              </motion.div>
              <motion.div variants={itemVariants} className="mt-5" transition={{ delay: 0.4, duration: 0.3 }}>
                <Typography.H4>{surveyQuestionsArray[page]?.question}</Typography.H4>
              </motion.div>
              <motion.div variants={itemVariants} transition={{ delay: 0.6, duration: 0.3 }}>
                <Typography.SubText>{surveyQuestionsArray[page]?.description}</Typography.SubText>
              </motion.div>
            </motion.div>

            <motion.div
              key={surveyQuestionsArray[page]?.question}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
              className="w-[100%]"
            >
              {SurveyTypes({ type: surveyQuestionsArray[page]?.questionType, newData: surveyQuestionsArray[page] })}
            </motion.div>
          </div>
          <div className="flex items-center w-[60%] justify-end space-x-4 mt-5">
            <motion.div
              className="bg-primary-50 w-[80px] h-[40px] rounded-md px-5 flex items-center justify-center text-primary-800 text-xl cursor-pointer"
              onClick={decrementPage}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Typography.SubText className="px-5 font-[600] text-primary-800">Prev</Typography.SubText>
            </motion.div>

            <motion.div
              className="bg-primary-800 w-[80px] h-[40px] px-5 rounded-md flex items-center justify-center text-primary-50 text-xl cursor-pointer"
              onClick={incrementPage}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Typography.SubText className="px-5 font-[600] text-white">Next</Typography.SubText>
            </motion.div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PreviewCard;
