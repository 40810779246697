export const getHeader = (value) => {
  const x = value.split("/")[3];
  const y = value.split("/")[4];

  switch (x) {
    case "contacts":
      switch (y) {
        case "upload":
          return "Upload a file";
        case "create":
          return "Individual Entry";
        case "all-contacts?tab=0":
          return "All Contacts";
        case "all-contacts?tab=1":
          return "All leads";
        case "all-contacts?tab=2":
          return "Tags";
        case "all-contacts?tab=3":
          return "Segments";
        default:
          return "Contacts";
      }

    case "settings":
      return "Settings";

    case "broadcasts":
      switch (y) {
        case "sms":
          return "SMS";
        case "voice":
          return "Voice";
        case "email":
          return "Email";
        default:
          return "Messaging";
      }

    case "research":
      switch (y) {
        case "online-survey":
          return "Online Surveys";
        case "field-interview":
          return "Field Interviews";
        case "brand-tracking":
          return "Brand Tracking";
        default:
          return "Research";
      }

    case "dashboard":
      return "Home";

    case "pricing":
      return "Pricing";

    case "calendar":
      return "Calendar";
    default:
  }
};
