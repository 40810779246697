import React, { useEffect, useState } from "react";
import { surveyIcons } from "lib/surveyFunctions";
import { surveyType } from "lib/surveyFunctions";

import Tooltip from "components/Tooltip";
import Skeleton from "react-loading-skeleton";
import Mascot from "assets/images/mascotOne.png";
import Filters from "./SummaryCards/Filters";
import BlueButton from "components/Button/BlueButton";
import { DotLoader } from "react-spinners";
import { TimeIcon } from "assets/icons/survey/TimeIcon";
import { SurveyIcon } from "assets/icons/sidebar";
import { formatDateAlone } from "lib/formatDate";
import LineCharts from "pages/Research/components/Charts/LineCharts";
import MatrixBarCharts from "pages/Research/components/Charts/MatrixBarCharts";

const Summary = ({
  allData,
  data,
  filterPersonalData,
  filterCoreData,
  filterWorkData,
  overview,
  handleFilter,
  isFetchingSummary,
  filterData,
  setFilterData,
  filterLength,
}) => {
  const [viewState, setViewState] = useState([]);
  const [open, setOpen] = useState(null);
  const [viewMoreNumber, setViewMoreNumber] = useState(5);
  const [keywordValue, setKeywordValue] = useState(null);

  const [responseArray, setResponseArray] = useState([]);

  const handleKeyword = (value, i, parentIndex, g) => {
    if (keywordValue === null || keywordValue !== value) {
      setKeywordValue(value);

      const filteredSentences = data[g]?.responsesTxt[parentIndex]?.keywords?.response.filter((sentence) =>
        sentence.toLowerCase().includes(value.split("-")[1]),
      );

      setResponseArray(filteredSentences);

      setOpen(i);
    } else {
      setKeywordValue(null);
      setOpen(null);
    }
  };

  const analyticData = [
    {
      id: 1,
      icon: <SurveyIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Responses
          <Tooltip text="The number of people who completed your survey compared to the total number of people who received your survey." />
        </div>
      ),
      number: overview?.responses === undefined ? "0" : overview?.responses,
    },

    {
      id: 2,
      icon: <SurveyIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Completion time
          <Tooltip text=" The number of people who started and completed your survey, i.e., clicked the 'Submit' button." />
        </div>
      ),
      number: `${overview?.surveyTtl === undefined ? "0" : overview?.surveyTtl} sec`,
    },
    {
      id: 3,
      icon: <TimeIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Drop-off rate
          <Tooltip text="The number of people who started your survey but did not complete it, i.e. did not click the 'Submit' button." />
        </div>
      ),
      number: `${overview?.dropOffRate === undefined ? "0" : overview?.dropOffRate}%`,
    },

    {
      id: 4,
      icon: <TimeIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Avg. Completion time <Tooltip text="The average time taken to complete the survey." />
        </div>
      ),
      number: `${overview?.timeToComplete === undefined ? "0" : overview?.timeToComplete}sec`,
    },
  ];

  // const viewType = ["default_view", "column_chart", "pie_chart"];

  // const handleDropdown = (id) => {
  //   setOpen(open === id ? null : id);
  // };

  // const handleTypeSelect = (x, index) => {
  //   setViewState((prevValues) => {
  //     const updatedValues = [...prevValues];
  //     updatedValues[index] = {
  //       ...updatedValues[index],
  //       view: x,
  //     };
  //     return updatedValues;
  //   });
  //   handleDropdown();
  // };

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) {
      return;
    }

    const arrayState = data?.map((el) => ({
      id: el?.id,
      view: "default_view",
    }));

    setViewState(arrayState);
  }, [data]);

  function transformData(data) {
    return data.map((item) => {
      const newItem = { date: formatDateAlone(item.date) }; // Convert "date" to number
      for (const key in item) {
        if (key !== "date") {
          newItem[key] = item[key].value; // Extract value from nested object
        }
      }
      return newItem;
    });
  }

  const displayChart = (index) => {
    const newData = data && data[index]?.responsesOption;
    switch (viewState[index]?.view) {
      case "default_view":
        return <LineCharts data={transformData(newData)} filterLength={filterLength} />;
      default:
    }
  };

  const createNewObject = (messages) => {
    const transformedArray = messages?.map((item) => {
      const [key] = Object.keys(item); // Extract the key ('male', 'female', etc.)
      const { count, percentage } = item[key]; // Destructure count and percentage from the nested object

      return { name: key, value: count, percentage };
    });

    return transformedArray;
  };

  const handleViewMore = (type) => {
    if (type === "more") {
      setViewMoreNumber(viewMoreNumber + 5);
    } else if (type === "less") {
      if (viewMoreNumber < 10 && viewMoreNumber >= 5) {
        const newValue = viewMoreNumber - 5;
        setViewMoreNumber(viewMoreNumber - newValue);
      } else {
        setViewMoreNumber(viewMoreNumber - 5);
      }
    }
  };

  const transformMatrixData = (options) => {
    return Object.keys(options).map((column) => {
      const rows = options[column];
      const transformedRow = { name: column };
      Object.keys(rows).forEach((row) => {
        transformedRow[row] = rows[row].value;
      });
      return transformedRow;
    });
  };

  return (
    <div>
      <div className="flex justify-between ">
        {analyticData.map((el) => {
          return (
            <div className="md:w-[271px] " key={`analyticData3-${el}`}>
              <div>
                <div className="flex items-start text-left bg-white h-[81px] w-full border print:border-[1px] rounded-lg space-x-4 p-4">
                  <div className="w-[30px] h-[30px] rounded-full bg-primary-50 flex items-center justify-center ">
                    {el.icon}
                  </div>
                  <div>
                    <p className="font-semibold text-lg text-gray-800">
                      {el.number === 0 ? "0" : el.number || <Skeleton />}
                    </p>

                    <div className="text-s text-neutral-500">{el.title || <Skeleton />}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bg-white p-6 mt-[1em]">
        <div className="bg-primary-50 bg-no-repeat w-[100%] bg-cover p-4 rounded-md border-[1px] flex items-center mb-[1em] space-x-4">
          <img src={Mascot} alt="Mascot" className="w-[90px]" />

          <div>
            <div className="flex items-center">
              <p className="text-ss  bg-primary-100 text-primary-700 p-1 px-2 rounded-[4px] ">Progress Report</p>
            </div>

            <div className="text-ss mt-2 h-[6px] w-[370px] bg-[#D7DDEA] rounded-lg overflow-hidden">
              <div
                style={{ width: `${(overview?.progress?.response / +overview?.progress?.expected) * 100}%` }}
                className="bg-success-500 h-[100%] w-[50%]"
              ></div>
            </div>
          </div>

          <p className="text-s w-[35%]">
            <span className="font-bold">
              You currently have: {overview?.progress?.response} of {overview?.progress?.expected} Responses.
            </span>
            You will receive an email when your audience completes the survey.
          </p>
        </div>
        <div className="flex items-start justify-between">
          <div className="shadow-md w-[25%] p-4 rounded-md border-[1px] max-h-[60vh] overflow-y-scroll ">
            <p className=" font-[500] text-m">Questions</p>
            {isFetchingSummary ? (
              <div className="h-[30vh] flex items-center justify-center space-x-6">
                <DotLoader size={30} color="#29085C" />
                <p className="text-sm">Loading Questions</p>
              </div>
            ) : (
              <>
                {data?.map((el, i) => {
                  return (
                    <a
                      href={`#question-${i}`}
                      className="flex space-x-2 text-[#525252] items-center cursor-pointer my-2  h-[35px] hover:bg-primary-100 rounded-sm px-2"
                      key={i}
                    >
                      <div>{surveyIcons(el.optionType)}</div>
                      <p className="text-ss">{i + 1}</p>
                      <p className="text-ss pl-4">
                        {el.question.substring(0, 27)}
                        {el.question.length > 27 ? "..." : ""}
                      </p>
                    </a>
                  );
                })}
              </>
            )}
          </div>

          {isFetchingSummary ? (
            <div className="w-[45%] space-y-6 h-[60vh] flex items-center justify-center border-[1px] rounded-md space-x-4">
              <DotLoader size={30} color="#29085C" />

              <p className="text-sm">Loading Responses</p>
            </div>
          ) : (
            <div className="w-[45%] space-y-6 h-[60vh] overflow-y-scroll ">
              {data?.map((el, i) => {
                return (
                  <div id={`question-${i}`} key={`card${i}`} className="border-[1px] rounded-md p-5">
                    <div className="flex  justify-between items-center">
                      <div className="flex items-center space-x-2 ">
                        <p className="bg-primary-100 flex items-center justify-center text-primary-800 w-[25px] h-[28px] rounded-md font-medium">
                          {i + 1}
                        </p>
                        <div className="border-[1px] flex items-center space-x-2 p-[5px] px-2 rounded-md">
                          <div>{surveyIcons(el.optionType)}</div>
                          <p className="text-ss">{surveyType(el.optionType)}</p>
                        </div>
                      </div>

                      {el.optionType === "text_response" ||
                      el.optionType === "image" ||
                      el.optionType === "video" ||
                      el.optionType === "matrix" ||
                      el.optionType === "ranking" ? null : (
                        <></>
                      )}
                    </div>

                    <p className="text-lg font-bold my-3">{el.question}</p>
                    <hr className="my-[1em]" />
                    <div>
                      {el.optionType === "text_response" ? (
                        <div className="space-y-3">
                          <div className="flex items-center gap-3 flex-wrap">
                            {el?.responsesTxt?.map((elm, j) =>
                              elm?.keywords?.analytics.map((datum, w) => {
                                return (
                                  <div
                                    key={`textKey${i}`}
                                    className={`min-w-150px px-4 $  ${
                                      keywordValue === `${i}-${datum.word}`
                                        ? "bg-primary-800 text-white"
                                        : "bg-neutral-200 hover:bg-primary-200"
                                    } py-1 flex text-xs justify-center items-center rounded space-x-2 cursor-pointer`}
                                    onClick={() =>
                                      handleKeyword(`${i}-${datum.word}`, `${el.word}${el.count}${el.question}`, j, i)
                                    }
                                  >
                                    <p> {datum.word}</p> <p> ({datum.count})</p>
                                    {/* <p> ({datum.date})</p> */}
                                  </div>
                                );
                              }),
                            )}
                          </div>

                          <>
                            {open === `${el.word}${el.count}${el.question}` ? (
                              <>
                                {responseArray?.slice(0, viewMoreNumber).map((elm, i) => {
                                  return (
                                    <div
                                      key={`textRes${i}`}
                                      className="text-[#737373] bg-[#FAFAFA] p-2 rounded-md text-s flex items-center justify-between"
                                    >
                                      {elm.split(" ").forEach((el) => {
                                        if (el === keywordValue) {
                                          return <p className="text-primary-800">{el}</p>;
                                        }
                                      })}

                                      <p> {elm}</p>

                                      {/* {elm.map((word, j) => (
                                      <span key={`wordKey${j}`} className={word === keywordValue ? "text-primary-800" : ""}>
                                        {word}
                                      </span>
                                    ))} */}
                                    </div>
                                  );
                                })}{" "}
                              </>
                            ) : null}
                          </>

                          <div className="flex items-center space-x-4">
                            {viewMoreNumber >= responseArray?.length ? (
                              <></>
                            ) : (
                              <BlueButton
                                onClick={() => handleViewMore("more")}
                                text="View More"
                                className="w-[100%] text-sm"
                                type="button"
                              />
                            )}

                            {viewMoreNumber > 5 ? (
                              <BlueButton
                                onClick={() => handleViewMore("less")}
                                text="View Less"
                                className="w-[100%] text-sm"
                                type="button"
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : el.optionType === "image" ? (
                        <>
                          <div className="flex space-x-4 h-[200px] overflow-y-scroll">
                            {el.theResponses.length === 0
                              ? "No Response yet"
                              : el.theResponses.map((el, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="bg-[#FAFAFA] mb-2 w-[200px] h-[100%] p-2 text-ss rounded-lg"
                                    >
                                      <img src={el} alt="" className="object-contain w-[100%] h-[100%]" />
                                    </div>
                                  );
                                })}
                          </div>
                        </>
                      ) : el.optionType === "matrix" || el.optionType === "ranking" ? (
                        <>
                          <MatrixBarCharts data={transformMatrixData(el?.options)} />
                        </>
                      ) : el.optionType === "video" ? (
                        <>
                          <div className="flex space-x-4 h-[200px] overflow-y-scroll">
                            {el.theResponses.length === 0
                              ? "No Response yet"
                              : el.theResponses.map((el, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="bg-[#FAFAFA] mb-2 w-[200px] h-[100%] p-2 text-ss rounded-lg"
                                    >
                                      <video className="h-[100%] w-full rounded-[8px] object-cover" controls>
                                        <source src={el} />
                                      </video>
                                    </div>
                                  );
                                })}
                          </div>
                        </>
                      ) : (
                        <>
                          {displayChart(i)}

                          <>
                            {el.optionType === "single_choice" ? (
                              <>
                                {data[i]?.options?.MooyiOption?.value.length > 0 ? (
                                  <p className="mt-3 u underline text-xs">Others </p>
                                ) : null}

                                <div className="flex items-center gap-3 flex-wrap mt-2">
                                  {data[i]?.options?.MooyiOption?.value?.map((el, index) => {
                                    return (
                                      <p
                                        className="min-w-150px px-4 bg-neutral-200 hover:bg-primary-200 py-1 flex text-xs justify-center items-center rounded space-x-2 cursor-pointer"
                                        key={`${el}${i}${index}`}
                                      >
                                        {el}
                                      </p>
                                    );
                                  })}
                                </div>
                              </>
                            ) : null}
                          </>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="w-[25%] border-[1px] rounded-md shadow-md p-4 h-[60vh] overflow-y-scroll ">
            <Filters
              filterData={filterData}
              setFilterData={setFilterData}
              handleFilter={handleFilter}
              ageData={createNewObject(filterCoreData?.age)}
              stateData={createNewObject(filterCoreData?.state)}
              cityData={createNewObject(filterCoreData?.city)}
              countryData={createNewObject(filterCoreData?.country)}
              genderData={createNewObject(filterCoreData?.gender)}
              earningData={createNewObject(filterPersonalData?.earning)}
              educationLevelData={createNewObject(filterPersonalData?.educationLevel)}
              relationshipStatusData={createNewObject(filterPersonalData?.relationshipStatus)}
              parentalStatusData={createNewObject(filterPersonalData?.parentalStatus)}
              employmentTypeData={createNewObject(filterWorkData?.employmentStatus)}
              categoryOfInterestData={createNewObject(filterWorkData?.categoryOfInterest)}
              occupationData={createNewObject(filterWorkData?.occupation)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
