import { useMutation } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createSurveyTarget } from "services/newSurveyService";
import { queryClient } from "config/gateway";
import PrimaryInput from "components/Inputs/PrimaryInput";
import Typography from "utils/typography";
import BottomBar from "./BottomBar";
import SaveAndExitModal from "./SaveAndExitModal";
import Tooltip from "components/Tooltip";

const GenerateLink = ({ audienceType, setStep, step }) => {
  const [disableButton, setDisabledButton] = useState(true);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [surveyData, setSurveyData] = useState({
    audienceType: audienceType,
    expectedResponse: 0,
    reward: 0,
  });

  const locationArray = location.pathname.split("/");

  const { mutate, isPending } = useMutation({
    mutationFn: createSurveyTarget,
    onSuccess: () => {
      setStep(step + 1);

      Toast.success("Audience saved successfully");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: saveMutate, isPending: saveLoading } = useMutation({
    mutationFn: createSurveyTarget,
    onSuccess: () => {
      Toast.success("Audience saved successfully");

      queryClient.invalidateQueries({ queryKey: ["onlineSurveyDraft", "brandTrackingDraft", "offline_surveyDraft"] });
      if (locationArray.includes("brand-tracking")) {
        navigate(`/research/brand-tracking`);
      } else if (locationArray.includes("online-survey")) {
        navigate(`/research/online-survey`);
      } else {
        navigate(`/research/field-interview`);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleSave = () => {
    const newSurveyData = {
      ...surveyData,
      expectedResponse: +surveyData.expectedResponse,
      reward: +surveyData.reward,
    };
    mutate({
      surveyId: id,
      payload: newSurveyData,
    });
  };

  const handleSaveAndExit = () => {
    const newSurveyData = {
      ...surveyData,
      expectedResponse: +surveyData.expectedResponse,
      reward: +surveyData.reward,
    };

    saveMutate({
      surveyId: id,
      payload: newSurveyData,
    });
  };

  console.log(surveyData);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSurveyData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleForeignChange = (event) => {
    const { name, value } = event.target;

    setSurveyData((prevData) => ({
      ...prevData,
      foreignVoucher: {
        ...prevData.foreignVoucher,
        [name]: value,
      },
    }));
  };

  const checkState = useCallback(() => {
    if (surveyData.expectedResponse > 0 && (surveyData.reward === 0 || surveyData.reward % 100 === 0)) {
      setDisabledButton(false);
    }
  }, [surveyData.expectedResponse, surveyData.reward]);

  useEffect(() => {
    checkState();
  }, [checkState]);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  if (isPending || saveLoading) {
    return <PreLoader />;
  }

  return (
    <div className="">
      {open === 4 ? <SaveAndExitModal handleModal={handleModal} callBackFn={handleSaveAndExit} /> : null}

      <div className="border mt-6 p-6 max-w-[70%] rounded-md flex flex-col  items-start ">
        <div className="w-full flex items-start justify-between space-x-5">
          <div className="w-full space-y-1">
            <div className="w-full">
              <div className="flex items-center space-x-1">
                <Typography.SubText className="font-semibold">Responses needed</Typography.SubText>
                <Tooltip text="How many responses are you aiming for?" />
              </div>

              <div className="mt-1">
                <PrimaryInput
                  type="tel"
                  onChange={handleOnChange}
                  value={surveyData.expectedResponse}
                  name="expectedResponse"
                  placeholder="0"
                />
              </div>
              {/* </div> */}
            </div>
          </div>

          <div className="w-full space-y-1">
            <div className="flex items-center space-x-1">
              <Typography.SubText className="font-semibold">(Optional) Set rewards per response</Typography.SubText>
              <Tooltip text="Respondents can cash out as airtime or to their bank accounts." />
            </div>

            <div className="mt-1 ">
              <PrimaryInput
                type="tel"
                onChange={handleOnChange}
                value={surveyData.reward}
                name="reward"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="my-5 pt-1 rounded-md bg-gray-200 w-full"></div>
        <div className="flex items-center space-x-5 justify-between w-full">
          {/* <div className="flex space-x-4"> */}
          <div className="w-full">
            <div className="flex items-center space-x-1">
              <Typography.SubText className="font-semibold">(Optional) Add a promo code</Typography.SubText>
              {/* <Tooltip text="Minimum number of responses is 50" /> */}
            </div>

            <div className="mt-1">
              <PrimaryInput
                type="text"
                onChange={handleForeignChange}
                value={surveyData.voucher}
                name="voucher"
                placeholder="0"
              />
            </div>
            {/* </div> */}
          </div>
          <div className="w-full">
            <div className="flex items-center space-x-1">
              <Typography.SubText className="font-semibold">
                Provide instructions to redeem your offer
              </Typography.SubText>
              {/* <Tooltip text="Minimum number of responses is 50" /> */}
            </div>

            <div className="mt-1 ">
              <PrimaryInput
                type="text"
                onChange={handleForeignChange}
                value={surveyData.link}
                name="link"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>

      <BottomBar
        step={step}
        setStep={setStep}
        handleSave={handleSave}
        handleModal={() => handleModal(4)}
        disableButton={disableButton}
      />
    </div>
  );
};

export default GenerateLink;
