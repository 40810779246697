import React from "react";
import moment from "moment";
import { campStatus } from "lib/campaignFunction";
import { toTitleCase } from "lib/titleCase";
import { IoPersonCircleSharp } from "react-icons/io5";
import { formatDate } from "lib/formatDate";
import Typography from "utils/typography";

const Details = ({ data }) => {
  console.log(data);
  return (
    <div className="mt-4 bg-white p-4 rounded-md border-[1px]">
      {/* <p className="py-2 text-[20px] font-[500]">Details</p> */}

      <div className="flex items-start justify-between space-x-5">
        <div className="w-[50%] p-5 border rounded-lg space-y-3 bg-white">
          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Objective</p>
            <p className=" text-black font-[500]">{data?.campaignObjective}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Type</p>
            <p className=" text-black font-[500]">{toTitleCase(data?.CampaignChannel)}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Published</p>
            <p className=" text-black font-[500]">{moment(data?.campaignDate).format("MMMM Do YYYY, h:mm:ss a")}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px] items-center">
            <p className="w-[30%] text-[#525252] font-[400]">Tag</p>
            <div className="flex space-x-4">
              {data?.audience?.personalContacts?.map((el, i) => {
                return (
                  <p key={i} className="text-[12px] p-2 bg-gray-200 rounded-md">
                    {el}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Status</p>
            <p className=" text-black font-[500]">{campStatus(data?.campaignStatus)}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Created</p>
            <p className=" text-black font-[500]">
              {data?.createdAt === undefined ? "" : moment(data?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>
          <div className="text-m flex justify-between p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Unit spent</p>
            <p className=" text-[20px] font-[700] text-primary-700">{data.campaignCost}</p>
          </div>
        </div>
        <div className="w-[45%] flex flex-col items-center justify-center bg-[#FAFAFA] ">
          <div className="bg-white p-4 w-[100%] border-[1px]">
            <Typography>Preview</Typography>
          </div>

          <div className="border-[2px]  border-black  w-[60%] h-[60vh] items-end rounded-[42px] p-[10px] pb-0 my-6">
            <div className="border-[2px]  border-[#D0D5DD]  w-[100%] h-[98%] items-end rounded-[32px] overflow-hidden">
              <div className="bg-[#F5F5F5] h-[90px] flex items-center justify-center flex-col space-y-1">
                <div className="bg-[#969AA5] w-[40px] h-[40px] mx-auto rounded-full flex justify-center items-center">
                  <div className=" bg-[#969AA5]  flex items-center justify-center rounded-full overflow-hidden w-[30px] h-[30px]">
                    <IoPersonCircleSharp className="text-[28px] text-white" />
                  </div>
                </div>

                <p className="text-s font-[400]">{data?.senderId}</p>
              </div>

              <div className="text-center space-y-[1px] mt-2 text-ss">
                <p>Text message</p>
                <p>{formatDate(data?.campaignDate)}</p>
              </div>

              <div className="bg-[#E9E9EB] m-3 p-3 rounded-[10px] min-h-[100px]">
                <p className="text-xs">{data?.campaignContent}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
