import React, { useMemo, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SelectInput from "./Inputs/SelectInput";

const Pagination = ({ data, dataCount, itemsPerPage, setItemsPerPage, currentPage, setCurrentPage }) => {
  const dataSize = dataCount ?? 20;
  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = dataCount ?? data?.length;

    let startValue = (currentPage - 1) * itemsPerPage;
    startValue = startValue + 1;

    let endValue = Math.min(currentPage * itemsPerPage, totalValue);
    return [startValue, endValue];
  }, [currentPage, data?.length, dataCount, itemsPerPage]);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (pageCountEnd < dataSize) {
      setCurrentPage(currentPage + 1);
    }
  };

  function generateLimitedOptions(totalDataLength, itemsPerPage, maxOptions = 5) {
    const maxOptionValue = Math.floor(totalDataLength / itemsPerPage) * itemsPerPage;
    const step = Math.ceil(maxOptionValue / maxOptions);

    const options = Array.from({ length: maxOptions }, (_, index) => {
      const value = (index + 1) * step;
      return value <= maxOptionValue ? { label: value, value: value } : null;
    }).filter(Boolean);

    return options;
  }

  const countOption = [
    { label: "20", value: 20 },
    { label: "40", value: 40 },
    { label: "60", value: 60 },
    { label: "80", value: 80 },
    { label: "100", value: 100 },
  ];

  const options = generateLimitedOptions(dataCount, itemsPerPage);

  const resolveOptions = dataCount !== undefined ? (dataCount > 100 ? countOption : options) : options;

  return (
    <div className="flex items-center justify-between w-full ">
      {dataSize < itemsPerPage ? null : (
        <div className="flex items-center space-x-2">
          <p>Per page</p>
          <SelectInput
            value={itemsPerPage}
            options={resolveOptions}
            onChange={(e) => setItemsPerPage(e.target.value)}
          />
        </div>
      )}
      <div className={`flex items-center space-x-4 mt-5 ${dataSize < itemsPerPage && "hidden"}`}>
        <div
          onClick={pageCountStart === 1 ? null : handlePrev}
          className={`hover:border py-1 px-2 ${
            pageCountStart === 1 ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <MdKeyboardArrowLeft />
        </div>
        <div className="flex items-center space-x-1 text-s">
          <span className="border py-1 px-3 flex items-center justify-center">
            {pageCountStart} - {pageCountEnd}
          </span>{" "}
          <span>of</span> <span className="border py-1 px-3 flex items-center justify-center">{dataSize}</span>
        </div>
        <div
          onClick={pageCountEnd === dataSize ? null : handleNext}
          className={`hover:border py-1 px-2 ${
            pageCountEnd === dataSize ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <MdKeyboardArrowRight />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
