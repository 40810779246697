import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";
import AuthButton from "components/Button/AuthButton";
import PrimaryInput from "components/Inputs/PrimaryInput";
import Toast from "config/toast";
import { useFormik } from "formik";
import { useState } from "react";
import { resetPassword } from "services/authService";
import * as Yup from "yup";
import Logo from "assets/images/newLogo.png";

const initialValues = {
  password: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
});

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const { mutate } = useMutation({
    mutationFn: resetPassword,
    onError: (error) => {
      Toast.error("user can not reset the password");
    },
    onSuccess: () => {
      Toast.success("Password Changed!");
      navigate("/auth/login");
    },
  });

  const onSubmit = async (data) => {
    const reqBody = { ...data, token };

    try {
      mutate(reqBody);
    } catch (err) {
      Toast.error(err);
    }
  };

  const hidePassword = () => {
    setShowPassword(!showPassword);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  return (
    <div className="w-[85%] mx-auto flex flex-col ">
      <div className="flex justify-center">
        <img src={Logo || ""} alt="Mooyi logo" className="w-[150px] mt-[1em]" />
      </div>
      {/* <img alt="" src={ResetImg} className="w-[100px]" /> */}

      <h1 className="font-semibold text-[36px]">Set New Password</h1>
      <p className="text-[#737373] my-[10px]">Enter new password to proceed</p>

      <form onSubmit={handleSubmit} className="mt-[2em]">
        <div className="relative">
          <PrimaryInput
            type={`${showPassword ? "text" : "password"}`}
            placeholder="8+ characters"
            onChange={handleChange}
            text="Password"
            name="password"
            onBlur={handleBlur}
            value={values.password}
            error={getError("password")}
          />

          <p
            className={`absolute select-none cursor-pointer ${showPassword ? "text-black" : "text-grey"} ${
              getError("password") ? "top-[45%]" : "top-[52%] "
            } mr-6 right-0 text-s font-extrabold`}
            onClick={hidePassword}
          >
            {showPassword ? "Hide" : "Show"}
          </p>
        </div>
        <div className="mt-6"></div>
        <AuthButton type="submit" text="Proceed" />
      </form>
    </div>
  );
}
