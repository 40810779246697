import FileUpload from "components/Inputs/FileUpload";
import PrimaryInput from "components/Inputs/PrimaryInput";
import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Style = () => {
  const [open, setOpen] = useState(null);

  const [styleData, setStyleData] = useState({
    bgColor: "",
    buttonColor: "",
    buttonTextColor: "",
    logo: "",
  });

  const handleChange = (e) => {
    setStyleData({
      ...styleData,
      [e.target.name]: e.target.value,
    });
  };

  const toggleModal = (id) => {
    setOpen(open === id ? null : id);
  };
  return (
    <div>
      <div className="p-2 space-y-3">
        <PrimaryInput
          text="Background color"
          type="text"
          name="bgColor"
          placeholder="#000000"
          onChange={handleChange}
          value={styleData.bgColor}
        />

        <PrimaryInput
          text="Button color"
          type="text"
          name="butonColor"
          placeholder="#000000"
          onChange={handleChange}
          value={styleData.butonColor}
        />

        <PrimaryInput
          text="Button text color"
          type="text"
          name="buttonTextColor"
          placeholder="#000000"
          onChange={handleChange}
          value={styleData.buttonTextColor}
        />

        <hr className="w-[100%] my-2" />
        <div className="flex justify-between items-center cursor-pointer mt-4" onClick={() => toggleModal(2)}>
          <p className="text-sm">Add Logo</p>

          {open === 2 ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>

        <p className="text-[11px] text-gray-500 mt-2">Add your business logo to personalise your survey.</p>

        {open === 2 ? (
          <div className="mt-2">
            <FileUpload name="logo" setData={setStyleData} data={styleData} />
          </div>
        ) : null}

        <hr className="w-[100%] mt-2" />
      </div>
    </div>
  );
};

export default Style;
