import moment from "moment";
import React from "react";

import Typography from "utils/typography";
import previewImage from "assets/images/previewImage.png";
// import GreyButton from "components/Button/GreyButton";

const Details = ({ data }) => {
  return (
    <div className="bg-white rounded-md border-1 p-6 flex items-start justify-between gap-x-4">
      <div className="space-y-6 rounded-md p-6 w-[60%] border-[1px]">
        <div className="pb-4 border-b border-1">
          <div className="flex items-center space-x-4">
            <div className="w-[70px] h-[40px] overflow-hidden border-[1px] rounded-md">
              <img
                src={data?.surveyDetails?.coverImage || previewImage}
                className="w-[100%] h-[100%] object-cover text-[6px]"
                alt="SurveyImage"
              />
            </div>

            <div>
              <Typography.Text>{data?.surveyDetails?.surveyName}</Typography.Text>

              <div className="flex items-center space-x-3">
                <Typography.SubText className="text-gray-600">{data?.surveyDetails?.surveyCategory}</Typography.SubText>

                <Typography.SubText>{moment(data?.publishedAt).format("MMMM Do YYYY | h:mm a")}</Typography.SubText>
              </div>
            </div>
          </div>

          <div></div>
        </div>

        <div className="flex items-center justify-between border-b border-1   pb-4 text-sm text-gray-700">
          <Typography.SubText className="font-semibold">Targeted Responses</Typography.SubText>

          <p>{data?.targetingOption?.expectedResponse}</p>
        </div>

        <div className="flex border-b border-1 pb-2 text-s font-medium">
          <Typography.SubText className="font-semibold">Tags</Typography.SubText>
          <div className="flex space-x-4">
            {data?.tags?.map((el, i) => {
              return (
                <p
                  className=" text-black font-[500] bg-[#F7F7F7] p-2 text-ss rounded-[4px]"
                  key={`${i}personalContact`}
                >
                  {el}
                </p>
              );
            })}

            {data?.targetingOption?.demographics?.country?.map((el, i) => {
              return (
                <p
                  className="min-w-[90px] flex items-center justify-center text-black font-[500] bg-[#F7F7F7] p-2 text-ss rounded-[4px]"
                  key={`${i}personalContact`}
                >
                  {el}
                </p>
              );
            })}

            {data?.targetingOption?.demographics?.state?.slice(0, 5).map((el, i) => {
              return (
                <p
                  className=" text-black font-[500] bg-[#F7F7F7] p-2 text-ss rounded-[4px] min-w-[90px] flex items-center justify-center"
                  key={`${i}personalContact`}
                >
                  {el}
                </p>
              );
            })}

            {data?.targetingOption?.demographics?.interests?.map((el, i) => {
              return (
                <p
                  className="min-w-[90px] flex items-center text-black font-[500] bg-[#F7F7F7] p-2 text-ss rounded-[4px]"
                  key={`${i}personalContact`}
                >
                  {el}
                </p>
              );
            })}
          </div>
        </div>

        <div className="flex justify-between pb-2 text-s font-medium">
          <Typography.SubText className="font-semibold">Total survey units</Typography.SubText>
          <Typography.H2 className="text-primary-800">{data?.cost}</Typography.H2>
        </div>
      </div>

      <div className="w-[40%] bg-gray-50 border-[1px] h-[70vh]">
        <div className="text-sm bg-white p-4 border-b-[1px]">
          <p className="text-xs text-gray-600 text-center">Preview</p>
        </div>

        <div className="p-4">
          <div className="bg-green-400 h-[55vh] w-[100%] flex items-start justify-center flex-col space-y-5 p-4">
            <div className="h-[120px] w-[100%] bg-green-100 rounded-lg overflow-hidden">
              <img
                src={data?.surveyDetails?.coverImage || previewImage}
                alt="surveyDetails.coverImage"
                className="w-[100%] h-[100%] object-cover text-[6px]"
              />
            </div>

            <div className="h-[100px] w-[100%] bg-white rounded-lg p-4 overflow-y-scroll">
              <Typography.SubText className="font-semibold">{data?.surveyDetails?.surveyName}</Typography.SubText>

              <Typography.MicroText className="w-[100%]">{data?.surveyDetails?.surveyDescription}</Typography.MicroText>
            </div>

            <div className="bg-green-800 w-[140px] flex items-center justify-center h-[40px] rounded-3xl">
              <Typography.MicroText className="font-semibold text-white">Start</Typography.MicroText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
