import React, { useState } from "react";
import reportClicked from "assets/images/broadcast/reportClicked.png";
import emailSent from "assets/images/broadcast/newSent.png";
import bounced from "assets/images/broadcast/bounced.png";
import blackDoc from "assets/images/broadcast/blackDoc.png";
import EmailGraph from "./EmailGraph";
import ToggleButton from "components/Button/ToogleButton";
import Dropdown from "components/DropDown";

const Overview = ({ data, campDetails, graphData, select, setSelect }) => {
  const [toggleValue, setToggleValue] = useState(false);
  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };

  const trends = [
    { label: "Trends", value: "" },
    { label: "Trends per day", value: "D" },
    { label: "Trends per Week", value: "W" },
    { label: "Trends per Month", value: "M" },
  ];

  return (
    <div>
      <div className="flex items-center space-x-3">
        <p className={`font-[500] text-[16px] ${toggleValue ? "text-gray-300" : ""}`}>Absolute (#)</p>
        <ToggleButton toggleValue={toggleValue} onChange={handleToggleChange} />
        <p className={`font-[500] text-[16px] ${!toggleValue ? "text-gray-300" : ""}`}>Percentage (%)</p>
      </div>
      <div className="">
        <div className="space-x-4 flex items-center justify-between">
          <div className="w-full bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
            <div className="flex justify-between">
              <div className="flex items-center justify-between space-x-5">
                <img src={emailSent} alt="" className="w-[51px] h-[51px]" />
                <div>
                  <p className="text-[28px] font-semibold">
                    {toggleValue ? <span>{Math.round(data?.sent?.percentage ?? 0)}%</span> : data?.sent?.value}
                  </p>
                  <p className="text-m">Sent</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
            <div className="flex justify-between">
              <div className="flex items-center justify-between space-x-5">
                <img src={blackDoc} alt="" className="w-[51px] h-[51px]" />
                <div>
                  <p className="text-[28px] font-semibold">
                    {toggleValue ? <span>{Math.round(data?.opened?.percentage ?? 0)}%</span> : data?.opened?.value}
                  </p>
                  <p className="text-m">Opened</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
            <div className="flex justify-between">
              <div className="flex items-center justify-between space-x-5">
                <img src={reportClicked} alt="" className="w-[51px] h-[51px]" />
                <div>
                  <p className="text-[28px] font-semibold">
                    {toggleValue ? <span>{Math.round(data?.clicked?.percentage ?? 0)}%</span> : data?.clicked?.value}
                  </p>
                  <p className="text-m">Clicked</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
            <div className="flex justify-between">
              <div className="flex items-center justify-between space-x-5">
                <img src={bounced} alt="" className="w-[51px] h-[51px]" />
                <div>
                  <p className="text-[28px] font-semibold">
                    {toggleValue ? <span>{Math.round(data?.bounced?.percentage ?? 0)}%</span> : data?.bounced?.value}
                  </p>
                  <p className="text-m">Bounced</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-white h-[400px] text-ss rounded-[12px] border-[1px] mt-4 p-4">
          <div className="w-[15%] mb-[1em]">
            <Dropdown
              tag={"Trends"}
              options={trends}
              onSelect={(select) => setSelect(select.value)}
              name="trends"
              value={select}
            />
          </div>

          <EmailGraph data={graphData} />

          <div className="flex justify-center items-center space-x-4">
            <div className="flex justify-center items-center space-x-2">
              <div className="w-[10px] h-[10px] bg-primary-800"></div>
              <p>Opened</p>
            </div>

            <div className="flex justify-center items-center space-x-2">
              <div className="w-[10px] h-[10px] bg-[#DD0BAF]"></div>
              <p>Clicked</p>
            </div>

            {/* <div className="flex justify-center items-center space-x-2">
              <div className="w-[30px] h-[10px] bg-[#737373]"></div>
              <p>DnD</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
