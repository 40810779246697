import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { getContactSpace } from "services/contactService";
import UploadCSV from "pages/Contacts/NewContact/UploadCSV";
import IndividualEntry from "pages/Contacts/NewContact/IndividualEntry";

const UploadNewContactModal = ({ contactModal, setContactModal }) => {
  const [activeTab, setActiveTab] = useState("csv");
  const { data } = useQuery({ queryKey: ["contactCount"], queryFn: getContactSpace });

  return (
    <div
      className={`bg-grey fixed top-0 bottom-0 left-0 h-[100vh] w-full flex justify-center items-center z-[999] transition-all duration-300 ${
        contactModal ? "opacity-100 block" : "hidden opacity-0"
      }`}
    >
      <div
        className={`bg-white text-[#292D32] rounded-[8px] p-6 w-[90%] max-w-[640px] transition-all duration-300 delay-300 ${
          contactModal ? "opacity-100 block transition-y-0" : "hidden opacity-0 translate-y-[12px]"
        }`}
      >
        <div className="flex items-center justify-between">
          <p className="text-[24px] font-bold">Upload New Contact</p>
          <div
            className="text-[#737373] bg-[#ECECECB2] h-[30px] w-[30px] flex items-center justify-center rounded-full text-[20px] cursor-pointer"
            onClick={() => setContactModal(!contactModal)}
          >
            <FaTimes className="w-[10px] " />
          </div>
        </div>
        <div>
          <p className="text-s leading-[21px] text-grey mt-2">Upload additional contacts to send your surveys to.</p>

          <div className="mt-6">
            <div className="flex items-center mb-3">
              <button
                onClick={() => setActiveTab("csv")}
                className={`p-1 mr-2 ${
                  activeTab === "csv" ? "border-b-2 border-purple" : "text-grey"
                } transition-all duration-300 text-s`}
              >
                Upload CSV or XLSV File
              </button>
              <button
                onClick={() => setActiveTab("individual")}
                className={`p-1 ${
                  activeTab === "individual" ? "border-b-2 border-purple" : "text-grey"
                } transition-all duration-300 text-s`}
              >
                Individual Entry
              </button>
            </div>

            <div className="overflow-y-scroll h-[550px] w-full">
              {activeTab === "csv" && <UploadCSV step={1} setStep={() => {}} countContact={data} />}
              {activeTab === "individual" && <IndividualEntry />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadNewContactModal;
