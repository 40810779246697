import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "pages/Dashboard";
import EmailSent from "pages/auth/EmailSent";
import AuthLayout from "layouts/AuthLayout";
import Workspace from "pages/WorkSpace";
import WorkspaceLayout from "layouts/WorkspaceLayout";
import DashLayout from "layouts/DashLayout";
import Auth from "pages/auth";
import "react-loading-skeleton/dist/skeleton.css";
import VerifyInvite from "pages/auth/VerifyInvite";
import Home from "pages/Dashboard/NewUser/Home";
import Choose from "pages/MyAccount/ChoosePlan";
import MyAccount from "pages/MyAccount";
import Settings from "pages/Settings";
import PublicPages from "pages/Public";
import Broadcasts from "pages/Broadcasts";
import PluginComponent from "pages/PluginComponent";
import { useEffect } from "react";
import { initMixpanel } from "config/mixpanel";
import Contacts from "pages/Contacts";
import UnSubscribeLayout from "layouts/UnSubscribeLayout";
import UnSubscribe from "pages/Unsubscribe";
import Pricing from "pages/Settings/Pricing";
import CalenderIndex from "pages/calender";
import Research from "pages/Research";
import Insight from "pages/Insight";

function App() {
  useEffect(() => {
    initMixpanel();
  }, []);

  return (
    <div className="font-primary">
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="*" element={<Navigate to="/auth" replace />} />
          <Route exact path="/auth/*" element={<Auth />} />
        </Route>
        <Route exact path="/auth/email-sent" element={<EmailSent />} />

        <Route exact path="/auth/verify-invite/:id" element={<VerifyInvite />} />

        <Route exact path="/public/*" element={<PublicPages />} />

        <Route element={<DashLayout />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/editor" element={<PluginComponent />} />
          <Route exact path="/broadcasts/*" element={<Broadcasts />} />
          <Route exact path="/calendar" element={<CalenderIndex />} />
          <Route exact path="/contacts/*" element={<Contacts />} />
          <Route exact path="/settings/*" element={<Settings />} />
          <Route exact path="/account/*" element={<MyAccount />} />

          <Route exact path="/research/*" element={<Research />} />
          <Route exact path="/insights/*" element={<Insight />} />
          <Route exact path="/pricing/*" element={<Pricing />} />
        </Route>

        <Route element={<WorkspaceLayout />}>
          <Route exact path="/account/plans" element={<Choose />} />
          <Route exact path="/organization" element={<Workspace />} />
          <Route exact path="/dashboard/kyc" element={<Home />} />
        </Route>

        <Route element={<UnSubscribeLayout />}>
          <Route exact path="/unsubscribe/*" element={<UnSubscribe />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
