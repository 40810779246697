import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import React, { useCallback, useEffect, useState } from "react";
import user from "assets/icons/user.png";
import { BsSearch } from "react-icons/bs";
import GreyButton from "components/Button/GreyButton";
import useGetAllTags from "hooks/useGetAllTags";

const AddToTagsModal = ({ handleAddToTags, setSelectedTag, toggleModal, selectedTag }) => {
  const { tagData } = useGetAllTags();

  console.log(tagData, "all tags items");

  const [filterData, setFilteredData] = useState();
  const [query, setQuery] = useState("");

  // const addTag = (id) => {
  //   if (selectedTag.includes(id)) {
  //     const updatedTags = selectedTag.filter(tagId => tagId !== id);
  //     setSelectedTag(updatedTags);
  //   } else {
  //     setSelectedTag([...selectedTag, id]);
  //   }
  // };

  const handleModal = () => {
    toggleModal(8);
  };

  // console.log(selectedTag)

  const handleFilter = useCallback(
    (searchTerm) => {
      const filteredData = searchTerm
        ? tagData?.tags.filter((item) => {
            const content = item.name.toLowerCase();
            return content.includes(searchTerm.toLowerCase());
          })
        : tagData?.tags;

      setFilteredData(filteredData);
    },
    [tagData],
  );

  useEffect(() => {
    handleFilter(query);
  }, [handleFilter, query]);

  return (
    <Modal title="Add contacts to tag" onClose={toggleModal}>
      <div className={`w-[400px] px-5`}>
        <div className="flex justify-between items-center">
          <p className="text-m font-semibold my-2">Choose a tag to add your contacts</p>

          <form
            action="
"
          >
            <p className="text-s text-primary-800 font-[500] cursor-pointer" onClick={handleModal}>
              Create Tag
            </p>
          </form>
        </div>
        <div className="my-5">
          <div className="bg-white border w-full h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search Tag... "
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
        <div className={`space-y-2 ${tagData?.tags?.length > 6 && "h-[300px] overflow-y-auto"}`}>
          {filterData?.map((el, i) => (
            <div
              className={`flex items-start space-x-2 hover:bg-primary-50 cursor-pointer p-2 rounded-md ${
                selectedTag === el.name && "bg-primary-50"
              }`}
              onClick={() => setSelectedTag(el.name)}
            >
              <div className="w-[30px] mt-1 h-[30px] rounded-full p-2 bg-primary-100">
                <img src={user} alt="" />
              </div>
              <div>
                <p className="text-[15px] font-[500]">{el.name}</p>
                <p className="text-[13px] text-gray-400">{el.contactCount} contacts</p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-5 mt-[3em]">
          <div>
            <GreyButton text="Cancel" onClick={toggleModal} />
          </div>
          <div className="flex space-x-2">
            <BlueButton text="Add" css="" onClick={handleAddToTags} disabled={selectedTag === ""} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddToTagsModal;
