import React from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Typography from "utils/typography";

const COLORS = ["#29085C", "#00DA7A", "#5F0BDD", "#A26BF4", "#CEAFFA"];

const formatLabel = (value) => `${value}%`;

const BarCharts = ({ data, questionOption, filterLength }) => {
  return (
    <div>
      <div className="flex gap-4 flex-wrap mb-4">
        {data?.map((elm, index) => {
          return (
            <div key={`optionTag${index}`} className="flex items-center space-x-2 p-1 px-2 border-[1px] rounded-[4px]">
              <div className="w-[10px] h-[10px] rounded-sm" style={{ background: COLORS[index % COLORS.length] }}></div>
              <p className="text-ss">{elm.name === "MooyiOption" ? "Others" : elm.name}</p>
            </div>
          );
        })}
      </div>

      {questionOption !== undefined ? (
        <div className="flex items-center justify-between py-2">
          <Typography.SubText className="font-semibold">
            {questionOption?.min?.value} - {questionOption?.min?.label}
          </Typography.SubText>

          <Typography.SubText className="font-semibold">
            {questionOption?.max?.value} - {questionOption?.max?.label}
          </Typography.SubText>
        </div>
      ) : null}

      <div className="w-[100%] h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={150}
            height={40}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: -20,
              bottom: 5,
            }}
          >
            <Bar
              dataKey={`${filterLength === 0 ? "percentage" : "staticPercentage"}`}
              fill="#8884d8"
              radius={[4, 4, 0, 0]}
              label={{ position: "insideTop", fill: "white", fontSize: 12, formatter: formatLabel }}
            >
              {data.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % 10]} />
              ))}
            </Bar>

            <XAxis dataKey="name" tick={{ fontSize: 12 }} axisLine={{ stroke: "#ECECEC" }} />
            <YAxis axisLine={{ stroke: "#ECECEC" }} tick={{ fontSize: 12 }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarCharts;
