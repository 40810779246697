import React from "react";
import "./types.js";
import QuestionsPanel from "./QuestionsPanel.jsx";
import WelcomePanel from "./WelcomePanel.jsx";
import useSurveyStore from "store/insightStore.js";
import { motion } from "framer-motion";
// import { nps } from "data/insightData.js";

/**
 * @type {Question[]}
 */

const containerVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const itemVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
};

const AnimationWrapper = ({ children }) => (
  <motion.div
    initial="initial"
    animate="animate"
    variants={containerVariants}
    transition={{ duration: 0.4, staggerChildren: 0.2 }} // Adjust staggerChildren for delay between items
  >
    {children}
  </motion.div>
);

const Questions = () => {
  const { surveyQuestionsArray, setSurveyQuestionsArray, screenConfig } = useSurveyStore();

  return (
    <AnimationWrapper>
      <motion.div variants={itemVariants}>
        <WelcomePanel info={screenConfig.welcome} nameKey="welcome" />
      </motion.div>

      {surveyQuestionsArray?.map((el, i) => (
        <motion.div key={`Questions-${i}`} variants={itemVariants}>
          <QuestionsPanel
            setSurveyQuestionsArray={setSurveyQuestionsArray}
            surveyQuestionsArray={surveyQuestionsArray}
            question={el}
            index={i}
          />
        </motion.div>
      ))}

      <motion.div variants={itemVariants}>
        <WelcomePanel info={screenConfig.thankYou} nameKey="thankYou" />
      </motion.div>
    </AnimationWrapper>
  );
};

export default Questions;
