import { CustomButton } from "components/Button/CustomButton";
import React, { useState } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import VideoPlayer from "../components/VideoPlayer";
import AudioPlayer from "../components/AudioPlayer";
import TextResponse from "../components/TextResponse";
import { useQuery } from "@tanstack/react-query";
import { getSingleResponse } from "services/newSurveyService";
import dayjs from "dayjs";
import { getTimeDuration } from "lib/formatDate";
import PreLoader from "components/Loader/PreLoader";
import { convertTimeStampToSecs } from "lib/checkTime";
import { FiDownload } from "react-icons/fi";

const transcriptData = [];

for (let i = 0; i < 10; i++) {
  transcriptData.push({
    startTime: "0:00",
    endTime: "0:12",
    content: "How well do you understand the concept? (Scale 1-5)",
  });
}

const SingleResponse = () => {
  const { responseId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [current, setCurrent] = useState(0);
  const [clickIndex, setIndex] = useState();
  // const [playbackTime, setPlaybackTime] = useState(0)
  console.log(responseId);

  const { data, isLoading } = useQuery({
    queryKey: ["getSingleResponse", responseId],
    queryFn: () => getSingleResponse(responseId),
  });
  const responseData = data?.data;
  const fileType = responseData !== undefined ? responseData?.mediaProp?.mediaType : null;

  console.log(responseData);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  // https://groundible.fra1.digitaloceanspaces.com/survey/4c5b729e-37cf-4173-ad7b-1ed2d08ec326-REC8753469954492865803.mp4

  const mediaType =
    fileType !== "Audio_Response" ? (
      <VideoPlayer url={responseData?.mediaUrl} current={current} />
    ) : (
      <AudioPlayer
        url={
          responseData?.mediaUrl !== undefined
            ? responseData?.mediaUrl
            : "https://groundible.fra1.digitaloceanspaces.com/survey/80bb7149-90cd-411b-8f6c-6177684fb542-df7a2b7b-3f1e-4cc2-a484-7527d8eb1244.aac"
        }
        current={current}
        className="h-[400px]"
      />
    );

  const dateFormat = (minutes, seconds) => {
    return `${minutes?.toString().padStart(2, "0")}:${seconds?.toString().padStart(2, "0")}`;
  };

  const handleClick = ({ index, time }) => {
    setCurrent(time);
    setIndex(index);
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex items-center text-primary-800 space-x-2">
            <HiChevronLeft />
            <p className="text-s font-medium" onClick={() => navigate(-1)}>
              Back to responses
            </p>
          </div>
          <div className="flex space-x-4">
            <CustomButton text="Download" icon={<FiDownload />} css="px-3" onClick={() => handleModal(1)} />
          </div>
        </div>
        <div className="rounded-xl border p-5 mt-5 bg-white">
          {/* <div className="flex items-center justify-between">
            <p className="font-[500] text-[20px]">{state?.name}</p>
            <div className="flex justify-between items-center border rounded-md p-2">
              <div className="flex items-center space-x-4">
                <CgArrowLongLeft type="button" text="Prev" disabled={false} onClick={() => ""} />

                <p className="text-s font-semibold">1 out of 1000 results</p>

                <CgArrowLongRight onClick={() => ""} disabled={false} text="Next" type="button" />
              </div>
            </div>
          </div> */}
          {state?.responseType !== "text" ? (
            <div className="mt-10 flex space-x-10 items-start">
              <div className="w-[60%]">
                <div>
                  <div>
                    {/* {state?.responseType === "video" ? (
                      <VideoPlayer url="" />
                    ) : (
                      <div>
                        <AudioPlayer url={responseData?.mediaUrl} className="h-[400px]" />
                      </div>
                    )} */}
                    {mediaType}
                  </div>
                </div>
                <div className="mt-10 space-y-5">
                  <div className=" border-b pb-1 text-[14px]">
                    <div className="flex items-center w-[100%] justify-between">
                      <p className="font-[500] w-[40%]">Completed</p>
                      <p className="text-left w-[60%]">
                        {dayjs(responseData?.responseTime?.endTime).format("MMMM D, YYYY | h:mma")}
                      </p>
                    </div>
                  </div>
                  <div className=" border-b pb-1 text-[14px]">
                    <div className="flex items-center w-[100%] justify-between">
                      <p className="font-[500] w-[40%]">Completion time</p>
                      <p className="text-left w-[60%]">
                        {getTimeDuration(responseData?.responseTime?.startTime, responseData?.responseTime?.endTime)}
                      </p>
                    </div>
                  </div>
                  <div className="pb-1 text-[14px]">
                    <div className="flex items-center w-[100%]">
                      <p className="font-[500] w-[40%]">Location</p>
                      <p className="text-left w-[60%]">Lagos, Nigeria</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border rounded-md p-3 w-[40%]">
                <div>
                  <p className="font-[500] text-[14px]">Transcript</p>
                  <div className="space-y-5 mt-5">
                    {responseData?.responses.map((el, i) => {
                      const startTime = dateFormat(
                        el?.response?.startTimestamp?.minutes,
                        el?.response?.startTimestamp?.seconds,
                      );
                      const endTime = dateFormat(
                        el?.response?.endTimestamp?.minutes,
                        el?.response?.endTimestamp?.seconds,
                      );
                      const startSecs = convertTimeStampToSecs(el?.response?.startTimestamp);

                      return (
                        <div
                          key={i}
                          className={`grid grid-cols-3 rounded transition-all duration-150 cursor-pointer p-3 w-full items-start text-[14px] ${
                            clickIndex === i ? "bg-primary-100" : ""
                          }`}
                          onClick={() => handleClick({ index: i, time: startSecs })}
                        >
                          <div className="bg-primary-100 w-[20%] px-2 py-1 rounded min-w-fit text-nowrap flex items-center">
                            {startTime} - {endTime}
                          </div>
                          <div className="col-span-2">
                            <p>{el.question}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <TextResponse />
            </div>
          )}
        </div>
      </div>
      {/* {open === 1 ? <ShareModal handleModal={handleModal} /> : null} */}
    </div>
  );
};

export default SingleResponse;
