import React, { useState } from "react";
import BuilderTab from "./builder/BuilderTab";
import { UIConstants } from "data/constants/uiConstants";
import Typography from "utils/typography";
import { FaPencilAlt } from "react-icons/fa";
import BottomBar from "./BottomBar";
import PreviewCard from "./PreviewCard";
import { useMutation } from "@tanstack/react-query";
import Toast from "config/toast";
import { createInsightQuestion } from "services/insightService";
import { useLocation, useSearchParams } from "react-router-dom";
import useSurveyStore from "store/insightStore";
import PreLoader from "components/Loader/PreLoader";
import CreationModal from "./CreationModal";

const CustomiseSurvey = ({ step, setStep, surveyName }) => {
  const [open, setOpen] = useState(null);
  const [params] = useSearchParams();
  const insightId = params.get("insightId");
  const { screenConfig, surveyQuestionsArray } = useSurveyStore();
  const location = useLocation();

  const pathArray = location.pathname.split("/");

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: createInsightQuestion,
    onSuccess: () => {
      Toast.success("Audience saved successfully");
      setStep(step + 1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleSubmit = () => {
    const questionData = {
      insightId,
      screenConfig,
      question: surveyQuestionsArray,
    };

    mutate(questionData);
  };

  if (isPending) {
    return <PreLoader />;
  }

  return (
    <div className="h-[70vh] flex items-start gap-[2em]">
      <div
        className={`w-[30%] border-[1px] shadow-md h-[100%] overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <BuilderTab />
      </div>

      <div
        className={`w-[70%] border-[1px] shadow-md h-[100%] bg-neutral-100  overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <div className="h-[50px] border-b-[1px] flex items-center justify-between px-4 bg-white">
          <div className="text-sm flex items-center">
            <Typography.SubText className="font-semibold">{surveyName}</Typography.SubText>

            <div
              className="hover:bg-gray-200 p-2 cursor-pointer rounded"
              onClick={() => handleModal("openSurveyModal")}
            >
              <FaPencilAlt className="text-gray-600" />
            </div>
          </div>
        </div>

        <div className="h-[calc(100%-50px)] flex items-center justify-center">
          <PreviewCard />
        </div>
      </div>

      {open === "openSurveyModal" && (
        <CreationModal
          from={pathArray.includes("nps") ? "nps" : pathArray.includes("csat") ? "csat" : "cp"}
          handleModal={handleModal}
        />
      )}

      <BottomBar
        step={step}
        setStep={setStep}
        handleSave={handleSubmit}
        handleModal={() => {}}
        disableButton={isPending}
      />
    </div>
  );
};

export default CustomiseSurvey;
