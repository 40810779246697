const SURVEY_BACKEND_URLS = {
  baseURl:
    process.env.REACT_APP_NODE_ENV !== "production"
      ? process.env.REACT_APP_SURVEY_BASE_URL
      : `${process.env.REACT_APP_BACKEND_LIVE_URL}/v2/api/`,
  survey: {
    create: "survey-microservice/create/",
    uploads: "survey-microservice/upload-files",
    all: "survey-microservice/retrieve",
    close: "survey-microservice/close",
    delete: "survey-microservice/delete",
    duplicate: "survey-microservice/duplicate",
    toggleShare: "survey-microservice/toggle-share-link",
    margin_error: "survey-microservice/error-margin?",
    closeMultiple: "survey-microservice/close-multiple/",
    statistic: "survey-microservice/stats/",
    coster: "survey-microservice/survey/cost",
    locations: "mooyi-audience/locations/",
    interests: "mooyi-audience/interests/",
    save: "survey-microservice/operation",
  },
  surveyRes: {
    reports: "survey-microservice/analytics",
    question: "survey-microservice/questions/",
    tracker: "survey-microservice/track/",
    respond: "survey-microservice/respond/",
    verifyContact: "survey-microservice/reward-auth/",
    preClaim: "survey-microservice/pre-reward/",
    claimReward: "survey-microservice/reward/",
    screening: "survey-microservice/verify-screening-answer",
    recentSurveys: "activities",
  },
  insightUrls: {
    GET_INSIGHT_OVERVIEW: (insightType) => `/survey-microservice/insight/overview?insightType=${insightType}`,
    insightResponses: "/survey-microservice/insight/insight-responses",
    CREATE_INSIGHT_NAME: "/survey-microservice/insight/create-name",
    CREATE_INSIGHT_QUESTION: "/survey-microservice/insight/create-question",
    CREATE_INSIGHT_AUDIENCE: "/survey-microservice/insight/create-audience",
    CREATE_INSIGHT_DISPATCH: "/survey-microservice/insight/dispatch-mode",
    GET_INSIGHT_COST: (insightId) => `/survey-microservice/insight/cost/${insightId}`,
    PUBLISH_INSIGHT: (insightId) => `/survey-microservice/insight/publish/${insightId}`,
    GET_INSIGHT_QUESTION: "/survey-microservice/insight/get-question",
    GET_INSIGHT: (insightType) => `/survey-microservice/insight/get-all-insights?insightType=${insightType}`,
    GET_INSIGHT_STATUS: (insightType) => `/survey-microservice/insight/get-insight-status?insightType=${insightType}`,
  },
};

export default SURVEY_BACKEND_URLS;
