import { useQuery } from "@tanstack/react-query";

import Toast from "config/toast";
import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import { getAllContacts } from "services/contactService";
import contactEmpty from "assets/icons/contacts/ContactEmpty.png";
import EmptyState from "pages/Contacts/EmptyState";
import { formatDateAlone } from "lib/formatDate";
import { formatDate } from "lib/formatDate";
import Pagination from "components/Pagination";

const ContactTable = ({ selectArray, setSelectArray }) => {
  const [searchVal] = useState("");
  const [contactCurrentPage, setContactCurrentPage] = useState(1);
  const [contactItemPerPage, setContactItemPerPage] = useState(10);

  const {
    data: contact,
    isLoading: loadingContacts,
    isFetching: isFetchingContacts,
  } = useQuery({
    queryKey: ["allContacts", contactCurrentPage, searchVal, contactItemPerPage],
    queryFn: () => getAllContacts(contactCurrentPage, "name", searchVal.toLowerCase(), contactItemPerPage),

    onError: (error) => {
      Toast.error(error);
    },
  });

  console.log(contact, "contact array");

  const selectAll = (e) => {
    if (e.target.checked) {
      contact?.contacts?.map((el) => selectArray.push(el._id));
      for (let i = 0; i < contact?.contacts?.length; i++) {
        setSelectArray([...selectArray, contact?.contacts[i]?._id]);
      }
    } else {
      setSelectArray([]);
    }
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in selectArray) {
      if (selectArray[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectArray([...selectArray, x]);
    } else {
      const filterArray = selectArray?.filter((el) => el !== x);
      setSelectArray(filterArray);
    }
  };

  const columns = [
    {
      field: "inputProps",
      header: (
        <p className="pl-0 flex items-center justify-center">
          <input
            type="checkbox"
            name="inputProps"
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={(e) => selectAll(e)}
          />
        </p>
      ),
    },
    { field: "firstname", header: "First name" },
    { field: "lastname", header: "Last name" },
    { field: "email", header: "Email Address" },
    { field: "phone", header: "Phone Number" },
    { field: "dateOfBirth", header: "Date of birth" },
    { field: "gender", header: "Gender" },
    // { field: "status", header: "Status" },
    { field: "tag", header: "Tag" },
    { field: "createdAt", header: "Date created" },
    { field: "updatedAt", header: "Date updated" },
  ];

  const contactData = contact?.contacts?.map((item, i) => ({
    inputProps: (
      <p className="absolute mx-auto left-0 right-0 flex justify-center items-center w-[100%] h-[100%] top-0">
        <input
          type="checkbox"
          name="recipient"
          id="recipient"
          checked={checkInArray(item?._id, selectArray)}
          className="pl-0 w-[15px] h-[15px] accent-purple"
          onChange={(e) => handleCheck(e, item?._id)}
        />
      </p>
    ),
    id: item._id,
    firstname: item.firstname,
    lastname: item.lastname,
    email: item.email,
    phone: item.phone,
    dateOfBirth: formatDateAlone(item.birthday),
    gender: item.gender,
    // status: "status",
    tag: (
      <div className="flex items-center space-x-1">
        {item.tag.length > 2
          ? item.tag.slice(3).map((el, i) => (
              <div key={i} className="p-1 bg-gray-100 text-[12px] rounded">
                {i === 2 ? `+${item.tag.length - 2} more` : el}
              </div>
            ))
          : item.tag.map((el, i) => (
              <div key={i} className="p-1 bg-gray-100 text-[12px] rounded">
                {el}
              </div>
            ))}
      </div>
    ),
    createdAt: formatDate(item.createdAt),
    updatedAt: formatDate(item.updatedAt),
  }));

  const handleChange = (e) => {
    console.warn(e.target.value);
  };
  return (
    <div>
      <div className="border rounded px-5 py-3">
        <div className="flex items-center justify-between">
          <div className="flex space-x-3 ">
            <button
              className={`flex items-center bg-primary-100 text-primary-800 border rounded-[6px] px-[15px] py-[9px] text-s space-x-2`}
              // onClick={active ? () => toggleModal(2) : null}
            >
              <p>{selectArray?.length}</p>
              <p>contact selected</p>
            </button>
          </div>
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search contacts... "
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="min-w-[100%] mb-4 border-b-[1px] bg-white overflow-x-auto w-screen">
          <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
            <tr className="h-[50px] border-r-[1px] rounded-[2px] overflow-hidden">
              {columns &&
                columns.map((head, i) => (
                  <th key={i} className="px-2 text-left border-l-[1px]">
                    {head.header}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {loadingContacts || isFetchingContacts ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                  <tr
                    key={i}
                    className="h-[50px]  text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px] "
                  >
                    {columns?.map((col, i) => (
                      <td key={i} className="pl-2 border-l-[1px] relative">
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : contactData?.length === 0 || contactData?.length === undefined ? (
              <tbody className="flex justify-center w-full mt-20">
                <EmptyState
                  title="No Contacts Found."
                  icon={contactEmpty}
                  content="You haven't added any contacts yet. Click the 'Add Contacts' button to begin."
                  link="/contacts/create"
                  button="Add contacts"
                />
              </tbody>
            ) : (
              <>
                {contactData &&
                  contactData?.map((row, i) => (
                    <tr
                      className="h-[50px] text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white hover:bg-slate-200 cursor-pointer border-b-[1px]"
                      // onClick={() => handleMore(row.id)}
                      key={i}
                    >
                      {columns?.map((col, j) => (
                        <td key={j} className="px-4 border-l-[1px] relative">
                          {row[col.field] || "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
        {contactData?.length > 0 && (
          <Pagination
            data={contact.contacts}
            dataCount={contact.totalContacts}
            itemsPerPage={contactItemPerPage}
            setItemsPerPage={setContactItemPerPage}
            currentPage={contactCurrentPage}
            setCurrentPage={setContactCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default ContactTable;
