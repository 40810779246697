import { useQuery } from "@tanstack/react-query";
import { fetchPlan } from "services/userService";

const useSubscription = () => {
  const { data: subscription, isFetching } = useQuery({ queryKey: ["myPlan"], queryFn: fetchPlan });

  return {
    subscription,
    isFetching,
  };
};

export default useSubscription;
