import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";

const VoicePreview = ({ handleModal, justPreview }) => {
  const { voiceTemplate: el } = useStore();

  const navigate = useNavigate();

  const routeToCreation = () => {
    navigate("/broadcasts/voice/create");
  };

  return (
    <Modal title={el.campaign_name} onClose={handleModal}>
      <div className="w-[750px] px-5">
        <div className={`w-full flex justify-center bg-cover bg-no-repeat overflow-hidden rounded-md `}>
          <div className="w-full h-full items-end rounded-t-[20px] pb-0">
            <div className="w-full h-[30%] items-end rounded-t-[18px] overflow-hidden">
              <div className="bg-white rounded-md p-2">
                <div className="bg-white drop-shadow-sm"></div>
                {/* <div className="border rounded">
                  <AudioPlayer url="https://res.cloudinary.com/db7ggxq9j/video/upload/v1711073334/file_example_MP3_2MG_aoq901.mp3" />
                </div> */}
                <div className="mt-5 border-[2px] border-dotted rounded-md p-5">
                  <p className="font-[500]">Script</p>
                  <p>{el.content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {justPreview ? null : (
          <div className="flex justify-between mt-[1em]">
            <GreyButton text="Back" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={handleModal} />
            <BlueButton text="Use Template" onClick={routeToCreation} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default VoicePreview;
