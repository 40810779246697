import React from "react";

export const SingleContactIcon = ({ fill1, fill2 }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 8.75C10.6569 8.75 12 7.40685 12 5.75C12 4.09315 10.6569 2.75 9 2.75C7.34315 2.75 6 4.09315 6 5.75C6 7.40685 7.34315 8.75 9 8.75Z"
      fill={fill2}
    />
    <path
      d="M7.5 10.25C5.01472 10.25 3 12.2647 3 14.75C3 15.5785 3.67157 16.25 4.5 16.25H13.5C14.3285 16.25 15 15.5785 15 14.75V14C15 11.929 13.321 10.25 11.25 10.25H7.5Z"
      fill={fill1}
    />
  </svg>
);
