import React from "react";
import { BsSearch } from "react-icons/bs";
import { RiFilter2Fill } from "react-icons/ri";
import activityIcon from "assets/images/contacts/activityIcon.png";
import { formatDate } from "lib/formatDate";

const Activity = ({ data }) => {
  return (
    <div>
      <div>
        <div className="flex items-center justify-between">
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search activity... "
              // onChange={handleChange}
            />
          </div>

          <div className="flex space-x-3 ">
            <button
              className={`flex items-center text-gray-500 border rounded-[6px] px-[15px] py-[9px] text-s space-x-2 
                `}
              // onClick={active ? () => toggleModal(2) : null}
            >
              <p>Filter by</p>
              <RiFilter2Fill />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-7 space-y-7">
        {data?.length !== 0 ? (
          <div className="p-3 space-y-3">
            {data.map((el, i) => (
              <div className="bg-gray-50 w-full p-3 space-y-3">
                <p className="font-[600] text-blue-800 text-xs">{el.message}</p>
                <p className="text-xs">{el.description}</p>
                <p className="text-xs">{formatDate(el.activityDate)}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-[300px] flex flex-col gap-y-3 justify-center items-center">
            <div className="w-[81px]">
              <img src={activityIcon} alt="" />
            </div>
            <p>Looks like there are no activities to show yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Activity;
