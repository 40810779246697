import Pagination from "components/Pagination";
import { formatAmount } from "lib/formatAmount";
import { formatDate } from "lib/formatDate";
import { accStatus } from "lib/survStatus";
import { toTitleCase } from "lib/titleCase";
import React, { useCallback, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { debounce } from "lodash";

const columns = [
  { field: "sn", header: "S/N" },
  { field: "amount", header: "Amount" },
  { field: "type", header: "Type" },
  { field: "transactionStatus", header: "Status" },
  { field: "date", header: "Date" },
  // { field: "viewProps", header: "" },
];

const AccountHistory = ({ ledger }) => {
  const [query, setQuery] = useState("");
  const [searchedData, setSearchedData] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const contactData = ledger?.map((el, i) => ({
    id: el?._id,
    sn: i + 1,
    date: formatDate(el?.transactionDate),
    transactionStatus: accStatus(toTitleCase(el?.transactionStatus)),
    type: toTitleCase(el?.transaction_type),
    amount: <p>{formatAmount(el?.amount)} MC</p>,
  }));

  console.log(contactData, "contactData display");

  const [currentPage, setCurrentPage] = useState(1);

  // const paginatedData = useMemo(() => {
  //   const start = (currentPage - 1) * 10;
  //   const end = start + 10;
  //   return contactData?.slice(start, end);
  // }, [contactData, currentPage]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const searchFilter = useCallback(
    debounce((searchTerm) => {
      if (!searchTerm) {
        setSearchedData(contactData);
        return;
      }

      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      const dataSet = contactData?.filter((item) => {
        const content = [
          item?.id?.toLowerCase() || "",
          String(item?.amount) || "",
          item?.type?.toLowerCase() || "",
          String(item?.date) || "",
        ].join(" "); // Combine all fields into a single string

        return content.includes(lowerCaseSearchTerm);
      });

      setSearchedData(dataSet);
    }, 300),
    [contactData],
  );

  useEffect(() => {
    searchFilter(query);
  }, [query]);

  return (
    <div>
      <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
        <BsSearch className="text-[#9CA3AF]" />
        <input
          type="text"
          className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
          placeholder="Search transaction... "
          onChange={handleChange}
        />
      </div>
      <table className="w-[100%] mt-5">
        <thead className=" text-ss bg-[#F9FAFB]  border-b-[1px] border-t-[1px]">
          <tr className=" h-[45px] px-7 text-left border-b-[1px] border-r-[1px]">
            {columns &&
              columns.map((head, i) => (
                <th key={i} className="pl-4 border-l-[1px]">
                  {head.header}{" "}
                </th>
              ))}
          </tr>
        </thead>

        <tbody>
          {searchedData &&
            searchedData.map((row, i) => (
              <tr key={i} className="h-[50px] border-b-[1px] border-r-[1px] text-ss text-[#151515] font-[500]">
                {columns?.map((col, i) => (
                  <td key={i} className="pl-4 border-l-[1px]">
                    {row[col.field]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        data={contactData}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        // dataCount={0}
        setItemsPerPage={setItemsPerPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default AccountHistory;
