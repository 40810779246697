import { useQuery } from "@tanstack/react-query";
import { getInsightOverview } from "services/insightService";

const useInsightOverview = (insightType) => {
  const { data, isLoading } = useQuery({
    queryKey: ["getInsightOverview"],
    queryFn: () => getInsightOverview(insightType),
  });

  return {
    data,
    isLoading,
  };
};

export { useInsightOverview };
