import { useMutation } from "@tanstack/react-query";
import AuthButton from "components/Button/AuthButton";
import DropDownWithSearch from "components/DropDownWithSearch";
import SecondaryInput from "components/Inputs/SecondaryInput";
import SecondarySelectInput from "components/Inputs/SecondarySelectInput";
import PreLoader from "components/Loader/PreLoader";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useFormik } from "formik";
// import { urlRegex } from "lib/regex";
import React from "react";
import { updateBusiness } from "services/userService";
import * as Yup from "yup";

const options1 = [
  { value: null, label: "Select Industry" },
  { value: "art/design", label: "Art/Design" },
  { value: "agriculture", label: "Agriculture" },
  { value: "architecture", label: "Architecture" },
  { value: "aviation", label: "Aviation" },
  { value: "banking/finance", label: "Banking/Finance" },
  { value: "construction", label: "Construction" },
  { value: "ecommerce", label: "Ecommerce" },
  { value: "education", label: "Education" },
  { value: "engineering", label: "Engineering" },
  { value: "entertainment", label: "Entertainment" },
  { value: "FMCG", label: "FMCG" },
  { value: "healthcare", label: "Healthcare" },
  { value: "hospitality", label: "Hospitality" },
  { value: "housing/real estate", label: "Housing/Real estate" },
  { value: "management", label: "Management" },
  { value: "marketing/advertising", label: "Marketing/Advertising" },
  { value: "information technology", label: "Information Technology" },
  { value: "logistics", label: "Logistics" },
  { value: "science", label: "Science" },
];

const sizeOptions = [
  { value: null, label: "Select Company Size" },
  { value: "0-9 employees", label: "0-9 Employees" },
  { value: "11-50 employees", label: "11-50 Employees" },
  { value: "50-200 employees", label: "50-200 Employees" },
  { value: "251-1000 employees", label: "251-1000 Employees" },
];

const categoryOptions = [
  { value: null, label: "Select a category" },
  { value: "Automotive and Accessories", label: "Automotive and Accessories" },
  { value: "Baby and Kids Products", label: "Baby and Kids Products" },
  { value: "Banking and Financial Services", label: "Banking and Financial Services" },
  { value: "Beauty and Personal Care", label: "Beauty and Personal Care" },

  { value: "Education", label: "Education" },
  { value: "Electronics and Gadgets", label: "Electronics and Gadgets" },

  { value: "Food and Beverages", label: "Food and Beverages" },
  { value: "Health and Wellness Products", label: "Health and Wellness Products" },

  { value: "Home Decor and Furnishings", label: "Home Decor and Furnishings" },
  { value: "Home and Kitchen Appliances", label: "Home and Kitchen Appliances" },

  { value: "Jobs and Career", label: "Jobs and Career" },
  { value: "Mobile Phones and Accessories", label: "Mobile Phones and Accessories" },
];

const Business = ({ user, setStep }) => {
  const { mutate, isLoading: isSubmitting } = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      Toast.success("Business updated successfully");
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      setStep(2);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const initialValues = {
    businessName: user?.data?.business?.businessName || "",
    companySize: user?.data?.business?.companySize || "0-9 employees",
    industry: user?.data?.business?.industry || "agriculture",
    companyAddress: user?.data?.business?.companyAddress || "",
    websiteUrl: user?.data?.business?.websiteUrl || "",
    category: user?.data?.business?.category || "",
  };

  const validationSchema = Yup.object({
    businessName: Yup.string().required("Company name is required"),
    companySize: Yup.string().required("Company size is required"),
    industry: Yup.string().required("Industry is required"),
    companyAddress: Yup.string().required("Company address is required"),
    // websiteUrl: Yup.string().required("URL is required").matches(urlRegex, "Invalid website URL"),
  });

  const onSubmit = async (data) => {
    const userData = {
      ...data,
    };
    mutate(userData);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched, isValid, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  if (isSubmitting) {
    return <PreLoader text="submitting data..." />;
  }

  console.log(values);

  return (
    <div>
      <p className="text-[#292D32] font-semibold text-[24px]">Company information</p>
      <p className="text-[#737373] text-s font-[450]">Let’s know a bit more about your company.</p>

      <form onSubmit={handleSubmit} className="mt-[2em] space-y-3">
        <SecondaryInput
          text="Company name"
          placeholder="Enter Company name"
          onChange={handleChange}
          onBlur={handleBlur}
          name="businessName"
          type="text"
          value={values.businessName}
          error={getError("businessName")}
        />

        <SecondaryInput
          text="Company address"
          placeholder="Enter Company address"
          onChange={handleChange}
          onBlur={handleBlur}
          name="companyAddress"
          type="text"
          error={getError("companyAddress")}
          value={values.companyAddress}
        />

        <div>
          <p className="text-[#292D32] text-m font-[500] pb-2"> Industry</p>
          <DropDownWithSearch
            tag={values.industry || "Select an option"}
            options={options1}
            error={getError("industry")}
            onSelect={(select) => setFieldValue("industry", select.value)}
            name="industry"
            value={values.industry}
          />
        </div>

        <div>
          <p className="text-[#292D32] text-m font-[500] pb-2"> Category (Optional)</p>
          <DropDownWithSearch
            tag={values.category || "Select an option"}
            options={categoryOptions}
            onSelect={(select) => setFieldValue("category", select.value)}
            error={getError("category")}
            name="category"
            value={values.category}
          />
        </div>

        <SecondarySelectInput
          text="Company size"
          options={sizeOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          name="companySize"
          error={getError("companySize")}
          value={values.companySize}
        />

        {/* <SecondaryInput
          text="Website URL"
          placeholder="Enter Website URL"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="websiteUrl"
          type="text"
          value={formik.values.websiteUrl}
        />
        {formik.touched.websiteUrl && formik.errors.websiteUrl ? (
          <div className="text-red-500 -mt-2 mb-3 text-ss italic">{formik.errors.websiteUrl}</div>
        ) : null} */}

        <div className="mt-[1em]">
          <AuthButton text="Continue" type="submit" disabled={!isValid || isSubmitting} />
        </div>
      </form>
    </div>
  );
};

export default Business;
