import React, { useEffect, useRef, useState } from "react";
import Summary from "./Summary";
import { Link, useLocation, useParams } from "react-router-dom";
import { BsFillShareFill } from "react-icons/bs";
import { BiSolidCopy } from "react-icons/bi";
import Toast from "config/toast";
import { useQuery } from "@tanstack/react-query";
import {
  fetchByPublishId,
  fetchCSV,
  fetchDemoGraphics,
  fetchResponse,
  fetchSingleOverview,
  fetchSummary,
} from "services/newSurveyService";
import { baseMooyiRewardsURL } from "helpers/getBaseUrl";
import { IoCloudDownload } from "react-icons/io5";
import { removeEmptyKeysAndValues } from "lib/removeEmptyValueAndKey";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";

import Responses from "pages/Research/components/Analytics/Responses";
import ShareModal from "pages/Research/components/Analytics/ShareModal";
import Details from "pages/Research/components/Analytics/Details";
import Typography from "utils/typography";
import CSVModal from "../../components/Analytics/CSVModal";
import { IoIosRefreshCircle } from "react-icons/io";
import { FaChevronLeft } from "react-icons/fa";
import moment from "moment";
import { LuDot } from "react-icons/lu";
import { toast } from "react-toastify";
import { Msg } from "config/toast";
import useSummaryStore from "store/useSummaryStore";
import Modal from "components/Modal";

const tabs = [
  { name: "Summary", value: 0 },
  { name: "Responses", value: 1 },
  { name: "Details", value: 2 },
];

const CustomButton = ({ onClick, text, icon }) => {
  return (
    <div
      onClick={onClick}
      className="bg-primary-50 px-6 text-primary-800  h-[40px] rounded-md flex items-center justify-center space-x-2 cursor-pointer border-[1px] border-primary-100"
    >
      {icon}
      <p className="text-s font-bold">{text}</p>
    </div>
  );
};

const OnlineSurveyAnalytics = () => {
  const { state } = useLocation();
  const { user } = useLoggedInUser();
  const { ageFilter, applyAgeFilter } = useSummaryStore();
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(null);
  const { pubId, surveyId } = useParams();
  const [imgIndex, setImgIndex] = useState(0);
  const hasRun = useRef(false);
  const [section, setSection] = useState("section1");
  const [questionsFilter, setQuestionsFilter] = useState([]);
  const [filterData, setFilterData] = useState({
    age: {},
    state: "",
    city: "",
    country: "",
    gender: "",
    earning: "",
    educationLevel: "",
    relationshipStatus: "",
    parentalStatus: "",
    employmentStatus: "",
    categoryOfInterest: "",
  });

  const { data: detailsData } = useQuery({
    queryKey: ["fetchByPublishId", pubId],
    queryFn: () => fetchByPublishId(pubId),
  });

  const branchingDetails = detailsData?.data?.surveyDetails?.branchingQuestion;

  const {
    data: summaryData,
    refetch: refetchSummary,
    isLoading: isFetching,
  } = useQuery({
    queryKey: ["fetchSummary", pubId, section],
    queryFn: () =>
      toast.promise(
        fetchSummary({
          payload: {
            publishId: [pubId],
            section:
              branchingDetails?.questionName !== "" && branchingDetails?.questionName !== undefined ? section : "",
            filter: {
              questionFilter: questionsFilter,
              ...removeEmptyKeysAndValues({
                ...filterData,
                ...(ageFilter.min > 0 && ageFilter.max > ageFilter.min
                  ? {
                      age: {
                        lte: +ageFilter.max,
                        gte: +ageFilter.min,
                      },
                    }
                  : null),
              }),
              // ...(ageFilter.min > 0 && ageFilter.max > ageFilter.min ? { ageFilter } : null),
            },
          },
        }),
        {
          pending: {
            render() {
              return <Msg message="Fetching summary..." title={"Loading"} />;
            },
          },
          success: {
            render() {
              return <Msg message="Summary fetched successfully!" title={"Success"} />;
            },
          },
          error: {
            render() {
              return <Msg message="Error fetching summary" title={"Loading"} />;
            },
          },
        },
        {
          icon: false,
          hideProgressBar: true,
        },
      ),

    enabled: !!pubId && !!branchingDetails,
  });

  const { data, refetch } = useQuery({
    queryKey: ["fetchCSV"],
    queryFn: () => fetchCSV(surveyId),

    enabled: false,
    onSuccess: () => {},
  });

  const controlCSVModal = () => {
    refetch();
    handleModal(2);
    trackEvent("download_report", {
      email: user?.data?.email,
      surveyName: state?.surveyName,
      surveyStatus: state?.surveyStatus,
      date: dayjs().format(),
    });
  };

  useEffect(() => {
    hasRun.current = false; // Reset the flag when dependencies change
  }, [pubId, section]);

  // console.log(branchingDetails, "branchingDetails");

  const handleFilter = async (filter, data) => {
    const currentFilterData = { ...filterData };

    if (filter === "age") {
      if (data === "N/A") {
        currentFilterData.age = {
          lte: 12,
          gte: 0,
        };
      } else if (data === "65+") {
        currentFilterData.age = {
          gte: 65,
        };
      } else {
        currentFilterData.age = {
          lte: +data.split("-")[1],
          gte: +data.split("-")[0],
        };
      }
    } else if (filter === "reset") {
      // Reset all filters

      setFilterData({
        age: "",
        state: "",
        city: "",
        country: "",
        gender: "",
        earning: "",
        educationLevel: "",
        relationshipStatus: "",
        parentalStatus: "",
        employmentStatus: "",
        categoryOfInterest: "",
        occupation: "",
      });

      setQuestionsFilter([]);
      // ... (rest of the keys)

      return;
    } else {
      // Check if the number of filled keys is less than or equal to 3
      const filledKeys = Object.keys(currentFilterData).filter((key) => currentFilterData[key] !== "").length;

      if (filledKeys <= 2 || currentFilterData[filter] !== "") {
        currentFilterData[filter] = data;
      }
    }

    setFilterData(currentFilterData);
  };

  function removeEmptyItems(array) {
    const filteredArray = [];

    for (let item of array) {
      if (item && (typeof item !== "object" || Object.keys(item).length > 0)) {
        filteredArray.push(item);
      }
    }

    return filteredArray;
  }

  const { data: overviewData } = useQuery({
    queryKey: ["fetchSingleOverview", surveyId],
    queryFn: () => fetchSingleOverview(surveyId),
  });

  const [currentPage, setCurrentPage] = useState(1);

  const { data: responsesData, isLoading: isFetchingResponses } = useQuery({
    queryKey: ["fetchResponse", surveyId, currentPage],
    queryFn: () => fetchResponse({ surveyId: surveyId, batch: currentPage }),
  });

  const handleDisplayImg = (index) => {
    setOpen(index);
    setImgIndex(index);
  };

  const {
    data: demoData,
    refetch: refetchDemo,
    isLoading: isLoadingDemo,
  } = useQuery({
    queryKey: ["fetchDemoGraphics", surveyId],
    queryFn: () =>
      fetchDemoGraphics({
        surveyId: surveyId,
        filter: {
          questionFilter: questionsFilter,
          ...removeEmptyKeysAndValues(filterData),
          ...(ageFilter.min > 0 && ageFilter.max > ageFilter.min ? { ageFilter } : null),
        },
      }),

    enabled: !!surveyId,
  });

  useEffect(() => {
    if ((filterData || questionsFilter || applyAgeFilter) && detailsData !== undefined) {
      refetchSummary();

      refetchDemo(); // This will run after the filterData is updated
    }
  }, [filterData, questionsFilter, applyAgeFilter]);

  const surveyLink =
    detailsData?.data?.targetingOption?.audienceType === "buy_targeted_response"
      ? `${baseMooyiRewardsURL}jobs`
      : `${baseMooyiRewardsURL}generated-link/${pubId}-SURVEY-${surveyId}`;

  // const surveyLink = `${baseMooyiRewardsURL}generated-link/${pubId}-SURVEY-${surveyId}`;

  const copyFunc = async () => {
    trackEvent("share_report", {
      email: user?.data?.email,
      surveyName: state?.surveyName,
      surveyStatus: state?.surveyStatus,
      date: dayjs().format(),
    });

    await navigator.clipboard.writeText(`${surveyLink?.toString()}`);
    Toast.success("Link copied");
  };

  const demographics = demoData?.data;

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <Summary
            allData={summaryData?.data?.defaultSummary}
            data={summaryData?.data?.defaultSummary}
            overview={overviewData?.data}
            filterPersonalData={demographics?.personal}
            filterCoreData={demographics?.core}
            filterWorkData={demographics?.work}
            handleFilter={handleFilter}
            handleDisplayImg={handleDisplayImg}
            isFetchingSummary={isFetching}
            filterData={filterData}
            setFilterData={setFilterData}
            detailsData={detailsData?.data}
            section={section}
            setSection={setSection}
            filterLength={Object.values(removeEmptyItems(Object.values(filterData))).length || questionsFilter.length}
            isLoadingDemo={isLoadingDemo}
            refetchSummary={refetchSummary}
            questionsFilter={questionsFilter}
            setQuestionsFilter={setQuestionsFilter}
          />
        );
      case 1:
        return (
          <Responses
            responseCount={overviewData?.data?.responses}
            responsesData={responsesData}
            isFetchingResponses={isFetchingResponses}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case 2:
        return <Details data={detailsData?.data} />;
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const survey = detailsData?.data?.surveyDetails;

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex items-start space-x-3">
          <Link to="/research/online-survey">
            <div className="bg-white border-[1px] rounded h-[25px] w-[25px] flex items-center justify-center mt-1">
              <FaChevronLeft className="text-xs text-gray-600" />
            </div>
          </Link>

          <div>
            <Typography.Text>{survey?.surveyName}</Typography.Text>

            <div className="flex items-center space-x-1">
              <Typography.MicroText>{survey?.surveyCategory}</Typography.MicroText>

              <LuDot />

              <Typography.MicroText>
                {moment(detailsData?.data?.publishedAt).format("MMMM Do YYYY | h:mm a")}
              </Typography.MicroText>
            </div>
          </div>
        </div>

        <div className="flex space-x-4">
          <CustomButton text="Refresh" icon={<IoIosRefreshCircle />} onClick={handleRefresh} />

          <CustomButton text="Copy link" icon={<BiSolidCopy />} onClick={copyFunc} />
          {/* <CustomButton text="Retarget" icon={<BsArrowRepeat />} /> */}
          <CustomButton text="Get CSV" icon={<IoCloudDownload />} onClick={controlCSVModal} />
          <CustomButton text="Share" icon={<BsFillShareFill />} onClick={() => handleModal(1)} />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <Typography.SubText
              className={` pb-[6px] px-2 cursor-pointer ${
                step === el.value
                  ? "border-b-[3px]  border-primary-800 text-neutral-900 font-secondary font-semibold "
                  : "text-neutral-500"
              } `}
              onClick={el.value !== 3 ? () => handleTabChange(el.value) : null}
              key={el.value}
            >
              {el.name}
            </Typography.SubText>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
      {open === 1 ? <ShareModal handleModal={handleModal} data={state} /> : null}

      {open === 2 ? <CSVModal handleModal={handleModal} fileUrl={data?.data?.filePath} data={state} /> : null}
      {open === imgIndex ? (
        <Modal onClose={() => setImgIndex(null)} title="Image">
          <div className="w-[700px] h-[400px] overflow-y-auto px-5">
            <img src={imgIndex} alt="" className="w-full" />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default OnlineSurveyAnalytics;
