import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Logo from "assets/images/newLogo.png";
import Toast from "config/toast";
import PreLoader from "components/Loader/PreLoader";
import { verifyInvite } from "services/workspaceService";

const VerifyInvite = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading } = useQuery({
    queryKey: ["inviteUser", id],
    queryFn: () => verifyInvite(id),
    enabled: !!id,
    onSuccess: () => {
      Toast.success("Invite Verified");
    },
    onError: () => {
      // Toast.error("Contact Admin");
      navigate("/auth/login");
    },
  });

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div>
      <Link to="/" className="flex items-center justify-center my-[4em]">
        <img src={Logo} alt="Mooyi logo" />
      </Link>

      <div className="w-[60%] h-[400px] shadow-lg mx-auto text-center p-[4em] rounded-[6px]">
        <p className="text-[28px] font-semibold">You have been successfully added to the Workspace</p>
        <p className="mt-[2em]">
          Kindly{" "}
          <Link to="/auth/login" className="text-primary-800 underline">
            Login
          </Link>{" "}
          into your account to view the workspace.
        </p>
      </div>
    </div>
  );
};

export default VerifyInvite;
