import * as Yup from "yup";
export const lowerCaseRegex = /(?=.*[a-z])\w+/;
export const upperCaseRegex = /(?=.*[A-Z])\w+/;
export const numberRegex = /\d/;
export const specialCharacterRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export const checkRegex = (str, regex) => {
  return regex.test(str);
};

export const getLabels = (arr) => {
  return arr.map((el) => el.label);
};

export const getValues = (arr) => {
  return arr.map((el) => el.value);
};

export const passwordRegex = Yup.string()
  .required("Password is required.")
  .min(8, "Password must be at least 8 characters long.")
  .matches(upperCaseRegex, "Password must contain at least an uppercase letter.")
  .matches(numberRegex, "Password must contain at least a number.")
  .matches(specialCharacterRegex, "Password must contain at least a special character.")
  .matches(lowerCaseRegex, "Password must contain at least a lowercase letter.");
