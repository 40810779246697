import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import React from "react";
import Typography from "utils/typography";

const EmptyState = ({ icon, text, content, status, buttonText }) => {
  return (
    <div className="flex items-center flex-col justify-center h-[40vh] w-[100%] space-y-3">
      <img src={icon} alt={text} className="w-[70px] mx-auto" />
      <Typography.Text className="text-center">{text}</Typography.Text>
      <Typography.SubText className="text-center text-gray-500 w-[50%]">{content}</Typography.SubText>
      {status !== "" ? (
        <div className="flex items-center space-x-2 mt-2">
          {status === "closed" ? <GreyButton text="View active survey" /> : null}

          {buttonText && <BlueButton text={buttonText} />}
        </div>
      ) : null}
    </div>
  );
};

export default EmptyState;
