import Tooltip from "components/Tooltip";
import useGetAllTags from "hooks/useGetAllTags";
import React, { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { AiOutlineSearch } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowDown, IoMdCheckmark, IoMdPie } from "react-icons/io";

const Audience = ({ data, setData }) => {
  const [contactSize, setContactSize] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(false);
  const refer = useOnclickOutside(() => setOpenDropdown(false));
  const { tagData } = useGetAllTags();
  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const [searchedArray, setSearchedArray] = useState(tagData);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSearchedArray(tagData);
  }, [setSearchedArray, tagData]);

  const handleCheck = (e, el) => {
    if (e.target.checked) {
      if (!data.recipientArray.includes(el?.name) && el?.contactCount !== 0) {
        // setRecipientArray([...recipientArray, { name: el?.name, noOfContact: el?.contactCount }]);
        const updatedPersonal = contactSize + +el?.contactCount;
        setContactSize(updatedPersonal);

        setData({
          ...data,
          recipientArray: [...data.recipientArray, { name: el?.name, noOfContact: el?.contactCount }],
          personal: updatedPersonal,
        });
      }
    } else {
      const updatedPersonal = contactSize - +el?.contactCount;
      setContactSize(updatedPersonal);
      const filterArray = data.recipientArray?.filter((elm) => elm?.name !== el?.name);
      setData({
        ...data,
        recipientArray: filterArray,
        personal: updatedPersonal,
      });
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
    if (query !== "") {
      const filteredData = tagData?.filter(
        (filtered) => filtered?.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
      );
      if (filteredData.length !== 0) {
        setSearchedArray(filteredData);
      }
    } else {
      setSearchedArray(tagData);
    }
  };

  const removedFromArray = (el) => {
    const filterArray = data.recipientArray?.filter((elm) => elm?.name !== el?.name);
    setData({
      ...data,
      recipientArray: filterArray,
    });
    const updatedPersonal = contactSize - +el?.noOfContact;
    setContactSize(updatedPersonal);
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in data.recipientArray) {
      if (data.recipientArray[x].name === y) {
        checked = true;
      }
    }
    return checked;
  };

  return (
    <div className="h-[100%] w-[100%]">
      <div className="">
        <p className="text-base font-medium">Add your audience</p>
        <p className="text-sm text-gray-700"> Select the contact to share your message with.</p>
      </div>

      <div className="rounded-md mt-[1em]">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2 items-center">
            <p className="text-s font-[500] text-[#262626]">Tags</p>
            <Tooltip text="Select the category of contacts you want to send your survey to." />
          </div>
        </div>

        <div
          className="rounded-[8px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px] pl-2 outline-none my-2 flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%] flex items-center relative"
          onClick={toggleDropdown}
          ref={refer}
        >
          {data.recipientArray <= 0 ? (
            <p className="text-neu-400 text-ss"> One of the tags are displayed here.</p>
          ) : (
            <p className="text-neu-400 text-ss">{data.recipientArray.length} Selected</p>
          )}

          <div className="text-[#101828] h-[100%] absolute top-0 right-6 flex items-center justify-center text-[22px]">
            <IoIosArrowDown />
          </div>
        </div>

        <div ref={refer} className=" flex-wrap gap-[1em]  py-[0.5em] max-w-[100%] flex items-center relative pb-[1em]">
          {data.recipientArray?.map((el, i) => {
            return (
              <div
                key={i}
                className="bg-bg border-[1px] border-blue-100 flex items-center px-4 rounded-[6px] h-[30px] text-ss  cursor-pointer"
              >
                {el?.name}
                <span className="ml-2 p-1 bg-neu-300 rounded-full">
                  <FaTimes className="font-thin text-[9px] text-white " onClick={() => removedFromArray(el)} />
                </span>{" "}
              </div>
            );
          })}
        </div>

        {openDropdown && (
          <div
            ref={refer}
            className="rounded-[8px] shadow-secondary pb-4 h-[300px] overflow-y-scroll bg-white cursor-pointer"
          >
            <div
              className="bg-[#F5F5F5] flex min-h-[40px] items-center 
                 gap-[.7em] px-[1em] py-[5px] max-w-[100%]"
            >
              <span className="text-[#737373]">
                <AiOutlineSearch size={20} />
              </span>

              <input
                type="text"
                className="w-full h-[100%]  outline-none text-s placeholder:text-s bg-transparent"
                placeholder="Search for tags"
                value={query}
                name="query"
                onChange={handleChange}
              />
            </div>

            {searchedArray?.map((el) => {
              return (
                <div
                  className={`px-4 my-1 mx-3 text-s text-primary-800-1 hover:text-white border-b-[1px] border-[#D0D5DD] 
                            py-[8px] flex items-center justify-between  cursor-pointer hover:bg-primary-800 hover:rounded-[8px] group ${
                              checkInArray(el) ? "bg-neu-100 rounded-lg hover:!bg-white hover:text-primary-800" : ""
                            }`}
                  key={el._id}
                >
                  <div className="flex items-center space-x-4 relative w-[100%] cursor-pointer">
                    <div>
                      <input
                        type="checkbox"
                        name="recipient"
                        id="recipient"
                        onChange={(e) => handleCheck(e, el)}
                        checked={checkInArray(el?.name)}
                        className="absolute w-[100%] h-[100%] top-0 right-0 opacity-0 z-[9999]"
                      />

                      <IoMdPie
                        className={` text-[24px] ${
                          checkInArray(el?.name)
                            ? "block text-primary-800 group-hover:text-primary-800"
                            : "group-hover:text-white "
                        }`}
                      />
                    </div>
                    <div className={`${checkInArray(el) ? "group-hover:text-primary-800" : "group-hover:text-white"}`}>
                      <p className="text-sm font-[500]">{el?.name}</p>
                      <p className="text-xs font-[400]">{el?.contactCount} contacts</p>
                    </div>
                  </div>

                  <IoMdCheckmark
                    className={`text-[24px] ${
                      checkInArray(el?.name) ? "block group-hover:text-primary-800" : "hidden group-hover:text-white"
                    }`}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div className="h-10">
          <div className="flex gap-x-2 items-center ">
            <p className="text-s my-1 font-[500] text-[#262626]">Contact size</p>
          </div>
          <p className="text-neu-1 text-s">{contactSize}</p>
        </div>
      </div>
    </div>
  );
};

export default Audience;
