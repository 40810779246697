import { checkRatingIconType } from "lib/surveyFunctions";

const { ImageUploadIcon } = require("assets/icons/icon");
const { default: SelectInput } = require("components/Inputs/SelectInput");
// const { IoHeart } = require("react-icons/io5");

const SurveyTypes = (props) => {
  const { type, newData } = props;

  function createNumberArray(start, end) {
    const numberArray = Array.from({ length: +end - +start + 1 }, (_, index) => index + +start);
    const stringArray = numberArray.map(String);
    return stringArray;
  }

  function generateArrayOfObjects(n) {
    const data = [];
    for (let i = 1; i <= n; i++) {
      data.push({ value: i, label: i });
    }
    return data;
  }

  const mapTypes = ["multi_choice", "single_choice", "drop_down"];

  const newArray = () => {
    if (mapTypes.includes(newData?.questionType)) {
      const array = (newData?.options).map((el) => ({ value: el, label: el }));
      return array;
    }
  };

  switch (type) {
    case "text_response":
      return (
        <div className="flex items-start space-x-2 w-[100%] ">
          <div className="w-[100%] h-[80px] border-[1px] rounded-md outline-none p-3 mt-2 text-sm "></div>
        </div>
      );

    case "single_choice":
      return (
        <div className="flex items-start space-x-2 w-[100%]">
          <div className="w-[100%]">
            {newData?.options?.map((el, index) => {
              if (el === "MooyiOption") {
                return null;
              } else {
                return (
                  <div className="flex space-x-2 my-6 items-center cursor-pointer" key={index}>
                    <div className="w-[15px] h-[15px] rounded-full flex items-center justify-center border-[1px] border-darkGreen">
                      <div
                        className={`"bg-darkGreen" : ""
                        } w-[8px] h-[8px] rounded-full`}
                      ></div>
                    </div>

                    <p className="text-sm">{el}</p>
                  </div>
                );
              }
            })}

            {newData?.options?.map((el, index) => {
              if (el === "MooyiOption") {
                return (
                  <div key={`${index}-MooyiOption`} className="flex space-x-2 items-baseline">
                    <p className="text-sm">Others :</p>

                    <input type="text" className="w-[50%] border-b-[1px] border-gray-400 outline-none  p-1 text-sm" />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      );

    case "multi_choice":
      return (
        <div className="flex items-start space-x-2 w-[100%]">
          <div>
            {newData?.options?.map((el, index) => {
              if (el === "MooyiOption") {
                return null;
              } else {
                return (
                  <div className="flex space-x-2 my-6 items-center cursor-pointer" key={index}>
                    <div className="w-[15px] h-[15px] rounded-sm flex items-center justify-center border-[1px] border-darkGreen">
                      <div className={` w-[8px] h-[8px] rounded-sm`}></div>
                    </div>

                    <p className="text-s">{el}</p>
                  </div>
                );
              }
            })}

            {newData?.options?.map((el, index) => {
              if (el === "MooyiOption") {
                return (
                  <div key={`${index}-MooyiOption`} className="flex space-x-2 items-baseline">
                    <p className="text-sm">Others :</p>

                    <input type="text" className="w-[50%] border-b-[1px] border-gray-400 outline-none  p-1 text-sm" />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      );

    case "drop_down":
      return (
        <div className="flex items-start space-x-2 w-[100%]">
          <div>
            <div className="w-[50%]  mt-[3em]">
              <SelectInput
                options={[
                  {
                    label: "Select a Value",
                    value: "",
                  },
                  ...(newArray() || []),
                ]}
                css=""
              />
            </div>
          </div>
        </div>
      );

    case "likert":
      return (
        <div className="flex items-start space-x-2 w-[100%]">
          <div className={"w-[100%]"}>
            <div className="flex justify-between items-end space-x-4 mt-[3em] w-[100%] mx-auto">
              <p className="text-sm">{newData?.options?.min?.label}</p>

              <div className="flex  space-x-2 w-[100%] justify-between">
                {createNumberArray(newData?.options?.min?.value || "0", newData?.options?.max?.value || "0")?.map(
                  (el) => {
                    return (
                      <div className="space-y-[2px] flex-col flex items-center justify-center cursor-pointer" key={el}>
                        <p className="text-center text-s">{el}</p>
                        <div className="sm:w-[15px] w-[12px] sm:h-[15px] h-[12px] rounded-full flex items-center justify-center border-[1px] border-darkGreen">
                          <div className={`sm:w-[8px] w-[6px] sm:h-[8px] h-[6px] rounded-full`}></div>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>

              <p className="text-s">{newData?.options?.max?.label}</p>
            </div>
          </div>
        </div>
      );
    case "rating":
      return (
        <div className="flex items-start space-x-2 w-[100%]">
          <div className={"w-[100%]"}>
            <div className=" mt-[3em] mx-auto w-[80%]">
              <div className="flex justify-between w-[90%] mx-auto">
                {createNumberArray("1", newData?.options?.iconQuantity || "0")?.map((el) => {
                  return (
                    <div className="space-y-[2px] flex-col flex items-center justify-center cursor-pointer" key={el}>
                      <div className="text-2xl rounded-full flex items-center justify-center">
                        {checkRatingIconType(newData.options.shape)}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={"flex items-center justify-between pt-[1em]"}>
                <p className="text-sm">{newData?.options?.label?.first}</p>

                <p className="text-sm">{newData?.options?.label?.last}</p>
              </div>
            </div>
          </div>
        </div>
      );

    case "ranking":
      return (
        <div className="flex items-start space-x-2 w-[100%]">
          <div className={"w-[100%]"}>
            <div className=" mt-[3em] mx-auto w-[100%]">
              {(newData?.options).map((option, elementIndex) => {
                const n = newData?.options?.length;

                return (
                  <div className={"flex items-center space-x-[1em] pt-[1em]"} key={`ranking-${option}`}>
                    <div className={"w-[25%]"}>
                      <SelectInput
                        options={[
                          {
                            label: "Select a Value",
                            value: "",
                          },
                          ...generateArrayOfObjects(n),
                        ]}
                      />
                    </div>

                    <p className={"text-sm text-gray-600"}>{option}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    case "image":
      return (
        <div className="flex items-start space-x-2 w-[100%]">
          <div>
            <div className="mt-[2em]">
              <div className="border-dashed border-[#D0D5DD]-[1px] text-ss h-[100px] bg-[#FAFAFA]  w-full border flex justify-center items-center rounded-md text-[#737373] flex-col gap-1 relative">
                <input
                  type="file"
                  className="w-[100%] h-[100%] absolute cursor-pointer top-0 left-0 opacity-0"
                  accept=".jpeg, .jpg, .png"
                />
                <ImageUploadIcon />
                <div className="flex gap-1 justify-center items-center">
                  Drag and drop PNG or JPEG file or
                  <div className="text-darkGreen !bg-transparent underline">browse computer</div>
                </div>
                <p>1200px by 900px</p>
              </div>
            </div>
          </div>
        </div>
      );

    case "matrix":
      return (
        <div className="flex items-start space-x-2 w-[100%] ">
          <div className="w-[100%]">
            <div className="flex justify-between items-center w-[100%] space-x-4">
              <p className="w-[30%]"></p>

              <div className="flex justify-around  items-center w-[70%]  ">
                {newData.options.row.map((el) => {
                  return <p key={el}>{el}</p>;
                })}
              </div>
            </div>

            {newData.options.column.map((elm, columnIndex) => {
              return (
                <div className="flex justify-between items-center w-[100%] space-x-4 py-2" key={elm}>
                  <p className="w-[30%]">{elm}</p>

                  <div className="flex justify-around  w-[70%] ">
                    {newData.options.row.map((el) => {
                      return (
                        <div key={`${el}${elm}`} className="flex items-center justify-center place-content-center">
                          <div className="sm:w-[15px] w-[12px] sm:h-[15px] h-[12px] rounded-full flex items-center justify-center justify-self-center  border-[1px] border-darkGreen">
                            <div className={`sm:w-[8px] w-[6px] sm:h-[8px] h-[6px] rounded-full`}></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default SurveyTypes;
