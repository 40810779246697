import React from "react";
// import ResearchHub from "./ResearchHub";
import EngagementHub from "./EngagementHub";
// import CustomHub from "./CustomHub";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

// const tabs = [
//   { name: "Research hub", value: 0 },
//   { name: "Engagement hub", value: 1 },
//   { name: "Custom plan", value: 2 },
// ];

const Home = () => {
  // const [step, setStep] = useState(0);
  const navigate = useNavigate();

  // const displayStep = () => {
  //   switch (step) {
  //     case 0:
  //       return <ResearchHub />;
  //     case 1:
  //       return <EngagementHub />;
  //     case 2:
  //       return <CustomHub />;
  //     default:
  //   }
  // };

  // const handleTabChange = (e) => {
  //   setStep(e);
  // };

  return (
    <div className="px-5">
      <div
        className="w-full flex justify-start items-center text-[14px] text-primary-800 space-x-2 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <IoChevronBackOutline />
        <p>Back</p>
      </div>
      {/* <div className="flex space-x-4 mt-4 border-b">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[4px] px-2 cursor-pointer ${
                step === el.value ? "border-b-[2px] text-[#0F172A] border-blue-800 font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.value !== 4 ? () => handleTabChange(el.value) : null}
              key={el.value}
            >
              {el.name}
            </p>
          );
        })}
      </div> */}
      <div className="mt-5">
        <EngagementHub />
      </div>
    </div>
  );
};

export default Home;
