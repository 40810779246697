import Dropdown from "components/DropDown";
import PrimaryInput from "components/Inputs/PrimaryInput";

import React from "react";
import { useFormik } from "formik";
import BlueButton from "components/Button/BlueButton";
import { campaignSettings, getCampaignSettings } from "services/campaignService";
import Toast from "config/toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { trackEvent } from "config/mixpanel";
import dayjs from "dayjs";
import useLoggedInUser from "hooks/useLoggedInUser";
import { formatTime } from "lib/checkTime";
import PreLoader from "components/Loader/PreLoader";

const cleanUpDate = [
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
];

const creditData = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

const Voice = () => {
  const user = useLoggedInUser();
  const { data, isLoading: loadSettings } = useQuery({
    queryKey: ["voiceSettings"],
    queryFn: () => getCampaignSettings("robocall"),
  });

  const settings = data?.data;

  const initialValues = {
    amount: settings?.sendingLimit?.amount,
    timeframe: settings?.sendingLimit?.timeframe,
    cleanUp: settings?.cleanupList?.unit,
    interval: settings?.cleanupList?.interval,
    from: loadSettings ? "" : formatTime(settings?.deliveryWindow?.from),
    to: loadSettings ? "" : formatTime(settings?.deliveryWindow?.to),
    automaticRetry: settings?.automaticRetry?.retry,
  };

  const { mutate: updateRobocall, isLoading } = useMutation({
    mutationFn: campaignSettings,
    onSuccess: () => {
      Toast.success("voice setting updated successfully");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    const channel = "robocall";
    const payload = {
      sendingLimit: {
        amount: data?.amount,
        timeframe: data?.timeframe,
      },
      deliveryWindow: {
        from: data?.from,
        to: data?.to,
      },
      cleanupList: {
        unit: data?.cleanUp,
        interval: data.interval,
      },
      automaticRetry: data.automaticRetry,
    };
    try {
      updateRobocall({ channel, payload });
    } catch (err) {
      Toast.error(err);
    }

    trackEvent("update_settings", {
      email: user?.data?.email,
      date: dayjs().format(),
      channe: "voice",
    });
  };

  const { handleChange, values, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  // const [currentPage, setCurrentPage] = useState(1);

  // const paginatedData = useMemo(() => {
  //   const start = (currentPage - 1) * 5;
  //   const end = start + 5;
  //   return smsData.slice(start, end);
  // }, [smsData, currentPage]);

  if (loadSettings) {
    return <PreLoader />;
  }

  return (
    <div className="bg-white p-5">
      <form
        action="
      "
        onSubmit={handleSubmit}
      >
        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Set sending limit</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Limit the number of voice messages to be sent out from your account in a specified period.
          </p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <p className="text-sm font-semibold">Enter number of voicecalls</p>
              <div className="my-1 ">
                <PrimaryInput
                  name="amount"
                  text=""
                  type="number"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="0"
                />
              </div>
            </div>

            <div>
              <p className="text-sm font-semibold">Time frame</p>
              <div className="my-1">
                <Dropdown
                  tag="Choose an option"
                  options={creditData}
                  onSelect={(select) => setFieldValue("timeframe", select.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Auto retry</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Automatically retries unsent messages after 48 hours of hitting the sending limit
          </p>

          <p className="text-sm font-semibold">Retry messages after:</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="automaticRetry"
                  text=""
                  value={values.automaticRetry}
                  type="number"
                  onChange={handleChange}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="my-1 w-[150px]">
              <div className="border p-2 w-[100px] rounded-md flex items-center justify-center text-[12px]">
                <p>Hours</p>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Delivery window</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">Set a time to send out your voice call.</p>

          <p className="text-sm font-semibold">Set a timeframe</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="from"
                  value={values.from}
                  text=""
                  onBlur={handleBlur}
                  type="time"
                  onChange={handleChange}
                />
              </div>
            </div>
            <p>to</p>
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="to"
                  value={values.to}
                  text=""
                  onBlur={handleBlur}
                  type="time"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Clean up list</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Remove contacts who have not interacted with your voice messages over a period of time.
          </p>

          <p className="text-sm font-semibold">Set a timeframe</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="cleanUp"
                  text=""
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cleanUp}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="my-1 w-[150px]">
              <Dropdown
                tag="Choose an option"
                options={cleanUpDate}
                onSelect={(select) => setFieldValue("interval", select.value)}
              />
            </div>
          </div>
          <div>
            <BlueButton type="submit" text="Save" loading={isLoading} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Voice;
