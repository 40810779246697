import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { getAllWorkspace } from "services/workspaceService";
import Skeleton from "react-loading-skeleton";
import WorkspaceModal from "./WorkspaceModal";
import { checkInArray } from "lib/checkInArray";
import DeactivateModal from "./DeactivateModal";
import ReactivateModal from "./ReactivateModal";
import { deactivateAccount } from "services/userService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";

const Workspaces = () => {
  const { data, isLoading } = useQuery({ queryKey: ["allWorkspaces"], queryFn: getAllWorkspace });
  const [openModal, setOpenModal] = useState(null);
  const [workspace, setId] = useState(null);
  const [selectArray, setSelectArray] = useState([]);
  const [active, setActive] = useState(false);

  console.log({ data, openModal });

  console.log(workspace);

  const handleWorkspaceModal = ({ el, id }) => {
    setOpenModal(openModal === id ? null : id);
    setId(el);
  };

  const handleActionModals = (id) => {
    setOpenModal(id === openModal ? null : id);
  };

  const { mutate: deactivate } = useMutation({
    mutationFn: deactivateAccount,
    onSuccess: () => {
      Toast.success("Workspace deactivated successfully");
      queryClient.invalidateQueries({ queryKey: ["workspaceUsers"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleDeactivate = () => {
    const data = { deactivate: "true" };
    deactivate(data);
  };

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectArray([...selectArray, x]);
    } else {
      const filterArray = selectArray?.filter((el) => el !== x);
      setSelectArray(filterArray);
    }
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      const ids = data?.map((el) => el?._id);
      setSelectArray([...ids]);
    } else {
      setSelectArray([]);
    }
  };

  const checkArray = useCallback(() => {
    if (selectArray.length > 0) {
      setActive(true);
    } else if (selectArray.length <= 0) {
      setActive(false);
    }
  }, [selectArray.length]);

  useEffect(() => {
    checkArray();
  }, [checkArray]);

  const columns = [
    {
      accessor: "inputProps",
      header: (
        <p className="pl-0 flex items-center justify-center">
          <input
            type="checkbox"
            name="inputProps"
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={selectAll}
          />
        </p>
      ),
    },
    { accessor: "name", header: "Name" },
    { accessor: "members", header: "Members" },
    { accessor: "createdAt", header: "Date created" },
    { accessor: "status", header: "Status" },
  ];

  const workspaceData =
    data &&
    data?.map((el, i) => ({
      inputProps: (
        <>
          <p className="absolute mx-auto left-0 right-0 flex justify-center items-center w-[100%] h-[100%] top-0">
            <input
              type="checkbox"
              name="recipient"
              id="recipient"
              checked={checkInArray(el?._id, selectArray)}
              className="pl-0 w-[15px] h-[15px] accent-purple"
              onChange={(e) => handleCheck(e, el?._id)}
            />
          </p>
        </>
      ),
      name: (
        <>
          <p className="hover:underline cursor-pointer" onClick={() => handleWorkspaceModal({ el, id: i })}>
            {el?.name}
          </p>
          {data && openModal === i ? (
            <WorkspaceModal
              name={workspace.name}
              handleModal={handleActionModals}
              status={workspace?.deactivate}
              members={workspace?.admins}
              checkArray={checkArray}
              handleCheck={handleCheck}
              checkInArray={checkInArray}
              selectArray={selectArray}
              active={active}
            />
          ) : null}
        </>
      ),
      members: <p>{el?.admins?.length}</p>,
      createdAt: <p>{dayjs(el?.created_at).format("DD/MM/YYYY")}</p>,
      status: (
        <p className={`${el?.deactivate === false ? "text-green-500" : "text-red-500"}`}>
          {el?.deactivate === false ? "Active" : "Deactivated"}
        </p>
      ),
    }));

  return (
    <div className="bg-white rounded-md p-5 h-[calc(100vh-140px)] overflow-y-auto">
      <p className="font-[600]">Workspaces {`(${data?.length})`}</p>

      <div className="mt-5">
        <table className="w-[100%] my-4 border-b-[1px]">
          <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
            <tr className="h-[50px] border-r-[1px]  rounded-[2px] overflow-hidden">
              {columns &&
                columns.map((head, i) => (
                  <th key={i} className="px-2 text-left border-l-[1px] font-normal">
                    {head.header}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {isLoading ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                  <tr
                    key={i}
                    className="h-[50px]  text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white"
                  >
                    {columns?.map((col, i) => (
                      <td key={i} className="pl-2 border-l-[1px] border-b-[1px] relative">
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <>
                {workspaceData &&
                  workspaceData?.map((row, i) => (
                    <tr key={i} className="h-[50px] text-ss  text-[#404040] font-[500] rounded-[2px] bg-white">
                      {columns?.map((col, i) => (
                        <td key={i} className={`pl-2 border-l-[1px] border-b-[1px] relative`}>
                          {row[col.accessor] ? row[col.accessor] : row[col.accessor] === 0 ? "0" : <Skeleton />}
                        </td>
                      ))}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      {openModal === 10 ? (
        <DeactivateModal
          workspaceName={workspace?.name}
          toggleModal={() => handleActionModals(null)}
          handleDeactiveate={handleDeactivate}
        />
      ) : null}

      {openModal === 11 ? (
        <ReactivateModal workspaceName={workspace?.name} toggleModal={() => handleActionModals(null)} />
      ) : null}
    </div>
  );
};

export default Workspaces;
