import { useMutation, useQuery } from "@tanstack/react-query";
import { CustomButton } from "components/Button/CustomButton";
import { cardType, surveyIcons, surveyType } from "lib/surveyFunctions";
import React, { useEffect, useState } from "react";
import { BiSolidCopy } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { useParams } from "react-router-dom";
import Header from "./Header";
import { copyFunc } from "lib/copyFunc";
import PreLoader from "components/Loader/PreLoader";
import { BsThreeDotsVertical } from "react-icons/bs";
import QuestionsModal from "./QuestionsModal";
import { fetchSummary } from "services/newSurveyService";
import BlueButton from "components/Button/BlueButton";
import { mergeSort } from "lib/optimizedDataSort";
import { replaceMooyiOptionWithOthers } from "lib/ReplaceOther";
import BarCharts from "../Research/components/Charts/BarCharts";
import PieCharts from "../Research/components/Charts/PieCharts";
import Customized from "../Research/components/Charts/Customized";
import { fetchByPublishId } from "services/newSurveyService";
import MatrixCustomized from "pages/Research/components/Charts/MatrixCustomized";

const Summary = () => {
  const { pubId } = useParams();
  const [open, setOpen] = useState(null);
  const [viewState, setViewState] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const [viewMoreNumber, setViewMoreNumber] = useState(5);
  const [keywordValue, setKeywordValue] = useState(null);
  const [responseArray, setResponseArray] = useState([]);

  const handleKeyword = (value, i, parentIndex) => {
    if (keywordValue === null || keywordValue !== value) {
      setKeywordValue(value);

      const filteredSentences = newData?.summary[parentIndex]?.textResponse?.responses.filter((sentence) =>
        sentence.toLowerCase().includes(value.split("-")[1]),
      );

      setResponseArray(filteredSentences);

      setOpen(i);
    } else {
      setKeywordValue(null);
      setOpen(null);
    }
  };
  const { mutate: mutateSummary, isLoading: isFetchingSummary } = useMutation({
    mutationFn: fetchSummary,
    onSuccess: (data) => {
      setSummaryData(data);
    },
  });

  const { data: detailsData } = useQuery({
    queryKey: ["fetchByPublishId", pubId],
    queryFn: () => fetchByPublishId(pubId),
  });

  const surveyName = detailsData?.data?.surveyDetails?.surveyName;

  const newData = summaryData?.data?.defaultSummary?.responseData;

  console.log(newData, "newData");

  // useEffect(() => {
  //   const filterSentences = newData?.summary?.flatMap(
  //     (el) =>
  //       el?.textResponse?.responses?.filter((elm) => {
  //         const words = elm.toLowerCase().split(/[,\s]+/);
  //         return words.includes(keywordValue);
  //       }) || [],
  //   );

  //   console.log("first", filterSentences);

  //   setResponseArray(filterSentences);
  // }, [newData?.summary, keywordValue]);

  useEffect(() => {
    mutateSummary({
      surveyId: pubId,
      payload: {
        publishId: [pubId],
      },
    });
  }, [mutateSummary, pubId]);

  const viewType = ["default_view", "column_chart", "pie_chart"];

  const handleDropdown = (id) => {
    setOpen(open === id ? null : id);
  };

  useEffect(() => {
    const arrayState = (newData || [])?.map((el) => ({
      id: el?.id,
      view: "default_view",
    }));
    setViewState(arrayState);
  }, [newData]);

  const displayChart = (index) => {
    const newDataChart = newData[index]?.options;
    let finalValue = [];
    const keys = Object.keys(newDataChart);

    for (const elm of keys) {
      finalValue.push({
        name: elm,
        value: newDataChart[elm].value ?? 0,
        percentage: newDataChart[elm].percentage ?? 0,
      });
    }

    switch (viewState[index]?.view) {
      case "default_view":
        return <Customized data={replaceMooyiOptionWithOthers(mergeSort(finalValue))} filterLength={0} />;
      case "column_chart":
        return <BarCharts data={replaceMooyiOptionWithOthers(mergeSort(finalValue))} filterLength={0} />;
      case "pie_chart":
        return <PieCharts data={replaceMooyiOptionWithOthers(mergeSort(finalValue))} filterLength={0} />;
      default:
    }
  };

  const handleTypeSelect = (x, index) => {
    setViewState((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        view: x,
      };
      return updatedValues;
    });
    handleDropdown();
  };

  const handleViewMore = (type) => {
    if (type === "more") {
      setViewMoreNumber(viewMoreNumber + 5);
    } else if (type === "less") {
      if (viewMoreNumber < 10 && viewMoreNumber >= 5) {
        const newValue = viewMoreNumber - 5;
        setViewMoreNumber(viewMoreNumber - newValue);
      } else {
        setViewMoreNumber(viewMoreNumber - 5);
      }
    }
  };

  const transformMatrixData = (options) => {
    return Object.keys(options).map((column) => {
      const rows = options[column];
      const transformedRow = { name: column };
      Object.keys(rows).forEach((row) => {
        transformedRow[row] = rows[row].value;
      });
      return transformedRow;
    });
  };

  if (isFetchingSummary) {
    return <PreLoader />;
  }

  return (
    <div className="bg-[#F7F7F8] md:h-[100vh] h-auto">
      <Header text={surveyName} />

      <div className="w-[90%] mx-auto ">
        <div className="md:hidden flex justify-between items-center pt-[2em]">
          <p className="font-bold text-[20px]">{surveyName}</p>
          <BsThreeDotsVertical className="text-[22px]" onClick={() => handleDropdown("questionModal")} />
        </div>

        <div className="flex justify-between my-4">
          <div className="md:w-[90%] w-[70%] flex items-start flex-col">
            <p className="border-b-[2px] border-purple pb-2">Summary</p>
            <hr className="w-[100%] border-[1px]" />
          </div>

          <CustomButton
            text="Copy link"
            icon={<BiSolidCopy />}
            onClick={() => copyFunc(window.location.href, "Link Copied")}
          />
        </div>

        <div className="flex md:flex-row flex-col items-start justify-between bg-white p-6 rounded-lg my-[1em] border  md:h-[calc(100vh-200px)] h-auto md:gap-0 gap-[2em]">
          <div className="shadow-md md:w-[30%] w-[100%] hidden sm:block p-6 rounded-md border-[1px] md:overflow-y-scroll overflow-auto md:max-h-[calc(100vh-250px)] h-auto">
            <p className=" font-[500] text-m">Questions</p>
            {newData?.map((el, i) => {
              return (
                <a
                  href={`#question-${i}`}
                  className="flex space-x-2 text-[#525252] items-center cursor-pointer my-2  h-[35px]"
                  key={i}
                >
                  <div>{surveyIcons(el.optionType)}</div>
                  <p className="text-ss">{i + 1}</p>
                  <p className="text-ss pl-4 truncate">
                    {el.question.substring(0, 42)}
                    {el.question.length > 42 ? "..." : ""}
                  </p>
                </a>
                // </Link>
              );
            })}
          </div>
          <div className="md:w-[50%] w-[100%] space-y-6 md:overflow-y-scroll overflow-auto md:h-[calc(100vh-250px)] h-auto">
            {newData?.map((el, i) => {
              return (
                <div id={`question-${i}`} key={`card${i}`} className="border-[1px] rounded-md p-5">
                  <div className="flex  justify-between items-center">
                    <div className="flex items-center space-x-2 ">
                      <p className="bg-[#F6F0FE] flex items-center justify-center text-primary-800 w-[25px] h-[28px] rounded-md font-bold">
                        {i + 1}
                      </p>
                      <div className="border-[1px] flex items-center space-x-2 p-[5px] px-2 rounded-md">
                        <div>{surveyIcons(el.optionType)}</div>
                        <p className="md:text-ss text-[10px]">{surveyType(el.optionType)}</p>
                      </div>
                    </div>

                    {el.optionType === "text_response" ||
                    el.optionType === "image" ||
                    el.optionType === "video" ? null : (
                      <div className="relative">
                        <div
                          className="border-[1px] md:w-[150px] w-[120px] h-[30px]  flex items-center text-s justify-between md:space-x-4 space-x-2 rounded-md cursor-pointer px-4"
                          onClick={() => handleDropdown(i)}
                        >
                          <div className="flex space-x-2 items-center">
                            <p className="md:text-ss text-[10px]">{cardType(viewState[i]?.view)}</p>
                          </div>

                          <IoIosArrowDown />
                        </div>

                        {open === i ? (
                          <div className="shadow-md bg-white overflow-hidden rounded-[8px] absolute top-[2em] w-[150px] z-20">
                            {viewType.map((elm, j) => {
                              return (
                                <div
                                  className="flex items-center space-x-2 h-[35px] text-[#737373] pl-4 border-b-[1px] cursor-pointer "
                                  key={elm}
                                  onClick={() => handleTypeSelect(elm, i)}
                                >
                                  <p className="text-ss">{cardType(elm)}</p>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>

                  <p className="sm:text-[20px] text-base font-bold my-3">{el.question}</p>
                  <hr className="my-[1em]" />
                  <div>
                    {el.optionType === "text_response" ? (
                      <div className="space-y-3">
                        <div className="flex items-center gap-3 flex-wrap">
                          {el?.textResponse?.keywords?.map((elm, j) => {
                            return (
                              <div
                                key={`textKey${i}`}
                                className={`min-w-150px px-4 $  ${
                                  keywordValue === `${i}-${elm.word}`
                                    ? "bg-primary-800 text-white"
                                    : "bg-violet-100 hover:bg-violet-200"
                                } py-1 flex text-xs justify-center items-center rounded space-x-2 cursor-pointer`}
                                onClick={() =>
                                  handleKeyword(`${i}-${elm.word}`, `${el.word}${el.count}${el.question}`, i)
                                }
                              >
                                <p> {elm.word}</p> <p> ({elm.count})</p>
                              </div>
                            );
                          })}
                        </div>

                        <>
                          {open === `${el.word}${el.count}${el.question}` ? (
                            <>
                              {responseArray?.slice(0, viewMoreNumber).map((elm, i) => {
                                return (
                                  <div
                                    key={`textRes${i}`}
                                    className="text-[#737373] bg-[#FAFAFA] p-2 rounded-md text-s flex items-center justify-between"
                                  >
                                    {elm.split(" ").forEach((el) => {
                                      if (el === keywordValue) {
                                        return <p className="text-primary-800">{el}</p>;
                                      }
                                    })}

                                    <p> {elm}</p>

                                    {/* {elm.map((word, j) => (
                                      <span key={`wordKey${j}`} className={word === keywordValue ? "text-primary-800" : ""}>
                                        {word}
                                      </span>
                                    ))} */}
                                  </div>
                                );
                              })}{" "}
                            </>
                          ) : null}
                        </>

                        <div className="flex items-center space-x-4">
                          {viewMoreNumber >= responseArray?.length ? (
                            <></>
                          ) : (
                            <BlueButton
                              onClick={() => handleViewMore("more")}
                              text="View More"
                              className="w-[100%] text-sm"
                              type="button"
                            />
                          )}

                          {viewMoreNumber > 5 ? (
                            <BlueButton
                              onClick={() => handleViewMore("less")}
                              text="View Less"
                              className="w-[100%] text-sm"
                              type="button"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : el.optionType === "image" ? (
                      <>
                        <div className="flex space-x-4 h-[200px] overflow-y-scroll">
                          {false
                            ? "No Response yet"
                            : el?.responses?.map((el, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="bg-[#FAFAFA] mb-2 w-[200px] h-[100%] p-2 text-ss rounded-lg"
                                  >
                                    <img src={el} alt="" className="object-contain w-[100%] h-[100%]" />
                                    {/* {el} */}
                                  </div>
                                );
                              })}
                        </div>
                      </>
                    ) : el.optionType === "matrix" || el.optionType === "ranking" ? (
                      <>
                        {/* <MatrixBarCharts data={transformMatrixData(el?.options)} /> */}

                        <MatrixCustomized
                          data={transformMatrixData(el?.options)}
                          questionIndex={i}
                          datum={el}
                          // setQuestionsFilter={setQuestionsFilter}
                        />
                      </>
                    ) : el.optionType === "video" ? (
                      <>
                        <div className="flex space-x-4 h-[200px] overflow-y-scroll">
                          {false
                            ? "No Response yet"
                            : el?.responses?.map((el, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="bg-[#FAFAFA] mb-2 w-[200px] h-[100%] p-2 text-ss rounded-lg"
                                  >
                                    <video className="h-[100%] w-full rounded-[8px] object-cover" controls>
                                      <source src={el} />
                                    </video>
                                    {/* <img src={el} alt="" className="object-contain w-[100%] h-[100%]" /> */}
                                    {/* {el} */}
                                  </div>
                                );
                              })}
                        </div>
                      </>
                    ) : (
                      <>{displayChart(i)}</>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            {open === "questionModal" ? <QuestionsModal data={newData} handleDropdown={handleDropdown} /> : null}
          </div>

          <div className=""></div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
