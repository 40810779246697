import React from "react";
import Typography from "utils/typography";

const MatrixCustomized = ({ data, setQuestionsFilter, datum }) => {
  const calculatePercentages = (obj) => {
    const total = Object.values(obj).reduce((acc, value) => acc + (typeof value === "number" ? value : 0), 0);
    return Object.keys(obj).reduce((acc, key) => {
      if (typeof obj[key] === "number") {
        acc.push({ key, value: obj[key], percentage: ((obj[key] / total) * 100).toFixed(2) });
      }
      return acc;
    }, []);
  };

  const handleBarClick = ({ key, value }) => {
    setQuestionsFilter([
      {
        question: datum.question,
        option:
          datum.optionType === "matrix"
            ? [key, value]
            : {
                item: key,
                rank: value,
              },
        questionType: datum.optionType,
      },
    ]);
  };

  // console.log(datum, "datum");

  // Predefined array of colors
  const colors = ["#BDCEFF", "#95ADFF", "#6B7FFF", "#4852FF", "#2D28FF", "#251DE5", "#1D19AF", "#1F1E91", "#141254"];

  // Function to select a color from the array
  const getColorFromArray = (index) => {
    // Cycle through the colors array using modulo to prevent going out of bounds
    return colors[index % colors.length];
  };

  return (
    <div>
      <div>
        {data.map((item, index) => {
          const percentages = calculatePercentages(item);
          return (
            <div key={index} className="my-2">
              <Typography.SubText>{item.name}</Typography.SubText>
              <div className="rounded h-[40px] flex bg-[#f0f0f0] w-[100%] overflow-hidden">
                {percentages.map((barItem, idx) => (
                  <div
                    key={barItem.key}
                    style={{
                      width: `${barItem.percentage}%`,
                      backgroundColor: getColorFromArray(idx),
                    }}
                    className="text-center text-xs text-white flex items-center justify-center space-x-1 cursor-pointer"
                    onClick={() =>
                      handleBarClick({
                        key: item.name,
                        value: barItem.key,
                      })
                    }
                  >
                    {barItem.value === 0 ? null : (
                      <>
                        {barItem.key} : {barItem.percentage}% ({barItem.value})
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatrixCustomized;
