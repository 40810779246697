import { cn } from "helpers/classHelpers";
import React from "react";
import { IoIosArrowRoundDown } from "react-icons/io";
import { Link } from "react-router-dom";
import Typography from "utils/typography";
import defaultSurveyImage from "assets/images/defaultSurveyImage.png";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import { toTitleCase } from "lib/titleCase";

const Research = ({ recentSurveys, isLoading }) => {
  return (
    <div>
      <div className="flex items-center p-2 bg-gray-50 border-b-[1px] space-x-8">
        <div className="flex w-[40%] items-center space-x-1 ">
          <Typography.MicroText>Name</Typography.MicroText>

          <IoIosArrowRoundDown />
        </div>

        <Typography.MicroText className="w-[10%]">Responses</Typography.MicroText>

        <Typography.MicroText className="w-[10%]">Status</Typography.MicroText>

        <Typography.MicroText className="w-[25%]">Last updated</Typography.MicroText>
      </div>

      {isLoading ? (
        <div className="">
          <Skeleton className="h-[60px]" />
          <Skeleton className="h-[40px]" />
          <Skeleton className="h-[20px]" />
        </div>
      ) : (
        <>
          {recentSurveys?.map((el, i) => (
            <Link
              to={`/research/online-survey/analytics/${el.publishId}/${el.surveyId}`}
              className={cn("flex items-center py-2 space-x-8 hover:bg-gray-100", i === 2 ? "" : "border-b-[1px]")}
            >
              <div className="flex items-center space-x-3 w-[40%]">
                <div className="w-[80px] border-[1px] h-[50px] rounded-md overflow-hidden">
                  <img
                    src={el?.surveyImage || defaultSurveyImage}
                    alt="defaultSurveyImage"
                    className="w-[100%] h-[100%] object-cover"
                  />
                </div>

                <div className="w-[60%]">
                  <Typography.MicroText className="font-semibold w-[100%] truncate ...">
                    {el?.surveyName}
                  </Typography.MicroText>

                  <Typography.MicroText>{el?.surveyCategory}</Typography.MicroText>
                </div>
              </div>

              <div className="w-[10%] flex items-center space-x-3">
                <Typography.MicroText>
                  {el?.responses} / {el?.expectedResponse}
                </Typography.MicroText>
              </div>

              <Typography.MicroText className="w-[10%]">{toTitleCase(el?.surveyStatus)}</Typography.MicroText>

              <Typography.MicroText className="w-[25%]">
                {dayjs(el.date).format("ddd, DD MMM, YYYY")}
              </Typography.MicroText>
            </Link>
          ))}
        </>
      )}
    </div>
  );
};

const Messaging = ({ recentBroadCasts, isLoading }) => {
  return (
    <div>
      <div className="flex items-center p-2 bg-gray-50 border-b-[1px] space-x-8 ">
        <div className="flex w-[50%] items-center space-x-1 ">
          <Typography.MicroText>Name</Typography.MicroText>

          <IoIosArrowRoundDown />
        </div>

        <Typography.MicroText className="w-[10%]">Sends</Typography.MicroText>

        <Typography.MicroText className="w-[10%]">Status</Typography.MicroText>

        <Typography.MicroText className="w-[25%]">Last updated</Typography.MicroText>
      </div>

      {isLoading ? (
        <div className="">
          <Skeleton className="h-[60px]" />
          <Skeleton className="h-[40px]" />
          <Skeleton className="h-[20px]" />
        </div>
      ) : (
        <>
          {recentBroadCasts?.map((el, i) => (
            <Link
              to={`/broadcasts/${el?.campaign_channel}/${el?._id}`}
              className={cn("flex items-center py-2 space-x-8 hover:bg-gray-100", i === 2 ? "" : "border-b-[1px]")}
            >
              <div className="flex items-center space-x-3 w-[50%]">
                <div className="w-[80px] border-[1px] h-[50px] rounded-md overflow-hidden">
                  <img src={defaultSurveyImage} alt="defaultSurveyImage" className="w-[100%] h-[100%] object-cover" />
                </div>

                <div className="w-[60%]">
                  <Typography.MicroText className="font-semibold w-[100%] truncate ...">
                    {el?.campaign_name}
                  </Typography.MicroText>

                  <Typography.MicroText>{el?.campaignObjective}</Typography.MicroText>
                </div>
              </div>

              <div className="w-[10%] flex items-center space-x-3">
                {/* <div className="bg-neutral-200 rounded-lg h-[7px] overflow-hidden w-[80%]">
                  <div
                    className="bg-success-600 h-[100%]"
                    style={{
                      width: el?.metrics?.delivered?.value
                        ? `${(el?.metrics?.delivered?.value / el?.metrics?.sent?.value) * 100}%`
                        : "0%",
                    }}
                  ></div>
                </div> */}
                <Typography.MicroText>
                  {el?.metrics?.delivered?.value} / {el?.metrics?.sent?.value}
                </Typography.MicroText>
              </div>

              <Typography.MicroText className="w-[10%]">{toTitleCase(el?.campaign_status)}</Typography.MicroText>

              <Typography.MicroText className="w-[25%]">
                {dayjs(el?.campaign_date).format("ddd, DD MMM, YYYY  ")}
              </Typography.MicroText>
            </Link>
          ))}
        </>
      )}
    </div>
  );
};

const RecentCampaignsCard = ({ recentSurveys, recentBroadCasts, isLoading }) => {
  return (
    <div className="w-[100%] bg-white border-[1px] rounded-md p-4">
      <div className="flex items-center justify-between"></div>

      <div className="flex space-x-4">
        <div className="w-[50%] border-[1px] rounded-md p-4">
          <Typography.Text className="py-4">Research</Typography.Text>
          <Research recentSurveys={recentSurveys} isLoading={isLoading} />
        </div>

        <div className="w-[50%] border-[1px] rounded-md p-4">
          <Typography.Text className="py-4">Messaging</Typography.Text>

          <Messaging recentBroadCasts={recentBroadCasts} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default RecentCampaignsCard;
