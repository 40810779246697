import { create } from "zustand";

const useSummaryStore = create((set) => ({
  summaryData: null,
  questionIndex: "",
  setQuestionIndex: (data) => set({ questionIndex: data }),
  isFetching: false,
  status: null,
  setSummaryData: (data) => set({ summaryData: data }),
  setIsFetching: (isFetching) => set({ isFetching }),
  setStatus: (status) => set({ status }),

  ageFilter: { min: 0, max: 0 },
  setAgeFilter: (name, value) =>
    set((state) => ({
      ageFilter: {
        ...state.ageFilter,
        [name]: value,
      },
    })),

  applyAgeFilter: false,
  setApplyAgeFilter: (data) => set({ applyAgeFilter: data }),
}));

export default useSummaryStore;
