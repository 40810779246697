import * as Yup from "yup";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { forgotPassword } from "services/authService";
import { Link, useNavigate } from "react-router-dom";
import Toast from "config/toast";
import PreLoader from "components/Loader/PreLoader";
import BlueButton from "components/Button/BlueButton";
import { setEmail } from "helpers/authHelper";
import Logo from "assets/images/newLogo.png";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Enter a valid email address").required("Please fill in this field"),
});

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation({
    mutationFn: forgotPassword,
    onError: (error) => {
      if (error === "Error fetching user!") {
        Toast.error("User with email doesn’t exist");
      } else {
        Toast.error(error);
      }
    },
    onSuccess: () => {
      Toast.success("Email sent!");
      navigate("/auth/email-sent-password");
      resetForm();
    },
  });

  const onSubmit = async (data) => {
    mutate(data);
    setEmail(data.email);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="w-[90%] mx-auto py-5 relative">
      {/* <Link to="/auth/login" className="flex items-center">
        <MdArrowBackIos className="mr-2 text-[#323232]" /> Login
      </Link> */}

      <div className="w-[90%] mx-auto flex flex-col">
        <div className="flex justify-center">
          <img src={Logo || ""} alt="Mooyi logo" className="w-[150px] mt-[1em]" />
        </div>
        <h1 className="font-semibold text-[28px] text-center">Reset your password</h1>
        <p className="text-[#737373] my-[10px] text-center">
          Don't panic. We'll email you instructions to reset your password.
        </p>

        <form onSubmit={handleSubmit} className="mt-[1em]">
          <div className="">
            <PrimaryInput
              type="email"
              name="email"
              placeholder=""
              onChange={handleChange}
              text="Email Address"
              onBlur={handleBlur}
              value={values.email}
              error={getError("email")}
            />

            <BlueButton type="submit" text="Reset Password" className="w-[100%] mt-8" />
          </div>
        </form>
      </div>
      <div className="mt-2 absolute -bottom-16 translate-x-[170px]">
        <span className="text-primary-800 font-[500] text-m inline-block">
          <Link to="/auth/login">Back to Login</Link>
        </span>
      </div>
    </div>
  );
}
