import Typography from "utils/typography";
import Tooltip from "./Tooltip";
import { formatAmount } from "lib/formatAmount";

const CreditCard = ({ name, icon, count, tooltipContent, id }) => {
  const Icon = icon;
  return (
    <div className="border-[1px] rounded-md h-full bg-[#141254] " key={name}>
      <div className="w-[100%] h-[100%] bg-footerHero p-2">
        <div className="w-[100%] h-[100%] bg-white bg-opacity-30 rounded-md border-[1px] border-white border-opacity-50 p-1 space-y-2">
          <div className="flex items-center space-x-3">
            <div className="w-[15px] h-[15px] bg-white flex items-center justify-center rounded">
              <Icon fill1="#141254" fill2={id === 1 ? "#141254" : "#fff"} />
            </div>
            <div className="flex items-center space-x-2">
              <Typography.MicroText className="text-white">{name}</Typography.MicroText>

              <Tooltip className="text-white" text={tooltipContent} />
            </div>
          </div>

          <Typography className="text-white text-[16px] font-bold">{formatAmount(count)}</Typography>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
