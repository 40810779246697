import React, { useState } from "react";
import { TiInfo } from "react-icons/ti";
import Upload from "../../../assets/images/upload.svg";
import MatchColumns from "./MatchColumns";
import * as XLSX from "xlsx";
import BlueButton from "../../../components/Button/BlueButton";
import { AiOutlineFile } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { byteConverter } from "../../../lib/byteConverter";
import { Line } from "rc-progress";
import Mock from "../../../assets/mock.csv";
import Toast from "../../../config/toast";
import Checkbox from "../../../components/Inputs/CheckBox";
import { Link } from "react-router-dom";
import TagDropDown from "components/dropdowns/TagDropDown";

const UploadCSV = ({ step, setStep, outSideContact, handleModal }) => {
  const [tagArr, setTagArr] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [rawFile, setRawFile] = useState({});
  const [fileHeaders, setFileHeaders] = useState([]);
  const [topThreeRows, setTopThreeRows] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [uploadText, setUploadText] = useState(false);

  const uploadRef = React.useRef();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
    } else {
      try {
        setUploadText(true);
        let file = e.target.files[0];
        setRawFile(file);
        setFileName(file.name);
        setFileSize(file.size);

        const data = await file.arrayBuffer();
        const wb = XLSX.read(data);
        const workbookToJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        let headers = Object.keys(workbookToJson[0]);
        if (workbookToJson && headers) {
          setDisableButton(false);
          setUploadText(false);
        }

        setFileHeaders(headers);
        setTopThreeRows(workbookToJson.slice(0, 6));
      } catch (error) {
        Toast.error(error);
      }
    }
  };

  const clearEntry = () => {
    setRawFile({});
    setFileName("");
    setFileSize("");
    setFileHeaders([]);
    setDisableButton(true);
  };

  return (
    <div>
      {step ? (
        <div className="w-full">
          <div className="border-[1px] border-primary-100 p-4 rounded-[8px]  bg-primary-50 flex space-x-2 my-4 mt-2 w-full">
            <TiInfo className="text-[#FBBF24] text-[24px]" />
            <div>
              <p className="text-[14px font-[600]">PLEASE NOTE</p>

              <p className="text-s font-[400]">
                We do not send confirmation emails to uploaded contacts. Ensure you have obtained permission before
                adding contacts, and follow guidelines to prevent misuse.
              </p>
            </div>
          </div>

          <div className="w-full py-2">
            <div>
              <div className="flex justify-between items-center mt-2 ">
                <p className="font-[600] text-sm">Upload File</p>
                {/* <a
                  href={Mock}
                  className="flex text-ss text-primary-800 underline underline-offset-1"
                  download="Mooyi Template"
                >
                  Download template
                </a> */}
              </div>

              {!fileName ? (
                <div className="h-[200px] border-[1px] mt-2 bg-[#FAFAFA] border-[#D0D5DD] rounded-[8px] flex items-center flex-col justify-center space-y-1 ">
                  <img src={Upload} alt="" className="" />
                  <p className="text-[#262626] text-sm">Drag and drop CSV or XLSX file </p>
                  <p className="text-[#737373] text-ss">or select it from your computer</p>

                  <div className="flex space-x-4 pt-3">
                    <div className="px-4 rounded border-neutral-400 text-neutral-700 font-semibold py-2 border-[1px] text-sm relative">
                      <input
                        type="file"
                        className="w-[100%] h-[100%] absolute cursor-pointer top-0 left-0 opacity-0"
                        accept=".csv, .xlsx"
                        onChange={onSelectFile}
                        ref={uploadRef}
                      />

                      <p className="font-semibold">Browse files</p>
                    </div>

                    <a href={Mock} download="Mooyi Template">
                      <div className="px-4 rounded border-neutral-400 text-neutral-700 font-semibold py-2 border-[1px] text-sm ">
                        Download template
                      </div>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="border-[1px] rounded-[6px]">
                  <div className="flex  p-2 space-x-2">
                    <div className="w-[30px] h-[30px] bg-[#29085C1A] text-primary-800 text-[18px] flex items-center justify-center rounded-full">
                      <AiOutlineFile />
                    </div>

                    <div className="flex justify-between w-[90%]">
                      <div>
                        <p className="text-s">{fileName}</p>
                        <p className="text-ss text-[#A3A3A3]">{byteConverter(fileSize)}</p>
                      </div>

                      <MdOutlineDelete onClick={clearEntry} className="cursor-pointer" />
                    </div>
                  </div>

                  <div className="flex space-x-2 px-4 items-center mb-2 justify-between">
                    <div className="min-w-[75%] rounded-[8px]">
                      <Line percent={100} strokeWidth={1} strokeColor="#29085C" trailWidth={1} />
                    </div>

                    {disableButton ? (
                      <p className="text-[#253241] text-ss">Uploading</p>
                    ) : (
                      <p className="text-[#253241] text-ss">Uploaded</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <TagDropDown tagArr={tagArr} setTagArr={setTagArr} />
            {/* {open ? <CreateTag toggleModal={toggleModal} /> : null} */}
          </div>

          <div className="mb-4">
            <div className="flex items-start space-x-3 w-full">
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />

              <div className="-mt-1">
                <p className="text-ss mt-1">
                  I confirm that I have permission from my customers (or they have signed up to receive messages) to
                  share their data with third-party platforms for marketing purposes. By proceeding, you agree to our{" "}
                  <Link to="https://usemooyi.com/terms-of-use" className="text-blue-800">
                    {" "}
                    Terms of use{" "}
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <BlueButton
            text={uploadText ? "Uploading" : "Import"}
            disabled={disableButton || !isChecked}
            onClick={() => setStep(!step)}
            type="button"
          />
        </div>
      ) : (
        <MatchColumns
          rawFile={rawFile}
          fileName={fileName}
          fileHeaders={fileHeaders}
          topThreeRows={topThreeRows}
          tagArr={tagArr}
          handleModal={handleModal}
          outSideContact={outSideContact}
        />
      )}
    </div>
  );
};

export default UploadCSV;
