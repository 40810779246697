import React from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import Typography from "utils/typography/index.jsx";
import { FaStar } from "react-icons/fa";
import { FaRegCircleDot } from "react-icons/fa6";
import { PiArrowsDownUpFill, PiSlidersHorizontalFill } from "react-icons/pi";
import PrimaryInput from "components/Inputs/PrimaryInput";
import SelectInput from "components/Inputs/SelectInput";
import { ratingOption } from "lib/surveyFunctions";
import { IoCaretDown, IoClose } from "react-icons/io5";
import { CgMathPlus } from "react-icons/cg";
import { option1 } from "lib/surveyFunctions";
import { option2 } from "lib/surveyFunctions";
import classNames from "classnames";
import { ratingShapes } from "lib/surveyFunctions";
import { MdOutlineLocationOn, MdOutlineMail, MdOutlineVideoLibrary } from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { BsCalendar2Date, BsImage } from "react-icons/bs";

export const Container = ({ children, className }) => {
  return <div className={classNames("mx-auto py-2 w-[92%]", className)}>{children}</div>;
};

export const questionTypes = ({
  question,
  questionType,
  handleText,
  createValueOptions,
  handleOptionChange,
  handleDeleteOption,
  handleLikertChange,
  handleRatingTextChange,
  handleRatingSelectChange,
  handleRatingIconChange,
}) => {
  switch (questionType) {
    case "text_response":
      return (
        <div>
          <Container>
            <PrimaryInput
              type="text"
              name="question"
              value={question.question}
              onChange={handleText}
              text={<Typography.MicroText>Question</Typography.MicroText>}
            />
          </Container>

          <div className="border-t-[1px] border-b-[1px]">
            <Container>
              <PrimaryInput
                type="text"
                name="description"
                onChange={handleText}
                value={question.description}
                text={<Typography.MicroText>Description</Typography.MicroText>}
              />
            </Container>
          </div>
        </div>
      );

    case "single_choice":
      return (
        <div>
          <Container>
            <PrimaryInput
              type="text"
              name="question"
              value={question.question}
              onChange={handleText}
              text={<Typography.MicroText>Question</Typography.MicroText>}
            />
          </Container>

          <div className="border-t-[1px] border-b-[1px]">
            <Container>
              <PrimaryInput
                type="text"
                name="description"
                value={question.description}
                onChange={handleText}
                text={<Typography.MicroText>Description</Typography.MicroText>}
              />
            </Container>
          </div>

          <Container>
            <Typography.MicroText>Options (You can add a maximum of 4 options)</Typography.MicroText>

            <div className="mt-2">
              {question.options.map((el, i) => (
                <div className="flex items-center  space-x-4">
                  <div className="w-[80%]">
                    <PrimaryInput
                      type="text"
                      value={el}
                      onChange={(e) => handleOptionChange(e, i)}
                      placeholder={`Option ${i + 1}`}
                    />
                  </div>

                  <IoClose className="text-xl text-gray-700 cursor-pointer" onClick={() => handleDeleteOption(i)} />
                </div>
              ))}

              <div
                className="border-[1px] bg-gray-100 w-[120px] flex items-center justify-center h-[35px] space-x-2 rounded-md my-2 hover:bg-gray-200 cursor-pointer transition duration-300 ease-in-out"
                onClick={createValueOptions}
              >
                <CgMathPlus />
                <Typography.MicroText>Add Option</Typography.MicroText>
              </div>
            </div>
          </Container>
        </div>
      );

    case "multi_choice":
      return (
        <div>
          <Container>
            <PrimaryInput
              type="text"
              name="question"
              value={question.question}
              onChange={handleText}
              text={<Typography.MicroText>Question</Typography.MicroText>}
            />
          </Container>

          <div className="border-t-[1px] border-b-[1px]">
            <Container>
              <PrimaryInput
                type="text"
                name="description"
                value={question.description}
                onChange={handleText}
                text={<Typography.MicroText>Description</Typography.MicroText>}
              />
            </Container>
          </div>

          <Container>
            <Typography.MicroText>Options (You can add a maximum of 4 options)</Typography.MicroText>

            <div className="mt-2">
              {question.options.map((el, i) => (
                <div className="flex items-center  space-x-4">
                  <div className="w-[80%]">
                    <PrimaryInput
                      type="text"
                      value={el}
                      onChange={(e) => handleOptionChange(e, i)}
                      placeholder={`Option ${i + 1}`}
                    />
                  </div>

                  <IoClose className="text-xl text-gray-700 cursor-pointer" onClick={() => handleDeleteOption(i)} />
                </div>
              ))}

              <div
                className="border-[1px] bg-gray-100 w-[120px] flex items-center justify-center h-[35px] space-x-2 rounded-md my-2 hover:bg-gray-200 cursor-pointer transition duration-300 ease-in-out"
                onClick={createValueOptions}
              >
                <CgMathPlus />
                <Typography.MicroText>Add Option</Typography.MicroText>
              </div>
            </div>
          </Container>
        </div>
      );

    case "rating":
      return (
        <div>
          <Container>
            <PrimaryInput
              type="text"
              name="question"
              value={question.question}
              onChange={handleText}
              text={<Typography.MicroText>Question</Typography.MicroText>}
            />
          </Container>

          <div className="border-t-[1px] border-b-[1px]">
            <Container>
              <PrimaryInput
                type="text"
                name="description"
                value={question.description}
                onChange={handleText}
                text={<Typography.MicroText>Description</Typography.MicroText>}
                placeholder="Add some text here (Optional)"
              />
            </Container>
          </div>

          <div className="border-b-[1px] py-2">
            <Container className={"flex items-center space-x-4"}>
              <Typography.MicroText>Icon style</Typography.MicroText>

              <div className="space-x-2 flex items-center">
                {ratingShapes.map((el, i) => (
                  <div
                    key={`likertIcons-${i}`}
                    className={classNames(
                      "w-[30px] h-[30px]  flex items-center justify-center rounded cursor-pointer",
                      el.name === question.options.shape ? "bg-primary-200" : "bg-primary-50",
                    )}
                    name={el.name}
                    onClick={() => handleRatingIconChange(el.name)}
                  >
                    {el.icon}
                  </div>
                ))}
              </div>
            </Container>
          </div>

          <div>
            <Container>
              <Typography.MicroText>Set number of icons to display</Typography.MicroText>
              <SelectInput
                options={ratingOption}
                value={question?.options?.iconQuantity}
                name="iconQuantity"
                onChange={handleRatingSelectChange}
              />
            </Container>
          </div>

          <div className="border-t-[1px]">
            <Container>
              <PrimaryInput
                type="text"
                value={question?.options?.label.first}
                name="first"
                onChange={handleRatingTextChange}
                text={<Typography.MicroText>First icon </Typography.MicroText>}
                placeholder="Add label (Optional)"
              />

              <PrimaryInput
                type="text"
                value={question?.options?.label.last}
                name="last"
                onChange={handleRatingTextChange}
                text={<Typography.MicroText>Last icon </Typography.MicroText>}
                placeholder="Add label (Optional)"
              />
            </Container>
          </div>
        </div>
      );

    case "likert":
      return (
        <div>
          <Container>
            <PrimaryInput
              type="text"
              name="question"
              value={question.question}
              onChange={handleText}
              text={<Typography.MicroText>Question</Typography.MicroText>}
            />
          </Container>

          <div className="border-t-[1px] border-b-[1px]">
            <Container>
              <PrimaryInput
                type="text"
                name="description"
                value={question.description}
                onChange={handleText}
                text={<Typography.MicroText>Description</Typography.MicroText>}
              />
            </Container>
          </div>

          <Container className={"space-y-1"}>
            <div>
              <Typography.MicroText className="font-semibold">Icon number</Typography.MicroText>
              <Typography.MicroText>Set number of icons to display</Typography.MicroText>
            </div>

            <div className="flex space-x-4  justify-between  items-center w-[100%]">
              <div className="w-[45%]">
                <SelectInput
                  options={option1}
                  value={+question?.options?.min.value}
                  name="value"
                  onChange={(e) => handleLikertChange(e, "likertMin")}
                />
              </div>

              <p className="text-xs">to</p>

              <div className="w-[45%]">
                <SelectInput
                  options={option2}
                  value={+question?.options?.max.value}
                  name="value"
                  onChange={(e) => handleLikertChange(e, "likertMax")}
                />
              </div>
            </div>

            <PrimaryInput
              type="text"
              value={question?.options?.min.label}
              name="label"
              onChange={(e) => handleLikertChange(e, "likertMin")}
              text={<Typography.MicroText>{question?.options?.min.value}</Typography.MicroText>}
              placeholder="Add label (Optional)"
            />

            <PrimaryInput
              type="text"
              value={question?.options?.max.label}
              name="label"
              onChange={(e) => handleLikertChange(e, "likertMax")}
              text={<Typography.MicroText>{question?.options?.max.value}</Typography.MicroText>}
              placeholder="Add label (Optional)"
            />
          </Container>

          {/* <div >
            <Container>

            </Container>
          </div> */}
        </div>
      );
    default:
  }
};

export const questionIcon = (value) => {
  switch (value) {
    case "text_response":
      return (
        <div className="space-y-[3px]">
          <div className="w-[22px] h-[2px] rounded bg-gray-700"></div>
          <div className="flex items-center space-x-[2px]">
            <span className="w-[8px] h-[2px] rounded bg-gray-700"></span>
            <span className="w-[5px] h-[2px] rounded bg-gray-700"></span>
          </div>
        </div>
      );

    case "single_choice":
      return <FaRegCircleDot className="text-gray-700" />;

    case "multi_choice":
      return (
        <div>
          <TfiMenuAlt className="text-gray-700" />
        </div>
      );

    case "drop_down":
      return (
        <div>
          <IoCaretDown className="text-gray-700" />
        </div>
      );
    case "date":
      return <BsCalendar2Date className="text-gray-700" />;

    case "email":
      return <MdOutlineMail className="text-gray-700" />;

    case "location":
      return <MdOutlineLocationOn className="text-gray-700" />;

    case "rating":
      return <FaStar className="text-gray-700" />;

    case "likert":
      return <PiSlidersHorizontalFill className="text-gray-700" />;

    case "ranking":
      return <PiArrowsDownUpFill className="text-gray-700" />;

    case "matrix":
      return <RiDashboardFill className="text-gray-700" />;

    case "image":
      return <BsImage className="text-gray-700" />;

    case "video":
      return <MdOutlineVideoLibrary className="text-gray-700" />;

    default:
  }
};

export const questionTag = (value) => {
  switch (value) {
    case "text_response":
      return "Text Response";

    case "single_choice":
      return "Single choice";

    case "multi_choice":
      return "Multi choice";

    case "rating":
      return "Rating";
    case "email":
      return "Email";
    case "date":
      return "Date";
    case "location":
      return "Location";

    case "likert":
      return "Likert scale";
    default:
  }
};
