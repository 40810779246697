import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchWorkspace } from "services/workspaceService";

const useWorkspace = () => {
  const { data } = useSuspenseQuery({ queryKey: ["defaultWorkspace"], queryFn: fetchWorkspace });

  return {
    data,
  };
};

export default useWorkspace;
