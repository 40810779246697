import React from "react";
import Skeleton from "react-loading-skeleton";

const EmptyStateSkeleton = () => {
  return (
    <div className="bg-white rounded-md border-[1px] p-4">
      <Skeleton className="h-[100px]" />
      <Skeleton className="h-[80px]" />
      <Skeleton className="h-[50px]" />
      <Skeleton className="h-[30px]" />
      <Skeleton className="h-[20px]" />
    </div>
  );
};

export default EmptyStateSkeleton;
