import useUserPlanStore from "store/useUserPlanStore";

export const hasFeatureAccess = (feature) => {
  const { features, loading, error } = useUserPlanStore.getState();

  if (loading) {
    console.log("Loading user plan...");
    return false;
  }

  if (error) {
    console.error("Error loading user plan:", error);
    return false;
  }

  return !!features[feature]; // Check if the feature is enabled
};
