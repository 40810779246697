import { useMutation, useQuery } from "@tanstack/react-query";
import SecondaryInput from "components/Inputs/SecondaryInput";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useFormik } from "formik";
import { deactivateWorkspace, editWsName, fetchWorkspace, getAllWorkspace } from "services/workspaceService";
import { uploadWorkspaceImage } from "utils/upload";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";
import PreLoader from "components/Loader/PreLoader";
import BlueButton from "components/Button/BlueButton";
import DeactivateModal from "./DeactivateModal";

const General = () => {
  const { data, isFetching: fetchingImage } = useQuery({ queryKey: ["defaultWorkspace"], queryFn: fetchWorkspace });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const { data: getWs, isLoading } = useQuery({ queryKey: ["allWorkspaces"], queryFn: getAllWorkspace });
  const { user } = useLoggedInUser();

  const { mutate: UploadImage, isLoading: loadingImage } = useMutation({
    mutationFn: uploadWorkspaceImage,
    onSuccess: () => {
      Toast.success("Profile image updated successfully");
      queryClient.invalidateQueries({ queryKey: ["defaultWorkspace"] });
      trackEvent("workspace image upload successfully", {
        email: user?.data?.email,
        settingSection: "Update workspace image",
        attributeUpdated: "update workspace image",
        date: dayjs().format(),
      });
    },
    onError: () => {
      trackEvent("workspace image upload unsuccessfully", {
        email: user?.data?.email,
        settingSection: "Update workspace image",
        attributeUpdated: "update workspace image",
        date: dayjs().format(),
      });
    },
  });

  const { mutate: deleteWS } = useMutation({
    mutationFn: deactivateWorkspace,
    onSuccess: () => {
      toggleModal();
      navigate("/organization");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: editWs } = useMutation({
    mutationFn: editWsName,
    onSuccess: () => {
      Toast.success("Workspace name updated successfully");
      queryClient.invalidateQueries({ queryKey: ["defaultWorkspace"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const toggleModal = () => {
    setOpen(!open);
  };

  const initialValues = {
    workspaceName: data?.name || "",
  };

  const onSubmit = async (data) => {
    const userData = {
      ...data,
    };
    editWs(userData);
  };

  const deactivateWs = (name) => {
    const payload = { workspaceIds: [data.id] };
    deleteWS(payload);

    trackEvent("deactivate_workspace", {
      email: user?.data?.email,
      date: dayjs().format(),
    });
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    // validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    const checkActive = () => {
      if (data?.name !== values.workspaceName) {
        setActive(true);
      } else {
        setActive(false);
      }
    };

    checkActive();
  }, [data?.name, values.workspaceName]);

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      console.error("Please select only one image");
    } else {
      try {
        let formData = new FormData();
        formData.append("profile", e.target.files[0]);
        UploadImage(formData);
      } catch (error) {
        Toast.error(error);
      }
    }
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="bg-white p-5 h-[calc(100vh-150px)] overflow-y-auto">
      <div>
        <p className="font-[500] text-[20px]">Details</p>
        <p className="text-[14px] pb-1">View and update your workspace details</p>
      </div>
      <hr />
      <p className="text-[#292D32] text-m font-[500] tracking-[-0.02em] mt-10">Workspace logo</p>

      <div>
        <div className="flex space-x-4 my-[1em]">
          <div className="min-w-[96px] h-[96px] rounded-full border-[1px] overflow-hidden flex justify-center items-center">
            {loadingImage || fetchingImage ? (
              <>
                <div className="bg-white flex justify-center items-center w-full ">
                  <ScaleLoader height="20px" width={1} margin={1} color="#29085C" />
                </div>
              </>
            ) : (
              <>
                {data?.wsProfile === null || data?.wsProfile === undefined ? (
                  <p className="bg-[#E5E5E5] rounded-full min-w-[96px] h-[96px] flex items-center justify-center text-[25px] font-[700]">
                    {data?.name?.slice(0, 2).toUpperCase()}
                  </p>
                ) : !data?.wsProfile?.includes("https://") ? (
                  <div className="h-[96px] w-[96px] overflow-hidden rounded-full">
                    <img src={`https://${data?.wsProfile}`} alt="" className="w-[100%] h-[100%] object-cover" />
                  </div>
                ) : (
                  <p className="bg-[#E5E5E5] rounded-full min-w-[96px] h-[96px]  flex items-center justify-center text-[25px] font-[700]">
                    {data?.name?.slice(0, 2).toUpperCase()}
                  </p>
                )}
              </>
            )}
          </div>

          <div>
            <div className="h-[33px] w-[130px] rounded-[6px] font-semibold flex items-center  justify-center text-primary-800 hover:text-primary-600 relative bg-primary-100 hover:shadow-lg">
              <p className="text-ss relative   flex items-center justify-center ">Update new image</p>
              <input
                type="file"
                name=""
                id=""
                onChange={onSelectFile}
                accept=".jpg, .jpeg, .png"
                className="absolute cursor-pointer top-0 left-0 opacity-0  w-[100%] h-[100%]  bottom-0 "
              />
            </div>

            <p className="text-[#737373] text-ss font-[450] mt-[1em]">Must be JPEG or PNG and cannot exceed 5mb</p>
            <p className="text-[#737373] text-ss font-[450]">Maximum size: 1152 x 720px</p>
          </div>
        </div>

        {/* <PrimaryButton text="Save " css="ml-2 bg-primary-800 text-white h-[40px] text-m font-[450]" /> */}
      </div>

      <div className="">
        <div className="w-[310px] mt-[2em]">
          <SecondaryInput
            text="Workspace name"
            placeholder="Enter Workspace name"
            onChange={handleChange}
            name="workspaceName"
            type="text"
            value={values.workspaceName}
          />
        </div>
        <p className="text-[12px]">workspace name must not exceed 11 characters</p>
        <div className="mt-5">
          <BlueButton text="Save" disabled={!active || values.workspaceName.length > 11} onClick={handleSubmit} />
        </div>

        {/* <p

          className={`mt-6 ${active ? "text-primary-800" : "text-gray-400"} text-s cursor-pointer`}
          onClick={active ? handleSubmit : null}
        >
          Save
        </p> */}
      </div>

      {getWs?.length === 1 ? null : (
        <>
          <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[2em]"></div>

          <p className="text-[#292D32] font-semibold text-[20px]">Deactivate workspace</p>
          <p className="text-[#737373] text-s font-[450]">
            This action cannot be undone. To reactivate your account, please contact
            <a href="mailto:support@mooyi.africa" className="text-primary-800 underline underline-offset-4">
              {" "}
              support
            </a>{" "}
          </p>

          <div
            className="text-white bg-[#EF4444] hover:bg-[#d31212] rounded-[4px] h-[40px] w-[180px] flex items-center justify-center text-s mt-[1em] cursor-pointer"
            onClick={toggleModal}
          >
            <p>Deactivate workspace</p>
          </div>
        </>
      )}

      {open ? (
        <DeactivateModal toggleModal={toggleModal} deactivateWs={deactivateWs} workspaceName={data?.name} />
      ) : null}
    </div>
  );
};

export default General;
