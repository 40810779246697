import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getAllEmailId, getAllSMSId } from "services/senderIdService";
import { getWorkspaceCredit, getWorkspaceUsers, workspaceUsage } from "services/workspaceService";
import Campaign from "./Campaigns";
import Data from "./Data";
import General from "./General";
import Members from "./Members";
import SenderID from "./SenderID";
import { useSearchParams } from "react-router-dom";
import { getWorkspaceToken } from "helpers/authHelper";

const tabs = [
  { name: "General settings", value: 0 },
  { name: "Team settings", value: 1 },
  { name: "Messaging settings ", value: 2 },
];

const Workspace = () => {
  const [step, setStep] = useState(0);
  const wsToken = getWorkspaceToken();
  const { data: sms, isLoading: smsLoading } = useQuery({ queryKey: ["smsSenderIDs"], queryFn: getAllSMSId });
  const { data: email, isLoading: emailLoading } = useQuery({ queryKey: ["emailSenderIDs"], queryFn: getAllEmailId });
  const { data: wsCredit, isLoading: wsLoading } = useQuery({
    queryKey: ["wsCredit"],
    queryFn: getWorkspaceCredit,
    enabled: !!wsToken,
  });
  const [searchBarParams, setSearchBarParams] = useSearchParams();
  const { data: wsUsage } = useQuery({ queryKey: ["wsUsage"], queryFn: workspaceUsage });
  const { data: wsUser } = useQuery({ queryKey: ["workspaceUsers"], queryFn: getWorkspaceUsers });

  const displayStep = () => {
    switch (step) {
      case 0:
        return <General />;
      case 1:
        return <Members data={wsUser} />;
      case 2:
        return <Campaign wsCredit={wsCredit} wsUsage={wsUsage} isLoading={wsLoading} />;
      case 3:
        return <SenderID sms={sms} email={email} smsLoading={smsLoading} emailLoading={emailLoading} />;
      case 4:
        return <Data />;
      default:
    }
  };

  useEffect(() => {
    if (searchBarParams.get("g") === "invite") {
      setStep(1);
    } else if (searchBarParams.get("reRoute")) {
      setStep(2);
    }
  }, [searchBarParams]);

  const handleTabChange = (e) => {
    setStep(e);
    setSearchBarParams("");
  };
  return (
    <div>
      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[4px] px-2 cursor-pointer ${
                step === el.value ? "border-b-[2px] text-[#0F172A] border-purple font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.value !== 4 ? () => handleTabChange(el.value) : null}
              key={el.value}
            >
              {el.name}
            </p>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
    </div>
  );
};

export default Workspace;
