import { formatDate } from "lib/formatDate";
import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import tagEmpty from "assets/icons/contacts/TagsEmpty.png";
import EmptyState from "pages/Contacts/EmptyState";
import { BsSearch } from "react-icons/bs";
import { MessagingIcon } from "assets/icons/sidebar";
import { Volume } from "assets/svgs/Volume";
import { SmsIcon } from "assets/svgs/SmsIcon";
import { Delete } from "assets/icons/contacts/Delete";
import DeleteContact from "pages/Contacts/DeleteContact";
import { GoArrowDown } from "react-icons/go";
import { useMutation } from "@tanstack/react-query";
import { deleteTag } from "services/contactService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import Pagination from "components/Pagination";

const actionsIcons = [
  { id: 1, icon: MessagingIcon, label: "Email" },
  { id: 2, icon: SmsIcon, label: "SMS" },
  { id: 3, icon: Volume, label: "Voice" },
  { id: 4, icon: Delete, label: "Delete" },
];

const Tags = ({ tag, isLoading, isFetchingTags, tagPage, setTagPage, tagPageCount, setTagPageCount, dataCount }) => {
  const navigate = useNavigate();
  const [selectTagArray, setSelectTagArray] = useState([]);
  const [selectTagObj, setSelectTagObj] = useState([]);
  const [tableData, setTableData] = useState();
  const [active, setActive] = useState(false);
  const [openModal, setopenModal] = useState(null);
  const [query, setQuery] = useState("");
  const [hover, setHover] = useState(null);

  const handleHover = (id) => {
    setHover(hover === id ? null : id);
  };

  console.log(dataCount);

  const { mutate: deleteTags, isLoading: loadingTagDelete } = useMutation({
    mutationFn: deleteTag,
    onSuccess: () => {
      Toast.success("Tag deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["allTags"] });
      handleModal();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const stringArray = JSON.stringify(selectTagObj);

  const selectAll = (e) => {
    if (e.target.checked) {
      tag?.map((el) => selectTagArray.push(el.name));
      for (let i = 0; i < tag?.length; i++) {
        setSelectTagArray([...selectTagArray, tag[i]?.name]);
      }
    } else {
      setSelectTagArray([]);
    }
  };

  const checkArray = useCallback(() => {
    if (selectTagArray?.length > 0) {
      setActive(true);
    } else if (selectTagArray?.length <= 0) {
      setActive(false);
    }
  }, [selectTagArray?.length, setActive]);

  const checkInArray = (y) => {
    let checked = false;
    for (let x in selectTagArray) {
      if (selectTagArray[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectTagArray([...selectTagArray, x?.name]);
      setSelectTagObj([...selectTagObj, x]);
    } else {
      const filterArray = selectTagArray?.filter((el) => el !== x?.name);
      setSelectTagArray(filterArray);
    }
  };

  const handleModal = (id) => {
    id === 2 && active
      ? navigate("/broadcasts/sms/create")
      : id === 3 && active
      ? navigate("/broadcasts/voice/create")
      : setopenModal(openModal !== id && active ? id : null);
    localStorage.setItem("tagData", stringArray);
  };

  const deleteTagFunc = () => {
    const payload = { name: selectTagArray };
    deleteTags(payload);
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const searchFilter = useCallback(
    (searchTerm) => {
      const dataSet = tag?.filter((item) => {
        const content = item?.name?.toLowerCase();
        return content?.includes(searchTerm?.toLowerCase());
      });

      setTableData(dataSet);
    },
    [tag],
  );

  useEffect(() => {
    searchFilter(query);
  }, [query, searchFilter]);

  useEffect(() => {
    checkArray();
  }, [checkArray]);

  const handleMore = ({ id, name }) => {
    navigate(`/contacts/tag/${id}/${name}`);
  };

  const columns = [
    {
      field: "inputProps",
      header: (
        <p className="pl-0 flex items-center justify-center">
          <input
            type="checkbox"
            name="inputProps"
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={(e) => selectAll(e)}
          />
        </p>
      ),
    },
    { field: "tagName", header: "Tag name" },
    { field: "contactSize", header: "Contact size" },
    {
      field: "dateCreated",
      header: (
        <p className="flex items-center space-x-5">
          {" "}
          <span className="font-[700]">Date created</span> <GoArrowDown />
        </p>
      ),
    },
    {
      field: "lastUpdated",
      header: (
        <p className="flex items-center space-x-5 ">
          {" "}
          <span className="font-[700]">Last Updated</span> <GoArrowDown />
        </p>
      ),
    },
  ];

  const tagData = tableData?.map((item, i) => ({
    inputProps: (
      <p className="absolute mx-auto left-0 right-0 flex justify-center items-center w-[100%] h-[100%] top-0 z-[100]">
        <input
          type="checkbox"
          name="recipient"
          id="recipient"
          checked={checkInArray(item?.name, selectTagArray)}
          className="pl-0 w-[15px] h-[15px] accent-purple"
          onChange={(e) => handleCheck(e, item)}
        />
      </p>
    ),
    id: item._id,
    tagName: (
      <p className="hover:underline cursor-pointer" onClick={() => handleMore({ id: item?._id, name: item.name })}>
        {item.name}
      </p>
    ),
    contactSize: item.contactCount,
    dateCreated: formatDate(item.created_at),
    lastUpdated: item.lastUpdated,
  }));

  return (
    <div>
      <div className="p-4 flex items-center justify-between relative border-x">
        <div className="flex items-center space-x-2">
          <p>Actions:</p>
          <div className="flex items-center space-x-2">
            {actionsIcons.map((el, i) => {
              const IconComponent = el.icon;
              return (
                <div
                  onClick={() => handleModal(el.id)}
                  onMouseEnter={() => handleHover(el.id)}
                  onMouseLeave={() => setHover(null)}
                  className={`w-[40px] h-[40px] cursor-pointer rounded-[4px] border ${
                    active ? "border-primary-800" : ""
                  } flex items-center justify-center`}
                >
                  <IconComponent fill1={active ? "#95ADFF" : "#A3A3A3"} fill2={active ? "#1D19AF" : "#404040"} />
                  {hover === el.id ? (
                    <p
                      className={`bg-white drop-shadow-md text-[12px] ${
                        active ? "text-primary-800" : ""
                      } py-1 px-2 top-12 rounded absolute`}
                    >
                      {el.label}
                    </p>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search tags... "
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <table className="w-[100%] mb-4 border-b-[1px] bg-white">
        <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
          <tr className="h-[50px] border-r-[1px] rounded-[2px] overflow-hidden">
            {columns &&
              columns.map((head, i) => (
                <th key={i} className="px-2 text-left border-l-[1px]">
                  {head.header}
                </th>
              ))}
          </tr>
        </thead>
        {tagData?.length === 0 || tagData?.length === undefined ? null : (
          <tbody>
            {isLoading || isFetchingTags || loadingTagDelete ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                  <tr
                    key={i}
                    className="h-[50px]  text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px]  "
                  >
                    {columns?.map((col, i) => (
                      <td key={i} className="pl-2 border-l-[1px] relative">
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <>
                {tagData &&
                  tagData?.map((row, i) => (
                    <tr
                      className="h-[50px] text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px] hover:bg-slate-50 cursor-pointer"
                      key={i}
                    >
                      {columns?.map((col, j) => (
                        <td key={j} className="px-4 border-l-[1px] relative">
                          {row[col.field] || "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        )}
      </table>
      {dataCount > tagPageCount && (
        <Pagination
          data={tagData}
          dataCount={dataCount}
          itemsPerPage={tagPageCount}
          setItemsPerPage={setTagPageCount}
          currentPage={tagPage}
          setCurrentPage={setTagPage}
        />
      )}

      {tagData?.length === 0 || tagData?.length === undefined ? (
        <tbody className="flex justify-center w-full mt-20">
          <EmptyState
            title="No Tags Yet."
            icon={tagEmpty}
            content={
              <p>
                Tags are like labels. Use them to categorise and organise your contacts. <br /> Click 'create new Tag'
                to create one.{" "}
              </p>
            }
            link="/contacts/tag/create"
            button="Create new Tag"
          />
        </tbody>
      ) : null}
      {openModal === 4 ? (
        <DeleteContact
          toggleModal={handleModal}
          deleteContactsFunc={deleteTagFunc}
          title="Delete tag(s)"
          content="Are you sure you want to delete? You cannot undo this action."
        />
      ) : null}
      {openModal === 1 ? navigate(`/broadcasts/email/setup`) : null}
    </div>
  );
};

export default Tags;
