import { useQuery } from "@tanstack/react-query";
const { getAllEmailId } = require("services/senderIdService");

const useEmailSenderId = () => {
  const { data: emailData, isLoading: emailLoading } = useQuery({
    queryKey: ["emailSenderIDs"],
    queryFn: getAllEmailId,
  });

  const emailOptions = emailData?.map((el) => ({
    value: `${el?.name}${" "}${el?.email}`,
    label: <>{`${el?.name}<${el?.email}>`}</>,
  }));

  return {
    emailData,
    emailOptions,
    emailLoading,
  };
};

export default useEmailSenderId;
