export const featureMatrix = {
  //true means is not to be gated, and false means to be gated
  free: {
    onlineSurvey: false,
    brandTracking: true,
    fieldInterview: true,
  },
  sme: {
    onlineSurvey: false,
    brandTracking: true,
    fieldInterview: true,
  },
  business: {
    onlineSurvey: false,
    brandTracking: true,
    fieldInterview: true,
  },
  pro: {
    onlineSurvey: false,
    brandTracking: false,
    fieldInterview: false,
  },
};
