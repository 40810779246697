import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import SecondaryInput from "components/Inputs/SecondaryInput";

import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useFormik } from "formik";
import React, { useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { updateBusiness } from "services/userService";
import { category, options1, proofData, sizeOptions } from "./data";
import * as Yup from "yup";
import { FiLoader } from "react-icons/fi";
import { TiTick } from "react-icons/ti";
// import { FaTimes } from "react-icons/fa";
import PurpleState from "assets/images/purpledrop.png";
import { uploadDocument } from "utils/upload";
import { BsFileImage } from "react-icons/bs";
import { byteConverter } from "lib/byteConverter";
import SelectInput from "components/Inputs/SelectInput";
import ReasonModal from "./ReasonModal";
import ViewModal from "./ViewModal";
import { urlRegex } from "lib/regex";
import { useStore } from "store";
import DropDownWithSearch from "components/DropDownWithSearch";
import Dropdown from "components/DropDown";
import SocialHandles from "./SocialHandles";

const Company = ({ user }) => {
  const [fileData, setFileData] = useState({
    incopCert: user?.data?.incopCert?.pathToFile,
    proofOfBusAdd: user?.data?.proofOfBusAdd?.pathToFile,
    incopCertName: "",
    proofOfBusAddName: "",
    fileType: user?.data?.proofOfBusAdd?.documentType,
  });
  const { onBoarding } = useStore();
  const [url, setUrl] = useState("");

  console.log(onBoarding);

  const [title, setTitle] = useState("");
  const [openModal, setOpenModal] = useState();

  const { mutate } = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      Toast.success("Company information updated successfully");
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const initialValues = {
    businessName: user?.data?.business?.businessName || "",
    companySize: user?.data?.business?.companySize || "0-9 employees",
    industry: user?.data?.business?.industry || "agriculture",
    companyAddress: user?.data?.business?.companyAddress || "",
    websiteUrl: user?.data?.business?.websiteUrl || "",
    rcNumber: user?.data?.rcNumber?.value || "",
    category: user?.data?.business?.category || "",
  };

  const validationSchema = Yup.object({
    websiteUrl: Yup.string().matches(urlRegex, "URL is not valid"),
  });

  const toggleModal = (id, x, y) => {
    setOpenModal(openModal === id ? null : id);
    if (id === 2) {
      setUrl(x);
      setTitle(y);
    }
  };

  const onSubmit = async (data) => {
    const userData = {
      ...data,
    };
    mutate(userData);
  };

  const saveDoc = () => {
    const userData = {
      incopCert: fileData.incopCert,
      proofOfBusAdd: {
        path: fileData.proofOfBusAdd,
        documentType: fileData.fileType,
      },
      rcNumber: +values.rcNumber,
    };

    mutate({ complianceDocuments: userData });
  };

  const { handleChange, values, handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const checkSelect = async (e, x) => {
    let file = e.target.files[0];
    let formData = new FormData();
    if (x === "incopCert") {
      setFileData({
        ...fileData,
        incopCertName: file,
      });
      formData.append("document", file);
      const response = await uploadDocument(formData);
      setFileData({ ...fileData, incopCert: `https://${response?.data?.filePath}`, incopCertName: file });
    } else if (x === "proofOfBusAdd") {
      setFileData({
        ...fileData,
        proofOfBusAddName: file,
      });
      formData.append("document", file);
      const response = await uploadDocument(formData);
      setFileData({ ...fileData, proofOfBusAdd: `https://${response?.data?.filePath}`, proofOfBusAddName: file });
    }
  };

  const checkCompliance = (x) => {
    console.log("compliance:", typeof x);
    if (!x) {
      return (
        <div className="bg-[#FFFAEB] text-[#B54708] min-w-[95px] h-[24px] flex items-center justify-center rounded-[30px] space-x-1">
          <p className="text-s">Pending</p>
          <FiLoader className="text-ss" />
        </div>
      );
    }
    return (
      <div className="bg-[#ECFDF3] text-[#027A48] min-w-[95px] h-[24px] flex items-center justify-center rounded-[30px] space-x-1">
        <p className="text-s">Verified</p>
        <TiTick className="text-ss" />
      </div>
    );
  };
  return (
    <div className="bg-white p-5 rounded-md">
      <p className="text-[#292D32] font-semibold text-[20px]">Details</p>
      <p className="text-[#737373] text-s font-[450] py-2">View and update your company information</p>
      <hr />

      <form action="" onSubmit={handleSubmit} className="w-[80%] mt-[1em]">
        <div className="grid grid-cols-2 gap-4">
          <SecondaryInput
            text="Company name"
            placeholder="Enter Company name"
            onChange={handleChange}
            name="businessName"
            type="text"
            value={values.businessName}
          />

          <SecondaryInput
            text="Company address"
            placeholder="Enter Company address"
            onChange={handleChange}
            name="companyAddress"
            type="text"
            value={values.companyAddress}
          />

          <div>
            <p className="text-[#292D32] text-m font-[500] pb-2"> Industry</p>
            <DropDownWithSearch
              tag={values.industry || "Select an option"}
              options={options1}
              onSelect={(select) => setFieldValue("industry", select)}
              name="industry"
              value={values.industry}
            />
          </div>

          <div>
            <p className="text-[#292D32] text-m font-[500] pb-2"> Category (Optional)</p>
            <DropDownWithSearch
              // text="Category (optional)"
              tag={values.category || "Select an option"}
              options={category}
              onSelect={(select) => setFieldValue("category", select.value)}
              name="category"
              value={values.category}
            />
          </div>
          <div>
            <p className="text-[#292D32] text-m font-[500] pb-2">Company size</p>
            <Dropdown
              tag={values.companySize || "Select an option"}
              options={sizeOptions}
              onSelect={(select) => setFieldValue("companySize", select)}
              name="companySize"
              value={values.companySize}
            />
          </div>

          <SecondaryInput
            text="Website URL"
            placeholder="Enter Website URL"
            onChange={handleChange}
            name="websiteUrl"
            type="text"
            value={values.websiteUrl}
            error={getError("websiteUrl")}
          />
        </div>

        <div className="mt-[1em]">
          <BlueButton text="Update" />
        </div>
      </form>

      <div className=" mt-[1em] mb-[3em]">
        <hr />
      </div>

      <div>
        <SocialHandles />
      </div>
      <div className=" mt-[1em] mb-[3em]">
        <hr />
      </div>

      <p className="text-[#292D32] font-semibold text-[20px] flex items-center">
        Compliance & Verification<span className="pl-2">{checkCompliance(onBoarding.compliance)}</span>
      </p>
      <p className="text-[#737373] text-s font-[450] my-2">Provide your company registration information below.</p>
      <hr />

      <div>
        <div className="w-[80%] mt-[2em]">
          <div className="grid grid-cols-2 gap-4">
            <div className="mt-2">
              <p className="text-[#292D32] text-m font-[500] tracking-[-0.02em]">Certificate of Incorporation</p>

              <div className="flex space-x-2 items-center">
                <div className="flex space-x-4 items-center my-2 w-full">
                  {!fileData.incopCertName && fileData.incopCert === undefined ? (
                    <div className="bg-[#FAFAFA] h-[40px] border-[1px] border-[#DFE5EC] rounded-[8px]  w-full flex items-center px-[1em]">
                      <div className="border-[1px] border-[#DFE5EC] shadow-xl bg-white w-[100px] h-[30px] flex items-center justify-center rounded-[5px] relative text-ss overflow-hidden">
                        <p className="font-[500]">Choose file</p>
                        <input
                          type="file"
                          name="incopCert"
                          id=""
                          accept=".jpg, .jpeg, .png"
                          className="text-ss absolute top-0 left-0 bottom-0 right-0 opacity-0"
                          onChange={(e) => checkSelect(e, "incopCert")}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex  w-full  border-[1px] border-[#DFE5EC] rounded-[8px]  h-[60px] items-center px-4 justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="bg-[#29085C1A] text-primary-800 w-[40px] h-[40px] rounded-full flex items-center justify-center ">
                            <BsFileImage />
                          </div>

                          <div>
                            <p className="text-s ">
                              {fileData.incopCert === ""
                                ? fileData?.incopCertName?.name?.substring(0, 40)
                                : fileData?.incopCert?.substring(0, 40)}
                              {fileData.incopCert ? "..." : <ScaleLoader width={2} height={15} color="#29085C" />}
                            </p>
                            <p className="text-ss text-[#A3A3A3]">{byteConverter(fileData?.incopCertName?.size)}</p>
                          </div>
                        </div>

                        <p
                          className="text-s text-primary-800 cursor-pointer"
                          onClick={() => toggleModal(2, fileData?.incopCert, "Certificate of Incorporation")}
                        >
                          View
                        </p>
                      </div>
                    </>
                  )}

                  {onBoarding.compliance === false && fileData.incopCert ? (
                    <p
                      className="text-s text-primary-800 cursor-pointer"
                      onClick={() =>
                        setFileData((prevState) => ({ ...prevState, incopCert: undefined, incopCertName: undefined }))
                      }
                    >
                      Remove
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                {user?.data?.incopCert?.status === "rejected" ? (
                  <p
                    className="text-s text-primary-800 min-w-[100px] underline underline-offset-4 cursor-pointer"
                    onClick={() => toggleModal(1)}
                  >
                    See reason
                  </p>
                ) : null}
              </div>

              {/* <p>{fileData.incopCertName}</p> */}
            </div>

            <div className="flex space-x-4 items-center w-full">
              <div className="w-full">
                <SecondaryInput
                  text="RC Number (Optional)"
                  placeholder="RC Number (Optional)"
                  onChange={handleChange}
                  name="rcNumber"
                  type="text"
                  value={values.rcNumber}
                />
              </div>

              <div className="flex items-center justify-center h-[100%] mt-[1.2em]">
                {/*} {checkStatus(user?.data?.rcNumber?.status)}*/}
              </div>
            </div>
            <div>
              <p>Proof of address</p>
              <div className="flex space-x-2 items-center w-full">
                <div className="flex justify-between items-center w-full ">
                  <div className="w-full">
                    <SelectInput
                      options={proofData}
                      onChange={(e) =>
                        setFileData({
                          ...fileData,
                          fileType: e.target.value,
                        })
                      }
                      css="w-full"
                      value={fileData.fileType}
                      name="fileType"
                    />
                  </div>

                  <div className="flex items-center justify-center h-[100%]">
                    {/*{checkStatus(user?.data?.proofOfBusAdd?.status)}*/}
                  </div>
                </div>

                {user?.data?.proofOfBusAdd?.status === "rejected" ? (
                  <p
                    className="text-s text-primary-800 min-w-[100px] underline underline-offset-4 cursor-pointer"
                    onClick={() => toggleModal(1)}
                  >
                    See reason
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[60%]">
          <div className="flex items-center">
            {!fileData.proofOfBusAddName && fileData.proofOfBusAdd === undefined ? (
              <div className="border-[1px] border-dashed border-[#D0D5DD] rounded-[8px] h-[120px] w-[500px] mt-2 bg-[#FAFAFA] flex flex-col items-center text-ss justify-center text-[#737373] space-y-2 relative">
                <img src={PurpleState} alt="PurpleState" className="w-[60px]" />
                <p>Drag and drop PNG or JPEG file or browse computer </p>
                <p>Max file size: 10MB</p>
                <input
                  type="file"
                  name="proofOfBusAdd"
                  id=""
                  className="text-ss absolute top-0 left-0 bottom-0 right-0 opacity-0"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => checkSelect(e, "proofOfBusAdd")}
                />
              </div>
            ) : (
              <div className="flex  w-[500px]  border-[1px] border-[#DFE5EC] rounded-[8px]  h-[60px] items-center px-4 justify-between">
                <div className="flex items-center space-x-4">
                  <div className="bg-[#29085C1A] text-primary-800 w-[40px] h-[40px] rounded-full flex items-center justify-center ">
                    <BsFileImage />
                  </div>

                  <div>
                    <p className="text-s">
                      {fileData.proofOfBusAdd === ""
                        ? fileData?.proofOfBusAddName?.name?.substring(0, 40)
                        : fileData.proofOfBusAdd?.substring(0, 40)}
                      {fileData.proofOfBusAdd ? "..." : <ScaleLoader width={2} height={15} color="#29085C" />}
                    </p>
                    <p className="text-ss text-[#A3A3A3]">{byteConverter(fileData?.proofOfBusAddName?.size)}</p>
                  </div>
                </div>

                <p
                  className="text-s text-primary-800 cursor-pointer"
                  onClick={() => toggleModal(2, fileData.proofOfBusAdd, "Proof of address")}
                >
                  View
                </p>
              </div>
            )}
            {onBoarding.compliance === false && fileData.proofOfBusAdd ? (
              <p
                className="text-s text-primary-800 cursor-pointer pl-3"
                onClick={() =>
                  setFileData((prevState) => ({
                    ...prevState,
                    proofOfBusAdd: undefined,
                    proofOfBusAddName: undefined,
                  }))
                }
              >
                Remove
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className="mt-[1em]">
        {onBoarding.compliance === false ? (
          <BlueButton text="Save Changes" onClick={saveDoc} />
        ) : (
          <BlueButton text="Save Changes" disabled />
        )}
      </div>

      {openModal === 1 ? <ReasonModal toggleModal={toggleModal} /> : null}
      {openModal === 2 ? <ViewModal onClose={toggleModal} url={url} title={title} /> : null}
    </div>
  );
};

export default Company;
