import { UIConstants } from "data/constants/uiConstants";
import React, { useState } from "react";
import Typography from "utils/typography";
import PreviewCard from "./PreviewCard";
import BottomBar from "./BottomBar";
import previewImage from "assets/images/previewImage.png";
import Toggle from "react-toggle";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Toast from "config/toast";
import PreLoader from "components/Loader/PreLoader";
import { getInsightCost } from "services/insightService";
import { toTitleCase } from "lib/titleCase";
import { publishInsight } from "services/insightService";

const PublishStep = ({ step, setStep }) => {
  const [saveLeads, setSaveLeads] = useState(false);
  const location = useLocation();

  const locationArray = location.pathname.split("/");
  const [params] = useSearchParams();
  const insightId = params.get("insightId");
  const navigate = useNavigate();

  const handleToggles = (value) => {
    setSaveLeads(value);
  };

  const { data: insightCost, isLoading } = useSuspenseQuery({
    queryKey: ["getInsightCost", insightId],
    queryFn: () => getInsightCost(insightId),
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { isPending: publishLoading, mutate } = useMutation({
    mutationKey: ["publishInsight"],
    mutationFn: publishInsight,
    onSuccess: () => {
      Toast.success("Insight published successfully");

      if (locationArray.includes("nps")) {
        navigate(`/insights/success?insightType=nps&insightId=${insightId}`);
      } else if (locationArray.includes("csat")) {
        navigate(`/insights/success?insightType=csat&insightId=${insightId}`);
      } else {
        navigate(`/insights/success?insightType=customer-profile&insightId=${insightId}`);
      }

      setStep(step + 1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  console.log(insightCost?.publishCache.cost, "insightCost");

  const handlePublish = () => {
    mutate(insightId);
  };

  if (publishLoading || isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="h-[70vh] flex items-start gap-[2em]">
      <div className={`w-[50%] h-[100%] overflow-y-auto ${UIConstants.customScrollBarClassNames}`}>
        <p className="font-semibold">Publish</p>
        <p className="text-sm text-gray-600">See how your survey will appear to respondents before publishing it.</p>

        <div className="border-[1px] rounded p-4 mt-4">
          <div className="flex items-center space-x-4">
            <div className="w-[70px] h-[40px] overflow-hidden border-[1px] rounded-md">
              <img src={"" || previewImage} className="w-[100%] h-[100%] object-cover text-[6px]" alt="SurveyImage" />
            </div>

            <div>
              <Typography.Text>{insightCost?.insightName}</Typography.Text>

              <Typography.SubText className="text-gray-600 uppercase">{insightCost?.insightType}</Typography.SubText>
            </div>
          </div>
        </div>

        <div className="border-[1px] rounded p-4  mt-4">
          <Typography.SubText className="font-semibold">Summary</Typography.SubText>

          <div className="flex items-center justify-between border-b-[1px] py-4">
            <Typography.SubText className="font-semibold">Total survey units</Typography.SubText>

            <p className="text-xl font-bold text-primary-800">{insightCost?.publishCache.cost}</p>
          </div>

          <div className="flex items-center justify-between border-b-[1px] py-4 text-sm text-gray-700">
            <Typography.SubText className="font-semibold">Audience</Typography.SubText>

            <div className="flex items-center space-x-2">
              {insightCost?.insightAudience?.contactTag.map((el) => (
                <Typography.MicroText key={`contactTag-${el}`} className="bg-gray-100 px-2 py-2 rounded">
                  {el}
                </Typography.MicroText>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-between border-b-[1px]   py-4 text-sm text-gray-700">
            <Typography.SubText className="font-semibold">Frequency</Typography.SubText>

            <p>{insightCost?.insightAudience?.frequencyConfig?.recurringInterval}</p>
          </div>

          <div className="flex items-center justify-between  py-4 pb-2 text-sm text-gray-700">
            <Typography.SubText className="font-semibold">Channel</Typography.SubText>

            <p>{toTitleCase(insightCost?.dispatchMode?.channel)}</p>
          </div>
        </div>

        <div className="border-[1px] rounded p-6 mt-4">
          <div className="flex items-start space-x-3 justify-between  mb-1">
            <div className="space-y-2">
              <p className=" text-sm font-medium">Add respondents to your audience</p>

              <p className="text-xs text-[#737373]">Save all leads automatically to your audience list</p>
            </div>

            <label>
              <Toggle
                checked={saveLeads}
                icons={false}
                onChange={() => handleToggles(!saveLeads)}
                name="saveLeads"
                className="toggle mt-1"
              />
            </label>
          </div>
        </div>
      </div>

      <div
        className={`w-[50%] border-[1px] shadow-md h-[100%] bg-neutral-100  overflow-y-auto ${UIConstants.customScrollBarClassNames}`}
      >
        <div className="h-[50px] border-b-[1px] flex items-center justify-between px-4 bg-white">
          <div className="text-sm flex items-center">
            <Typography.SubText className="font-semibold text-center">Preview</Typography.SubText>
          </div>
        </div>

        <div className="h-[calc(100%-50px)] flex items-center justify-center">
          <PreviewCard />
        </div>
      </div>

      <BottomBar
        step={step}
        setStep={setStep}
        handleSave={handlePublish}
        handleModal={() => {}}
        disableButton={false}
      />
    </div>
  );
};

export default PublishStep;
