export const surveyCategory = [
  { value: "Concept Test", label: "Concept Test" },
  { value: "Ad Test", label: "Ad Test" },
  { value: "Product Test", label: "Product Test" },
  { value: "Customer Profile", label: "Customer Profile" },
  { value: "Jobs to be done", label: "Jobs to be done" },
  { value: "Feedback from local communities", label: "Feedback from local communities" },
  { value: "Competitor Research", label: "Competitor Research" },
  { value: "General market research", label: "General market research" },
  { value: "Customer Feedback ", label: "Customer Feedback" },
  { value: "Event Feedback", label: "Event feedback" },
  { value: "Pricing Research", label: "Pricing Research" },
  { value: "Affiliate Program Feedback", label: "Affiliate Program Feedback" },
  { value: "Others", label: "Others" },
];

export const fieldSurveyCategory = [
  { value: "Market Research", label: "Market Research" },
  { value: "Performance Evaluation", label: "Performance Evaluation" },
  { value: "Customer Satisfaction", label: "Customer Satisfaction" },
  { value: "UX Research", label: "UX Research" },
  { value: "Product Feedback", label: "Product Feedback" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Employee Engagement", label: "Employee Engagement" },
  { value: "Focus Group", label: "Focus Group" },
  { value: "Brand Perception", label: "Brand Perception" },
  { value: "Public Opinion", label: "Public Opinion" },
  { value: "Education", label: "Education" },
  { value: "Community Assessment", label: "Community Assessment" },
  { value: "Environmental Awareness", label: "Environmental Awareness" },
  { value: "Tourism", label: "Tourism" },
  { value: "Telecom", label: "Telecom" },
  { value: "Media", label: "Media" },
  { value: "Hotel", label: "Hotel" },
  { value: "Restaurant", label: "Restaurant" },
  { value: "Airline", label: "Airline" },
  { value: "Retail", label: "Retail" },
];
