import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.scss";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/gateway";
import { ToastContainer } from "react-toastify";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ScrollToTop from "components/common/ScrollToTop";
import "react-toastify/dist/ReactToastify.css";
import PreLoader from "components/Loader/PreLoader";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        <ReactQueryDevtools position="top-left" initialIsOpen={false} />
        <Suspense fallback={<PreLoader />}>
          <App />

          <ToastContainer position="bottom-left" reverseOrder={false} hideProgressBar={false} />
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example:
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
