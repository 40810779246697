import React from "react";
import BlueButton from "components/Button/BlueButton";
import owl from "assets/images/contacts/owlnPeople.png";
// import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import { useNavigate } from "react-router-dom";
import GreyButton from "components/Button/GreyButton";

export const CreateSegmentSucess = ({ handleModal }) => {
  const navigate = useNavigate();
  return (
    <Modal title="Segment created" onClose={handleModal}>
      <div className="w-[500px] bg-white">
        <div className="flex justify-center py-10 bg-blue-50 mt-3">
          <div className="w-[208px]">
            <img src={owl} alt="" />
          </div>
        </div>
        <div className="flex justify-center my-5">
          <p className="text-center text-[14px] w-[80%]">Success! Your new segment has been created.</p>
        </div>
        <div className="flex justify-center border-t px-5 pt-5">
          {/* <div></div> */}
          <div className="flex gap-[1em]">
            <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={handleModal} />
            <BlueButton text="Done" type="submit" onClick={() => navigate("/contacts/all-contacts?tab=3")} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
