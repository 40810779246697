import { useMutation, useQuery } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import PreLoader from "components/Loader/PreLoader";
import { queryClient } from "config/gateway";
import { setWorkspaceToken } from "helpers/authHelper";
// import useLoggedInUser from "hooks/useLoggedInUser";
import { useState } from "react";
import { BsThreeDots } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { changeWorkspace, getAllWorkspace } from "services/workspaceService";
import WorkSpaceModal from "./WorkSpaceModal";
import Toast from "config/toast";

const Workspace = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({ queryKey: ["allWorkspaces"], queryFn: getAllWorkspace });
  const {
    data: newWorkspace,
    mutate,
    isLoading: workspaceLoading,
  } = useMutation({
    mutationFn: changeWorkspace,
    onSuccess: () => {
      queryClient.clear();
      setWorkspaceToken(newWorkspace?.token);
      navigate("/dashboard");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const cov = (x) => {
    return `bg-[#${x}]`;
  };

  if (isLoading || workspaceLoading) {
    return <PreLoader text="Loading your workspaces" />;
  }

  const handleChangeWorkspace = (id) => {
    mutate(id);
  };

  return (
    <div>
      <div className="w-[50%] mx-auto mt-[2em]">
        <div className="flex justify-between">
          <p className="text-[#292D32] font-extrabold text-[24px]">My Workspaces</p>
          <BlueButton text="Create" onClick={toggleModal} />
        </div>

        {isLoading ? (
          <div className="w-[100%] flex items-center justify-center h-[50vh]">
            <ScaleLoader height="70px" width={4} margin={4} color="#29085C" />
          </div>
        ) : (
          <>
            {data?.map((el) => {
              return (
                <div
                  key={el._id}
                  className="bg-[#F2F5F8] flex items-center justify-between h-[80px] px-8 rounded-[6px] my-4 cursor-pointer"
                  onClick={() => handleChangeWorkspace(el._id)}
                >
                  <div className="flex ">
                    {el?.wsProfile === null || el?.wsProfile === undefined ? (
                      <p
                        className={`h-[40px] w-[40px] rounded-[5px] text-white ${cov(
                          el?.colour,
                        )} flex items-center justify-center`}
                      >
                        {el?.name?.slice(0, 2).toUpperCase()}
                      </p>
                    ) : !el?.wsProfile?.includes("https://") ? (
                      <div className="h-[40px] w-[40px] rounded-[5px] overflow-hidden shadow-md border-[1px]">
                        <img src={`https://${el?.wsProfile}`} alt="" className="w-[100%] h-[100%] object-cover " />
                      </div>
                    ) : (
                      <p
                        className={`h-[40px] w-[40px] rounded-[5px] text-white ${cov(
                          el?.colour,
                        )} flex items-center justify-center`}
                      >
                        {el?.name?.slice(0, 2).toUpperCase()}
                      </p>
                    )}

                    <div className="ml-4">
                      <p className="text-s font-semibold text-black">{el?.name}</p>
                      <p className="text-ss text-[#737373]">
                        {el?.admins?.length} User{el?.admins?.length > 1 && "s"}
                      </p>
                    </div>
                  </div>

                  <BsThreeDots className="text-[24px]" />
                </div>
              );
            })}
          </>
        )}
      </div>

      {openModal && <WorkSpaceModal toggleModal={toggleModal} />}
    </div>
  );
};

export default Workspace;
