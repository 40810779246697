import React, { useMemo, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineModeEdit } from "react-icons/md";
import { getContactsByTag } from "services/contactService";
import { useQuery } from "@tanstack/react-query";
import Toast from "config/toast";
import AllContacts from "../AllContacts";
import CreateTagModal from "pages/Contacts/CreateTagModal";

const SingleTag = () => {
  const navigate = useNavigate();
  const { name, id } = useParams();
  const [openModal, setopenModal] = useState(null);
  const [contactCurrentPage, setContactCurrentPage] = useState(1);
  const [contactItemPerPage, setContactItemPerPage] = useState(10);
  const [show, setShow] = useState(false);

  const handleModal = (id) => {
    setopenModal(openModal !== id ? id : null);
  };

  // const deleteContactsFunc = (data) => {
  //   deleteMutate({ contactId: data });
  // };

  const {
    data: contact,
    isLoading: loadingContacts,
    isFetching: isFetchingContacts,
  } = useQuery({
    queryKey: ["tagContacts", contactCurrentPage],
    queryFn: () => getContactsByTag(name, contactCurrentPage),
    onError: (error) => {
      Toast.error(error);
    },
    refetchOnMount: true,
  });

  console.log(contact);

  // const { mutate: deleteMutate, isLoading: loadingDelete } = useMutation({mutationFn: deleteContacts,
  //   onSuccess: () => {
  //     Toast.success("Contact(s) deleted successfully!!");
  //     queryClient.invalidateQueries({queryKey:["tagContacts"]);
  //   },
  //   onError: (error) => {
  //     Toast.error(error);
  //   },
  // });

  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = contact?.totalContacts;

    let startValue = (contactCurrentPage - 1) * 10;
    startValue = startValue + 1;

    let endValue = Math.min(contactCurrentPage * 10, totalValue);
    return [startValue, endValue];
  }, [contactCurrentPage, contact?.totalContacts]);

  const handlePrev = () => {
    setContactCurrentPage(contactCurrentPage - 1);
  };

  const handleNext = () => {
    setContactCurrentPage(contactCurrentPage + 1);
  };

  return (
    <div className="border rounded-md px-5 py-5 bg-white">
      <div
        onClick={() => navigate(-1)}
        className="flex items-center space-x-1 my-2 text-primary-800 pl-2 cursor-pointer"
      >
        <FiChevronLeft />
        <p>Back to tags</p>
      </div>
      <div className="flex items-start space-x-2 mt-5">
        <div>
          <p className="text-[24px] font-[500]">{name}</p>
        </div>
        <div
          className="w-[24px] mt-2 h-[24px] rounded-md bg-white border flex items-center justify-center relative"
          onClick={() => handleModal(1)}
        >
          <p
            className="text-gray-500 cursor-pointer "
            onMouseEnter={() => setShow(!show)}
            onMouseLeave={() => setShow(false)}
          >
            <MdOutlineModeEdit />
          </p>
          {show ? (
            <div className="absolute top-5 bg-white border rounded-md text-blue-800 text-xs  py-1 w-[55px] justify-center flex items-center">
              Edit Tag
            </div>
          ) : null}
        </div>
      </div>
      <p>Tag description goes here.</p>
      <div className="mt-5">
        <div className="border-[1px]"></div>
        <AllContacts
          data={contact}
          dataCount={contact?.totalContacts}
          isFetchingContacts={isFetchingContacts}
          currentPage={contactCurrentPage}
          setCurrentPage={setContactCurrentPage}
          handlePrev={handlePrev}
          handleNext={handleNext}
          // loadingDelete={loadingDelete}
          // deleteContactsFunc={deleteContactsFunc}
          setContactItemPerPage={setContactItemPerPage}
          contactItemPerPage={contactItemPerPage}
          tableType={"special"}
        />
      </div>
      {openModal === 1 ? <CreateTagModal closeModal={handleModal} setModal={openModal} id={id} /> : null}
    </div>
  );
};

export default SingleTag;
