import React, { useRef, useState } from "react";
import GreaterThan from "assets/images/greaterThan.svg";
import CreateQuestions from "../components/CreateQuestions";
import TargetingOptions from "../components/TargetingOptions";
import Publish from "../components/Publish";
import { useQuery } from "@tanstack/react-query";
import { fetchBySurveyId } from "services/newSurveyService";
import { useParams } from "react-router-dom";
import BranchingBuilder from "../components/BranchingBuilder";
import PreLoader from "components/Loader/PreLoader";

const tabs = [
  { name: "Create questions", value: 1 },
  { name: "Targeting options", value: 2 },
  { name: "Publish", value: 3 },
];

const FieldInterview = () => {
  const [step, setStep] = useState(1);
  const newCardRef = useRef(null);
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["fetchBySurveyId", id],
    queryFn: () => fetchBySurveyId(id),
    enabled: !!id,
  });

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const branchOptions = data?.data?.surveyDetails?.branchingQuestion;

  const displayStep = () => {
    switch (step) {
      case 1:
        return branchOptions !== undefined && branchOptions?.questionName !== "" ? (
          <BranchingBuilder setNewStep={setStep} newStep={step} />
        ) : (
          <CreateQuestions newCardRef={newCardRef} setNewStep={setStep} newStep={step} />
        );
      case 2:
        return (
          <div>
            <TargetingOptions setStep={setStep} step={step} headingName="Set your targeting criteria" />
          </div>
        );
      case 3:
        return <Publish setStep={setStep} step={step} />;
      default:
    }
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="bg-white p-6 rounded-[8px] border-[1px]">
      <div className={` w-[100%] mx-auto`}>
        <div className="flex space-x-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%] mx-auto">
          {tabs.map((el, i) => {
            return (
              <div
                className="flex items-center justify-between w-[33.3%] cursor-pointer"
                key={el.value}
                onClick={() => moveToStep(el.value)}
              >
                <div className="flex items-center">
                  <p
                    className={`${
                      step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                    } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                  >
                    {el.value}
                  </p>
                  <p
                    className={`px-2 text-s cursor-pointer ${
                      step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                    } `}
                  >
                    {el.name}
                  </p>
                </div>

                {el.value === 3 ? null : <img src={GreaterThan} alt="GreaterThan" />}
              </div>
            );
          })}
        </div>
      </div>

      <div className={`w-[100%] mt-5`}>{displayStep()}</div>
    </div>
  );
};

export default FieldInterview;
