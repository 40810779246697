export function replaceMooyiOptionWithOthers(userData) {
  // Iterate through the user data
  for (let i = 0; i < userData.length; i++) {
    // Check if the current item has the name "MooyiOption"
    if (userData[i].name === "MooyiOption") {
      console.log(userData, "userData");
      // Replace the name with "Others"
      userData[i].name = "Others";

      break; // Assuming there is only one "MooyiOption," you can remove this line if there are multiple
    }
  }

  return userData;
}
