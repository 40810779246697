import React from "react";

export const RewardIcon = ({ fill1, fill2 }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66667 1C4.71202 1 1.5 4.21202 1.5 8.16667C1.5 12.1213 4.71202 15.3333 8.66667 15.3333C12.6213 15.3333 15.8333 12.1213 15.8333 8.16667C15.8333 4.21202 12.6213 1 8.66667 1Z"
      fill={fill1 || "#166534"}
    />
    <path
      d="M13.6449 9.93448C13.8491 9.93448 13.9949 10.1386 13.9191 10.3253C13.0616 12.4836 10.9559 14.0002 8.50011 14.0002C6.03853 14.0002 3.93277 12.4719 3.0753 10.3078C2.99947 10.1211 3.15113 9.91698 3.34945 9.91698H5.29189C5.45522 9.91698 5.58354 9.78865 5.58354 9.62533V8.02705C5.58354 7.4904 6.25435 7.23958 6.60434 7.64789L8.27845 9.64866C8.39511 9.78865 8.61094 9.78865 8.7276 9.64866L10.4017 7.64789C10.7459 7.23958 11.4167 7.4904 11.4167 8.02705V9.62533C11.4167 9.78865 11.545 9.91698 11.7083 9.91698L13.6449 9.93448Z"
      fill={fill2 || "#86EFAC"}
    />
    <path
      d="M2.66674 8.24667C2.66674 8.4275 2.67841 8.60832 2.70174 8.78332C2.71924 8.92914 2.84757 9.03998 2.99923 9.03998H4.41668C4.58001 9.03998 4.70834 8.91165 4.70834 8.74832V8.10667C4.70834 7.51753 5.02333 6.94588 5.55998 6.70089C6.16079 6.42673 6.84327 6.57839 7.27492 7.08588L8.27822 8.28167C8.39488 8.42166 8.6107 8.42166 8.72737 8.28167L9.7365 7.07421C10.1565 6.57839 10.8331 6.42673 11.4398 6.70089C11.9764 6.94588 12.2914 7.51753 12.2914 8.10667V8.67832C12.2914 8.92331 12.4197 9.05164 12.5831 9.05164L14.0064 9.05747C14.1522 9.05747 14.2863 8.94664 14.3038 8.80081C14.3213 8.59082 14.333 8.38083 14.333 8.165C14.333 4.63596 11.1889 1.83022 7.54907 2.4077C4.73167 2.85102 2.62591 5.38843 2.66674 8.24667Z"
      fill={fill2 || "#86EFAC"}
    />
  </svg>
);
