import { CartesianGrid, Bar, BarChart, ResponsiveContainer, Tooltip, YAxis } from "recharts";

const Graph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="80%">
      <BarChart
        width={500}
        height={250}
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <YAxis tickFormatter={(value) => Math.floor(value)} />
        <Tooltip />
        <Bar dataKey="clickThroughRate" fill="#5F0BDD" barSize={50} />
        <Bar dataKey="deliveryRate" fill="#2D28FF" barSize={50} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Graph;
