import { SingleContactIcon } from "assets/icons/dashboard/ContactIcon";
import { RewardIcon } from "assets/icons/dashboard/RewardIcon";
import { SendsIcon } from "assets/icons/dashboard/SendsIcon";
import { SurveyIcon } from "assets/icons/sidebar";
// import GreyButton from "components/Button/GreyButton";
import IconButton from "components/Button/IconButton";
// import Modal from "components/Modal";
import Tooltip from "components/Tooltip";
import { cn } from "helpers/classHelpers";
import useCredit from "hooks/useCredit";
import { formatAmount } from "lib/formatAmount";
// import { toTitleCaseg } from "lib/titleCase";
import React, { useEffect, useRef } from "react";
import { BsArrowUp } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import Typography from "utils/typography";

const BalanceModal = ({ toggleDropdown }) => {
  const { wsCredit } = useCredit();
  const modalRef = useRef(null);

  const data = [
    {
      id: 1,
      name: "Contacts",
      count: wsCredit?.contact,
      toolTip: "The total number of uploaded contacts and the limit associated with your account.",
      icon: SingleContactIcon,
    },
    {
      id: 2,
      name: "Sends",
      count: wsCredit?.sends,
      toolTip: "The number of messages or email campaigns sent from your account.",
      icon: SendsIcon,
    },

    {
      id: 3,
      name: "Research credits",
      count: `$ ${wsCredit?.research}`,
      toolTip: "The available research credits for conducting surveys and gathering insights.",
      icon: SurveyIcon,
    },
    {
      id: 4,
      name: "Rewards credits",
      count: `MC ${wsCredit?.incentives}`,
      toolTip: "Your reward credit balance for incentivising survey participants.",
      icon: RewardIcon,
    },
  ];

  useEffect(() => {
    // Function to handle click outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        toggleDropdown(); // Close the modal
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleDropdown]);

  return (
    <div
      className="absolute w-[500px] bg-white top-[3em] rounded-md border-[1px]  right-[20%] z-[10] p-4"
      ref={modalRef}
    >
      <div className="flex items-center justify-between">
        <Typography.MicroText className="">View your available balance and top-up with ease</Typography.MicroText>

        <div
          className="bg-gray-100 w-[20px] h-[20px] flex items-center justify-center rounded-full cursor-pointer"
          onClick={toggleDropdown}
        >
          <IoClose />
        </div>
      </div>

      <div className=" gap-4 my-[1.5em] bg-gray-50 rounded-md px-2">
        {data.map((el, i) => (
          <div className={cn("w-[100%] h-[50px]", i === data.length - 1 ? "" : "border-b-[1px]")} key={el.name}>
            <div className="w-[100%] h-[100%]  text-black p-3 flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="w-[25px] h-[25px]  flex items-center justify-center rounded">
                  <el.icon fill1="#95ADFF" fill2={el.id === 1 ? "#1D19AF" : "#1D19AF"} />
                </div>

                <div className="flex items-center space-x-2">
                  <Typography.MicroText className="">{el.name}</Typography.MicroText>

                  <Tooltip className="" text={el.toolTip} />
                </div>
              </div>

              <Typography.SubText className="font-bold">{formatAmount(el.count)}</Typography.SubText>
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between border-t-[1px] pt-4">
        {/* <GreyButton text="Cancel" /> */}

        <div></div>
        <IconButton
          text={<Typography.MicroText className="text-white">Top Up</Typography.MicroText>}
          onClick={() => toggleDropdown(7)}
          icon={<BsArrowUp />}
        />
      </div>
    </div>
  );
};

export default BalanceModal;
