import React, { useState } from "react";
import OnlineSurveyTab from "./OverviewTabs/OnlineSurveyTab";
import FieldInterviewTab from "./OverviewTabs/FieldInterviewTab";
import BrandTrackingTab from "./OverviewTabs/BrandTrackingTab";
import Typography from "utils/typography";
import BlueButton from "components/Button/BlueButton";
import researchGif from "assets/images/researchGif.png";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/Tooltip";

const tabs = [
  { id: 1, name: "Online surveys" },
  { id: 2, name: "Field interviews" },
  // { id: 3, name: "Brand tracking" },
];
const Home = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const handleTabChange = (e) => {
    setStep(e);
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return <OnlineSurveyTab />;
      case 2:
        return <FieldInterviewTab />;
      case 3:
        return <BrandTrackingTab />;
      default:
    }
  };

  return (
    <div className="">
      <div className="border-[1px] rounded-lg h-[240px] w-[100%] bg-white ">
        <div className="bg-contactBg bg-cover h-[100%] flex items-center justify-center ">
          <div className="w-[40%] space-y-4">
            <Typography.H3 className="font-semibold text-primary-800">Valuable insights on the go</Typography.H3>

            <Typography.SubText className="w-[60%]">
              Get fast and actionable insights from your target market or existing customers.
            </Typography.SubText>

            <BlueButton text="Get started" onClick={() => navigate("setup")} />
          </div>

          <div className="w-[40%] h-[100%]">
            <img src={researchGif} alt="researchGif" className="h-[100%]" />
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2 pt-5">
        <Typography.Text>Overview</Typography.Text>
        <Tooltip text="This shows a summary of the number of surveys sent, average completion and drop-off rates." />
      </div>

      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <Typography.SubText
              className={` pb-[4px] cursor-pointer ${
                step === el.id ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.id !== 4 ? () => handleTabChange(el.id) : null}
              key={el.id}
            >
              {el.name}
            </Typography.SubText>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
    </div>
  );
};

export default Home;
