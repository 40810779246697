import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Dropdown from "components/DropDown";
import SecondaryInput from "components/Inputs/SecondaryInput";
import { BranchingIcon } from "assets/icons/survey/BranchingIcon";
import ScreeningQuesIcon from "assets/icons/survey/ScreeningQuesIcon";
// import { frequencyData } from "data/constants/settingsConstant";
import Modal from "components/Modal";
import { trackEvent } from "config/mixpanel";
import Toast from "config/toast";
// import { surveyRecurrence } from "data/constants/settingsConstant";
import dayjs from "dayjs";
import useLoggedInUser from "hooks/useLoggedInUser";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { MdDelete, MdOutlineCancel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Toggle from "react-toggle";
import { LogicTypes } from "data/constants/surveyConstants";
import docIcon from "assets/images/docIcon.png";
import fieldIcon from "assets/images/fieldIcon.png";
import { ImageUploadIcon } from "assets/icons/icon";
import { surveyCategory } from "data/surveyCategory";
import { baseApiURL } from "helpers/getBaseUrl";
import axios from "axios";
import { BiTrash } from "react-icons/bi";
import Typography from "utils/typography";
import useBranchingStore from "store/branchingStore";
import { cn } from "helpers/classHelpers";
import { createSurvey } from "services/newSurveyService";

const SurveyDetailsModal = ({ handleOpen }) => {
  const { user } = useLoggedInUser();
  const navigate = useNavigate();
  const [screeningQuestionArray, setScreeningQuestionArray] = useState([
    {
      question: "",
      options: [],
    },
  ]);
  const [selectType, setType] = useState(0);
  // const plan = usePlan();
  const { id } = useParams();
  // const [frequency, setFrequency] = useState("");
  const [showDropNDrag, setShowDropNDrag] = useState(false);
  const [logicType, setLogicType] = useState("");
  const [surveyData, setSurveyData] = useState({
    surveyName: "",
    surveyCategory: "",
    recurringInterval: "",
    surveyType: "onlineSurvey",
    coverImage: "",
    timeOfTheDay: "",
    surveyDescription: "",
    allowScreeningQuestion: false,
    surveyInstruction: "",
    allowImage: false,
  });

  const type = [
    { name: "Online survey", value: "onlineSurvey", id: 0, icon: docIcon },
    { name: "Field interview", value: "fieldSurvey", id: 1, icon: fieldIcon },
  ];

  const handleSelectType = (id) => {
    setType(selectType !== id ? id : null);
    setSurveyData({
      ...surveyData,
      surveyType: type[selectType]?.value,
    });
  };
  // const handleFreq = (id) => setFrequency(frequency !== id ? id : null);

  /*************************** *****************************/

  const { mutate, isLoading: mutatingSurvey } = useMutation({
    mutationFn: createSurvey,
    onSuccess: (data) => {
      const id = data?.data?._id;
      navigate(selectType === 0 ? `/research/online-survey/${id}` : `/research/field-surveys/${id}`);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const createQuestion = () => {
    trackEvent("add_screening", {
      email: user?.data?.email,
      date: dayjs().format(),
      numberOfScreeningQuestions: screeningQuestionArray.length,
      surveyType: "Field survey",
    });
    setScreeningQuestionArray([
      ...screeningQuestionArray,
      {
        question: "",
        options: [],
      },
    ]);
  };

  // const handleScreeningQuestion = () => {
  //   setShowScreeningQuestion(!showScreeningQuestion);
  //   setScreeningQuestionArray([
  //     {
  //       question: "",
  //       options: [],
  //     },
  //   ]);

  //   setSurveyData({
  //     ...surveyData,
  //     allowScreeningQuestion: !surveyData.allowScreeningQuestion,
  //   });
  // };

  const createValueOptions = (index) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        options: [
          ...updatedValues[index]?.options,
          {
            value: "",
            answer: false,
          },
        ],
      };

      return updatedValues;
    });
  };

  const handleOptional = (index, buttonId, presentValue) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];

      updatedValues[index] = {
        ...updatedValues[index],
        options: updatedValues[index]?.options.map((option, i) =>
          i === buttonId
            ? {
                ...option,
                answer: !presentValue,
              }
            : option,
        ),
      };

      return updatedValues;
    });
  };

  const handleText = (e, index) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],

        [e.target.name]: e.target.value,
      };
      return updatedValues;
    });
  };

  const deleteValueOptions = (i, optionIndex) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];

      updatedValues[i] = {
        ...updatedValues[i],
        options: updatedValues[i]?.options?.filter((_, index) => index !== optionIndex),
      };

      return updatedValues;
    });
  };

  const handleOptionValues = (e, index, newIndex) => {
    setScreeningQuestionArray((prevValues) => {
      const updatedValues = [...prevValues];
      const updatedQuestion = { ...updatedValues[index] };

      updatedQuestion.options = [
        ...updatedQuestion.options,
        (updatedQuestion.options[newIndex].value = e.target.value),
      ];

      return updatedValues;
    });
  };

  useEffect(() => {
    if (surveyData.coverImage !== "" && !showDropNDrag) {
      setShowDropNDrag(true);
      setSurveyData({
        ...surveyData,
        allowImage: !surveyData.allowImage,
      });
    }
  }, [showDropNDrag, surveyData]);

  const handleDropNDrag = () => {
    setShowDropNDrag(!showDropNDrag);

    setSurveyData({
      ...surveyData,
      coverImage: "",
      allowImage: !surveyData.allowImage,
    });
  };

  // ===============================SURVEY LOGIC =============================
  const surveyLogic = [
    {
      name: "Screening question",
      description: "Add screening questions to your survey to filter out people who do not meet your target criteria.",
      icon: ScreeningQuesIcon,
      id: LogicTypes.SCREENING_QUESTION,
    },
    {
      name: "Branching question",
      description: "Route respondents to various sections within your survey based on their responses. ",
      icon: BranchingIcon,
      id: LogicTypes.BRANCHING_QUESTION,
    },
  ];

  const displaySurveyLogic = selectType !== 1 ? surveyLogic : surveyLogic?.slice(0, 1);

  const {
    handleBranchingOptionValues,
    deleteBranchingOptions,
    handleBranchingText,
    createBranchingOptionValues,
    branchingQuestion,
  } = useBranchingStore();

  // const [showScreeningQuestion, setShowScreeningQuestion] = useState(false);

  const handleLogicType = (value) => {
    if (logicType === value) {
      setLogicType("");
    } else {
      if (value === LogicTypes.BRANCHING_QUESTION) {
        setLogicType({
          ...logicType,
          isBranched: true,
        });
      } else {
        setLogicType({
          ...logicType,
          isBranched: false,
        });
      }
      setLogicType(value);
    }
  };

  // ==============================upload image ===============================

  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      console.error("Please select only one image");
      return;
    }

    try {
      const imageFile = new FormData();
      imageFile.append("file", e.target.files[0]);
      setUploading(true);

      const progress = {
        onUploadProgress: function (progressEvent) {
          let progressBar = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          setProgress(progressBar);
        },
      };

      const { data } = await axios.post(
        `${baseApiURL}/workspace-microservice/media/public-upload`,
        imageFile,
        progress,
      );

      setSurveyData((prevData) => ({
        ...prevData,
        coverImage: data.data.file,
        isImages: true,
      }));
    } catch (error) {
      Toast.error(error);
    } finally {
      setUploading(false);
    }
  };

  // useEffect(()=>{
  //   setSurveyData({
  //     ...surveyData,

  //   })

  // })

  const handleDeleteCover = () => {
    setSurveyData({
      ...surveyData,
      coverImage: "",
      isImages: false,
      show: false,
    });
  };

  /*************************** *****************************/

  const handleInput = (e) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (e, name) => {
    setSurveyData({
      ...surveyData,
      [name]: e,
    });
  };

  const deleteQuestion = (id) => {
    setScreeningQuestionArray((prevArray) => {
      const updatedArray = prevArray.filter((_, index) => index !== id);
      return updatedArray;
    });
  };

  const handleSave = () => {
    const userSurveyDetails = {
      surveyName: surveyData.surveyName,
      surveyCategory: surveyData.surveyCategory,
      // recurringProp: {
      //   recurringInterval: surveyData.recurringInterval,
      //   timeOfTheDay: surveyData.timeOfTheDay,
      // },
      coverImage: surveyData.coverImage,
      surveyDescription: surveyData.surveyDescription,
      screenQue: screeningQuestionArray,
    };
    mutate({ surveyId: id ?? null, payload: userSurveyDetails, surveyType: type[selectType]?.value });
    console.log(userSurveyDetails);
  };

  // if (mutatingSurvey) {
  //   return <PreLoader />;
  // }

  const disabled = surveyData.surveyName === "" || surveyData.surveyCategory === "" || mutatingSurvey;

  return (
    <Modal title={<p className="text-lg font-semibold ">Survey details</p>} onClose={handleOpen}>
      <div className="relative">
        <div className="w-[60vw] max-h-[80vh] px-5 overflow-y-scroll pr-4  ">
          <p className="text-sm text-neutral-500">Provide your survey details and add a screening question.</p>
          <div className="mt-7">
            <p className=" text-sm font-medium">Survey channel</p>

            <p className="text-xs text-neutral-600 pt-1">Choose the channel to share your survey</p>
            <div className="flex items-center space-x-5 w-[60%] pt-5">
              {type.map((el, i) => (
                <div
                  key={i}
                  className={`border rounded-[8px] cursor-pointer ${
                    selectType === el.id && "border-primary-600 bg-[#EBF1FF]"
                  } flex items-center space-x-2 px-4 py-3 w-full`}
                  onClick={() => handleSelectType(el.id)}
                >
                  <div
                    className={`w-[32px] h-[32px] p-2 ${
                      selectType === el.id ? "bg-white" : "bg-[#EBF1FF]"
                    } rounded-full flex items-center justify-center`}
                  >
                    <img src={el.icon} alt="" className="" />
                  </div>
                  <div>{el.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="my-[2rem]">
            <hr className=" bg-gray-50" />
          </div>
          <div className="flex justify-between items-center mt-[1em]">
            <div className="w-[48%]">
              <SecondaryInput
                text="Survey Name"
                placeholder="Use a name that captures the essence of your survey."
                onChange={handleInput}
                name="surveyName"
                type="text"
                value={surveyData.surveyName}
              />
            </div>

            <div className="w-[48%] -mt-2">
              <div className="flex space-x-2 items-center ">
                <p className="text-m font-[500]">Category</p>
              </div>
              <div className="mt-2">
                <Dropdown
                  tag={surveyData.surveyCategory !== "" ? surveyData.surveyCategory : "Select Category"}
                  options={surveyCategory}
                  onSelect={(el) => handleSelect(el.value, "surveyCategory")}
                  defaultValue={surveyData.surveyCategory}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between mt-[1.5em]">
              <p className="text-gray-700 text-sm font-[500] tracking-[-0.02em]">Description (Optional)</p>
            </div>

            {/* <p className="text-ss  text-neu-400">
          This will appear below your survey name and should give more information about your survey.
        </p> */}

            <div className="border-[1px] border-[#D0D5DD]  rounded-[5px] flex flex-col p-4 bg-[#FAFAFA] mt-1 h-[116px]">
              <textarea
                name="surveyDescription"
                id="surveyDescription"
                cols="30"
                rows="3"
                placeholder="Describe your survey"
                className="w-[97%] mr-4 bg-transparent outline-none text-s placeholder:text-s resize-none"
                onChange={handleInput}
                value={surveyData.surveyDescription}
              ></textarea>
            </div>
          </div>
          {selectType !== 1 ? null : (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between mt-[1.5em]">
                <p className="text-m font-[500]">Instructions (Optional)</p>
              </div>

              <p className="text-ss  text-neu-400">Add brief instructions for the Groundible agent</p>

              <div className="border-[1px] border-[#D0D5DD]  rounded-[5px] flex flex-col p-4 bg-[#FAFAFA] mt-1 h-[116px]">
                <textarea
                  name="surveyInstruction"
                  id="surveyInstruction"
                  cols="30"
                  rows="3"
                  placeholder="Add instructions"
                  className="w-[97%] mr-4 bg-transparent outline-none text-s placeholder:text-s resize-none"
                  onChange={handleInput}
                  value={surveyData.surveyInstruction}
                ></textarea>
              </div>
            </div>
          )}

          <div className="my-[2rem]">
            <hr className=" bg-gray-50" />
          </div>

          {/* {selectType !== 0 || hasProPlan === false ? null : (
            <div className="mt-6">
              <p className=" text-sm font-medium">frequency</p>

              <p className="text-xs font-light text-neutral-600">
                Share your survey once or over a period of time to gather more insights from your audience.
              </p>

              <div className="flex items-center space-x-5 mt-3">
                {freq.map((el, i) => (
                  <div
                    className="flex items-center space-x-2  cursor-pointer"
                    key={i}
                    onClick={() => handleFreq(el.id)}
                  >
                    <div
                      className={`rounded-full p-1 flex items-center justify-center border w-[20px] h-[20px] ${
                        frequency === el.id ? "border-blue-800" : ""
                      } `}
                    >
                      {frequency === el.id ? (
                        <div className={`rounded-full border p-1 bg-blue-800`}></div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <p className="text-sm">{el.name}</p>
                  </div>
                ))}
              </div>

              <div className="flex justify-between items-center mt-[1em]">
                <div className="w-[48%] -mt-2">
                  <div className="flex space-x-2 items-center ">
                    <p className="text-gray-700 text-sm font-[500] tracking-[-0.02em]">Repeat survey every</p>
                  </div>
                  <div className="mt-2">
                    <Dropdown
                      tag={surveyData.recurringInterval !== "" ? surveyData.recurringInterval : "Choose an option"}
                      options={surveyRecurrence}
                      onSelect={(el) => handleSelect(el.value, "recurringInterval")}
                      defaultValue={surveyData.recurringInterval}
                    />
                  </div>
                </div>

                <div className="w-[48%]">
                  <SecondaryInput
                    text="Time"
                    placeholder="Set time to send"
                    onChange={handleInput}
                    type="time"
                    name="timeOfTheDay"
                    value={surveyData.timeOfTheDay}
                  />
                </div>
              </div>
            </div>
          )} */}

          <div className="mt-3 flex flex-col gap-3 ">
            <div className="flex items-center space-x-3">
              <p className="-mt-1 text-s font-medium"> Add cover image</p>
              <label>
                <Toggle checked={showDropNDrag} icons={false} onChange={handleDropNDrag} className="toggle" />
              </label>
            </div>

            {showDropNDrag || surveyData.coverImage !== "" ? (
              <>
                {surveyData.coverImage === "" ? (
                  <>
                    {isUploading ? (
                      <div>
                        <div className="w-full bg-[#D7DDEA] rounded-full h-1.5">
                          <div
                            className={`bg-[#29085C] h-1.5 rounded-full  transition-width duration-300 ease-in-out`}
                            style={{ width: `${progress}` }}
                          ></div>
                        </div>
                        <p className="text-green text-s">Uploading Image... {progress}%</p>
                      </div>
                    ) : (
                      <>
                        <div className="border-dashed border-[#D0D5DD]-[1px] text-ss h-[100px] bg-[#FAFAFA]  w-full border flex justify-center items-center rounded-md text-[#737373] flex-col gap-1 relative">
                          <input
                            type="file"
                            className="w-[100%] h-[100%] absolute cursor-pointer top-0 left-0 opacity-0"
                            accept=".jpeg, .jpg, .png"
                            onChange={onSelectFile}
                          />
                          <ImageUploadIcon />
                          <div className="flex gap-1 justify-center items-center">
                            Drag and drop PNG or JPEG file or
                            <div className="text-primary-800 !bg-transparent underline">browse computer</div>
                          </div>
                          <p>1200px by 900px</p>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="w-[60%]">
                    <div className="border-[1px] rounded-md">
                      <img src={surveyData.coverImage} alt={"cover"} />
                    </div>

                    <div className="flex items-center gap-2 mt-2">
                      <button
                        onClick={handleDeleteCover}
                        className="rounded-[8px] h-[40px] text-black hover:bg-[#d3d3d3] bg-[#EFF2F6]"
                      >
                        <div className="flex justify-center items-center text-ss gap-2 hover:text-red-500 text-black px-6">
                          <BiTrash size={17} /> <p>Remove cover</p>
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>

          {/* <div className="pb-[3rem]">
            <div className="flex items-center space-x-3 mt-5">
              <p className=" text-sm font-medium">Set screening question</p>
              <Tooltip text="                    This helps get your survey to your exact target audience. Only respondents who qualify will participate in the survey" />
              <label>
                <Toggle
                  checked={showScreeningQuestion}
                  icons={false}
                  onChange={handleScreeningQuestion}
                  className="toggle mt-1"
                />
              </label>
            </div>
            <Typography.SubText className="mb-3">
              This helps get your survey to your exact target audience. Only respondents who pass will participate in
              the survey
            </Typography.SubText>

            {showScreeningQuestion || screeningQuestionArray.length > 1 ? (
              <div className=" gap-4 w-[100%]">
                <div className="">
                  <div className="flex w-[100%] gap-6 items-start">
                    {screeningQuestionArray?.map((_, index) => {
                      return (
                        <div className="border-[1px] p-3 rounded-md my-4 w-[50%]">
                          <SecondaryInput
                            placeholder="Type your screener question here"
                            value={screeningQuestionArray[index].question}
                            onChange={(e) => handleText(e, index)}
                            name="question"
                          />

                          <p className="text-xs text-gray-500">You can add a maximum of 5 options</p>

                          <div className="w-[100%]">
                            {screeningQuestionArray[index]?.options.map((_, i) => {
                              return (
                                <div className="flex items-center justify-between">
                                  <div className="flex space-x-3 my-3 w-[60%]">
                                    <p className="text-s">{i + 1}</p>
                                    <input
                                      type="text"
                                      placeholder="Add label Optional"
                                      className="border-b-[1px] outline-none bg-transparent text-s placeholder:text-s w-[100%]"
                                      value={screeningQuestionArray[index]?.options[i]?.value}
                                      name="value"
                                      onChange={(e) => handleOptionValues(e, index, i)}
                                    />
                                    <MdOutlineCancel
                                      className="text-[24px] text-grey-1 cursor-pointer"
                                      onClick={() => deleteValueOptions(index, i)}
                                    />
                                  </div>

                                  <div className="flex items-center space-x-2 ">
                                    <p className="text-[12px]">Set correct answer</p>
                                    <Toggle
                                      checked={screeningQuestionArray[index]?.options[i]?.answer}
                                      icons={false}
                                      onChange={() =>
                                        handleOptional(index, i, screeningQuestionArray[index]?.options[i]?.answer)
                                      }
                                      className="toggle"
                                    />
                                  </div>
                                </div>
                              );
                            })}

                            <div className="flex justify-between items-center">
                              {screeningQuestionArray[index]?.options.length >= 5 ? (
                                <p></p>
                              ) : (
                                <div
                                  className="flex space-x-2 items-center cursor-pointer my-[1em]"
                                  onClick={() => createValueOptions(index)}
                                >
                                  <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
                                  <p className="text-ss text-primary-800">Add Option</p>
                                </div>
                              )}
                              {screeningQuestionArray.length === 1 ? null : (
                                <MdDelete
                                  className="text-[24px] text-grey-1 cursor-pointer"
                                  onClick={() => deleteQuestion(index)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {screeningQuestionArray.length >= 2 ? null : (
                      <div className="space-y-2">
                        <div
                          className="flex space-x-2 text-gray-900 bg-gray-100 items-center cursor-pointer h-[35px] border-[1px] p-4 mt-4 rounded-md w-[150px] font-bold justify-center"
                          onClick={createQuestion}
                        >
                          <AiOutlinePlus />
                          <p className="text-ss">Add question</p>
                        </div>
                        <p className="text-xs text-gray-500">You can add only 2 screening questions.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {selectType !== 0 || hasProPlan === false ? null : (
              <div className="mt-5">
                <Typography.SubText className="font-semibold">Frequency</Typography.SubText>

                <Typography.SubText className="">
                  Share your survey once or over a period of time to gather more insights from your audience.
                </Typography.SubText>

                <div className="space-x-4 flex items-center my-2">
                  {frequencyData.map((el) => (
                    <div className="flex items-center space-x-2 cursor-pointer" key={el} onClick={() => handleFreq(el)}>
                      <div
                        className={`w-[15px] h-[15px] rounded-full border-[1px] flex items-center justify-center ${
                          frequency === el ? "border-primary-800" : "border-gray-600 "
                        }  `}
                      >
                        {frequency === el ? <div className="w-[8px] h-[8px] rounded-full bg-primary-800"></div> : null}
                      </div>
                      <Typography.SubText>{el}</Typography.SubText>
                    </div>
                  ))}
                </div>
                {frequency === "Recurring survey" ? (
                  <div className="flex justify-between items-center ">
                    <div className="w-[48%] -mt-2">
                      <div className="flex space-x-2 items-center ">
                        <Typography.SubText className="font-semibold">Repeat survey every</Typography.SubText>
                      </div>
                      <div className="mt-2">
                        <Dropdown
                          tag={surveyData.recurringInterval !== "" ? surveyData.recurringInterval : "Choose an option"}
                          options={surveyRecurrence}
                          onSelect={(el) => handleSelect(el.value, "recurringInterval")}
                          defaultValue={surveyData.recurringInterval}
                        />
                      </div>
                    </div>

                    <div className="w-[48%]">
                      <SecondaryInput
                        text="Time"
                        placeholder="Set time to send"
                        onChange={handleInput}
                        type="time"
                        name="timeOfTheDay"
                        value={surveyData.timeOfTheDay}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div> */}

          <div>
            <Typography.SubText className="font-semibold">Set survey logic (Optional)</Typography.SubText>

            <Typography.MicroText className="mb-3">
              Select either a screening or branching question to send respondents to based on their response. Learn more
              about logic features
            </Typography.MicroText>

            <div className="pb-[4rem]">
              <div className="flex items-center gap-[1em] pb-5">
                {displaySurveyLogic?.map((el) => {
                  return (
                    <div
                      className={cn(
                        "border-[1px] rounded-md p-4 w-[50%] space-y-2 cursor-pointer",
                        el.id === logicType ? "bg-primary-50 border-primary-800" : "bg-gray-100 border-gray-200",
                      )}
                      onClick={() => handleLogicType(el.id)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <el.icon
                            fill1={el.id === logicType ? "#95ADFF" : "#A3A3A3"}
                            fill2={el.id === logicType ? "#1D19AF" : "#404040"}
                          />

                          <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>
                        </div>

                        <div
                          className={cn(
                            "w-[15px] h-[15px] rounded-full border-[1px] flex items-center justify-center",
                            el.id === logicType ? "border-primary-800" : "border-gray-400",
                          )}
                        >
                          {el.id === logicType && (
                            <div className={cn("w-[10px] h-[10px] rounded-full border-[1px] bg-primary-800")}></div>
                          )}
                        </div>
                      </div>

                      <Typography.MicroText className="mb-3">{el.description}</Typography.MicroText>
                    </div>
                  );
                })}
              </div>
              <div>
                {logicType === LogicTypes.SCREENING_QUESTION ||
                (screeningQuestionArray && screeningQuestionArray.length > 1) ? (
                  <div className="w-[100%]">
                    <div className="flex w-[100%] gap-6 items-start">
                      {screeningQuestionArray?.map((_, index) => {
                        return (
                          <div className="border-[1px] p-3 rounded-md  w-[50%]" key={`screeningQuestionArray-${index}`}>
                            <SecondaryInput
                              placeholder="Type your screener question here"
                              value={screeningQuestionArray[index].question}
                              onChange={(e) => handleText(e, index)}
                              name="question"
                            />

                            <p className="text-xs text-gray-500">You can add a maximum of 5 options</p>

                            <div className="w-[100%]">
                              {screeningQuestionArray[index]?.options.map((_, i) => {
                                return (
                                  <div className="flex items-center justify-between">
                                    <div className="flex space-x-3 my-3 w-[50%]">
                                      <p className="text-s">{i + 1}</p>
                                      <input
                                        type="text"
                                        placeholder="Add label Optional"
                                        className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                                        value={screeningQuestionArray[index]?.options[i]?.value}
                                        name="value"
                                        onChange={(e) => handleOptionValues(e, index, i)}
                                      />
                                      <MdOutlineCancel
                                        className="text-[24px] text-grey-1 cursor-pointer"
                                        onClick={() => deleteValueOptions(index, i)}
                                      />
                                    </div>

                                    <div className="flex items-center space-x-3 ">
                                      <p className="text-xs">Set correct answer</p>
                                      <Toggle
                                        checked={screeningQuestionArray[index]?.options[i]?.answer}
                                        icons={false}
                                        onChange={() =>
                                          handleOptional(index, i, screeningQuestionArray[index]?.options[i]?.answer)
                                        }
                                        className="toggle"
                                      />
                                    </div>
                                  </div>
                                );
                              })}

                              <div className="flex justify-between items-center">
                                {screeningQuestionArray[index]?.options.length >= 5 ? (
                                  <p></p>
                                ) : (
                                  <div
                                    className="flex space-x-2 items-center cursor-pointer my-[1em]"
                                    onClick={() => createValueOptions(index)}
                                  >
                                    <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
                                    <p className="text-ss text-primary-800">Add Option</p>
                                  </div>
                                )}
                                {screeningQuestionArray.length === 1 ? null : (
                                  <MdDelete
                                    className="text-[24px] text-grey-1 cursor-pointer"
                                    onClick={() => deleteQuestion(index)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {screeningQuestionArray.length >= 2 ? null : (
                        <div className="space-y-2">
                          <div
                            className="flex space-x-2 text-gray-900 bg-gray-100 items-center cursor-pointer h-[35px] border-[1px] p-4 mt-4 rounded-md w-[150px] font-bold justify-center"
                            onClick={createQuestion}
                          >
                            <AiOutlinePlus />
                            <p className="text-ss">Add question</p>
                          </div>
                          <p className="text-xs text-gray-500">You can add only 2 screening questions.</p>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>

              {logicType === LogicTypes.BRANCHING_QUESTION && (
                <div>
                  <div className="border-[1px] p-3 rounded-md  w-[100%]">
                    <SecondaryInput
                      placeholder="Type your screener question here"
                      value={branchingQuestion.questionName}
                      onChange={(e) => handleBranchingText(e.target.value)}
                      name="question"
                    />

                    <p className="text-xs text-gray-500">
                      You can add a maximum of 3 options, your options determine the number of branches within your
                      survey.
                    </p>

                    <div className="w-[100%]">
                      {branchingQuestion?.options.map((_, i) => {
                        return (
                          <div className="flex items-center justify-between">
                            <div className="flex space-x-3 my-3 w-[80%]">
                              <p className="text-s">{i + 1}</p>
                              <input
                                type="text"
                                placeholder="Add label Optional"
                                className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                                value={branchingQuestion.options[i].option}
                                name="value"
                                onChange={(e) => handleBranchingOptionValues(i, e.target.value)}
                              />
                              <MdOutlineCancel
                                className="text-[24px] text-grey-1 cursor-pointer"
                                onClick={() => deleteBranchingOptions(i)}
                              />
                            </div>
                          </div>
                        );
                      })}

                      <div className="flex justify-between items-center">
                        {branchingQuestion.options.length >= 3 ? (
                          <p></p>
                        ) : (
                          <div
                            className="flex space-x-2 items-center cursor-pointer my-[1em]"
                            onClick={createBranchingOptionValues}
                          >
                            <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
                            <p className="text-ss text-primary-800">Add Option</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 px-5 bg-white w-full flex items-center justify-between border-t-[1px] h-[50px] mt-4 pt-4">
          <GreyButton text="Cancel" className="w-[120px]" onClick={handleOpen} />

          <BlueButton text="Save" className="w-[120px]" onClick={handleSave} disabled={disabled} />
        </div>
      </div>
    </Modal>
  );
};

export default SurveyDetailsModal;
