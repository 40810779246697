import React, { useState } from "react";
import GreaterThan from "assets/images/greaterThan.svg";
import Content from "./Content";
import Preview from "./Preview";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getACampaignReport } from "services/campaignService";
import Toast from "config/toast";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import SuccessPage from "./SuccessPage";
import PublishModal from "./PublishModal";
import { trackEvent } from "config/mixpanel";
import { reTargetCampaign } from "services/campaignService";
import { createTestCampaign } from "services/campaignService";
import { queryClient } from "config/gateway";

const tabs = [
  { name: "Retargeting Options", value: 1 },
  { name: "Preview", value: 2 },
  { name: "Publish", value: 3 },
];

const EmailRetargeting = () => {
  const [step, setStep] = useState(1);
  const { emailId } = useParams();
  const [open, setOpen] = useState(null);

  const { data: campDetails } = useQuery({
    queryKey: ["emailCampaignRetargetReport", emailId],
    queryFn: () => getACampaignReport(emailId),
    onError: (error) => {
      Toast.error(error);
    },
  });

  console.log(emailId);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const { mutate } = useMutation({
    mutationFn: reTargetCampaign,
    onSuccess: () => {
      Toast.success("Email retargeted successfully");
      handleModal(2);
      // navigate("/broadcasts/sms");
    },
    onError: () => {
      Toast.error("Email retargeting failed");
    },
  });

  const { mutate: testMutate } = useMutation({
    mutationFn: createTestCampaign,
    onSuccess: () => {
      Toast.success("Test created");
      queryClient.invalidateQueries({ queryKey: ["wsCredit"] });
    },
    onMutate: () => {
      Toast.success("Sending test campaign..");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleTest = () => {
    const userData = {
      campaign_channel: "email",
      content: JSON.stringify(campDetails?.campaignContent),
      sender_name: campDetails?.senderId,
    };
    const newUserData = {
      ...userData,
      campaign_subject: campDetails?.campaignSubject || "Mooyi Camp",
      sender_mail: campDetails?.senderEmail?.toString().replace(",", " "),
      tester: {
        email: emailData?.testContact,
      },
    };
    try {
      testMutate(newUserData);
    } catch (error) {}
  };

  const [emailData, setEmailData] = useState({
    content: "",
    sender_name: "",
    campaign_name: "",
    campaignObjective: "",
    recipientArray: [],
    personal: 0,
    audience: "",
  });

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return <Content data={emailData} setData={setEmailData} setStep={setStep} handleTest={handleTest} />;
      case 2:
        return <Preview campDetails={updatedInfo} />;
      default:
    }
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(step + 1);
    } else if (step === 2) {
      setOpen(1);
    }
  };

  const updatedInfo = {
    campaign_channel: "email",
    campaignCost: campDetails?.campaignCost,
    campaign_name: campDetails?.campaignName,
    sender_name: campDetails?.senderId,
    campaign_subject: campDetails?.campaignSubject,
    content: campDetails?.campaignContent,
    audience: emailData?.audience,
    campaign_id: emailId,
    campaignObjective: campDetails?.campaignObjective,
    totalReceiver: emailData?.totalReceiver,
  };

  const proceedSubmit = async () => {
    const user = {
      campaign_channel: "email",
      campaign_name: campDetails.campaignName,
      sender_name: campDetails.senderId,
      campaign_subject: campDetails?.campaignSubject,
      content: campDetails?.campaignContent,
      audience: emailData.audience,
      campaign_id: emailId,
      campaignObjective: campDetails.campaignObjective,
      totalReceiver: emailData.totalReceiver,
    };

    trackEvent("retarget_campaign", {
      email: user?.data?.email,
      campaignName: campDetails?.campaign_name,
      date: new Date(),
      isScheduledForLater: emailData.scheduled,
      campaignType: "Email",
    });

    mutate(user);
  };

  return (
    <div className="bg-white p-4">
      <div className="flex space-x-4 mt-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%]">
        {tabs.map((el, i) => {
          return (
            <div
              className="flex items-center justify-between w-[45%]"
              key={el.value}
              onClick={() => moveToStep(el.value)}
            >
              <div className="flex items-center">
                <p
                  className={`${
                    step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                  } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                >
                  {el.value}
                </p>
                <p
                  className={`px-2 cursor-pointer text-s ${
                    step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                  } `}
                >
                  {el.name}
                </p>
              </div>

              {el.value === 2 ? null : <img src={GreaterThan} alt="GreaterThan" />}
            </div>
          );
        })}
      </div>

      <div className={`mx-auto mt-[2em] flex justify-between gap-[3em]`}>
        <div className="w-[55%]">{displayStep()}</div>
      </div>

      <div className="border-t-[1px] bg-white h-[50px] w-[calc(100vw-236px)] absolute bottom-0 right-0 flex items-center justify-between px-6 z-[999]">
        <Link to="/broadcasts/email">
          <GreyButton text="Cancel" css="h-[35px]" />
        </Link>

        <BlueButton
          text={`${step === 3 ? "Publish" : "Next"}`}
          css="h-[35px]"
          onClick={handleNext}
          // disabled={disableButton}
        />
      </div>

      {open === 2 ? <SuccessPage /> : null}

      {open === 1 ? (
        <PublishModal handleSubmit={proceedSubmit} setData={setEmailData} data={emailData} handleModal={handleModal} />
      ) : null}
    </div>
  );
};

export default EmailRetargeting;
